import { SingleTenantId } from '@wemogy/reactbase-authorization'
import { useModalStore } from '@wemogy/reactbase';
import { useCallback } from 'react';
import useDataAccessStore from '../../../../dataLayer/stores/hooks/UseDataAccessStore';
import IMember from '../../../containers/authorizationTmp/member/interfaces/IMember';
import IMemberManagementProps from '../../../containers/authorizationTmp/memberManagementCreator/IMemberManagementProps';
import createMemberManagementComponent from '../../../containers/authorizationTmp/memberManagementCreator/MemberManagementComponentCreator';
import NotificationManager from '../../../wrappers/inAppFeedback/NotificationManager';
import RestApiServiceFactory from '../../../../dataLayer/api/rest/RestApiServiceFactory';

const CustomerMemberManagement: React.FC<IMemberManagementProps> = createMemberManagementComponent({
  pathIndex: 0,
  levelIndex: 1,
  hooks: {
    useMembersDeletedHandlerHook: () => {
      const modalStore = useModalStore();
      const { customerStore } = useDataAccessStore();

      return (resourceId: string, subjectIds: string[]): void => {
        // ToDo: fix useSubjectsAddedHandlerHook
        modalStore.openModal('loadingOverlay', 'Wird gelöscht');
        RestApiServiceFactory.memberApiService.deleteMemberAsync(
          'customer',
          SingleTenantId,
          resourceId,
          subjectIds
        )
          .then((): void => {
            const customer = customerStore.getItem(resourceId);
            if (!customer) {
              return;
            }

            subjectIds.map(subjectId => customer.deleteMember(subjectId));
            NotificationManager.success('Erfolgreich gelöscht');
          })
          .finally((): void => modalStore.closeModal());
      }
    },
    useSubjectsAddedHandlerHook: () => {
      const modalStore = useModalStore();
      const { customerStore } = useDataAccessStore();

      return (resourceId: string, subjectIds: string[], roleId: string): void => {
        // ToDo: fix useSubjectsAddedHandlerHook
        modalStore.openModal('loadingOverlay');
        RestApiServiceFactory.memberApiService.upsertMemberAsync(
          'customer',
          SingleTenantId,
          resourceId,
          roleId,
          subjectIds
        )
        .then((): void => {
          const customer = customerStore.getItem(resourceId);
          if (!customer) {
            return;
          }

          subjectIds.map(subjectId => customer.putMember(subjectId, roleId));

          NotificationManager.success('Erfolgreich geändert');
        })
          .finally((): void => modalStore.closeModal());
      }
    },
    useMembersResolverHook: (resourceId: string): IMember[] => {
      const {
        customerStore, userStore
      } = useDataAccessStore();

      const customer = customerStore.getItem(resourceId);

      if (!customer) {
        return [];
      }

      return customer.members.filter(x => userStore.getItem(x.userId) !== undefined).map((member): IMember => {
        const user = userStore.getItem(member.userId);

        if (!user) {
          throw new Error('');
        }

        return {
          id: user.id,
          email: user.email,
          username: user.fullname,
          roleId: member.roleId
        };
      }).filter(x => x !== undefined);
    },
    useMemberRoleIdChangedHandlerHook: () => {
      const {customerStore} = useDataAccessStore();
      const modalStore = useModalStore();

      const memberRoleIdChangedHandler = useCallback(
        (subjectId: string, resourceId: string, roleId: string): void => {
          modalStore.openModal(
            'loadingOverlay',
            'Zuweisung wird durchgeführt',
          );

          RestApiServiceFactory.memberApiService
            .upsertMemberAsync('customer', SingleTenantId, resourceId, roleId, [
              subjectId,
            ])
            .then((): void => {
              customerStore.getItem(resourceId)?.putMember(subjectId, roleId);

              NotificationManager.success(
                'Die Zuweisung wurde erfolgreich gespeichert',
              );
              console.log('changed customer role to', roleId);
            })
            .finally(() => modalStore.closeModal());
        },
        [customerStore],
      );

      return memberRoleIdChangedHandler;
    },

  }
});

export default CustomerMemberManagement;
