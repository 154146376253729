import React, {
  useCallback, useEffect, useState
} from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, useModalStore, Text
} from '@wemogy/reactbase';
import IArtScreenProps from './IArtScreenProps';
import QrScanner from '../../components/qrScanner/QrScanner';
import MainLayout from '../../layouts/mainLayout/MainLayout';
import ArtStatusUpdateModal from '../../modals/artStatusUpdate/ArtStatusUpdateModal';
import useDataAccessStore from '../../../dataLayer/stores/hooks/UseDataAccessStore';
import ArtQrCodeDetectedModal from '../../modals/artQrCodeDetected/ArtQrCodeDetectedModal';

const ArtScreen: React.FC<IArtScreenProps> = ({formId}) => {
  const { formStore } = useDataAccessStore();
  const modalStore = useModalStore();
  const [mode, setMode] = useState<'capture' | 'statusUpdate'>('capture')

  useEffect((): void => {
    const form = formStore.getItem(formId);
    if (!form) {
      modalStore.openModal('loadingOverlay', 'Daten werden vorbereitet');
      formStore.get(formId).then((): void => {
        const currentForm = formStore.getItem(formId);
        if (currentForm) {
          formStore.setActiveItem(currentForm);
        }
        modalStore.closeModal();
      });
      // customerStore.query();
      return;
    }
    formStore.setActiveItem(form);
  }, [formId]);

  const handleQrCodeScanned = useCallback((qrCodePayload: string): void => {
    modalStore.openModal(mode === 'capture' ? 'artQrCodeDetected' : 'artStatusUpdate', qrCodePayload);
  }, [mode]);

  const handleCapturePress = useCallback((): void => {
    setMode('capture');
  }, []);

  const handleStatusUpdatePress = useCallback((): void => {
    setMode('statusUpdate');
    // modalStore.openModal('artStatusUpdate', 'AAA_ID4;Hauptstraße;Berlin;14.05.1996;Confidential');
  }, []);

  const { activeItem } = formStore;

  if (!activeItem) {
    return null;
  }

  if(!activeItem.artSettings){
    return (
      <StackLayout backgroundColor="danger" width100 hCenter vCenter>
        <Text lightTitle>ART Feature für diese Datenbank ist deaktiviert</Text>
      </StackLayout>
);
  }

  if(!activeItem.artSettings.scannerQrCodeSourceId) {
    return (
      <StackLayout backgroundColor="danger" width100 hCenter vCenter>
        <Text lightTitle>Es ist keine Qr Code Quelle für in App Scanning gewählt</Text>
      </StackLayout>
);
  }

  return (
    <MainLayout>
      <StackLayout stretch>
        <StackLayout horizontal>
          <StackLayout hCenter vCenter stretch height={5} onPress={handleCapturePress} backgroundColor={mode === 'capture' ? 'primary' : 'grey500'} borderRadiusBottomLeft={20} borderRadiusTopLeft={20}>
            <Text>Erfassen</Text>
          </StackLayout>
          <StackLayout hCenter vCenter stretch height={5} onPress={handleStatusUpdatePress} backgroundColor={mode === 'statusUpdate' ? 'primary' : 'grey500'} borderRadiusBottomRight={20} borderRadiusTopRight={20}>
            <Text>Status updaten</Text>
          </StackLayout>
        </StackLayout>
        <StackLayout stretch hCenter paddingTopBottom={2}>
          <QrScanner onQrCodeScanned={handleQrCodeScanned} />
        </StackLayout>
      </StackLayout>
      <ArtQrCodeDetectedModal />
      <ArtStatusUpdateModal />
    </MainLayout>
);
};

export default observer(ArtScreen);
