import { Instance } from 'mobx-state-tree';
import { enumerationIntValuesType } from '@wemogy/reactbase';

enum DataType {
  Int = 1,
  String = 2,
  Double = 4,
  Boolean = 8,
  Date = 16,
  DateTime = 32,
  StringList = 64,
  Address = 128
}

export const DataTypeType = enumerationIntValuesType(DataType);

export default DataType;

export type IDataType = Instance<typeof DataTypeType>;
