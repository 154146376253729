import {  Instance } from 'mobx-state-tree';
import { enumerationIntValuesType } from '@wemogy/reactbase';

enum FormSection {
  Main = 0,
  Index = 1,
  Keywording = 2
}

export default FormSection;

export const FormSectionType = enumerationIntValuesType(FormSection);

export type IFormSection = Instance<typeof FormSectionType>;
