import React from 'react';
import { observer } from 'mobx-react';
import { StackLayout } from '@wemogy/reactbase';
import IWorkingPackageItemListProps from './IWorkingPackageItemListProps';
import WorkingPackageItem from '../../components/workingPackageItem/WorkingPackageItem';

const WorkingPackageItemList: React.FC<IWorkingPackageItemListProps> = ({
  workingPackages, onWorkingPackageRemove
}) => {

  return (
    <StackLayout>
      {
        workingPackages.map(workingPackage => (
          <WorkingPackageItem
            key={workingPackage.id}
            workingPackage={workingPackage}
            onRemove={(): void => {
              onWorkingPackageRemove(workingPackage);
            }}
          />
        ))
      }
    </StackLayout>
  );
};

export default observer(WorkingPackageItemList);
