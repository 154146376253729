import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';

const ControlDataSource =  types.model({
  id: types.string,
  sourceName: types.optional(types.string, '')
})
  .named('ControlDataSource')
  .actions(self => ({
    setSourceName(sourceName: string): void{
      self.sourceName = sourceName;
    }
  }));
export default ControlDataSource;

export interface IControlDataSource extends Instance<typeof ControlDataSource>{}
export interface IControlDataSourceSnapshotIn extends SnapshotIn<typeof ControlDataSource>{}
export interface IControlDataSourceSnapshotOut extends SnapshotOut<typeof ControlDataSource>{}
