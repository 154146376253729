import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import {
  Dropdown, DropdownItemProps, DropdownProps
} from 'semantic-ui-react';
import ISubjectSearchProps from './ISubjectSearchProps';

const SubjectSearch: React.FC<ISubjectSearchProps> = ({
subjects, selectedSubjects, onSubjectsSelectionChanged
}) => {
  const subjectOptions = useMemo(() => {
    return subjects.map(subject => ({
      key: subject.id,
      text: subject.username,
      value: subject.id,
      image: {
        avatar: true, src: '/img/avatar-circle.png'
      }
    }) as DropdownItemProps)
  }, [subjects]);

  const handleChange = useCallback((_event: any, data: DropdownProps): void => {
    onSubjectsSelectionChanged(data.value as string[]);
  }, [onSubjectsSelectionChanged]);

    return (
      <Dropdown
        placeholder='Nutzer suchen...'
        fluid
        multiple
        search
        selection
        options={subjectOptions}
        value={selectedSubjects}
        onChange={handleChange}
      />
    )
}

export default observer(SubjectSearch);
