import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Button, isEmptyOrWhiteSpace, StackLayout
} from '@wemogy/reactbase';
import IMarkerCodeFormProps from './IMarkerCodeFormProps';
import Checkbox from '../../../../components/checkbox/Checkbox';
import AnnotatedTextInput from '../../containers/formElements/annotatedTextInput/AnnotatedTextInput';
import NotificationManager from '../../../inAppFeedback/NotificationManager';
import MarkerCode from '../../dataLayer/models/MarkerCode';

const MarkerCodeForm: React.FC<IMarkerCodeFormProps> = ({
  markerCode, onCreated, onUpdated
}) => {
  const [id, setId] = useState('');
  const [name, setName] = useState(markerCode?.name || '');
  const [hint, setHint] = useState(markerCode?.hint || '');
  const [showIdInput, setShowIdInput] = useState(false);

  return (
    <StackLayout>
      {markerCode ? null : (
        <>
          <Checkbox boxStyle label="ID manuell festlegen (Nur in Ausnahmen verwenden!)" checked={showIdInput} onChange={setShowIdInput} />
          {showIdInput ? <AnnotatedTextInput marginBottom placeholder="GUID" description="ID im GUID Format" onChange={setId} value={id} /> : null}
        </>
      )}

      <AnnotatedTextInput marginBottom placeholder="Name" description="Name" onChange={setName} value={name} />
      <AnnotatedTextInput
        marginBottom
        placeholder="Kennzeichen"
        description="Kennzeichen (maximal 2 Zeichen)"
        onChange={(val): void => {
          if (val.length > 2) {
            return;
          }
          setHint(val);
        }}
        value={hint}
      />
      <StackLayout hEnd>
        <Button onPress={(): void => {
          if (markerCode) {
            markerCode.beginEditing();
            markerCode.setName(name);
            markerCode.setHint(hint);
            const patch = markerCode.commitChanges();
            onUpdated(markerCode, patch);
          } else {
            if (isEmptyOrWhiteSpace(hint)) {
              NotificationManager.error('Bitte geben Sie ein Kennzeichen ein');
              return;
            }
            onCreated(MarkerCode.create({
              name, hint, id: isEmptyOrWhiteSpace(id) ? undefined : id
            }));
          }
          setName('');
          setHint('');
          setId('');
          setShowIdInput(false);
        }}
        >
          {markerCode ? 'Speichern' : 'Hinzufügen'}

        </Button>
      </StackLayout>
    </StackLayout>
  );
};

export default observer(MarkerCodeForm);
