import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, Modal, useModalStore
} from '@wemogy/reactbase';
import IExportKeywordingModalProps from './IExportKeywordingModalProps';
import OnlyTheseWorkingPackagesSelector from '../../wrappers/formBuilder/containers/onlyTheseWorkingPackagesSelector/OnlyTheseWorkingPackagesSelector';
import useDataAccessStore from '../../../dataLayer/stores/hooks/UseDataAccessStore';
import RestApiServiceFactory from '../../../dataLayer/api/rest/RestApiServiceFactory';
import useExportHandler from '../../wrappers/formBuilder/hooks/UseExportHandler';

const ExportKeywordingModal: React.FC<IExportKeywordingModalProps> = () => {
  const { formStore } = useDataAccessStore();
  const modalStore = useModalStore();
  const [onlyExportFromTheseWorkingPackages, setOnlyExportFromTheseWorkingPackages] = useState<string[] | undefined>(undefined);

  const { activeItem } = formStore;
  const {handleExportResult} = useExportHandler();

  if (!activeItem) {
    return null;
  }


  return (
    <Modal modalKey="exportKeywording" icon="getApp" title="Verschlagwortung exportieren" large>
      <StackLayout>
        <OnlyTheseWorkingPackagesSelector
          form={activeItem}
          onlyTheseWorkingPackageIds={onlyExportFromTheseWorkingPackages}
          onOnlyTheseWorkingPackageIdsChanged={setOnlyExportFromTheseWorkingPackages}
        />
        <StackLayout hEnd marginTop={4}>
          <Button onPress={(): void => {
            const promise = modalStore.activeParameters
              ? RestApiServiceFactory.getDataItemService(activeItem.id).getKeywordingImpExport(onlyExportFromTheseWorkingPackages)
              : RestApiServiceFactory.getDataItemService(activeItem.id).getKeywordingCsvExport(onlyExportFromTheseWorkingPackages);

            handleExportResult(promise, 'Verschlagwortung wird exportiert.', `${activeItem?.name}_Verschlagwortung`);
          }}
          >
            Exportieren

          </Button>
        </StackLayout>
      </StackLayout>
    </Modal>
  );
};

export default observer(ExportKeywordingModal);
