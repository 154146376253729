import React from 'react';
import { observer } from 'mobx-react';
import {
StackLayout, Button, Text, Modal, useModalStore
} from '@wemogy/reactbase';
import IManageNavigationConfigurationModalProps from './IManageNavigationConfigurationModalProps';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import AnnotatedFormView from '../../containers/formElements/annotatedFormView/AnnotatedFormView';
import NavigationConfigurationItem from '../../components/navigationConfigurationItem/NavigationConfigurationItem';
import RestApiServiceFactory from '../../../../../dataLayer/api/rest/RestApiServiceFactory';
import NotificationManager from '../../../inAppFeedback/NotificationManager';

const ManageNavigationConfigurationModal: React.FC<IManageNavigationConfigurationModalProps> = () => {
  const { formStore } = useDataAccessStore();
  const modalStore = useModalStore();

  const { activeItem } = formStore;
  if(!activeItem){
    return null;
  }


  const options = activeItem.allControlsDestinationNamesUnique.map(x => ({
    label: x,
    value: x
  }));

  return (
    <Modal modalKey="manageNavigationConfiguration" icon="settings" title="Navigation verwalten" large>
      <StackLayout minHeight={{custom: '50vh'}}>
        <AnnotatedFormView description="Navigationsspalten" marginBottom>
          <StackLayout>
            {
              activeItem.navigationConfiguration.columns.map((navigationConfiguration, index) => {

                return (
                  <NavigationConfigurationItem
                    key={`column_${index}`}
                    navigationConfigurationColumn={navigationConfiguration}
                    options={options}
                    onRemove={(): void => {
                      activeItem.navigationConfiguration.removeColum(index);
                    }}
                  />
                );
              })
            }
            <Button onPress={(): void => {
              formStore.activeItem?.navigationConfiguration.addColumn();
            }}
            >
              Spalte hinzufügen
            </Button>
          </StackLayout>
        </AnnotatedFormView>
        <StackLayout backgroundColor="green200" padding borderRadius marginTop={5}>
          <Text marginBottom>Wenn Sie neue Spalten hinzufügen oder die Eigenschaft einer Spalte ändern, ist ggf. eine neue Initalisierung erforderlich. Wir empfehlen diese nicht im Tagesgeschäft anzustoßen (über Nacht im Optimalfall)</Text>
          <Button onPress={(): void => {
            modalStore.closeModal();
            RestApiServiceFactory.getDataItemService(activeItem.id).updateNavigationPropertiesOfDataItems()
              .then(x => {
                if (x.ok) {
                  NotificationManager.success('Initialisierung wurde erfolgreich geplant. Dies kann mehrere Stunden in Anspruch nehmen.')
                }
                else {
                  NotificationManager.error(`Beim Planen ist ein Fehler aufgetreten: ${x.status}`)
                }
              })
            }}
          >
            Navigationsspalten neu initialisieren
          </Button>
        </StackLayout>
      </StackLayout>
    </Modal>
  );
};

export default observer(ManageNavigationConfigurationModal);
