enum WorkingPackageRole{
  CanAssignLocalAttachments = 1,
  CanAssignAttachmentStoreAttachments = 2,
  CanUploadToAttachmentStore = 3,
  CanIndexData = 4,
  CanCollectData = 5,
  CanPerformQualityAssurance = 6,
  CanPerformSampling = 7,
  CanQuery = 8,
  CanCreateDataItems = 9,
  CanDuplicateDataItems = 10,
  CanDeleteDataItems = 11,
  CanAccessStatistics = 12,
  CanDeleteAssignedAttachments = 13,
  CanMoveAssignedAttachments = 14,
  CanCopyAssignedAttachments = 15,
  CanExportIndex = 16,
  CanExportComments = 17,
  CanExportDataItems = 18,
  CanExportDataItemsWithFlags = 19,
  CanCreateComments = 20,
  CanAnswerComments = 21,
  CanResolveComments = 22,
  CanRead = 23,
  CanDownloadAttachments = 24,
}

export default WorkingPackageRole;
