import React from 'react';
import { observer } from 'mobx-react';
import './ScrollContainer.css';
import {StackLayout} from '@wemogy/reactbase';
import IScrollContainerProps from './IScrollContainerProps';

const ScrollContainer: React.FC<IScrollContainerProps> = ({
  children, stretch, horizontal, hCenter, hEnd, width, height, maxHeight, className, paddingRight, customMaxHeight, paddingTop // backgroundImageUri, backgroundImageResizeMode, padding, paddingTop, wrap, spaceBetween, customHeight, customMaxHeight, width100, fullscreen, borderRadius, border, borderColor, backgroundColor
}) => {

  return (
    <StackLayout
      customStyle={{overflow: 'scroll'}}
      stretch={stretch}
      horizontal={horizontal}
      hCenter={hCenter}
      hEnd={hEnd}
      width={width}
      height={height}
      maxHeight={customMaxHeight === undefined ? maxHeight : {custom: customMaxHeight}}
      paddingRight={paddingRight}
      className={`scrollContainer ${className}`}
      paddingTop={paddingTop}
    >
      {children}
    </StackLayout>
  );

  /*
  return (
    <SimpleBar
      className={`${fullscreen ? 'scrollContainerFullscreen' : ''} ${horizontal ? 'scrollContainerHorizontal' : 'scrollContainerVertical'} ${hEnd ? 'hEndScroll' : ''} ${className}`}
      //  options={{scrollingThreshold:}}
      style={{
        display: 'flex',
        width: (width100 ? '100%' :  width ? (typeof width === 'boolean' ? 1 :width) * 8 : undefined),
        backgroundSize: backgroundImageResizeMode,
        background: backgroundImageUri ? `url("${backgroundImageUri}")` : undefined,
        flex: stretch ? 1 : undefined,
        flexDirection: horizontal ? 'row' : 'column',
        flexWrap: wrap ? 'wrap' : undefined,
        justifyContent: spaceBetween ? 'space-between' : hCenter ? 'center' : hEnd ? 'flex-end' : undefined,
        height: customHeight || (height ? (typeof height === 'boolean' ? 1 :height) * 8 : undefined),
        maxHeight: customMaxHeight || (maxHeight ? (typeof maxHeight === 'boolean' ? 1 : maxHeight) * 8 : undefined),
        padding: (padding ? (typeof padding === 'boolean' ? 1 : padding) * 8 : undefined),
        paddingTop: (paddingTop ? (typeof paddingTop === 'boolean' ? 1 : paddingTop) * 8 : undefined),
        borderRadius: borderRadius ? 8 : undefined,
        border: border ? 2 : 0,
        borderColor,
        backgroundColor
      }}
    >
      {children}
    </SimpleBar>
  ); */
};

export default observer(ScrollContainer);
