import { Instance } from 'mobx-state-tree';
import { enumerationIntValuesType } from '@wemogy/reactbase';

enum DataSourceType {
  Csv = 1
}

export default DataSourceType;

export const DataSourceTypeType = enumerationIntValuesType(DataSourceType);

export type IDataSourceType = Instance<typeof DataSourceTypeType>;
