import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, TextInput, Button
} from '@wemogy/reactbase';
import IArtQrCodeMappingEditorProps from './IArtQrCodeMappingEditorProps';
import AnnotatedFormView from '../formElements/annotatedFormView/AnnotatedFormView';
import Dropdown from '../../../../components/dropdown/Dropdown';

const ArtQrCodeMappingEditor: React.FC<IArtQrCodeMappingEditorProps> = ({
  pdfMatchingConfig, qrCodeSourceDropdownOptions, destinationNames
}) => {
  const [selectedDestinationName, setSelectedDestinationName] = React.useState<string | undefined>(undefined);
  useEffect((): void => {
    if(!pdfMatchingConfig.hasQrCodeMappings && qrCodeSourceDropdownOptions.length > 0){
      pdfMatchingConfig.addQrCodeMapping(qrCodeSourceDropdownOptions[0].value);
    }
  }, [pdfMatchingConfig, qrCodeSourceDropdownOptions]);
  const handleAddQrCodeMappingPress = useCallback((): void => {
    if(!selectedDestinationName){
      return;
    }
    pdfMatchingConfig.addQrCodeMapping(selectedDestinationName);
  }, [pdfMatchingConfig, selectedDestinationName]);

  return (
    <StackLayout paddingBottom={6}>
      {pdfMatchingConfig.qrCodeMappings.map((qrCodeMapping) => (
        <StackLayout key={qrCodeMapping.destinationName}>
          <StackLayout horizontal>
            <StackLayout stretch marginRight>
              <AnnotatedFormView description="Zielname im DigitTool" marginBottom>
                <Dropdown
                  light
                  selectedOption={destinationNames.find(x => x.value === qrCodeMapping.destinationName)}
                  options={destinationNames}
                  onOptionSelected={(option): void => {
                    qrCodeMapping.setDestinationName(option.value);
                  }}
                />
              </AnnotatedFormView>
            </StackLayout>
            <StackLayout stretch>
              <AnnotatedFormView description="Werte Index" marginBottom>
                <TextInput
                  numeric
                  placeholder="Der Index des Wertes (0 für den ersten Wert)"
                  value={qrCodeMapping.valueIndex.toString()}
                  onChange={(value): void => {
                  qrCodeMapping.setValueIndex(parseInt(value));
                }}
                />
              </AnnotatedFormView>
            </StackLayout>
          </StackLayout>
        </StackLayout>
      ))}
      <StackLayout horizontal>
        <StackLayout stretch marginRight>
          <Dropdown
            light
            selectedOption={destinationNames.find(x => x.value === selectedDestinationName)}
            options={destinationNames}
            onOptionSelected={(option): void => {
              setSelectedDestinationName(option.value);
            }}
          />
        </StackLayout>
        <Button onPress={handleAddQrCodeMappingPress}>Zuordnung hinzufügen</Button>
      </StackLayout>
    </StackLayout>
);
}
export default observer(ArtQrCodeMappingEditor);
