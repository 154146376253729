import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, Modal, useModalStore
} from '@wemogy/reactbase';
import IImportDataModalProps from './IImportDataModalProps';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import AnnotatedFormView from '../../containers/formElements/annotatedFormView/AnnotatedFormView';
import CheckGroup from '../../../../components/checkGroup/CheckGroup';
import KeyValuePair from '../../dataLayer/models/general/KeyValuePair';
import RestApiServiceFactory from '../../../../../dataLayer/api/rest/RestApiServiceFactory';
import NotificationManager from '../../../inAppFeedback/NotificationManager';
import RealtimeService from '../../../../../dataLayer/services/RealtimeService';

const ImportDataModal: React.FC<IImportDataModalProps> = () => {
  const {formStore} = useDataAccessStore();
  const modalStore = useModalStore();
  const [dataSources, setDataSources] = useState<string[]>([]);


  const { activeItem } = formStore;
  if(!activeItem){
    return null;
  }

  const canImport = dataSources.length > 0;

  return (
    <Modal modalKey="importData" icon="cloudUpload" title="Daten importieren" large>
      <StackLayout>
        <AnnotatedFormView description="Datenquelle" marginBottom>
          <StackLayout horizontal vCenter>
            <CheckGroup
              onSelectedOptionsChange={(selectedOptions): void => {
                setDataSources(selectedOptions);
              }}
              selectedOptions={dataSources}
              options={activeItem.dataSources.map(x => new KeyValuePair(x.name, x.id))}
            />
          </StackLayout>
        </AnnotatedFormView>
        <AnnotatedFormView description="Datendatei" marginBottom>
          <form id="importDataModalForm">
            <input accept=".csv" name="file" type="file" />
          </form>
        </AnnotatedFormView>
        <StackLayout hEnd marginTop height={4}>
          {
            canImport ? (
              <Button onPress={(): void => {

                const form: any = document.getElementById('importDataModalForm');
                if (!form) {
                  return;
                }
                const fd = new FormData(form);

                (async (): Promise<void> => {
                  try {
                    const realtimeConnectionId = RealtimeService.connectionId;
                    if (!realtimeConnectionId) {
                      NotificationManager.error('Es besteht keine Verbindung zur Echtzeitverbindung. Bitte laden Sie die Seite neu');
                      return;
                    }
                    modalStore.openModal('loadingOverlay', 'Importiere die Daten');
                    const response = await RestApiServiceFactory.importDataService.importCsv(realtimeConnectionId, activeItem.id, dataSources, fd);
                    if (response.ok) {
                      NotificationManager.success('Import erfolgreich geplant.');
                    }
                    else {
                      NotificationManager.error(`Beim Import ist etwas schief gelaufen. Statuscode: ${response.status}`);
                      modalStore.closeModal();
                    }
                  } catch {
                    NotificationManager.error('Beim Import ist etwas schief gelaufen.');
                    modalStore.closeModal();
                  }
                })();

              }}
              >
                Importieren
              </Button>
            ) : null
          }
        </StackLayout>

      </StackLayout>
    </Modal>
  );
};

export default observer(ImportDataModal);
