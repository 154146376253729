import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, Modal, useModalStore, TextInput
} from '@wemogy/reactbase';
import IExportDataModalProps from './IExportDataModalProps';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import RestApiServiceFactory from '../../../../../dataLayer/api/rest/RestApiServiceFactory';
import NotificationManager from '../../../inAppFeedback/NotificationManager';
import RealtimeService from '../../../../../dataLayer/services/RealtimeService';
import OnlyTheseWorkingPackagesSelector from '../../containers/onlyTheseWorkingPackagesSelector/OnlyTheseWorkingPackagesSelector';
import OnlyTheseDataSourcesSelector from '../../containers/onlyTheseDataSourcesSelector/OnlyTheseDataSourcesSelector';
import Checkbox from '../../../../components/checkbox/Checkbox';
import AnnotatedFormView from '../../containers/formElements/annotatedFormView/AnnotatedFormView';
import IExportDataModalParameters from './IExportDataModalParameters';



const ExportDataModal: React.FC<IExportDataModalProps> = () => {
  const {formStore} = useDataAccessStore();
  const modalStore = useModalStore();
  const [onlyExportFromTheseWorkingPackages, setOnlyExportFromTheseWorkingPackages] = useState<string[] | undefined>(undefined);
  const [onlyExportFromTheseDataSources, setOnlyExportFromTheseDataSources] = useState<string[] | undefined>(undefined);
  const [onlyExportOriginalAttachments, setOnlyExportOriginalAttachments] = useState(false);
  const [onlyExportGeneratedAttachments, setOnlyExportGeneratedAttachments] = useState(false);
  const [attachmentSuffix, setAttachmentSuffix] = useState<string>('');

  const { activeItem } = formStore;
  if(!activeItem){
    return null;
  }

  const {
    withAttachmentsAndKeywording,
    onlyTheseDataItems
} = modalStore.getActiveParameters<IExportDataModalParameters>('exportData') || {};

  return (
    <Modal
      modalKey="exportData"
      icon="getApp"
      title="Daten exportieren"
      large
      onOpened={(): void => {
        setAttachmentSuffix('')
      }}
    >
      <StackLayout>
        <AnnotatedFormView description="Anhänge einschränken" marginBottom>
          <StackLayout>
            <Checkbox boxStyle label="Nur originale Anhänge" checked={onlyExportOriginalAttachments} onChange={setOnlyExportOriginalAttachments} />
            <Checkbox boxStyle label="Nur optimierte Anhänge" checked={onlyExportGeneratedAttachments} onChange={setOnlyExportGeneratedAttachments} />
            {
              onlyExportGeneratedAttachments ? (<TextInput marginTop placeholder="Datei-Suffix" value={attachmentSuffix} onChange={setAttachmentSuffix} />) : null
            }
          </StackLayout>
        </AnnotatedFormView>
        {
          onlyTheseDataItems ? null : (
            <>
              <OnlyTheseDataSourcesSelector form={activeItem} onlyTheseDataSourceIds={onlyExportFromTheseDataSources} onOnlyTheseDataSourceIdsChanged={setOnlyExportFromTheseDataSources} />
              <OnlyTheseWorkingPackagesSelector form={activeItem} onlyTheseWorkingPackageIds={onlyExportFromTheseWorkingPackages} onOnlyTheseWorkingPackageIdsChanged={setOnlyExportFromTheseWorkingPackages} />
            </>
          )
        }

        <StackLayout hEnd marginTop height={4}>
          <Button onPress={(): void => {
            (async (): Promise<void> => {
              try {
                if (!RealtimeService.connectionId) {
                  NotificationManager.error('Es besteht keine Verbindung zum Echtzeit Service, bitte laden Sie die Seite neu.');
                  return;
                }
                modalStore.openModal('loadingOverlay', 'Die Daten werden exportiert und heruntergeladen, einen Moment bitte.');

                if (withAttachmentsAndKeywording) {
                  await RestApiServiceFactory.getDataItemService(activeItem.id)
                    .getCsvExportWithKeywordingAndAttachments(
                      RealtimeService.connectionId,
                      onlyExportFromTheseDataSources,
                      onlyExportFromTheseWorkingPackages,
                      onlyTheseDataItems,
                      false,
                      onlyExportOriginalAttachments,
                      onlyExportGeneratedAttachments,
                      attachmentSuffix);
                } else {
                  await RestApiServiceFactory.getDataItemService(activeItem.id)
                    .getCsvExport(
                      RealtimeService.connectionId,
                      onlyExportFromTheseDataSources,
                      onlyExportFromTheseWorkingPackages,
                      onlyTheseDataItems);
                }
              } catch {
                NotificationManager.error('Beim Export ist etwas schief gelaufen. ');
                modalStore.closeModal();
              }
            })();

          }}
          >
            Exportieren
          </Button>
        </StackLayout>

      </StackLayout>
    </Modal>
  );
};

export default observer(ExportDataModal);
