import { types } from 'mobx-state-tree';

const FormDesignerStore = types.model({activeToolboxElementType: types.maybe(types.number)})
  .named('FormDesignerStore')
  .views(self => ({
    get isDragging(): boolean{
      return self.activeToolboxElementType !== undefined;
    },
    anyOfToolboxElementTypesIsActive(toolboxElementTypes: number[]): boolean{
      if(!self.activeToolboxElementType){
        return false;
      }
      return toolboxElementTypes.find(x => x === self.activeToolboxElementType) !== undefined;
    }
  }))
  .actions(self => ({
    setActiveToolboxElementType(activeToolboxElementType: number): void{
      self.activeToolboxElementType = activeToolboxElementType;
    },
    unsetActiveToolboxElementType(): void{
      self.activeToolboxElementType = undefined;
    }
  }));

export default FormDesignerStore;
