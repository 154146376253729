import {
types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';

const FieldStatistic = types.model({
  name: types.optional(types.string, ''),
  numberOfDataItemsWhereDataCollectedAndFirstQualityAssuredNotMatch: types.optional(types.number, 0),
  numberOfDataItemsCollected: types.optional(types.number, 0)
})
  .named('FieldStatistic');
export default FieldStatistic;

export interface IFieldStatistic extends Instance<typeof FieldStatistic>{}
export interface IFieldStatisticSnapshotIn extends SnapshotIn<typeof FieldStatistic>{}
export interface IFieldStatisticSnapshotOut extends SnapshotOut<typeof FieldStatistic>{}
