import React from 'react';
import { observer } from 'mobx-react';
import {
  Text, StackLayout, Icon
} from '@wemogy/reactbase';
import IDataItemAttachmentColumnProps from './IDataItemAttachmentColumnProps';
import TooltipHelp from '../../../../../tooltipHelp/TooltipHelp';
import useDataItemStore from '../../../../dataLayer/stores/hooks/UseDataItemStore';

const DataItemAttachmentColumn: React.FC<IDataItemAttachmentColumnProps> = ({
  dataItem, onPress, onContextMenu
}) => {
  const dataItemStore = useDataItemStore();

  if (!dataItemStore) {
    return null;
  }

  return (
    <TooltipHelp position="right" text={dataItem.hasAttachments ? `Zugeordnete Dateien: ${dataItem.attachmentCount}` : 'Keine Dateien zugeordnet'}>
      <StackLayout onPress={onPress} onContextMenu={onContextMenu} height100 width100 className={dataItemStore.isSelected(dataItem) ? 'selectedDataItem' : dataItemStore.isActive(dataItem) ? 'activeDataItem' : ''} vCenter horizontal>
        <Icon xl color={dataItem.hasAttachments ? 'green400': 'grey500'} fileCopy />
        <Text label>{dataItem.attachmentCount}</Text>
      </StackLayout>
    </TooltipHelp>
  );
};

export default observer(DataItemAttachmentColumn);
