import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, Modal
} from '@wemogy/reactbase';
import IExportCommentsModalProps from './IExportCommentsModalProps';
import OnlyTheseWorkingPackagesSelector from '../../wrappers/formBuilder/containers/onlyTheseWorkingPackagesSelector/OnlyTheseWorkingPackagesSelector';
import useDataAccessStore from '../../../dataLayer/stores/hooks/UseDataAccessStore';
import Checkbox from '../../components/checkbox/Checkbox';
import RestApiServiceFactory from '../../../dataLayer/api/rest/RestApiServiceFactory';
import useExportHandler from '../../wrappers/formBuilder/hooks/UseExportHandler';

const ExportCommentsModal: React.FC<IExportCommentsModalProps> = () => {
  const { formStore } = useDataAccessStore();
  const [onlyExportFromTheseWorkingPackages, setOnlyExportFromTheseWorkingPackages] = useState<string[] | undefined>(undefined);
  const [onlyUnresolved, setOnlyUnresolved] = useState(false);


  const { activeItem } = formStore;
  const {handleExportResult} = useExportHandler();

  if (!activeItem) {
    return null;
  }


  return (
    <Modal modalKey="exportComments" icon="getApp" title="Kommentare exportieren" large>
      <StackLayout>
        <Checkbox marginBottom boxStyle label="Nur ungelöste Kommentare" checked={onlyUnresolved} onChange={setOnlyUnresolved} />
        <OnlyTheseWorkingPackagesSelector
          form={activeItem}
          onlyTheseWorkingPackageIds={onlyExportFromTheseWorkingPackages}
          onOnlyTheseWorkingPackageIdsChanged={setOnlyExportFromTheseWorkingPackages}
        />
        <StackLayout hEnd marginTop={4}>
          <Button onPress={(): void => {
            handleExportResult(RestApiServiceFactory.commentService.getCsvExport(activeItem.id, onlyUnresolved, onlyExportFromTheseWorkingPackages), 'Kommentare werden exportiert.', onlyUnresolved ? `${activeItem?.name}_UnresolvedComments` : `${activeItem?.name}_AllComments`);
          }}
          >
            Exportieren

          </Button>
        </StackLayout>
      </StackLayout>
    </Modal>
  );
};

export default observer(ExportCommentsModal);
