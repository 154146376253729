import React, { useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { StackLayout } from '@wemogy/reactbase';
import IFieldStatisticsChartProps from './IFieldStatisticsChartProps';
import BarChart from '../../wrappers/charts/barChart/BarChart';
import SegmentedControl from '../../wrappers/segmentedControl/SegmentedControl';

const FieldStatisticsChart: React.FC<IFieldStatisticsChartProps> = ({
  fieldStatistics, max
}) => {
  const [fieldStatisticProperty, setFieldStatisticProperty] = useState('');


  const sections = _.orderBy(fieldStatistics.map(fieldStatistic => ({
    name: fieldStatistic.name,
    value: {
      name: fieldStatistic.name,
      value: fieldStatisticProperty === 'Erfasste Felder' ? fieldStatistic.numberOfDataItemsCollected : fieldStatistic.numberOfDataItemsWhereDataCollectedAndFirstQualityAssuredNotMatch
    }
  })), x => x.value, 'desc');

  return (
    <StackLayout>
      <SegmentedControl
        options={['Erfasste Felder', 'Felder mit Differenz zwischen Erfassung und QS']}
        defaultOption="Erfasste Felder"
        onOptionSelected={(option): void => {
          setFieldStatisticProperty(option);
                }}
      />
      <BarChart
        maxValue={max}
        noLabel
        noXAxisLabels
        sections={sections}
        minHeight={sections.length * 4}
      />
    </StackLayout>
    )
}

export default observer(FieldStatisticsChart);
