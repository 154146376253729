import React from 'react';
import { observer } from 'mobx-react';
import { StackLayout } from '@wemogy/reactbase';
import IDataSourceItemListProps from './IDataSourceItemListProps';
import DataSourceItem from '../../components/dataSourceItem/DataSourceItem';

const DataSourceItemList: React.FC<IDataSourceItemListProps> = ({
  dataSources, onDataSourceRemove
}) => {

  return (
    <StackLayout>
      {
        dataSources.map(dataSource => (
          <DataSourceItem
            key={dataSource.id}
            dataSource={dataSource}
            onRemove={(): void => {
              onDataSourceRemove(dataSource);
            }}
          />
        ))
      }
    </StackLayout>
  );
};

export default observer(DataSourceItemList);
