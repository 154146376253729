import * as signalR from '@microsoft/signalr';
import { IModalStore } from '@wemogy/reactbase';
import { RealtimeApiUri } from '../../base/Constants';
import NotificationManager from '../../ui/wrappers/inAppFeedback/NotificationManager';
import PdfMetadata from '../../ui/wrappers/formBuilder/dataLayer/models/PdfMetadata';
import IAnalyseAttachmentResultParameters from '../../ui/wrappers/formBuilder/modals/analyseResultModal/interfaces/IAnalyseAttachmentResultParameters';

function startDownloadFromUri(uri: string): void {
  console.log('startDownloadFromUri', uri);
  const link = document.createElement('a');
  link.download = '';
  link.href = uri;
  link.target = '_blank';
  link.click();
}

class RealtimeService{
  public connectionId: string | undefined;

  private accessToken: string | undefined;

  private getAccessToken(): string | undefined {
    return this.accessToken;
  }

  public updateAccessToken(accessToken: string): void{
    this.accessToken = accessToken;
  }

  public async initialize(modalStore: IModalStore): Promise<void> {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(RealtimeApiUri, {
        accessTokenFactory: (): string => {
          const accessToken = this.getAccessToken();

          if (!accessToken) {
            // throw new Error('No AccessToken');
            return 'noToken';
          }
          return accessToken;
        }
      })
      .withAutomaticReconnect()
      .build();

    connection.onreconnected((connectionId): void => {
      this.connectionId = connectionId;
      console.log('ReConnected with connectionId', connectionId);
    });

    connection.start().then((): void => {
      console.log('connection.connectionId => ', connection.connectionId);
      if (connection.connectionId) {
        this.connectionId = connection.connectionId;
      }
    }).catch(console.error);

    connection.on('downloadCompleted', (uri: string): void => {
      console.log('Download completed', uri);
      startDownloadFromUri(uri);
      modalStore.closeModal();
    });

    connection.on('importCsvProcess', (status: string): void => {
      console.log('importCsvProcess', status);
      modalStore.openModal('loadingOverlay', status);
    });

    connection.on('importCsvCompleted', (): void => {
      console.log('importCsvCompleted');
      modalStore.closeModal();
      NotificationManager.success('Der Import wurde erfolgreich abgeschlossen');
    });

    connection.on('pdfProcess', (status: string): void => {
      if (!this.fileName) {
        return;
      }
      console.log('pdfProcess', status)
      modalStore.openModal('loadingOverlay', status);
    });

    connection.on('pdfResult', (meta: any): void => {
      modalStore.openModal('analyseAttachmentResult', {
        pdfMetadata: PdfMetadata.create(meta.pdfMetadata),
        fileName: this.fileName || ''
      } as IAnalyseAttachmentResultParameters);
      this.fileName = undefined;
    });
  }

  public fileName: string | undefined;
}

export default new RealtimeService();
