import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import IMemberRoleDropdownProps from './IMemberRoleDropdownProps';
import RolesDropdown from '../../rolesDropdown/RolesDropdown';

const MemberRoleDropdown: React.FC<IMemberRoleDropdownProps> = ({
permissionLevel, member, resourceId, useMemberRoleIdChangedHandlerHook
}) => {
  const memberRoleIdChangedHandler = useMemberRoleIdChangedHandlerHook();

  const handleRoleSelected = useCallback((newRoleId: string): void => {
    memberRoleIdChangedHandler(member.id, resourceId, newRoleId);
  }, [memberRoleIdChangedHandler, member]);

    return (
      <RolesDropdown
        selectedRoleId={member.roleId}
        onRoleSelected={handleRoleSelected}
        permissionLevel={permissionLevel}
      />
    )
}

export default observer(MemberRoleDropdown);
