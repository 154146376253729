import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { StackLayout } from '@wemogy/reactbase';
import ICustomerStatisticsProps from './ICustomerStatisticsProps';
import StatisticSection from '../statisticSection/StatisticSection';
import useDataAccessStore from '../../../../dataLayer/stores/hooks/UseDataAccessStore';
import NavigationManager from '../../../../navigation/NavigationManager';

const CustomerStatistics: React.FC<ICustomerStatisticsProps> = () => {
  const { customerStore } = useDataAccessStore();

  useEffect((): void => {
    customerStore.query();
  }, []);

  return (
    <StackLayout>
      {
        customerStore.items.map(customer => (
          <StatisticSection
            onPress={(): void => {
              NavigationManager.navigate(`/statistics/customer/${customer.id}`);
            }}
            key={customer.id}
            marginBottom={2}
            name={customer.companyName}
            maxValue={customer.numberOfDataItems}
            barSections={[
              {
                name: 'Datensätze (Gesamt)',
                value: customer.numberOfDataItems !== undefined ? {
                  name: 'Gesamt',
                  value: customer.numberOfDataItems
                } : undefined
              },

              {
                name: 'Datensätze in Arbeitspaketen',
                value: customer.numberOfDataItemsWithWorkingPackage !== undefined ? {
                  name: 'in Arbeitspaketen',
                  value: customer.numberOfDataItemsWithWorkingPackage
                } : undefined
              },

              {
                name: 'Datensätze ohne Arbeitspaket',
                value: customer.numberOfDataItemsWithWorkingPackage !== undefined &&  customer.numberOfDataItems !== undefined ? {
                  name: 'ohne Arbeitspaket',
                  value: customer.numberOfDataItems - customer.numberOfDataItemsWithWorkingPackage
                } : undefined
              },

              {
                name: 'Datensätze indexiert',
                value: customer.numberOfDataItemsWhichAreIndexed !== undefined ? {
                  name: 'indexiert',
                  value: customer.numberOfDataItemsWhichAreIndexed
                } : undefined
              },

              {
                name: 'mit mind. einem Dokument',
                value: customer.numberOfDataItemsWithAttachment !== undefined ? {
                  name: 'Datensätze mit mind. einem Dokument',
                  value: customer.numberOfDataItemsWithAttachment
                } : undefined
              },

              {
                name: 'mit mind. einem Dokument ohne Arbeitspaket',
                value: customer.numberOfDataItemsWithAttachmentWithoutWorkingPackage !== undefined ? {
                  name: 'Datensätze mit mind. ohne Arbeitspake',
                  value: customer.numberOfDataItemsWithAttachmentWithoutWorkingPackage
                } : undefined
              },


              {
                name: 'Datensätze ohne Dokument',
                value: customer.numberOfDataItemsWithoutAttachment !== undefined ? {
                  name: 'ohne Dokument',
                  value: customer.numberOfDataItemsWithoutAttachment
                } : undefined
              },
            ]}
          />
        ))
      }
    </StackLayout>
  );
};

export default observer(CustomerStatistics);
