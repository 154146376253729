import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Image, StackLayout, TextInput, Text, Icon
} from '@wemogy/reactbase';
import IInvitationScreenProps from './IInvitationScreenProps';
import NavigationManager from '../../../../navigation/NavigationManager';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import NotificationManager from '../../../wrappers/inAppFeedback/NotificationManager';
import { validatePassword } from '../../../../helpers/StringHelpers';
import PasswordValidationOutput from '../../../components/passwordValidationOutput/PasswordValidationOutput';
import RestApiServiceFactory from '../../../../dataLayer/api/rest/RestApiServiceFactory';
import ConfirmInvitationRequest from '../../../../dataLayer/api/dtos/account/ConfirmInvitationRequest';

const InvitationScreen: React.FC<IInvitationScreenProps> = ({
  invitationId, token
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [password, setPassword] = useState<string>('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [showAllErrorLabels, setShowAllErrorLabels] = useState(false);


  const confirmInvitation = async (): Promise<void> => {
    setShowAllErrorLabels(true);
    try{
      setIsLoading(true);
      const loginResult = await RestApiServiceFactory.invitationService.confirmInvitation(new ConfirmInvitationRequest(
        invitationId,
        token,
        firstname,
        lastname,
        password,
        true,
        true
      ));

      if(loginResult){
        NavigationManager.navigate('/customerOverview');
        return;
      }
      NotificationManager.error('Logindaten ungültig');
    }catch{
      NotificationManager.error('Es ist ein Fehler aufgetreten, bitte probieren Sie es später erneut.');
    }
    finally{
      setIsLoading(false);
    }
  };

  // passsword validation
  const passwordIsValid = validatePassword(password);
  const repeatPasswordIsValid = passwordIsValid && password === passwordRepeat;


  return (
    <StackLayout testId="loginScreen" height100 stretch hCenter spaceBetween backgroundImageUri="/img/bg.jpeg" backgroundImageResizeMode="cover">
      <StackLayout />
      <StackLayout padding={4} width={{custom: '25vw'}} border={0.125} borderColor="grey600" backgroundColor={{custom: 'rgba(255,255,255,0.95)'}} borderRadius hCenter spaceBetween>
        <StackLayout hCenter width100>
          <Image
            uri="/logo.png"
            customStyle={{
width: 158, height: 47
}}
            alt="logo"
          />
          <Text noTranslation marginTop={2} largeTitle>Legen Sie jetzt Ihren Account an:</Text>
          <StackLayout width100 marginTop={5}>
            <TextInput
              light
              noAutocomplete
              placeholder="Vorname"
              value={firstname}
              testId="firstnameInput"
              onChange={setFirstname}
            />
            <StackLayout horizontal marginTopBottom={0.75}>
              {
                showAllErrorLabels && !firstname
                  ? (
                    <>
                      <Icon clear xlDanger size={2} marginRight />
                      <Text invalid>Bitte geben Sie Ihren Vornamen ein</Text>
                    </>
                  ): null
              }
            </StackLayout>
          </StackLayout>
          <StackLayout width100>
            <TextInput
              light
              noAutocomplete
              placeholder="Nachname"
              value={lastname}
              testId="lastnameInput"
              onChange={setLastname}
            />
            <StackLayout horizontal marginTopBottom={0.75}>
              {
                showAllErrorLabels && !lastname
                  ? (
                    <>
                      <Icon clear xlDanger size={2} marginRight />
                      <Text invalid>Bitte geben Sie Ihren Nachnamen ein</Text>
                    </>
                  ): null
              }
            </StackLayout>
          </StackLayout>
          <StackLayout width100>
            <TextInput
              noAutocomplete
              light={{password: true}}
              placeholder="Passwort"
              value={password}
              testId="password"
              onChange={setPassword}
            />
            <StackLayout marginTopBottom={0.75}>
              {
                password.length && !passwordIsValid ? (
                  <PasswordValidationOutput password={password} />
                ) : showAllErrorLabels && !passwordIsValid ? (
                  <StackLayout horizontal>
                    <Icon clear xlDanger size={2} marginRight />
                    <Text invalid>Bitte geben Sie ein Passwort ein</Text>
                  </StackLayout>
                ) : null
              }
            </StackLayout>
          </StackLayout>
          <StackLayout width100 marginBottom={3}>
            <TextInput
              noAutocomplete
              className="password"
              light={{password: true}}
              placeholder="Passwort wiederholen"
              value={passwordRepeat}
              testId="passwordRepeat"
              onChange={setPasswordRepeat}
            />
            <StackLayout horizontal height={2} marginTopBottom={0.75}>
              {
                showAllErrorLabels && !repeatPasswordIsValid
                  ? (
                    <>
                      <Icon clear xlDanger size={2} marginRight />
                      <Text invalid>{passwordIsValid ? 'Die Passwörter stimmen nicht überein' : 'Bitte wiederholen Sie das Passwort'}</Text>
                    </>
                  ): null
              }
            </StackLayout>
          </StackLayout>
          {
            isLoading ? (<LoadingIndicator />) : (
              <StackLayout
                backgroundColor="primary"
                height={5}
                padding
                paddingRightLeft={3}
                hCenter
                borderRadius={0.25}
                vCenter
                onPress={(): void => {
                  confirmInvitation();
                }}
                testId="loginBtn"
              >
                <Text color="white" fontWeight="bold">Account anlegen</Text>
              </StackLayout>
            )
          }
        </StackLayout>

      </StackLayout>
      <StackLayout />
    </StackLayout>
  );
};

export default observer(InvitationScreen);
