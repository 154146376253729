enum StatusStep{
  Created = 0,
  Imported = 1,
  AttachmentsAssigned = 2,
  Indexed = 4,
  WorkingPackageAssigned = 8,
  DataCollected = 16,
  DataQualityAssured = 32,
  DataCollectionVerified = 64,
  Exported = 128
}

export default StatusStep;
