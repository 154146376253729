import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, Text, Modal, useModalStore
} from '@wemogy/reactbase';
import IManageDataSourcesModalProps from './IManageDataSourcesModalProps';
import DataSourceItemList from '../../containers/dataSourceItemList/DataSourceItemList';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';

const ManageDataSourcesModal: React.FC<IManageDataSourcesModalProps> = () => {
  const {formStore} = useDataAccessStore();
  const modalStore = useModalStore();

  if(!formStore.activeItem){
    return null;
  }

  return (
    <Modal modalKey="manageDataSources" icon="settings" title="Datenquellen verwalten" large>
      {
        formStore.activeItem.dataSources.length ? (
          <StackLayout>
            <DataSourceItemList dataSources={formStore.activeItem.dataSources} onDataSourceRemove={formStore.activeItem.removeDataSource} />
          </StackLayout>
        ) : (
          <StackLayout hCenter>
            <Text title marginTopBottom={2}>Es sind keine Datenquellen vorhanden</Text>
            <Button onPress={(): void =>{
              modalStore.openModal('addDataSource');
            }}
            >
              Jetzt eine Datenquelle anlegen

            </Button>
          </StackLayout>
        )
      }

    </Modal>
  );
};

export default observer(ManageDataSourcesModal);
