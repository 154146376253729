import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, Modal, useModalStore, Text
} from '@wemogy/reactbase';
import IArtStatusUpdateModalProps from './IArtStatusUpdateModalProps';
import useDataAccessStore from '../../../dataLayer/stores/hooks/UseDataAccessStore';
import RestApiServiceFactory from '../../../dataLayer/api/rest/RestApiServiceFactory';
import AnnotatedFormView from '../../wrappers/formBuilder/containers/formElements/annotatedFormView/AnnotatedFormView';
import Dropdown from '../../components/dropdown/Dropdown';
import IDropdownOption from '../../components/dropdown/interfaces/IDropdownOption';
import ArtStatus from '../../wrappers/formBuilder/dataLayer/enums/art/ArtStatus';
import NotificationManager from '../../wrappers/inAppFeedback/NotificationManager';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';

const artStatusOptions: IDropdownOption[] = [
  {
    value: ArtStatus.Packed.toString(),
    label: 'Verpackt'
  },
  {
    value: ArtStatus.PickedUp.toString(),
    label: 'Abgeholt'
  },
  {
    value: ArtStatus.Digitized.toString(),
    label: 'Digitalisiert'
  },
  {
    value: ArtStatus.Provided.toString(),
    label: 'Bereitgestellt'
  },
  {
    value: ArtStatus.Error.toString(),
    label: 'Fehler'
  }
]

const ArtStatusUpdateModal: React.FC<IArtStatusUpdateModalProps> = () => {
  const { formStore } = useDataAccessStore();
  const modalStore = useModalStore();
  const [artStatus, setArtStatus] = React.useState<ArtStatus | undefined>(undefined);
  const [sending, setSending] = React.useState(false);

  const qrCodePayload = modalStore.getActiveParameters<string>('artStatusUpdate');



  const { activeItem } = formStore;

  const handleSaveCore = useCallback(async (): Promise<void> => {
    try {
      if(!artStatus){
        return;
      }
      setSending(true);
      if(!activeItem || !qrCodePayload){
        if(!qrCodePayload){
          NotificationManager.error('Leerer QR Code erkannt')
        }
        if(!activeItem){
          NotificationManager.error('Datenbank nicht geladen')
        }
        return;
      }
      const { scannerQrCodeSourceId } = activeItem.artSettings;

      if(!scannerQrCodeSourceId) {
        NotificationManager.error('Keine QR Code Quelle für in App Scanning gefunden')
        return;
      }

      const apiResult = await RestApiServiceFactory.artService.updateDataItemArtStatus(activeItem.id, {
        qrCodeSourceId: scannerQrCodeSourceId,
        qrCodePayload,
        artStatus
      });

      if(!apiResult.ok) {
        NotificationManager.error(`Speichern fehlgeschlagen: ${apiResult.status} - ${apiResult.errors}`);
        return;
      }

      NotificationManager.success('Speichern erfolgreich');
    } catch (error) {
      NotificationManager.error(`Speichern fehlgeschlagen: ${error}`);
    }
    finally {
      setSending(false);
    }
  }, [activeItem, qrCodePayload, artStatus]);

  const reset = useCallback((): void => {
    setArtStatus(undefined);
    setSending(false);
  }, [handleSaveCore]);

  const handleSave = useCallback(async (): Promise<void> => {
    await handleSaveCore();
    reset();
    modalStore.closeModal();
  }, [handleSaveCore, reset]);

  const handleSaveContinue = useCallback(async (): Promise<void> => {
    await handleSaveCore();
    reset();
  }, [handleSaveCore, reset]);

  if (!activeItem || !qrCodePayload) {
    console.log(RestApiServiceFactory)
    return null;
  }

  console.log('qrCodePayload', qrCodePayload)


  return (
    <Modal modalKey="artStatusUpdate" icon="smartphone" title="QR Code erkannt" large>
      <StackLayout>
        <Text title>Inhalt</Text>
        <StackLayout wrap backgroundColor="green100">
          <Text noTranslation>{qrCodePayload}</Text>
        </StackLayout>

        <StackLayout marginTopBottom={2} height={0.5} backgroundColor="primary" />

        <AnnotatedFormView description="Bitte wählen Sie den Status">
          <Dropdown
            disableSorting
            light
            stretch
            selectedOption={artStatusOptions.find(x => x.value === artStatus?.toString())}
            options={artStatusOptions}
            onOptionSelected={(selectedOption): void => {
              setArtStatus(parseInt(selectedOption.value));
            }}
          />
        </AnnotatedFormView>

        {sending ? <LoadingIndicator /> : artStatus && (
        <StackLayout hEnd horizontal marginTop={4}>
          <Button
            onPress={handleSave}
            marginRight={2}
          >
            Senden

          </Button>
          <Button onPress={handleSaveContinue}>
            Senden & Nächste Aktion

          </Button>
        </StackLayout>
)}
      </StackLayout>
    </Modal>
  );
};

export default observer(ArtStatusUpdateModal);
