import React from 'react';
import { observer } from 'mobx-react';
import IAllUiElementsProps from './IAllUiElementsProps';
import ToolboxElementList from '../../toolboxElementList/ToolboxElementList';
import TextIcon from '../../../components/textIcon/TextIcon';
import ToolboxElementType from '../../../dataLayer/uiEnums/ToolboxElementType';
import UiElementType from '../../../dataLayer/enums/UiElementType';

export const UiElementH2Config = { text: '' };

const AllUiElements: React.FC<IAllUiElementsProps> = () => {

  return (
    <ToolboxElementList
      type={ToolboxElementType.UiElement}
      elements={[
        {
          key: UiElementType.H1,
          label: 'Titel',
          icon: <TextIcon>H1</TextIcon>,
          config: {text: ''}
        },
        {
          key: UiElementType.H2,
          label: 'Überschrift',
          icon: <TextIcon>H2</TextIcon>,
          config: UiElementH2Config
        },
        {
          key: UiElementType.Label,
          label: 'Beschriftung',
          icon: 'textFields',
          config: {text: ''}
        },
        {
          key: UiElementType.Hr,
          label: 'Trennline',
          icon: 'remove'
        }
      ]}
      onElementSelected={(element): void => {
        console.log(element);
      }}
    />
  );
};

export default observer(AllUiElements);
