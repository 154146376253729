import React from 'react';
import { observer } from 'mobx-react';
import {StackLayout} from '@wemogy/reactbase';
import IPdfSettingsFormProps from './IPdfSettingsFormProps';
import Checkbox from '../../../../components/checkbox/Checkbox';
import AnnotatedTextInput from '../../containers/formElements/annotatedTextInput/AnnotatedTextInput';

const PdfSettingsForm: React.FC<IPdfSettingsFormProps> = ({pdfSettings}) => {

  return (
    <StackLayout>
      <Checkbox boxStyle label="Drehen Sie die Seiten des Dokuments entsprechend der Textdrehung" checked={pdfSettings.isRotationEnabled} onChange={pdfSettings.setIsRotationEnabled} />
      <Checkbox boxStyle label="Aktivierung der optischen Texterkennung (OCR)" checked={pdfSettings.isOcrEnabled} onChange={pdfSettings.setIsOcrEnabled} />
      <Checkbox boxStyle label="Komprimierung einschalten" checked={pdfSettings.isCompressionEnabled} onChange={pdfSettings.setIsCompressionEnabled} />
      <Checkbox boxStyle label="PDF/A-2B Konvertierung einschalten" checked={pdfSettings.isPdfAConversionEnabled} onChange={pdfSettings.setIsPdfAConversionEnabled} />
      <Checkbox boxStyle label="Prüfen, ob das verarbeitete Dokument PDF/A-2B-konform ist" checked={pdfSettings.isPdfAValidationEnabled} onChange={pdfSettings.setIsPdfAValidationEnabled} />
      <Checkbox boxStyle label="Aggressives Entfernen von Zeilen, die kein Text sind, aus dem Dokument, um die OCR-Qualität zu verbessern" checked={pdfSettings.isAggressiveOcrPreProcessingEnabled} onChange={pdfSettings.setIsAggressiveOcrPreProcessingEnabled} />
      <Checkbox boxStyle label="Kopieren Sie die Lesezeichen des alten Dokuments in das neue Dokument" checked={pdfSettings.preserveBookmarks} onChange={pdfSettings.setPreserveBookmarks} />
      <Checkbox boxStyle label="Debug-Meldungen in die Standardausgabe schreiben" checked={pdfSettings.isDebugEnabled} onChange={pdfSettings.setIsDebugEnabled} />

      <AnnotatedTextInput marginBottom numeric placeholder="Wie viele Threads/CPU-Kerne verwendet werden sollen" description="Wie viele Threads/CPU-Kerne verwendet werden sollen" onChange={(value): void=>pdfSettings.setThreadCount(parseInt(value))} value={pdfSettings.threadCount.toString()} />
      <AnnotatedTextInput marginBottom numeric placeholder="Die Zeitüberschreitung für die tesseract OCR-Engine" description="Die Zeitüberschreitung für die tesseract OCR-Engine" onChange={(value): void=>pdfSettings.setTesseractTimeout(parseInt(value))} value={pdfSettings.tesseractTimeout.toString()} />

      <AnnotatedTextInput marginBottom placeholder="Die Sprachen, die für die OCR verwendet werden sollen" description="Die Sprachen, die für die OCR verwendet werden sollen" onChange={pdfSettings.setOcrLanguages} value={pdfSettings.ocrLanguages} />
    </StackLayout>
  );
};

export default observer(PdfSettingsForm);
