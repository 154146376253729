/* eslint-disable no-await-in-loop */
import React from 'react';
import { observer } from 'mobx-react';
import { Item } from 'react-contexify';
import {
  Text, StackLayout, Icon, useContextMenuStore, ContextMenu, useModalStore
} from '@wemogy/reactbase';
import IAttachmentContextMenuProps from './IAttachmentContextMenuProps';
import useUiStore from '../../../../../dataLayer/stores/hooks/UseUiStore';
import { confirmDialogYesNoDanger } from '../../../dialogs/DialogManager';
import useDataItemStore from '../../dataLayer/stores/hooks/UseDataItemStore';
import RestApiServiceFactory from '../../../../../dataLayer/api/rest/RestApiServiceFactory';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import NotificationManager from '../../../inAppFeedback/NotificationManager';
import { IAttachment } from '../../dataLayer/models/Attachment';
import { buildAttachmentUri } from '../../base/Conventions';
import { clearDataItemId } from '../../dataLayer/api/restServices/DataItemService';
import FormOrWorkingPackagePermissionFeature from '../../../../plugins/authorization/permissionFeatures/FormOrWorkingPackagePermissionFeature';

function startDownloadFromUri(uri: string, name?: string): void {
  console.log('startDownloadFromUri', uri);
  const link = document.createElement('a');
  link.download = name ||'';
  link.href = uri;
  link.click();
}

const AttachmentContextMenu: React.FC<IAttachmentContextMenuProps> = () => {
  const {clipboardStore} = useUiStore();
  const modalStore = useModalStore();
  const contextMenuStore = useContextMenuStore();
  const { formStore } = useDataAccessStore();

  const dataItemStore = useDataItemStore();
  const { activeItem } = formStore;
  const dataItem = dataItemStore?.activeItem;

  if (!dataItem || !activeItem) {
    return null;
  }

  const attachment = contextMenuStore.getActiveParameters<IAttachment>('attachment');

  return (
    <ContextMenu contextMenuKey="attachment">
      <FormOrWorkingPackagePermissionFeature requiredPermission="CanDownloadAttachments">
        <Item onClick={(): void => {
          if (!attachment) {
            return;
          }
          const uri = buildAttachmentUri(activeItem, attachment);
          startDownloadFromUri(uri, attachment.filename);
          NotificationManager.info(`${attachment.filename} wird heruntergeladen, anschließend können Sie diese in einem installieren Programm öffnen.`);
        }}
        >
          <StackLayout vCenter padding horizontal>
            <Icon marginRight size={2} getApp />
            <Text>Download</Text>
          </StackLayout>
        </Item>
      </FormOrWorkingPackagePermissionFeature>
      <FormOrWorkingPackagePermissionFeature requiredPermission="CanCopyAssignedAttachments">
        <Item onClick={(): void => {
          if (!attachment) {
            return;
          }
          clipboardStore.copyAttachment(attachment);
          NotificationManager.info('Die Datei wurde in die Zwischablage kopiert');
        }}
        >
          <StackLayout vCenter padding horizontal>
            <Icon marginRight size={2} fileCopy />
            <Text>Kopieren</Text>
          </StackLayout>
        </Item>
      </FormOrWorkingPackagePermissionFeature>
      <FormOrWorkingPackagePermissionFeature requiredPermission="CanMoveAssignedAttachments">
        <Item onClick={(): void => {
          if (!attachment) {
            return;
          }
          clipboardStore.moveAttachment(attachment);
          NotificationManager.info('Die Datei wurde in die Zwischablage kopiert');
        }}
        >
          <StackLayout vCenter padding horizontal>
            <Icon marginRight size={2} chevronRight />
            <Text>Verschieben</Text>
          </StackLayout>
        </Item>
      </FormOrWorkingPackagePermissionFeature>
      <FormOrWorkingPackagePermissionFeature requiredPermission="CanDeleteAssignedAttachments">
        <Item onClick={(): void => {
          if (!attachment) {
            return;
          }
          confirmDialogYesNoDanger(`Möchten Sie die Datei ${attachment.filename} wirklick löschen?`, (): void => {
            modalStore.openModal('loadingOverlay', 'Die Datei wird gelöscht, einen Moment bitte.');
            RestApiServiceFactory.getDataItemService(activeItem.id).deleteAttachment(activeItem.id, dataItem.id, attachment.id).then((response): void => {
              modalStore.closeModal();
              if (response.ok) {
                NotificationManager.success('Die Datei wurde erfolgreich gelöscht.');
                dataItem.removeAttachment(attachment);
                const originalDataItem = dataItemStore?.getItem(clearDataItemId(dataItem.id));
                console.log(originalDataItem, originalDataItem?.id,dataItem.id);
                (originalDataItem as any)?.removeAttachment(attachment);
              }
              else {
                NotificationManager.error(`Beim Löschen ist etwas schief gelaufen. Statuscode: ${response.status}`);
              }
            });
          });
        }}
        >
          <StackLayout vCenter padding horizontal>
            <Icon marginRight size={2} delete xlDanger />
            <Text>Löschen</Text>
          </StackLayout>
        </Item>
      </FormOrWorkingPackagePermissionFeature>
      {attachment?.isGenerated ? null :(
        <Item onClick={(): void => {
        if (!attachment) {
          return;
        }
        confirmDialogYesNoDanger(`Möchten Sie die Datei ${attachment.filename} wirklick als generiert markieren?`, (): void => {
          modalStore.openModal('loadingOverlay', 'Die Datei wird markiert, einen Moment bitte.');
          RestApiServiceFactory.getDataItemService(activeItem.id).updateAttachment(activeItem.id, dataItem.id, attachment.id, true).then((response): void => {
            modalStore.closeModal();
            if (response.ok) {
              NotificationManager.success('Die Datei wurde erfolgreich gelöscht.');
              attachment.setIsGenerated(true);
              const originalDataItem = dataItemStore?.getItem(clearDataItemId(dataItem.id));
              (originalDataItem as any)?.updateAttachment(attachment.id, x => x.setIsGenerated(true));
            }
            else {
              NotificationManager.error(`Beim Markieren ist etwas schief gelaufen. Statuscode: ${response.status}`);
            }
          });
        });
      }}
        >
          <StackLayout vCenter padding horizontal>
            <Icon marginRight size={2} settingsSuggest xl />
            <Text>Als generiert markieren</Text>
          </StackLayout>
        </Item>
)}
    </ContextMenu>
  );
};

export default observer(AttachmentContextMenu);
