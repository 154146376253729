import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import { BaseModel } from '@wemogy/reactbase';
import PdfMatchingConfig from './PdfMatchingConfig';

const ArtSettingsSubType = types.model({
  featureEnabled: types.optional(types.boolean, false),
  scannerQrCodeSourceId: types.maybe(types.string),
  pdfMatchingConfig: types.maybe(PdfMatchingConfig),
  batchNumberDestinationName: types.maybe(types.string),
  transportContainerIdDestinationName: types.maybe(types.string),
  qrCodePayloadDestinationName: types.maybe(types.string),
  batchNumbers: types.optional(types.array(types.string), []),
  transportContainerIds: types.optional(types.array(types.string), []),
})
  .named('ArtSettingsSubType')
  .actions(self => ({
    setFeatureEnabled(featureEnabled: boolean): void {
      self.featureEnabled = featureEnabled;
      if(!featureEnabled){
        self.pdfMatchingConfig = undefined;
      }
    },
    setPdfMatchingQrCodeSourceId(pdfMatchingQrCodeSourceId: string): void {
      if(!self.pdfMatchingConfig){
        self.pdfMatchingConfig = PdfMatchingConfig.create({qrCodeSourceId: pdfMatchingQrCodeSourceId});
      }
      else{
        self.pdfMatchingConfig.setPdfMatchingQrCodeSourceId(pdfMatchingQrCodeSourceId);
      }
    },
    setBatchNumberDestinationName(batchNumberDestinationName: string): void {
      self.batchNumberDestinationName = batchNumberDestinationName;
    },
    setTransportContainerIdDestinationName(transportContainerIdDestinationName: string): void {
      self.transportContainerIdDestinationName = transportContainerIdDestinationName;
    },
    setQrCodePayloadDestinationName(qrCodePayloadDestinationName: string): void {
      self.qrCodePayloadDestinationName = qrCodePayloadDestinationName;
    },
    addBatchNumber(batchNumber: string): void {
      self.batchNumbers.push(batchNumber);
    },
    removeBatchNumber(batchNumber: string): void {
      self.batchNumbers.remove(batchNumber);
    },
    addTransportContainerId(transportContainerId: string): void {
      self.transportContainerIds.push(transportContainerId);
    },
    removeTransportContainerId(transportContainerId: string): void {
      self.transportContainerIds.remove(transportContainerId);
    },
    setScannerQrCodeSourceId(scannerQrCodeSourceId: string): void {
      self.scannerQrCodeSourceId = scannerQrCodeSourceId;
    }
  }));

const ArtSettings = types.compose(BaseModel, ArtSettingsSubType)
  .named('ArtSettings');
export default ArtSettings;

export interface IArtSettings extends Instance<typeof ArtSettings>{}
export interface IArtSettingsSnapshotIn extends SnapshotIn<typeof ArtSettings>{}
export interface IArtSettingsSnapshotOut extends SnapshotOut<typeof ArtSettings>{}
