import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
StackLayout, Text, Modal, useModalStore
} from '@wemogy/reactbase';
import IResetHistoryModalProps from './IResetHistoryModalProps';
import { IDataItem } from '../../dataLayer/models/genericModels/DataItem';
import StatusLogEntryList from '../../containers/statusLogEntryList/StatusLogEntryList';
import { IStatusLogEntry } from '../../dataLayer/models/status/StatusLogEntry';
import ConfirmCriticalOperation from '../../components/confirmCriticalOperation/ConfirmCriticalOperation'
import RestApiServiceFactory from '../../../../../dataLayer/api/rest/RestApiServiceFactory';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import NotificationManager from '../../../inAppFeedback/NotificationManager';

const ResetHistoryModal: React.FC<IResetHistoryModalProps> = () => {
  const modalStore = useModalStore();
  const { formStore } = useDataAccessStore();
  const [selectedStatusLogEntry, setSelectedStatusLogEntry] = useState<IStatusLogEntry | undefined>(undefined);


  const dataItem = modalStore.getActiveParameters<IDataItem>('resetHistory');
  const formId = formStore.activeItem?.id;

  if (!dataItem || !formId) {
    return null;
  }

    return (
      <Modal
        modalKey="resetHistory"
        title="Status zurücksetzen"
        icon="history"
        onOpened={(): void => {
        setSelectedStatusLogEntry(undefined)
      }}
      >
        <StackLayout>
          <Text>Bitte wählen Sie den Status Schritt, ab dem alle neueren Transaktionen und Einträge gelöscht werden sollen</Text>
          <StatusLogEntryList
            status={dataItem.status}
            selectedStatusLogEntry={selectedStatusLogEntry}
            onStatusLogEntrySelected={setSelectedStatusLogEntry}
          />
          {selectedStatusLogEntry ? (
            <ConfirmCriticalOperation
              question="Möchten Sie den Status wirklich zurücksetzen?"
              onConfirmed={(): void => {
                modalStore.openModal('loadingOverlay')
                RestApiServiceFactory.getDataItemService(formId).resetToTimestamp(dataItem.id, selectedStatusLogEntry.timestamp)
                  .then((): void => {
                    dataItem.resetToTimestamp(selectedStatusLogEntry.timestamp);
                  })
                  .finally(modalStore.closeModal);
          }}
            />
          ) : null}

          <StackLayout marginTop borderTop={0.25} paddingTop border={0}>
            <Text>Letztes "Schritt Abschließen" löschen</Text>
            <ConfirmCriticalOperation
              question="Möchten Sie das letzte 'Schritt abschließen' wirklich löschen?"
              onConfirmed={(): void => {
                modalStore.openModal('loadingOverlay');

                dataItem.beginEditing();
                let popIndexed = false;
                let popDataCollected = false;
                let popDataQualityAssured = false;

                if (dataItem.status.isDataQualityAssured) {
                  dataItem.status.removeLastDataQualityAssured();
                  popDataQualityAssured = true;
                }
                else if (dataItem.status.isDataCollected) {
                  dataItem.status.removeLastDataCollected();
                  popDataCollected = true;
                } else {
                  dataItem.status.removeLastIndexed();
                  popIndexed = true;
                }

                RestApiServiceFactory.getDataItemService(formId).resetStatus(dataItem.id, popIndexed, popDataCollected, popDataQualityAssured)
                  .then((): void => {
                    dataItem.commitChanges();
                    NotificationManager.success('Erfolgreich gelöscht')
                  })
                  .finally(modalStore.closeModal);
          }}
            />
          </StackLayout>
        </StackLayout>
      </Modal>
    )
}

export default observer(ResetHistoryModal);
