import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import './DataItemTable.css';
import {
  Column, Table, TableCellDataGetterParams, AutoSizer
} from 'react-virtualized';
import 'react-virtualized/styles.css';
import _ from 'lodash';
import {
  StackLayout, QueryParameters, Text, IQueryParameters, Button, Icon, useContextMenuStore, ScrollView
} from '@wemogy/reactbase';
import IDataItemTableProps from './IDataItemTableProps';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import useDataItemStore from '../../dataLayer/stores/hooks/UseDataItemStore';
import DataItemContextMenu from '../../contextMenus/dataItemContextMenu/DataItemContextMenu';
import DataItemTableFilter from '../dataItemTableFilter/DataItemTableFilter';
import { IDataItem } from '../../dataLayer/models/genericModels/DataItem';
import AssignWorkingPackageModal from '../../modals/assignWorkingPackageModal/AssignWorkingPackageModal';
import RestApiServiceFactory from '../../../../../dataLayer/api/rest/RestApiServiceFactory';
import DataItemDataColumn from './columns/dataItemDataColumn/DataItemDataColumn';
import DataItemWorkingPackageColumn from './columns/dataItemWorkingPackageColumn/DataItemWorkingPackageColumn';
import DataItemAttachmentColumn from './columns/dataItemAttachmentColumn/DataItemAttachmentColumn';
import DataItemLoadingIndicator from '../dataItemLoadingIndicator/DataItemLoadingIndicator';
import HistoryContextMenu from '../../contextMenus/historyContextMenu/HistoryContextMenu';
import useAppStore from '../../../../../dataLayer/stores/hooks/UseAppStore';
import ProcessInformation from '../../../../containers/processInformation/ProcessInformation';
import WorkingPackageRole from '../../dataLayer/enums/roles/WorkingPackageRole';
import useFormPermissionFeature from '../../../../plugins/authorization/hooks/UseFormPermissionFeature';
import useWorkingPackagePermissionFeature from '../../../../plugins/authorization/hooks/UseWorkingPackagePermissionFeature';

function smartGetter(params: TableCellDataGetterParams): any{
  return _.property(params.dataKey)(params.rowData);
}

const DataItemTable: React.FC<IDataItemTableProps> = ({
  showStaticInfo, onItemPressed
}) => {
  const { activeUserStore } = useAppStore();
  const { formStore } = useDataAccessStore();
  const dataItemStore = useDataItemStore();
  const contextMenuStore = useContextMenuStore();
  const { hasOnActiveFormPermission } = useFormPermissionFeature();
  const { getWorkingPackagePermissions } = useWorkingPackagePermissionFeature();
  const [workingPackageId, setWorkingPackageId] = useState<string | undefined>(undefined);
  const [onlyUnresolved, setOnlyUnresolved] = useState(true);
  const fullAccess = hasOnActiveFormPermission('CanQuery');

  const getDefaultQueryParameters = (): IQueryParameters => {
    const query = QueryParameters.create().withTake(100);
    const navigationColumns = formStore.activeItem?.navigationConfiguration?.columns;

    if (workingPackageId) {
      query.putFilter('workingPackageId', workingPackageId, 'equals')
    }

    if (!fullAccess) {
      if (workingPackageId) {
        const workingPackageRole = getWorkingPackagePermissions(workingPackageId)

        if (onlyUnresolved) {
          if (workingPackageRole.hasFlag(WorkingPackageRole.CanAccessStatistics)) {
            query.putFilter('status.dataCollected', null, 'isEmpty')
          }
          else if (workingPackageRole.hasFlag(WorkingPackageRole.CanAccessStatistics)) {
            query.putFilter('status.dataCollected', null, 'isNotEmpty')
            query.putFilter('status.dataQualityAssured', null, 'isEmpty')
          }
        }
        else if (workingPackageRole.hasFlag(WorkingPackageRole.CanAccessStatistics)) {
            query.putFilter('status.dataCollected', null, 'isNotEmpty')
          }
      }
    }

    if (navigationColumns) {
      navigationColumns.forEach(col => {
        query.withSorting(`data.${col.propertyName}`);
      });
    }

    // always with createdAt at least
    // ID is not working because mongo integrates a timestamp in the id...
    query.withSorting('createdAt').withSorting('id');

    return query;
  };

  const [multiselectIsActive, setMultiselectIsActive] = useState(false);
  const [singleSelectIsActive, setSingleSelectIsActive] = useState(false);
  const [lastSelected, setLastSelected] = useState<any>(undefined);
  const [lastLoadMoreToken, setLastLoadMoreToken] = useState<string | undefined>('');

  const [total, setTotal] = useState(0);

  const queryKey = `collectorQueryKey_${formStore.activeItem?.id}`;
  const dataItems: IDataItem[] = dataItemStore?.queryItems(queryKey) as any;


  const runQuery = (withSearchAfter?: boolean, loadAll?: boolean): void => {
    if (!dataItemStore) {
      return;
    }
    const queryParameters = dataItemStore.getOrAddStoredQuery(queryKey, getDefaultQueryParameters(), true);
    const formId = formStore.activeItem?.id;

    if (!formId || !dataItemStore) {
      console.warn('runQuery error', formId, dataItemStore);
      return;
    }

    console.log('searchAfter values:', queryParameters.sortings.map(x => x.searchAfter));
    console.log('dataItemStore', dataItemStore.items.length, dataItemStore.items);

    if (loadAll) {
      queryParameters.withTake(2000000);
    } else {
      queryParameters.withTake(100);
    }

    if (loadAll || !withSearchAfter) {
      const dataItemService = RestApiServiceFactory.getDataItemService(formId);
      dataItemService.queryTotalItemCount(queryParameters).then(t => setTotal(t));
    }

    if(!withSearchAfter) {
      queryParameters.removeSearchAfter();
      dataItemStore.clearSelectedItems();
      dataItemStore.unsetActiveItem();
      dataItemStore.clearItems();
    }


    dataItemStore.query(queryParameters).then((): void => {
      if (!dataItemStore.activeItem) {
        const first = dataItemStore.queryItems(queryParameters)[0];
        if (first) {
          onItemPressed(first as any);
        }
      }
    });
  };

  useEffect((): void => {
    formStore.activeItem?.setActiveWorkingPackage(workingPackageId)
    if (!workingPackageId || !dataItemStore) {
      return;
    }

    dataItemStore.putStoredQuery(queryKey, getDefaultQueryParameters(), true);
    runQuery();
  }, [workingPackageId, onlyUnresolved])

  useEffect((): void => {
    if (!fullAccess) {
      return;
    }
    const formId = formStore.activeItem?.id;

    if (formId) {
      if (!dataItemStore?.items.length) {
        runQuery();
      }
    }
  }, []);

  useEffect(
    (): (() => void) => {
      if (!dataItemStore) {
        return (): void => {
          //
        };
      }
      const multiselectKeys = ['Shift'];
      const singleSelectKeys = ['Meta', 'Control'];
      const keyDownHandler = (ev: KeyboardEvent): void => {
        // ignore when input is focused
        if ((ev.target as any)?.localName === 'input') {
          return;
        }
        if (multiselectKeys.includes(ev.key)) {
          setMultiselectIsActive(true);
        }
        if (singleSelectKeys.includes(ev.key)) {
          setSingleSelectIsActive(true);
        }
      };
      const keyUpHandler = (ev: KeyboardEvent): void => {
        // ignore when input is focused
        if ((ev.target as any)?.localName === 'input') {
          setMultiselectIsActive(false);
          setSingleSelectIsActive(false);
          return;
        }
        if (multiselectIsActive && dataItems) {
          if (!dataItemStore) {
            return;
          }
          if (ev.key === 'A') {
            dataItemStore.selectItems(dataItems);
          }
          if (ev.key === 'C') {
            dataItemStore.clearSelectedItems();
          }
        }
        if (multiselectKeys.includes(ev.key)) {
          setMultiselectIsActive(false);
        }
        if (singleSelectKeys.includes(ev.key)) {
          setSingleSelectIsActive(false);
        }

        setLastSelected(undefined);

      };
      window.addEventListener('keydown', keyDownHandler);
      window.addEventListener('keyup', keyUpHandler);

      return (): void => {
        window.removeEventListener('keydown', keyDownHandler);
        window.removeEventListener('keyup', keyUpHandler);
      };
    }
  );

  const { activeItem } = formStore;


  if (!activeItem || !dataItemStore) {
    return <span>No active formStore item or dataItemStore or dataItems</span>;
  }


  const onLoadMore = (): void => {
    const token = dataItemStore.getLoadMoreTokenOfStoredQuery(queryKey);

    console.log('onLoadMore', token);

    if (token === lastLoadMoreToken) {
      return;
    }

    setLastLoadMoreToken(token);
    runQuery(true);
  };

  const handleRowClick = (dataItem: IDataItem): void => {
    if (!lastSelected && !dataItemStore.isSelected(dataItem)) {
      setLastSelected(dataItem);
    }
    if (multiselectIsActive) {
      if (lastSelected) {
        const lastIndex = dataItems.indexOf(lastSelected);
        const currentIndex = dataItems.indexOf(dataItem);
        const begin = Math.min(lastIndex, currentIndex);
        const end = Math.max(lastIndex, currentIndex);
        dataItemStore.clearSelectedItems();
        dataItemStore.selectItems(dataItems.filter((_x, i) => {
          return i >= begin && i <= end;
        }));
      }
      else {
        dataItemStore.toggleSelectItem(dataItem);
      }
    } else if (singleSelectIsActive) {
      dataItemStore.toggleSelectItem(dataItem);
    } else {
      onItemPressed(dataItem);
    }
  };

  const handleRowRightClick = (event: any, dataItem: IDataItem): void => {
    contextMenuStore.openContextMenu(event, 'dataItem', dataItem);
  };


  if (!fullAccess && !workingPackageId) {
    const { userId } = activeUserStore;
    const workingPackagesOfUser = userId ? activeItem.workingPackages.filter(x => x.getMember(userId)) : [];

    if (!workingPackagesOfUser.length) {
      return (
        <StackLayout>
          <Text>Ihnen wurden noch keine Arbeitspakete zugeordnet</Text>
        </StackLayout>
)
    }

    return (
      <ScrollView>
        <StackLayout>
          <Text>Bitte wählen Sie ein Arbeitspaket aus</Text>
          {
          workingPackagesOfUser.map(workingPackage => (
            <StackLayout
              key={workingPackage.id}
              horizontal
              marginBottom
              padding
              borderRadius
              border={0.125}
              borderColor="primary"
              vCenter
              onPress={(): void => {
                setWorkingPackageId(workingPackage.id);
              }}
            >
              <Icon xl workOutline marginRight />
              <Text>{workingPackage.name}</Text>
            </StackLayout>
          ))
        }
        </StackLayout>
      </ScrollView>
    );
  }

  return (
    <StackLayout height100 stretch>
      <DataItemTableFilter
        form={activeItem}
        queryParameterKey={queryKey}
        onQueryParametersChanged={(): void => {
          // runQuery();
        }}
        onClosed={(loadAll?: boolean): void => {
          runQuery(false, loadAll);
        }}
      />
      <StackLayout marginTop>
        {fullAccess ? null : (
          <StackLayout>
            <StackLayout horizontal spaceBetween>
              <Button onPress={(): void => {
              setWorkingPackageId(undefined)
            }}
              >
                Arbeitspaket wechseln

              </Button>

              <Button onPress={(): void => {
              setOnlyUnresolved(!onlyUnresolved)
            }}
              >
                {onlyUnresolved ? 'Alle anzeigen' : 'Nur offene Datensätze'}

              </Button>
            </StackLayout>
            {workingPackageId ? <ProcessInformation formId={activeItem.id} workingPackageId={workingPackageId} total={total} /> : null}
          </StackLayout>
)}
      </StackLayout>
      {
        activeItem.navigationConfiguration.columns.length ? (
          <div style={{
            flex: 1, minHeight: 200
          }}
          >
            <AutoSizer>
              {({
                height, width
              }): JSX.Element => (
                <StackLayout width={{custom: width}} customStyle={{overflowX: 'scroll'}}>
                  <Table
                    onScroll={(e: any): void => {
                      const offset = e.clientHeight * 3;
                      const bottomReached = e.scrollHeight - e.scrollTop <= (e.clientHeight + offset);
                      if (bottomReached) {
                        onLoadMore();
                      }
                    }}
                    overscanRowCount={20}
                    className={`${multiselectIsActive ? 'disableSelection' : ''}`}
                    width={_.sumBy(activeItem.navigationConfiguration.columns, x => x.width) + (showStaticInfo ? 64 : 0)}
                    height={height}
                    headerHeight={20}
                    rowHeight={(x): number => x.index === 2 ? 50 : 23}
                    rowCount={dataItems.length}
                    rowGetter={({ index }): any => dataItems[index]}
                  >
                    {
                      showStaticInfo ? (
                        <Column
                          label=" "
                          dataKey=""
                          width={24}
                          cellDataGetter={smartGetter}
                          cellRenderer={(props): JSX.Element => (
                            <DataItemWorkingPackageColumn
                              dataItem={props.rowData}
                              form={activeItem}
                              onContextMenu={(event): void => {
                                handleRowRightClick(event, props.rowData);
                              }}
                              onPress={(): void => {
                                handleRowClick(props.rowData);
                              }}
                            />
                          )}
                        />
                      ) : null
                    }
                    {
                      showStaticInfo ? (
                        <Column
                          label=" "
                          dataKey=""
                          width={40}
                          cellDataGetter={smartGetter}
                          cellRenderer={(props): JSX.Element => (
                            <DataItemAttachmentColumn
                              dataItem={props.rowData}
                              onContextMenu={(event): void => {
                                handleRowRightClick(event, props.rowData);
                              }}
                              onPress={(): void => {
                                handleRowClick(props.rowData);
                              }}
                            />
                          )}
                        />
                      ) : null
                    }
                    {activeItem.navigationConfiguration.columns.map(column => (
                      <Column
                        key={column.propertyName}
                        label={column.displayName}
                        dataKey={`data.${column.propertyName}`}
                        width={column.width}
                        cellDataGetter={smartGetter}
                        cellRenderer={(props): JSX.Element => (
                          <DataItemDataColumn
                            dataItem={props.rowData}
                            cellData={props.cellData}
                            onContextMenu={(event): void => {
                              handleRowRightClick(event, props.rowData);
                            }}
                            onPress={(): void => {
                              handleRowClick(props.rowData);
                            }}
                          />
                        )}
                      />
                    ))}
                  </Table>
                </StackLayout>
              )}
            </AutoSizer>
          </div>
        ) : (
          <StackLayout width={20}>
            <Text>Sie müssen zuerst die Navigation konfigurieren. Gehen Sie hierzu in den Datenbank Manager und wählen Sie dann unter &quot;Einstellungen&quot; die Option &quot;Erfassermodus navigation&quot; aus</Text>
          </StackLayout>
        )
      }

      <StackLayout borderTop={0.25} border={0} spaceBetween horizontal vCenter borderColor="grey900">
        <Text textIcon>{`Σ ${dataItems.length}/${total}`}</Text>
        <Text>{dataItemStore.selectedItems.length ? `${dataItemStore.selectedItems.length} Datensätze ausgewählt` : 'Kein Datensatz ausgewählt'}</Text>
      </StackLayout>
      <DataItemLoadingIndicator />
      <DataItemContextMenu />
      <HistoryContextMenu />
      <AssignWorkingPackageModal />
    </StackLayout>
  );
};

export default observer(DataItemTable);
