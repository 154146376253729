import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import {BaseModel, isEmptyOrWhiteSpace} from '@wemogy/reactbase';
import WorkingPackageMember, { IWorkingPackageMember } from './members/WorkingPackageMember';
import PdfMetadata from './PdfMetadata';
import TimePeriodStatistics from './statistics/TimePeriodStatistics';
import FieldStatistic from './statistics/FieldStatistic';

const WorkingPackageSubType = types.model({
  name: types.optional(types.string, ''),
  begin: types.maybe(types.Date),
  end: types.maybe(types.Date),
  maximumDataItems: types.maybe(types.number),
  members: types.optional(types.array(WorkingPackageMember), []),
  // #region Statistics
  numberOfDataItems: types.optional(types.number, 0),
  numberOfAttachments: types.optional(types.number, 0),
  numberOfDataItemsWithAttachment: types.optional(types.number, 0),
  numberOfDataItemsWhichAreIndexed: types.optional(types.number, 0),
  numberOfDataItemsWhereDataCollected: types.optional(types.number, 0),
  numberOfDataItemsWhereDataQualityAssured: types.optional(types.number, 0),
  numberOfDataItemsWhereDataCollectionVerified: types.optional(types.number, 0),
  numberOfDataItemsWithComments: types.optional(types.number, 0),
  numberOfDataItemsWithResolvedComments: types.optional(types.number, 0),
  numberOfDataItemsWhereDataCollectedAndQualityAssuredNotMatch: types.optional(types.number, 0),
  pdfMetadataStatistics: types.optional(PdfMetadata, () => PdfMetadata.create()),
  dataCollectedHistory: types.optional(TimePeriodStatistics, () => TimePeriodStatistics.create()),
  dataQualityAssuredHistory: types.optional(TimePeriodStatistics, () => TimePeriodStatistics.create()),
  fieldStatistics: types.optional(types.array(FieldStatistic), [])
  // #endregion
})
  .named('WorkingPackageSubType')
  .views(self => ({
    get isValid(): boolean{
      return !isEmptyOrWhiteSpace(self.name);
    },
    get numberOfDataItemsString(): string{
      return `${self.numberOfDataItems} / ${this.hasMaximumDataItem ? self.maximumDataItems : '∞'}`;
    },
    get hasMaximumDataItem(): boolean{
      return self.maximumDataItems !== undefined;
    },
    get freeDataItems(): number{
      if (self.maximumDataItems === undefined) {
        // unlimited
        return -1;
      }
      const free = self.maximumDataItems - self.numberOfDataItems;
      return free;
    },
    canAddAllDataItems(numberOfDataItems: number): boolean{
      if (self.maximumDataItems === undefined) {
        return true;
      }
      return numberOfDataItems <= this.freeDataItems;
    },
    getMember(userId: string): IWorkingPackageMember | undefined{
      return self.members.find(x => x.userId === userId);
    }
  }))
  .actions(self => ({
    setName(name: string): void{
      self.name = name;
    },
    setBegin(begin: Date | undefined): void{
      self.begin = begin;
    },
    setEnd(end: Date | undefined): void{
      self.end = end;
    },
    setMaximumDataItems(maximumDataItems: number): void{
      self.maximumDataItems = maximumDataItems;
    },
    unsetMaximumDataItems(): void {
      self.maximumDataItems = undefined;
    },
    incrementNumberOfDataItems(amount: number = 1): void{
      self.numberOfDataItems += amount;
    },
    decrementNumberOfDataItems(amount: number = 1): void{
      self.numberOfDataItems -= amount;
    },
    deleteMember(userId: string): void{
      const member = self.getMember(userId);
      if (!member) {
        return;
      }
      self.members.remove(member);
    },
    putMember(userId: string, roleId: string): void{
      const member = self.getMember(userId);
      if (member) {
        member.setRoleId(roleId);
      } else {
        self.members.push(WorkingPackageMember.create({
          userId,
          roleId
        }))
      }
    }
  }));

const WorkingPackage = types.compose(BaseModel, WorkingPackageSubType)
  .named('WorkingPackage');
export default WorkingPackage;

export interface IWorkingPackage extends Instance<typeof WorkingPackage>{}
export interface IWorkingPackageSnapshotIn extends SnapshotIn<typeof WorkingPackage>{}
export interface IWorkingPackageSnapshotOut extends SnapshotOut<typeof WorkingPackage>{}
