import { Instance } from 'mobx-state-tree';
import { enumerationIntValuesType } from '@wemogy/reactbase';

enum ValueSeparator {
  Comma = 1,
  Semicolon = 2,
  LineBreak = 3
}

export default ValueSeparator;

export const ValueSeparatorType = enumerationIntValuesType(ValueSeparator);

export type IValueSeparator = Instance<typeof ValueSeparatorType>;
