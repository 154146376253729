import KeyValuePair from '../general/KeyValuePair';

export default class OptionsTypeConfig{
  public options: KeyValuePair[];

  public constructor(){
    this.options = [
      new KeyValuePair('Option 1', JSON.stringify('option1')),
      new KeyValuePair('Option 2', JSON.stringify('option2'))
    ];
  }
}
