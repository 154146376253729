import {
  types, Instance, SnapshotIn, SnapshotOut, getSnapshot
} from 'mobx-state-tree';
import {BaseModel, isEmptyOrWhiteSpace} from '@wemogy/reactbase';
import _ from 'lodash';
import { IForm, IFormSnapshotOut } from './Form';

const DatabaseTemplateSubType = types.model({
  name: types.optional(types.string, ''),
  formSerialized: types.maybe(types.string)
})
  .named('DatabaseTemplateSubType')
  .views(self => ({
    get isReadyToSave(): boolean{
      return !isEmptyOrWhiteSpace(self.name);
    },
    get formSnapshot(): IFormSnapshotOut | undefined{
      if(!self.formSerialized){
        return undefined;
      }
      return JSON.parse(self.formSerialized);
    }
  }))
  .actions(self => ({
    setName(name: string): void{
      self.name = name;
    },
    setForm(form: IForm): void{
      const snapshotClone = _.cloneDeep<IForm>(getSnapshot(form));
      snapshotClone.workingPackages.forEach(w => w.numberOfDataItems = 0);
      _.remove(snapshotClone.members);
      self.formSerialized = JSON.stringify(snapshotClone);
    }
  }));

const DatabaseTemplate = types.compose(BaseModel, DatabaseTemplateSubType)
  .named('DatabaseTemplate');
export default DatabaseTemplate;

export interface IDatabaseTemplate extends Instance<typeof DatabaseTemplate>{}
export interface IDatabaseTemplateSnapshotIn extends SnapshotIn<typeof DatabaseTemplate>{}
export interface IDatabaseTemplateSnapshotOut extends SnapshotOut<typeof DatabaseTemplate>{}
