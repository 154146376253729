import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, useTheme, Icon, useModalStore
} from '@wemogy/reactbase';
import { BeatLoader } from 'react-spinners';
import { useWindowWidth } from '@react-hook/window-size'
import ICustomerItemProps from './ICustomerItemProps';
import NavigationManager from '../../../../navigation/NavigationManager';
import useDataAccessStore from '../../../../dataLayer/stores/hooks/UseDataAccessStore';
import { confirmDialogYesNoDanger } from '../../../wrappers/dialogs/DialogManager';
import NotificationManager from '../../../wrappers/inAppFeedback/NotificationManager';
import CustomerPermissionFeature from '../../../plugins/authorization/permissionFeatures/CustomerPermissionFeature';

const CustomerItem: React.FC<ICustomerItemProps> = ({customer}) => {
  const modalStore = useModalStore();
  const {
    formStore, customerStore
  } = useDataAccessStore();
  const theme = useTheme();
  const windowWidth = useWindowWidth();
  const isTabletMode = windowWidth <= 1024;

  return (
    <StackLayout padding borderRadius={0.5} border={0.125} borderColor="primary" vCenter marginRight minWidth={30} marginBottom={isTabletMode ? 2 : 0} width100={isTabletMode}>
      <StackLayout horizontal vCenter marginBottom>
        <Icon business size={5} marginRight />

        <StackLayout stretch horizontal vCenter>
          <Text title stretch>{customer.companyName}</Text>
          <StackLayout horizontal vCenter>
            <CustomerPermissionFeature requiredPermission={{oneOf: ['CanManagePermissions', 'CanRename']}} id={customer.id}>
              <Icon
                edit
                size={2}
                marginLeft
                onPress={(): void => {
                  modalStore.openModal('editCustomer', customer.id);
                }}
              />
            </CustomerPermissionFeature>
            <CustomerPermissionFeature requiredPermission="CanDelete" id={customer.id}>
              <Icon
                deleteForever
                marginLeft
                size={2}
                onPress={(): void => {
                  confirmDialogYesNoDanger(`Möchten Sie den Kunden ${customer.companyName} wirklich löschen?`, (): void => {
                  modalStore.openModal('loadingOverlay')
                  customerStore.delete(customer.id).then((): void => {
                    NotificationManager.success('Der Kunde wurde erfolgreich gelöscht.')
                  })
                    .catch((): void => {
                      NotificationManager.error('Beim Löschen des Kunden ist ein Fehler aufgetreten.')
                    })
                    .finally(modalStore.closeModal);
                })
              }}
              />
            </CustomerPermissionFeature>
          </StackLayout>
        </StackLayout>
      </StackLayout>
      <StackLayout stretch>
        {
        formStore.getFormsOf(customer.id).map(form => (
          <StackLayout
            onPress={(): void => {
              if(isTabletMode){
                NavigationManager.navigate(`/art/${form.relatedId}/${form.id}`);
              }
              else {
                NavigationManager.navigate(`/database/${form.relatedId}/${form.id}`);
              }
            }}
            key={form.id}
            horizontal
            borderRadius
            border={0.125}
            borderColor="primary"
            padding={0.5}
            paddingRightLeft
            vCenter
            marginBottom
          >
            <Icon storage size={2} marginRight />
            <Text stretch>{form.name}</Text>
            <Icon chevronRight xl />
          </StackLayout>
        ))
      }
      </StackLayout>
      {formStore.isLoading ? (
        <StackLayout hCenter>
          <BeatLoader color={theme.colorScheme.primary} size={16} />
        </StackLayout>
      ) : null}
      <CustomerPermissionFeature subLevel="form" requiredPermission="CanCreate" id={customer.id}>
        <StackLayout
          horizontal
          hCenter
          onPress={(): void => {
            modalStore.openModal('addDatabase', customer);
          }}
          vCenter
        >
          <Icon add size={2} marginRight />
          <Text>Datenbank hinzufügen</Text>
        </StackLayout>
      </CustomerPermissionFeature>
    </StackLayout>
  );
};

export default observer(CustomerItem);
