import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Autosuggest from 'react-autosuggest';
import {Text, generateDomId} from '@wemogy/reactbase';
import IAutocompleteInputProps from './IAutocompleteInputProps';
import './AutocompleteInput.css';

const AutocompleteInput: React.FC<IAutocompleteInputProps> = ({
  suggestions, getSuggestionValue, filter, onChange, value, onSuggestionSelected,placeholder
}) => {
  const [id] = useState(generateDomId());
  const [activeSuggestions, setActiveSuggestions] = useState<any[]>([]);

  return (
    <Autosuggest
      suggestions={activeSuggestions}
      onSuggestionsFetchRequested={(params): any => {
        return setActiveSuggestions(suggestions.filter(suggestion => filter(suggestion, params.value)));
      }}
      getSuggestionValue={(suggestion): string => {
        return getSuggestionValue(suggestion);
      }}
      renderSuggestion={(suggestion): JSX.Element => (
        <Text>{getSuggestionValue(suggestion)}</Text>
      )}
      onSuggestionsClearRequested={(): void => {
        setActiveSuggestions([]);
      }}
      multiSection={false}
      shouldRenderSuggestions={(): boolean => true}
      id={id}
      onSuggestionSelected={(_ev, {suggestion}): void => {
        onSuggestionSelected(suggestion);
      }}
      inputProps={{
        onChange: (_ev, {newValue}): void => {
          onChange(newValue);
        },
        value,
        placeholder,
        style: {width: '100%'}
      }}
    />
  );
};

export default observer(AutocompleteInput);
