import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, Modal, useModalStore
} from '@wemogy/reactbase';
import IAddCustomerModalProps from './IAddCustomerModalProps';
import AnnotatedTextInput from '../../../wrappers/formBuilder/containers/formElements/annotatedTextInput/AnnotatedTextInput';
import useDataAccessStore from '../../../../dataLayer/stores/hooks/UseDataAccessStore';
import Customer from '../../../../dataLayer/models/Customer';

const AddCustomerModal: React.FC<IAddCustomerModalProps> = () => {
  const [name, setName] = useState('');
  const {customerStore} = useDataAccessStore();
  const modalStore = useModalStore();


  return (
    <Modal modalKey="addCustomer" icon="add" title="Kunden hinzufügen" large>
      <StackLayout>
        <AnnotatedTextInput description="Unternehmensname" placeholder="Name des Unternehmens" value={name} onChange={setName} marginBottom />
        <StackLayout hEnd>
          <Button onPress={(): void => {
            const form = Customer.create({companyName: name});
            customerStore.create(form);
            modalStore.closeModal();
            setName('');
          }}
          >
            Jetzt anlegen

          </Button>
        </StackLayout>
      </StackLayout>
    </Modal>
  );
};

export default observer(AddCustomerModal);
