import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Modal, useModalStore
} from '@wemogy/reactbase';
import IEditDataSourceModalProps from './IEditDataSourceModalProps';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import { IDataSource } from '../../dataLayer/models/DataSource';
import DataSourceForm from '../../components/dataSourceForm/DataSourceForm';

const EditDataSourceModal: React.FC<IEditDataSourceModalProps> = () => {
  const {formStore} = useDataAccessStore();
  const modalStore = useModalStore();

  const dataSource = modalStore.getActiveParameters<IDataSource>('editDataSource');

  if(!formStore.activeItem || !dataSource){
    return null;
  }

  return (
    <Modal modalKey="editDataSource" icon="settings" title="Datenquellen bearbeiten" large>
      <StackLayout>
        <DataSourceForm
          dataSource={dataSource}
          onFinished={(): void => {
            modalStore.openModal('manageDataSources');
          }}
        />
      </StackLayout>
    </Modal>
  );
};

export default observer(EditDataSourceModal);
