import AddTwoTone from '@material-ui/icons/AddTwoTone';
import ChatBubbleOutlineTwoTone from '@material-ui/icons/ChatBubbleOutlineTwoTone';
import CopyrightTwoTone from '@material-ui/icons/CopyrightTwoTone';
import CloudUploadTwoTone from '@material-ui/icons/CloudUploadTwoTone';
import SettingsTwoTone from '@material-ui/icons/SettingsTwoTone';
import PublishTwoTone from '@material-ui/icons/PublishTwoTone';
import CreateNewFolderTwoTone from '@material-ui/icons/CreateNewFolderTwoTone';
import HelpOutlineTwoTone from '@material-ui/icons/HelpOutlineTwoTone';
import PersonAddTwoTone from '@material-ui/icons/PersonAddTwoTone';
import ArrowDropDownTwoTone from '@material-ui/icons/ArrowDropDownTwoTone';
import ArrowDropDownCircleTwoTone from '@material-ui/icons/ArrowDropDownCircleTwoTone';
import ArrowRightTwoTone from '@material-ui/icons/ArrowRightTwoTone';
import FolderTwoTone from '@material-ui/icons/FolderTwoTone';
import FolderOpenTwoTone from '@material-ui/icons/FolderOpenTwoTone';
import NotificationsTwoTone from '@material-ui/icons/NotificationsTwoTone';
import NotificationsActiveTwoTone from '@material-ui/icons/NotificationsActiveTwoTone';
import CloseTwoTone from '@material-ui/icons/CloseTwoTone';
import ImageTwoTone from '@material-ui/icons/ImageTwoTone';
import GridOnTwoTone from '@material-ui/icons/GridOnTwoTone';
import TableChartTwoTone from '@material-ui/icons/TableChartTwoTone';
import ChevronLeftTwoTone from '@material-ui/icons/ChevronLeftTwoTone';
import GetAppTwoTone from '@material-ui/icons/GetAppTwoTone';
import HistoryTwoTone from '@material-ui/icons/HistoryTwoTone';
import CompareArrowsTwoTone from '@material-ui/icons/CompareArrowsTwoTone';
import SettingsBackupRestoreTwoTone from '@material-ui/icons/SettingsBackupRestoreTwoTone';
import VerticalSplitTwoTone from '@material-ui/icons/VerticalSplitTwoTone';
import FlipTwoTone from '@material-ui/icons/FlipTwoTone';
import ChevronRightTwoTone from '@material-ui/icons/ChevronRightTwoTone';
import KeyboardArrowUpTwoTone from '@material-ui/icons/KeyboardArrowUpTwoTone';
import KeyboardArrowDownTwoTone from '@material-ui/icons/KeyboardArrowDownTwoTone';
import MoreVertTwoTone from '@material-ui/icons/MoreVertTwoTone';
import ThumbUpTwoTone from '@material-ui/icons/ThumbUpTwoTone';
import EditTwoTone from '@material-ui/icons/EditTwoTone';
import SearchTwoTone from '@material-ui/icons/SearchTwoTone';
import ZoomInTwoTone from '@material-ui/icons/ZoomInTwoTone';
import ZoomOutTwoTone from '@material-ui/icons/ZoomOutTwoTone';
import UnfoldMoreTwoTone from '@material-ui/icons/UnfoldMoreTwoTone';
import CodeTwoTone from '@material-ui/icons/CodeTwoTone';
import FullscreenTwoTone from '@material-ui/icons/FullscreenTwoTone';
import CheckCircleTwoTone from '@material-ui/icons/CheckCircleTwoTone';
import CancelTwoTone from '@material-ui/icons/CancelTwoTone';
import FileCopyTwoTone from '@material-ui/icons/FileCopyTwoTone';
import SendTwoTone from '@material-ui/icons/SendTwoTone';
import PresentToAllTwoTone from '@material-ui/icons/PresentToAllTwoTone';
import RefreshTwoTone from '@material-ui/icons/RefreshTwoTone';
import LinkTwoTone from '@material-ui/icons/LinkTwoTone';
import FindInPageTwoTone from '@material-ui/icons/FindInPageTwoTone';
import AlternateEmailTwoTone from '@material-ui/icons/AlternateEmailTwoTone';
import DeleteForeverTwoTone from '@material-ui/icons/DeleteForeverTwoTone';
import PublicTwoTone from '@material-ui/icons/PublicTwoTone';
import VpnLockTwoTone from '@material-ui/icons/VpnLockTwoTone';
import MoreHorizTwoTone from '@material-ui/icons/MoreHorizTwoTone';
import ArrowUpwardTwoTone from '@material-ui/icons/ArrowUpwardTwoTone';
import ArrowDownwardTwoTone from '@material-ui/icons/ArrowDownwardTwoTone';
import DeleteTwoTone from '@material-ui/icons/DeleteTwoTone';
import ClearAllTwoTone from '@material-ui/icons/ClearAllTwoTone';
import ClearTwoTone from '@material-ui/icons/ClearTwoTone';
import LabelTwoTone from '@material-ui/icons/LabelTwoTone';
import SupervisorAccountTwoTone from '@material-ui/icons/SupervisorAccountTwoTone';
import WarningTwoTone from '@material-ui/icons/WarningTwoTone';
import ExitToAppTwoTone from '@material-ui/icons/ExitToAppTwoTone';
import DraftsTwoTone from '@material-ui/icons/DraftsTwoTone';
import DescriptionTwoTone from '@material-ui/icons/DescriptionTwoTone';
import FormatBoldTwoTone from '@material-ui/icons/FormatBoldTwoTone';
import FormatItalicTwoTone from '@material-ui/icons/FormatItalicTwoTone';
import FormatUnderlinedTwoTone from '@material-ui/icons/FormatUnderlinedTwoTone';
import FormatListBulletedTwoTone from '@material-ui/icons/FormatListBulletedTwoTone';
import FormatListNumberedTwoTone from '@material-ui/icons/FormatListNumberedTwoTone';
import FormatQuoteTwoTone from '@material-ui/icons/FormatQuoteTwoTone';
import VisibilityTwoTone from '@material-ui/icons/VisibilityTwoTone';
import VisibilityOffTwoTone from '@material-ui/icons/VisibilityOffTwoTone';
import PermContactCalendarTwoTone from '@material-ui/icons/PermContactCalendarTwoTone';
import BusinessTwoTone from '@material-ui/icons/BusinessTwoTone';
import SmartphoneTwoTone from '@material-ui/icons/SmartphoneTwoTone';
import PhoneTwoTone from '@material-ui/icons/PhoneTwoTone';
import AssignmentIndTwoTone from '@material-ui/icons/AssignmentIndTwoTone';
import AddAPhotoTwoTone from '@material-ui/icons/AddAPhotoTwoTone';
import DoneTwoTone from '@material-ui/icons/DoneTwoTone';
import PaletteTwoTone from '@material-ui/icons/PaletteTwoTone';
import InfoTwoTone from '@material-ui/icons/InfoTwoTone';
import ExpandMoreTwoTone from '@material-ui/icons/ExpandMoreTwoTone';
import LockTwoTone from '@material-ui/icons/LockTwoTone';
import PhotoLibraryTwoTone from '@material-ui/icons/PhotoLibraryTwoTone';
import FontDownloadTwoTone from '@material-ui/icons/FontDownloadTwoTone';
import ExposureTwoTone from '@material-ui/icons/ExposureTwoTone';
import PowerInputTwoTone from '@material-ui/icons/PowerInputTwoTone';
import LineStyleTwoTone from '@material-ui/icons/LineStyleTwoTone';
import RemoveTwoTone from '@material-ui/icons/RemoveTwoTone';
import TextFieldsTwoTone from '@material-ui/icons/TextFieldsTwoTone';
import VpnKeyTwoTone from '@material-ui/icons/VpnKeyTwoTone';
import CheckBoxTwoTone from '@material-ui/icons/CheckBoxTwoTone';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlankTwoTone';
import RadioButtonCheckedTwoTone from '@material-ui/icons/RadioButtonCheckedTwoTone';
import ToggleOnTwoTone from '@material-ui/icons/ToggleOnTwoTone';
import StorageTwoTone from '@material-ui/icons/StorageTwoTone';
import AddCircleTwoTone from '@material-ui/icons/AddCircleTwoTone';
import RemoveCircleTwoTone from '@material-ui/icons/RemoveCircleTwoTone';
import ExtensionTwoTone from '@material-ui/icons/ExtensionTwoTone';
import ScannerTwoTone from '@material-ui/icons/ScannerTwoTone';
import ColorizeTwoTone from '@material-ui/icons/ColorizeTwoTone';
import DoneAllTwoTone from '@material-ui/icons/DoneAllTwoTone';
import WorkOutlineTwoTone from '@material-ui/icons/WorkOutlineTwoTone';
import EventTwoTone from '@material-ui/icons/EventTwoTone';
import ScheduleTwoTone from '@material-ui/icons/ScheduleTwoTone';
import PhotoTwoTone from '@material-ui/icons/PhotoTwoTone';
import DragIndicatorTwoTone from '@material-ui/icons/DragIndicatorTwoTone';
import AccountCircleTwoTone from '@material-ui/icons/AccountCircleTwoTone';
import FilterListTwoTone from '@material-ui/icons/FilterListTwoTone';
import PostAddTwoTone from '@material-ui/icons/PostAddTwoTone';
import SaveTwoTone from '@material-ui/icons/SaveTwoTone';
import FaceTwoTone from '@material-ui/icons/FaceTwoTone';
import MenuBookTwoTone from '@material-ui/icons/MenuBookTwoTone';
import PieChartTwoTone from '@material-ui/icons/PieChartTwoTone';
import TimelineTwoTone from '@material-ui/icons/TimelineTwoTone';
import GroupTwoTone from '@material-ui/icons/GroupTwoTone';
import AssessmentTwoTone from '@material-ui/icons/AssessmentTwoTone';
import CalendarViewDayTwoTone from '@material-ui/icons/CalendarViewDayTwoTone';
import HorizontalSplitTwoTone from '@material-ui/icons/HorizontalSplitTwoTone';
import SettingsSuggestTwoTone from '@mui/icons-material/SettingsSuggestTwoTone';
import HourglassTopTwoTone from '@mui/icons-material/HourglassTopTwoTone';
import {
  CameraFrontTwoTone,
  CameraRearTwoTone,
  EditLocationTwoTone,
  GpsFixedTwoTone,
  HomeTwoTone,
  InsertDriveFileTwoTone,
  MapTwoTone,
  ReportTwoTone
} from '@material-ui/icons';
import IIcons from './interfaces/IIcons';

export default function iconResolver(props: IIcons): ((props: { style: any }) => JSX.Element) {
  if(props.add){
    return AddTwoTone;
  }
  if(props.comment){
    return ChatBubbleOutlineTwoTone;
  }
  if(props.copyright){
    return CopyrightTwoTone;
  }
  if(props.cloudUpload){
    return CloudUploadTwoTone;
  }
  if(props.settings){
    return SettingsTwoTone;
  }
  if(props.publish){
    return PublishTwoTone;
  }
  if(props.createNewFolder){
    return CreateNewFolderTwoTone;
  }
  if(props.help){
    return HelpOutlineTwoTone;
  }
  if(props.personAdd){
    return PersonAddTwoTone;
  }
  if(props.arrowDropDown){
    return ArrowDropDownTwoTone;
  }
  if(props.arrowDropDownCircle){
    return ArrowDropDownCircleTwoTone;
  }
  if(props.arrowRight){
    return ArrowRightTwoTone;
  }
  if(props.folder){
    return FolderTwoTone;
  }
  if(props.folderOpen){
    return FolderOpenTwoTone;
  }
  if(props.notifications){
    return NotificationsTwoTone;
  }
  if(props.notificationsActive){
    return NotificationsActiveTwoTone;
  }
  if(props.close){
    return CloseTwoTone;
  }
  if(props.image){
    return ImageTwoTone;
  }
  if(props.gridOn){
    return GridOnTwoTone;
  }
  if(props.tableChart){
    return TableChartTwoTone;
  }
  if(props.chevronLeft){
    return ChevronLeftTwoTone;
  }
  if(props.getApp){
    return GetAppTwoTone;
  }
  if(props.history){
    return HistoryTwoTone;
  }
  if(props.compareArrows){
    return CompareArrowsTwoTone;
  }
  if(props.settingsBackupRestore){
    return SettingsBackupRestoreTwoTone;
  }
  if(props.verticalSplit){
    return VerticalSplitTwoTone;
  }
  if(props.flip){
    return FlipTwoTone;
  }
  if(props.chevronRight){
    return ChevronRightTwoTone;
  }
  if(props.keyboardArrowUp){
    return KeyboardArrowUpTwoTone;
  }
  if(props.moreVert){
    return MoreVertTwoTone;
  }
  if(props.thumbUp){
    return ThumbUpTwoTone;
  }
  if(props.keyboardArrowDown){
    return KeyboardArrowDownTwoTone;
  }
  if(props.edit){
    return EditTwoTone;
  }
  if(props.search){
    return SearchTwoTone;
  }
  if(props.zoomIn){
    return ZoomInTwoTone;
  }
  if(props.zoomOut){
    return ZoomOutTwoTone;
  }
  if(props.unfoldMore){
    return UnfoldMoreTwoTone;
  }
  if(props.code){
    return CodeTwoTone;
  }
  if(props.fullscreen){
    return FullscreenTwoTone;
  }
  if(props.checkCircle){
    return CheckCircleTwoTone;
  }
  if(props.cancel){
    return CancelTwoTone;
  }
  if(props.fileCopy){
    return FileCopyTwoTone;
  }
  if(props.send){
    return SendTwoTone;
  }
  if(props.presentToAll){
    return PresentToAllTwoTone;
  }
  if(props.refresh){
    return RefreshTwoTone;
  }
  if(props.link){
    return LinkTwoTone;
  }
  if(props.findInPage){
    return FindInPageTwoTone;
  }
  if(props.alternateEmail){
    return AlternateEmailTwoTone;
  }
  if(props.deleteForever){
    return DeleteForeverTwoTone;
  }
  if(props.public){
    return PublicTwoTone;
  }
  if(props.vpnLock){
    return VpnLockTwoTone;
  }
  if(props.moreHoriz){
    return MoreHorizTwoTone;
  }
  if(props.arrowUpward){
    return ArrowUpwardTwoTone;
  }
  if(props.arrowDownward){
    return ArrowDownwardTwoTone;
  }
  if(props.delete){
    return DeleteTwoTone;
  }
  if(props.clearAll){
    return ClearAllTwoTone;
  }
  if(props.clear){
    return ClearTwoTone;
  }
  if(props.label){
    return LabelTwoTone;
  }
  if(props.supervisorAccount){
    return SupervisorAccountTwoTone;
  }
  if(props.warning){
    return WarningTwoTone;
  }
  if(props.exitToApp){
    return ExitToAppTwoTone;
  }
  if(props.drafts){
    return DraftsTwoTone;
  }
  if(props.description){
    return DescriptionTwoTone;
  }
  if(props.formatBold){
    return FormatBoldTwoTone;
  }
  if(props.formatItalic){
    return FormatItalicTwoTone;
  }
  if(props.formatUnderlined){
    return FormatUnderlinedTwoTone;
  }
  if(props.formatListBulleted){
    return FormatListBulletedTwoTone;
  }
  if(props.formatListNumbered){
    return FormatListNumberedTwoTone;
  }
  if(props.formatQuote){
    return FormatQuoteTwoTone;
  }
  if(props.visibility){
    return VisibilityTwoTone;
  }
  if(props.visibilityOff){
    return VisibilityOffTwoTone;
  }
  if(props.permContactCalendar){
    return PermContactCalendarTwoTone;
  }
  if(props.business){
    return BusinessTwoTone;
  }
  if(props.smartphone){
    return SmartphoneTwoTone;
  }
  if(props.phone){
    return PhoneTwoTone;
  }
  if(props.assignmentInd){
    return AssignmentIndTwoTone;
  }
  if(props.addAPhoto){
    return AddAPhotoTwoTone;
  }
  if(props.done){
    return DoneTwoTone;
  }
  if(props.palette){
    return PaletteTwoTone;
  }
  if(props.info){
    return InfoTwoTone;
  }
  if(props.expandMore){
    return ExpandMoreTwoTone;
  }
  if(props.lock){
    return LockTwoTone;
  }
  if(props.photoLibrary){
    return PhotoLibraryTwoTone;
  }
  if(props.fontDownload){
    return FontDownloadTwoTone;
  }
  if(props.exposure){
    return ExposureTwoTone;
  }
  if(props.powerInput){
    return PowerInputTwoTone;
  }
  if(props.lineStyle){
    return LineStyleTwoTone;
  }
  if(props.remove){
    return RemoveTwoTone;
  }
  if(props.textFields){
    return TextFieldsTwoTone;
  }
  if(props.vpnKey){
    return VpnKeyTwoTone;
  }
  if(props.checkBox){
    return CheckBoxTwoTone;
  }
  if(props.checkBoxBlank){
    return CheckBoxOutlineBlank;
  }
  if(props.radioButtonChecked){
    return RadioButtonCheckedTwoTone;
  }
  if(props.toggleOn){
    return ToggleOnTwoTone;
  }
  if(props.storage){
    return StorageTwoTone;
  }
  if(props.addCircle){
    return AddCircleTwoTone;
  }
  if(props.removeCircle){
    return RemoveCircleTwoTone;
  }
  if(props.extension){
    return ExtensionTwoTone;
  }
  if (props.scanner) {
    return ScannerTwoTone;
  }
  if (props.colorize) {
    return ColorizeTwoTone;
  }
  if (props.doneAll) {
    return DoneAllTwoTone;
  }
  if (props.workOutline) {
    return WorkOutlineTwoTone;
  }
  if (props.event) {
    return EventTwoTone;
  }
  if (props.schedule) {
    return ScheduleTwoTone;
  }
  if (props.photo) {
    return PhotoTwoTone;
  }
  if (props.dragIndicator) {
    return DragIndicatorTwoTone;
  }
  if (props.accountCircle) {
    return AccountCircleTwoTone;
  }
  if (props.filterList) {
    return FilterListTwoTone;
  }
  if (props.postAdd) {
    return PostAddTwoTone;
  }
  if (props.save) {
    return SaveTwoTone;
  }
  if (props.face) {
    return FaceTwoTone;
  }
  if (props.menuBook) {
    return MenuBookTwoTone;
  }
  if (props.pieChart) {
    return PieChartTwoTone;
  }
  if (props.timeline) {
    return TimelineTwoTone;
  }
  if (props.group) {
    return GroupTwoTone;
  }
  if (props.assessment) {
    return AssessmentTwoTone;
  }
  if (props.calendarViewDay) {
    return CalendarViewDayTwoTone;
  }
  if (props.horizontalSplit) {
    return HorizontalSplitTwoTone;
  }
  if (props.insertDriveFile) {
    return InsertDriveFileTwoTone;
  }
  if (props.home) {
    return HomeTwoTone;
  }
  if (props.map) {
    return MapTwoTone;
  }
  if (props.editLocation) {
    return EditLocationTwoTone;
  }
  if (props.gpsFixed) {
    return GpsFixedTwoTone;
  }
  if (props.cameraFront) {
    return CameraFrontTwoTone;
  }
  if (props.cameraRear) {
    return CameraRearTwoTone;
  }
  if(props.settingsSuggest){
    return SettingsSuggestTwoTone as any;
  }
  if(props.hourglassTop){
    return HourglassTopTwoTone as any
  }
  if(props.report){
    return ReportTwoTone;
  }
  return CameraRearTwoTone;
}
