import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
StackLayout, Text, Modal
} from '@wemogy/reactbase';
import IAttachmentStoreModalProps from './IAttachmentStoreModalProps';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import RestApiServiceFactory from '../../../../../dataLayer/api/rest/RestApiServiceFactory';
import ScrollContainer from '../../../scrollContainer/ScrollContainer';
import { CloudStorageUri } from '../../../../../base/Constants';

const AttachmentStoreModal: React.FC<IAttachmentStoreModalProps> = () => {
  const {formStore,} = useDataAccessStore();
  const [sas, setSas] = useState('');



  const { activeItem } = formStore;
  if(!activeItem){
    return null;
  }

  return (
    <Modal
      onOpened={(): void => {
        RestApiServiceFactory.attachmentStoreService.getSas(activeItem.id).then(apiResult => {
          if (!apiResult.ok) {
            return;
          }
          setSas(apiResult.data);
        });
      }}
      modalKey="attachmentStore"
      icon="fileCopy"
      title="Ablagespeicher Anleitung"
      large
    >
      <StackLayout>
        <ScrollContainer customMaxHeight="80vh">
          <StackLayout>
            <Text>1. Laden Sie sich die Software &quot;Azure Storage Explorer&quot; runter und installieren Sie diese.</Text>
            <a download href="https://digittool.blob.core.windows.net/downloads/StorageExplorer.exe">Zum Download hier klicken</a>
            <Text marginTop>2. Starten Sie den &quot;Azure Storage Explorer&quot; und wählen Sie &quot;Usa a shared access sigiture (SAS) Uri&quot; aus und klicken Sie &quot;next&quot;</Text>
            <img src="/Step-1.PNG" alt="" style={{ height: 500 }} />
            <Text marginTop>3. Tragen Sie &quot;Ablagespeicher&quot; im Displayname Feld ein. Fügen Sie anschließend die folgende URI in das URI Feld ein:</Text>
            <Text noTranslation>{`${CloudStorageUri}form-attachmentstore-${activeItem.id}${sas}`}</Text>
            <img src="/Step-2.PNG" alt="" style={{ height: 500 }} />
            <Text marginTop>4. Klicken Sie auf &quot;connect&quot;, um die Verbindung zum Ablagespeicher herzustellen</Text>
            <img src="/Step-3.PNG" alt="" style={{ height: 500 }} />
            <Text marginTop>5. Der Ablagespeicher wird geöffnet und wird ab jetzt in der Liste links angezeigt. Sie können nun Dateien in den Ablagespeicher hochladen. Diese sind nur innerhalb der aktuellen Datenbank verfügbar.</Text>
            <img src="/Step-4.PNG" alt="" style={{ height: 500 }} />
          </StackLayout>
        </ScrollContainer>
      </StackLayout>
    </Modal>
  );
};

export default observer(AttachmentStoreModal);
