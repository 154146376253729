import { Navigation } from 'navi';
import autobind from 'autobind-decorator';
import _ from 'lodash';

class NavigationManager{
  private navigation: Navigation<any> | undefined;

  private readonly history: string[];

  private readonly initialRouteWasHome: boolean;

  private allRoutePrefix: string | undefined;

  public constructor(){
    this.initialRouteWasHome = this.routeIsHome(window.location.pathname);
    this.history = this.initialRouteWasHome ? [] : ['/'];
    this.history.push(window.location.pathname);
  }

  private routeIsHome(route: string): boolean{
    return route === '/';
  }

  public setNavigation(navigation: Navigation<any>): void{
    this.navigation = navigation;
  }

  public setAllRoutePrefix(allRoutePrefix: string): void{
    this.allRoutePrefix = allRoutePrefix;
  }

  public navigate(route: string, replace: boolean = false): void{
    if(this.allRoutePrefix){
      route = `${this.allRoutePrefix}${route}`;
    }
    // ensure that we have not a route twice after each other in history
    if(_.last(this.history) !== route){
      this.history.push(route);
    }
    this.navigation?.navigate(route, {replace});
  }

  public navigateBackWhile(_shouldNavigate: (uri: string) => boolean): void{
    // ToDo: you see the x navigation steps => not nice
    // const numberOfGoBackCalls = _.takeRightWhile(this.history, x => shouldNavigate(x)).length;
    // ToDo: this is not the right way
    window.history.go(-this.history.length);
  }

  @autobind
  public navigateBack(): void{
    if(this.history.length === 1 && !this.initialRouteWasHome && !this.routeIsHome(this.history[0])){
      this.navigateHome();
      this.history.pop();
      return;
    }
    this.history.pop();
    this.navigation?.goBack();
  }

  @autobind
  public navigateHome(): void{
    console.log('this.navigation.extractState()', this.navigation?.getCurrentValue());
    this.navigate('/');
  }
}

export default new NavigationManager();
