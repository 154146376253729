import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, Icon
} from '@wemogy/reactbase';
import IInvitationProps from './IInvitationProps';
import { formatToDateString } from '../../wrappers/dateSelector/DateHelpers';
import TooltipHelp from '../../wrappers/tooltipHelp/TooltipHelp';

const Invitation: React.FC<IInvitationProps> = ({
  invitation,
  onResend,
  onRemove
}) => {

  return (
    <StackLayout marginBottom horizontal vCenter borderRadius={0.25} borderColor="green200" border={0.125} padding>
      <StackLayout stretch marginRight hStart>
        <Text noTranslation>{invitation.email}</Text>
        {invitation.userExpiresAt ? (
          <TooltipHelp text="Ablaufdatum">
            <Text danger noTranslation>{formatToDateString(invitation.userExpiresAt)}</Text>
          </TooltipHelp>
        ) : null}
      </StackLayout>
      <Icon xl refresh onPress={onResend} marginRight />
      <Icon xlDanger deleteForever onPress={onRemove} />
    </StackLayout>
  );
};

export default observer(Invitation);
