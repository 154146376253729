import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Text, StackLayout, Button
} from '@wemogy/reactbase';
import ICustomerStatisticsScreenProps from './ICustomerStatisticsScreenProps';
import MainLayout from '../../../layouts/mainLayout/MainLayout';
import ScrollContainer from '../../../wrappers/scrollContainer/ScrollContainer';
import useDataAccessStore from '../../../../dataLayer/stores/hooks/UseDataAccessStore';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import FormStatistics from '../../../containers/statistics/formStatistics/FormStatistics';
import StatisticsJobManagement from '../../../containers/statisticsJobManagement/StatisticsJobManagement'

const CustomerStatisticsScreen: React.FC<ICustomerStatisticsScreenProps> = ({ customerId }) => {
  const { customerStore } = useDataAccessStore();

  useEffect((): void => {
    customerStore.get(customerId);
  }, []);

  const customer = customerStore.getItem(customerId);

  if (!customer) {
    return <LoadingIndicator fullscreen grid />;
  }

  return (
    <MainLayout>
      <StackLayout horizontal marginBottom={2} spaceBetween vCenter>
        <Text largeTitle>{`${customer.companyName} Statistiken`}</Text>
        <StatisticsJobManagement />
        <Button>Exportieren</Button>
      </StackLayout>
      <ScrollContainer stretch>
        <FormStatistics customer={customer} />
      </ScrollContainer>
    </MainLayout>
  );
};

export default observer(CustomerStatisticsScreen);
