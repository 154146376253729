import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, Icon
} from '@wemogy/reactbase';
import { AutoSizer } from 'react-virtualized';
import IFormDesignerProps from './IFormDesignerProps';
import FormRoot from '../formRoot/FormRoot';
import useFormBuilderUiStore from '../../dataLayer/stores/hooks/UseFormBuilderUiStore';
import SegmentedControl from '../../../segmentedControl/SegmentedControl';
import FormSection from '../../dataLayer/enums/FormSection';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';

const FormDesigner: React.FC<IFormDesignerProps> = () => {
  const { formBuilderStore } = useFormBuilderUiStore();
  const [activeTab, setActiveTab] = useState<FormSection>(FormSection.Main);
  const { formStore } = useDataAccessStore();
  return (
    <StackLayout stretch>
      <StackLayout horizontal spaceBetween height={5} marginRight={4}>
        <Text title noTranslation>{`Form Designer: ${formStore.activeItem?.name}`}</Text>
        <Icon
          size={3}
          visibility={formBuilderStore.isEditing}
          visibilityOff={!formBuilderStore.isEditing}
          onPress={(): void => {
            formBuilderStore.setIsEditing(!formBuilderStore.isEditing);
          }}
        />
      </StackLayout>
      <StackLayout horizontal marginBottom marginRight={4}>
        <SegmentedControl
          options={['Dateiname', 'Verschlagwortung', 'Daten']}
          defaultOption="Daten"
          onOptionSelected={(option): void => {
            switch (option) {
              case 'Dateiname':
                setActiveTab(FormSection.Index);
                break;
              case 'Verschlagwortung':
                setActiveTab(FormSection.Keywording);
                break;
              default:
                setActiveTab(FormSection.Main);
                break;
            }
          }}
        />
      </StackLayout>

      <StackLayout stretch>
        <AutoSizer>
          {({
          height, width
        }): JSX.Element => (
          <div style={{
              height, width
            }}
          >
            <FormRoot readonlySections={[]} activeSection={activeTab} />
          </div>
          )}
        </AutoSizer>
      </StackLayout>
    </StackLayout>

  );
};

export default observer(FormDesigner);
