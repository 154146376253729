import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, invokeEventHandler
} from '@wemogy/reactbase';
import IWorkingPackageFormProps from './IWorkingPackageFormProps';
import AnnotatedTextInput from '../../containers/formElements/annotatedTextInput/AnnotatedTextInput';
import AnnotatedFormView from '../../containers/formElements/annotatedFormView/AnnotatedFormView';
import DateSelector from '../../../dateSelector/DateSelector';
import WorkingPackageMemberManagement from '../../../../plugins/authorization/memberManagements/WorkingPackageMemberManagement';

const WorkingPackageForm: React.FC<IWorkingPackageFormProps> = ({
  workingPackage, onFinished, addMode
}) => {

  return (
    <StackLayout minHeight={50} padding>
      <AnnotatedTextInput placeholder="Name des Arbeitspakets" description="Name des Arbeitspakets" value={workingPackage.name} onChange={workingPackage.setName} marginBottom />
      <AnnotatedTextInput
        numeric
        placeholder="Maximale Anzahl an Datensätzen"
        description="Maximale Anzahl an Datensätzen"
        value={workingPackage.maximumDataItems?.toString() || ''}
        onChange={(val): void => {
          if (!val) {
            workingPackage.unsetMaximumDataItems();
          } else {
            workingPackage.setMaximumDataItems(parseInt(val));
          }
        }}
        marginBottom
      />
      <AnnotatedFormView description="Zeitraum">
        <DateSelector
          startDate={workingPackage.begin}
          endDate={workingPackage.end}
          minDate={new Date()}
          onStartDateChanged={workingPackage.setBegin}
          onEndDateChanged={workingPackage.setEnd}
        />
      </AnnotatedFormView>
      {addMode ? null : (
        <AnnotatedFormView description="Benutzer">
          <StackLayout height={40}>
            <WorkingPackageMemberManagement resourceId={workingPackage.id} />
          </StackLayout>
        </AnnotatedFormView>
)}
      <StackLayout marginTop={2} horizontal hEnd height={4}>
        {
          workingPackage.isValid ? (
            <Button onPress={(): void => {
              invokeEventHandler(onFinished);
            }}
            >
              {addMode ? 'Hinzufügen' : 'Speichern'}
            </Button>
          ): null
        }
      </StackLayout>
    </StackLayout>
  );
};

export default observer(WorkingPackageForm);
