import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import ContainerDimensions from 'react-container-dimensions';
import { StackLayout, Icon } from '@wemogy/reactbase';
import IFileRendererAdvancedProps from './IFileRendererAdvancedProps';
import FileRenderer from '../fileRenderer/FileRenderer';
import FileFormat from '../../formBuilder/dataLayer/enums/FileFormat';

const FileRendererAdvanced: React.FC<IFileRendererAdvancedProps> = ({
  format,
  uri,
  onOutlineSave
}) => {
  const [activeScale] = useState<number[]>([1]);
  console.log('FileRenderer Advanced');

  return (
    <StackLayout stretch>
      <ContainerDimensions>
        { ({
          width, height
        }): JSX.Element => format === FileFormat.Pdf ? (
          <FileRenderer width={width} height={height} format={format} uri={uri} onOutlineSave={onOutlineSave} />
        ) :(
          <TransformWrapper
            onZoomChange={({scale}: any): void => {
            // HACK, because setState will trigger re-render which breaks the zoom function
              activeScale.length = 0;
              activeScale.push(scale);
            }}
            options={{}}
            // disabled, to avoid zoom on fast next/previous click
            doubleClick={{disabled: true}}
          >
            {({
              zoomIn, zoomOut, resetTransform
            }: any): JSX.Element => (
              <>
                <StackLayout zIndex={3} positionAbsolute top right={0} borderRadius={0.5} backgroundColor="green100">
                  <Icon xl onPress={zoomIn} zoomIn />
                  <Icon xl onPress={zoomOut} zoomOut />
                  <Icon xl onPress={resetTransform} fullscreen />
                </StackLayout>
                <TransformComponent>
                  <FileRenderer width={width} height={height} format={format} uri={uri} onOutlineSave={onOutlineSave} />
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        )}
      </ContainerDimensions>
    </StackLayout>
  );
};

export default observer(FileRendererAdvanced);
