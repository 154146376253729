import React from 'react';
import { observer } from 'mobx-react';
import { Item } from 'react-contexify';
import {
  StackLayout, Text, Icon, useContextMenuStore, ContextMenu, JsonPatchDocument
} from '@wemogy/reactbase';
import {useSearchAndReplace} from '@wemogy/reactbase-search-replace'
import IControlContextMenuProps from './IControlContextMenuProps';
import useDataItemStore from '../../dataLayer/stores/hooks/UseDataItemStore';
import { IControl } from '../../dataLayer/models/Control';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import DataType from '../../dataLayer/enums/DataType';
import NotificationManager from '../../../inAppFeedback/NotificationManager';
import FormPermissionFeature from '../../../../plugins/authorization/permissionFeatures/FormPermissionFeature';

const ControlContextMenu: React.FC<IControlContextMenuProps> = () => {
  const dataItemStore = useDataItemStore() as any;
  const { formStore } = useDataAccessStore();
  const { openSearchAndReplaceModal } = useSearchAndReplace(
    dataItemStore,
    (dataItemId: string, patch: JsonPatchDocument) => dataItemStore.patchDataItem(formStore.activeItem?.id, dataItemId, patch)
  );

  const contextMenuStore = useContextMenuStore();


    return (
      <ContextMenu contextMenuKey="control">
        <FormPermissionFeature requiredPermission="CanUseSearchReplace">
          <Item onClick={(): void => {
            const control = contextMenuStore.getActiveParameters<IControl>('control');

            if (!control) {
              return;
            }

            if (control.dataType !== DataType.String) {
              NotificationManager.error('Der Datentyp wird von Suchen und Ersetzen nicht unterstützt');
              return;
            }

            openSearchAndReplaceModal(`data.${control.destinationName}`, (dataItem: any): string => {
              return formStore.activeItem?.getDataItemName(dataItem) || '';
            }, (dataItem: any, newValue: string): void => {
              dataItem.setPropertyValue(control.destinationName, newValue)
            });
          }}
          >
            <StackLayout vCenter padding horizontal>
              <Icon marginRight size={2} findInPage />
              <Text>Suchen und Ersetzen</Text>
            </StackLayout>
          </Item>
        </FormPermissionFeature>
      </ContextMenu>
    )
}

export default observer(ControlContextMenu);
