import {
  RestService, ApiResult, ApiWrapper
} from '@wemogy/reactbase';
import LoginRequest from '../../dtos/authentication/LoginRequest';
import LoginResult from '../../dtos/authentication/LoginResult';
import SetupMfaRequest from '../../dtos/authentication/SetupMfaRequest';
import LoginMfaRequest from '../../dtos/authentication/LoginMfaRequest';

export default class AuthenticationService extends RestService<any>{
  public constructor(apiWrapper: ApiWrapper){
    super(apiWrapper, 'auth/');
  }

  public login(loginRequest: LoginRequest): Promise<ApiResult<string>>{
    return this.apiWrapper.post(`${this.restEndpoint}login`, loginRequest);
  }

  public loginMfa(loginMfaRequest: LoginMfaRequest): Promise<ApiResult<LoginResult>>{
    return this.apiWrapper.post(`${this.restEndpoint}login-mfa`, loginMfaRequest);
  }

  public setupMfa(request: SetupMfaRequest): Promise<ApiResult<string>>{
    return this.apiWrapper.post(`${this.restEndpoint}setup-mfa`, request);
  }
}
