import React, {
  useEffect, useRef, useState
} from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, Text, useTheme, generateGuid, Modal, useModalStore
} from '@wemogy/reactbase';
import { GridLoader } from 'react-spinners';
import { AzureStorageContainer } from '@wemogy/reactbase-azure-blob-storage'
import IAddAttachmentModalProps from './IAddAttachmentModalProps';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import AnnotatedFormView from '../../containers/formElements/annotatedFormView/AnnotatedFormView';
import RestApiServiceFactory from '../../../../../dataLayer/api/rest/RestApiServiceFactory';
import NotificationManager from '../../../inAppFeedback/NotificationManager';
import useDataItemStore from '../../dataLayer/stores/hooks/UseDataItemStore';
import Attachment from '../../dataLayer/models/Attachment';
import IAssignAttachmentModalParameters from '../assignAttachmentModal/IAssignAttachmentModalParameters';
import { buildAttachmentUri } from '../../base/Conventions';
import AzureBlobStorage from '../../../azureBlobStorage/AzureBlobStorage'
import IAddAttachmentModelParameters from './interfaces/IAddAttachmentModelParameters';
import { resolveFormat } from '../../dataLayer/enums/FileFormat';
import { CloudStorageUri } from '../../../../../base/Constants';
import FormOrWorkingPackagePermissionFeature from '../../../../plugins/authorization/permissionFeatures/FormOrWorkingPackagePermissionFeature';
import useAppStore from '../../../../../dataLayer/stores/hooks/UseAppStore';

const AddAttachmentModal: React.FC<IAddAttachmentModalProps> = () => {
  const { formStore, } = useDataAccessStore();
  const modalStore = useModalStore()
  const [canUploadLocalFile, setCanUploadLocalFile] = useState(false);
  const dataItemStore = useDataItemStore();
  const { activeUserStore } = useAppStore();
  const [sas, setSas] = useState('');
  const theme = useTheme();
  const fileInput = useRef<HTMLInputElement>(null);

  const parameters = modalStore.getActiveParameters<IAddAttachmentModelParameters>('addAttachment');

  const { activeItem } = formStore;
  const activeDataItem = dataItemStore?.activeItem;

  useEffect((): void => {
    if (!activeItem) {
      return;
    }
    RestApiServiceFactory.attachmentStoreService.getSas(activeItem.id).then(apiResult => {
      if (!apiResult.ok) {
        return;
      }
      setSas(apiResult.data);
    });
  }, []);

  if (!activeItem || !activeDataItem || !parameters) {
    return null;
  }

  const {
    addFromLocal, onAttachmentAdded
  } = parameters;

  return (
    <Modal
      onOpened={(): void => {
        setCanUploadLocalFile(false);
      }}
      modalKey="addAttachment"
      icon="fileCopy"
      title={addFromLocal ? 'Lokale Datei zuordnen' : 'Neue Datei zuordnen'}
      large
    >
      <StackLayout height100>

        {
          addFromLocal ? (
            <AnnotatedFormView description="Neue lokale Datei hochladen" marginBottom>
              <form id="AddAttachmentModalForm">
                <input
                  onChange={(): void => {
                    setCanUploadLocalFile(true);
                  }}
                  name="file"
                  type="file"
                  ref={fileInput}
                />
              </form>
            </AnnotatedFormView>
          ) : (
              <>
                <StackLayout height={{ custom: '45vh' }}>
                  <AzureStorageContainer
                    containerUriWithSas={`${CloudStorageUri}form-attachmentstore-${activeItem?.id}${sas}`}
                    onBlobSelected={(entry): void => {
                      modalStore.openModal('assignAttachment', {
                        uri: entry.uri,
                        format: resolveFormat(entry.name),
                        onConfirmed: async (): Promise<void> => {
                          modalStore.openModal('loadingOverlay', 'Die Datei wird zugewiesen, einen Moment bitte.');
                          const apiResult = await RestApiServiceFactory.attachmentStoreService.assignAttachmentToDataItem(activeItem.id, activeDataItem.id, entry.fullName);
                          if (!apiResult.ok) {
                            NotificationManager.error('Es ist ein Fehler aufgetreten');
                            modalStore.closeModal();
                            return;
                          }
                          const attachment = Attachment.create(apiResult.data);

                          dataItemStore?.dataItem?.addAttachment(attachment);
                          onAttachmentAdded(attachment)
                          NotificationManager.success('Die Datei wurde erfolgreich zugewiesen.');
                          modalStore.closeModal();
                        },
                        onDeclined: (): void => {
                          modalStore.openModal('addAttachment');
                        }
                      } as IAssignAttachmentModalParameters);
                    }}
                    emptyRenderer={(): JSX.Element => (
                      <StackLayout hCenter marginTop={4}>
                        <Text marginBottom>Es sind noch keine Dateien im Ablagespeicher</Text>
                        <FormOrWorkingPackagePermissionFeature requiredPermission="CanUploadToAttachmentStore">
                          <Button onPress={(): void => {
                            modalStore.openModal('attachmentStore');
                          }}
                          >
                            Anleitung zum Ablagespeicher öffnen

                        </Button>
                        </FormOrWorkingPackagePermissionFeature>
                      </StackLayout>
                    )}
                    loadingRenderer={(): JSX.Element => (
                      <StackLayout hCenter>
                        <GridLoader size={40} color={theme.colorScheme.primary} />
                      </StackLayout>
                    )}
                  />
                </StackLayout>
                <StackLayout horizontal hCenter>
                  <FormOrWorkingPackagePermissionFeature requiredPermission="CanUploadToAttachmentStore">
                    <Button onPress={(): void => {
                      modalStore.openModal('attachmentStore');
                    }}
                    >
                      Anleitung öffnen
                  </Button>
                  </FormOrWorkingPackagePermissionFeature>
                </StackLayout>
              </>
            )
        }



        {
          addFromLocal && canUploadLocalFile ? (
            <StackLayout hEnd marginTop height={4}>
              <Button onPress={(): void => {
                if (!fileInput.current?.files) {
                  return;
                }

                const file = fileInput.current.files[0];

                if (!file) {
                  return;
                }

                modalStore.openModal('loadingOverlay', 'Die Datei wird hochgeladen');
                const uploadId = generateGuid();

                AzureBlobStorage.upload(
                  uploadId,
                  file,
                  'users',
                  activeUserStore.userUploadSas,
                  { filename: file.name },
                  (progress, secondsRemaining): void => {
                    modalStore.openModal('loadingOverlay', `${progress}% hochgeladen. (${secondsRemaining}s verbleibend)`);
                  },
                  (): void => {
                    modalStore.closeModal();
                    modalStore.openModal('loadingOverlay', 'Verarbeite Upload.');
                    (async (): Promise<void> => {
                      try {

                        const response = await RestApiServiceFactory.getDataItemService(activeItem.id).uploadAttachment(activeItem.id, activeDataItem.id, uploadId);
                        if (response.ok) {
                          NotificationManager.success('Upload erfolgreich abgeschlossen.');
                          const json = await response.json();
                          const attachment = Attachment.create(json);
                          activeDataItem.addAttachment(attachment);
                          modalStore.openModal('assignAttachment', {
                            uri: buildAttachmentUri(activeItem, attachment),
                            format: attachment.fileFormat,
                            onDeclined: (): void => {
                              modalStore.openModal('loadingOverlay', 'Die Datei wird gelöscht');
                              RestApiServiceFactory.getDataItemService(activeItem.id).deleteAttachment(activeItem.id, activeDataItem.id, attachment.id).then((res): void => {
                                modalStore.closeModal();
                                if (res.ok) {
                                  NotificationManager.success('Die Datei wurde erfolgreich gelöscht.');
                                  activeDataItem.removeAttachment(attachment);
                                }
                                else {
                                  NotificationManager.error(`Beim Löschen ist etwas schief gelaufen. Statuscode: ${res.status}`);
                                }
                              });
                            },
                            onConfirmed: (): void => {
                              onAttachmentAdded(attachment);
                              modalStore.closeModal();
                              NotificationManager.success('Die Datei wurde erfolgreich zugewiesen.');
                            }
                          } as IAssignAttachmentModalParameters);
                        }
                        else {
                          modalStore.closeModal();
                          NotificationManager.error(`Beim Upload ist etwas schief gelaufen. Statuscode: ${response.status}`);
                        }
                      } catch {
                        modalStore.closeModal();
                      }
                    })();
                  },
                  (x): void => {
                    modalStore.closeModal();
                    NotificationManager.error(`Beim Upload ist etwas schief gelaufen. ${x}`);
                  }
                );
              }}
              >
                Datei hochladen und zuordnen
              </Button>
            </StackLayout>
          ) : null
        }


      </StackLayout>
    </Modal>
  );
};

export default observer(AddAttachmentModal);
