import {
  types, Instance, SnapshotIn, SnapshotOut, getParent
} from 'mobx-state-tree';
import {BaseModel, isEmptyOrWhiteSpace} from '@wemogy/reactbase';
import ControlType, {ControlTypeType} from '../enums/ControlType';
import  { IDataSource } from './DataSource';
import DataType, {DataTypeType} from '../enums/DataType';
import ValidationRule from './ValidationRule';
import ControlDataSource, { IControlDataSource } from './ControlDataSource';
import { ILayout } from './Layout';
import FormSection from '../enums/FormSection';
import ControlQrCodeSource, { IControlQrCodeSource } from './ControlQrCodeSource';
import { IQrCodeSource } from './QrCodeSource';

const ControlSubType = types.model({
  type: ControlTypeType,
  dataType: DataTypeType,
  orderId: types.number,
  // ToDo: validation rules will be implemented in another sprint
  validationRules: types.optional(types.array(ValidationRule), []),
  defaultValue: types.maybe(types.string),
  isRequired: types.optional(types.boolean, false),
  readOnly: types.optional(types.boolean, false),
  dataSources: types.optional(types.array(ControlDataSource), []),
  qrCodeSources: types.optional(types.array(ControlQrCodeSource), []),
  destinationName: types.optional(types.string, ''),
  config: types.maybe(types.string),
  notes: types.optional(types.string, ''),
  tooltip: types.optional(types.string, ''),
})
  .named('ControlSubType')
  .views(self => ({
    get hasTooltip(): boolean{
      return !isEmptyOrWhiteSpace(self.tooltip);
    },
    get hasAnyDataSource(): boolean{
      return self.dataSources.length > 0;
    },
    get controlName(): string{
      switch (self.type) {
        case ControlType.Input:
          switch (self.dataType) {
            case DataType.String:
              return 'Text';
            case DataType.Int:
              return 'Ganzzahl';
            case DataType.Double:
              return 'Kommazahl';
            default:
              return '?';
          }
        case ControlType.Checkbox:
          return 'Checkbox';
        case ControlType.Dropdown:
          return 'Dropdown';
        case ControlType.Radio:
          return 'Einzelauswahl';
        case ControlType.CheckGroup:
          return 'Mehrfachauswahl';
        default:
          return '?';
      }
    },
    hasDataSource(dataSourceId: string): boolean{
      return self.dataSources.find(x => x.id === dataSourceId) !== undefined;
    },
    getControlDataSource(dataSource: IDataSource): IControlDataSource | undefined{
      return self.dataSources.find(x => x.id === dataSource.id);
    },
    hasQrCodeSource(qrCodeSourceId: string): boolean{
      return self.qrCodeSources.find(x => x.id === qrCodeSourceId) !== undefined;
    },
    getControlQrCodeSource(qrCodeSource: IQrCodeSource): IControlQrCodeSource | undefined{
      return self.qrCodeSources.find(x => x.id === qrCodeSource.id);
    },
    getDefaultValue<T>(): T | undefined{
      if(!self.defaultValue){
        return undefined;
      }
      return JSON.parse(self.defaultValue);
    },
    getConfig<T>(): T | undefined{
      if(!self.config){
        return undefined;
      }
      return JSON.parse(self.config);
    },
    get formSection(): FormSection | undefined{
      const layout = getParent(getParent(self)) as ILayout;
      return layout?.formSection as FormSection | undefined;
    },
    get isMainFormSection(): boolean{
      return this.formSection === FormSection.Main;
    }
  }))
  .actions(self => ({
    setDefaultValueRaw(defaultValue: string): void{
      self.defaultValue = defaultValue;
    },
    setDefaultValue(defaultValue: any): void{
      self.defaultValue = JSON.stringify(defaultValue);
    },
    unsetDefaultValue(): void{
      self.defaultValue= undefined;
    },
    setIsRequired(isRequired: boolean): void{
      self.isRequired = isRequired ;
      if(!isRequired){
        self.defaultValue = undefined;
      }
    },
    setReadOnly(readOnly: boolean): void{
      self.readOnly = readOnly;
    },
    addDataSource(dataSource: IDataSource): void{
      self.dataSources.push(ControlDataSource.create({id: dataSource.id}));
    },
    removeDataSource(dataSource: IDataSource): void{
      const dataSourceToRemove = self.dataSources.find(x => x.id === dataSource.id);
      if (!dataSourceToRemove) {
        return;
      }
      self.dataSources.remove(dataSourceToRemove);
    },
    addQrCodeSource(qrCodeSource: IQrCodeSource): void{
      self.qrCodeSources.push(ControlQrCodeSource.create({id: qrCodeSource.id}));
    },
    removeQrCodeSource(qrCodeSource: IQrCodeSource): void{
      const qrCodeSourceToRemove = self.qrCodeSources.find(x => x.id === qrCodeSource.id);
      if (!qrCodeSourceToRemove) {
        return;
      }
      self.qrCodeSources.remove(qrCodeSourceToRemove);
    },
    setDestinationName(destinationName: string): void{
      destinationName = destinationName.replace(/[^A-Za-z0-9-_]/g, '');
      self.destinationName = destinationName;
    },
    setConfig(config: any): void{
      self.config = JSON.stringify(config);
    },
    setConfigIfUndefined<T>(defaultConfig: T): void{
      const config = self.getConfig<T>();
      if(config){
        return;
      }
      this.setConfig(defaultConfig);
    },
    updateConfig<T>(updateAction: (config: T) => void): void{
      const config = self.getConfig<T>();
      if(!config){
        return;
      }
      updateAction(config);
      this.setConfig(config);
    },
    setTooltip(tooltip: string): void{
      self.tooltip = tooltip;
    },
    setNotes(notes: string): void{
      self.notes = notes;
    },
    incrementOrderId(incrementAmount: number = 1): void{
      self.orderId += incrementAmount;
    },
    decrementOrderId(decrementAmount: number = 1): void{
      self.orderId -= decrementAmount;
    },
    setOrderId(orderId: number): void{
      self.orderId = orderId;
    },
  }));

const Control = types.compose(BaseModel, ControlSubType)
  .named('Control');
export default Control;

export interface IControl extends Instance<typeof Control>{}
export interface IControlSnapshotIn extends SnapshotIn<typeof Control>{}
export interface IControlSnapshotOut extends SnapshotOut<typeof Control>{}
