import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, Button, Icon, useModalStore
} from '@wemogy/reactbase';
import { AutoSizer } from 'react-virtualized';
import ICollectorModeProps from './ICollectorModeProps';
import FormRoot from '../../wrappers/formBuilder/containers/formRoot/FormRoot';
import SegmentedControl from '../../wrappers/segmentedControl/SegmentedControl';
import FormSection from '../../wrappers/formBuilder/dataLayer/enums/FormSection';
import useDataItemStore from '../../wrappers/formBuilder/dataLayer/stores/hooks/UseDataItemStore';
import DataItemTable from '../../wrappers/formBuilder/components/dataItemTable/DataItemTable';
import FileRendererAdvanced from '../../wrappers/fileRenderer/fileRendererAdvanced/FileRendererAdvanced';
import RestApiServiceFactory from '../../../dataLayer/api/rest/RestApiServiceFactory';
import useDataAccessStore from '../../../dataLayer/stores/hooks/UseDataAccessStore';
import useUiStore from '../../../dataLayer/stores/hooks/UseUiStore';
import { buildAttachmentUri } from '../../wrappers/formBuilder/base/Conventions';
import NotificationManager from '../../wrappers/inAppFeedback/NotificationManager';
import DataItemComments from '../../wrappers/formBuilder/containers/dataItemComments/DataItemComments';
import ScrollContainer from '../../wrappers/scrollContainer/ScrollContainer';
import { formatToShortDateTimeString } from '../../wrappers/dateSelector/DateHelpers';
import Attachment from '../../wrappers/formBuilder/dataLayer/models/Attachment';
import { IDataItem } from '../../wrappers/formBuilder/dataLayer/models/genericModels/DataItem';
import StatusLogEntryList from '../../wrappers/formBuilder/containers/statusLogEntryList/StatusLogEntryList';
import FilenamePreview from '../../components/filenamePreview/FilenamePreview'
import AttachmentList from '../attachmentList/AttachmentList';
import IAddAttachmentModelParameters from '../../wrappers/formBuilder/modals/addAttachmentModal/interfaces/IAddAttachmentModelParameters';
import useAppStore from '../../../dataLayer/stores/hooks/UseAppStore';
import TooltipHelp from '../../wrappers/tooltipHelp/TooltipHelp';
import FormPermissionFeature from '../../plugins/authorization/permissionFeatures/FormPermissionFeature';
import FormOrWorkingPackagePermissionFeature from '../../plugins/authorization/permissionFeatures/FormOrWorkingPackagePermissionFeature';
import useFormOrWorkingPackageFeature from '../../plugins/authorization/hooks/UseFormOrWorkingPackageFeature';
/*
const filterOptions: IDropdownOption[] = [
  {
    label: 'Alle',
    value: 'all'
  },
  {
    label: 'Nur geprüfte',
    value: 'proofed'
  },
  {
    label: 'Nur ungeprüfte',
    value: 'unproofed'
  },
  {
    label: 'Nur abgeschlossene',
    value: 'completed'
  },
]; */

const CollectorMode: React.FC<ICollectorModeProps> = ({
  form, activeAttachment, onActiveAttachmentChanged, onActiveItemChanged
}) => {
  const [showNavigation, setShowNavigation] = useState(true);
  const [showStaticInfo, setShowStaticInfo] = useState(true);
  const [activeMainSection, setActiveMainSection] = useState('Erfassung');

  const dataItemStore = useDataItemStore();
  const {
  formStore, userStore
} = useDataAccessStore();
  const {
    clipboardStore, collectorModeStore
  } = useUiStore();
  const modalStore = useModalStore();
  const { activeUserStore } = useAppStore();
  const { hasOnActiveFormOrWorkingPackagePermission } = useFormOrWorkingPackageFeature();

  if (!dataItemStore) {
    return <Text>No dataItemStore</Text>;
  }

  const {
  activeItem, dataItem
} = dataItemStore;
  const activeForm = formStore.activeItem;

  if (!activeForm) {
    return null;
  }

  const activeFileUri = activeAttachment && activeItem ? buildAttachmentUri(activeForm, activeAttachment) : undefined;
  const activeFileFormat = activeAttachment?.fileFormat;

  console.log('activeFileFormat', activeFileUri)

  const readonlyFormSections: FormSection[] = [];

  if(!hasOnActiveFormOrWorkingPackagePermission('CanPerformSampling')){
    if (!hasOnActiveFormOrWorkingPackagePermission('CanCollectData') && !hasOnActiveFormOrWorkingPackagePermission('CanPerformQualityAssurance')) {
      readonlyFormSections.push(FormSection.Main);
    }
    if (!hasOnActiveFormOrWorkingPackagePermission('CanIndexData')) {
      readonlyFormSections.push(FormSection.Index, FormSection.Keywording);
    }
  }

  const saveSampleResult = (verified: boolean): void => {
    const { userId } = activeUserStore;
    if (!userId || !dataItem) {
      return;
    }
    modalStore.openModal('loadingOverlay', 'Speichere Ergebnis')
    dataItem.status.verifyDataCollection(verified, userId);
    RestApiServiceFactory.getDataItemService(form.id).putStatus(form.id, dataItem.id, dataItem.status).then((response): void => {
      if (response.ok) {
        NotificationManager.success('Ergebnis gespeichert');
        if (!verified) {
          dataItemStore.clearSelectedItems();
          dataItemStore.unsetActiveItem();
          dataItemStore.clearItems();
          collectorModeStore.setSampleModeActive(false);
          modalStore.openModal('startSample');
        }
      }
      else {
        NotificationManager.error('Ergebnis konnte nicht gespeichert werden');
      }
      if (verified) {
        modalStore.closeModal();
      }
    })
      .catch((): void => {
        modalStore.closeModal();
        NotificationManager.error('Ergebnis konnte nicht gespeichert werden');
      });
  }

  return (
    <StackLayout stretch>
      <StackLayout horizontal>
        <StackLayout>
          <StackLayout horizontal vCenter>
            <StackLayout stretch height={0.5} backgroundColor="success" />
            <StackLayout size={5} border={0.5} borderRadius={2.5} hCenter vCenter borderColor="green500" backgroundColor="green100">
              <Icon getApp xl color="green900" />
            </StackLayout>
            <StackLayout stretch height={0.5} backgroundColor="success" />
          </StackLayout>
          <StackLayout hCenter>
            <Text label>Import</Text>
          </StackLayout>
        </StackLayout>
        <StackLayout height={5} vCenter stretch>
          <StackLayout height={0.5} backgroundColor="success" />
        </StackLayout>
        <StackLayout>
          <StackLayout horizontal vCenter>
            <StackLayout stretch height={0.5} backgroundColor="success" />
            <StackLayout size={5} border={0.5} borderRadius={2.5} hCenter vCenter borderColor={activeItem?.hasAttachments ? 'green500' : 'grey500'} backgroundColor={activeItem?.hasAttachments ? 'green100' : 'grey100'}>
              <Icon postAdd xl color="green900" />
            </StackLayout>
            <StackLayout stretch height={0.5} backgroundColor={activeItem?.hasAttachments ? 'green500' : 'grey500'} />
          </StackLayout>
          <StackLayout hCenter>
            <Text label>Zugeordnet</Text>
          </StackLayout>
        </StackLayout>
        <StackLayout height={5} vCenter stretch>
          <StackLayout height={0.5} backgroundColor={activeItem?.hasAttachments ? 'green500' : 'grey500'} />
        </StackLayout>
        <StackLayout>
          <StackLayout horizontal vCenter>
            <StackLayout stretch height={0.5} backgroundColor={activeItem?.hasAttachments ? 'green500' : 'grey500'}  />
            <StackLayout size={5} border={0.5} borderRadius={2.5} hCenter vCenter borderColor={activeItem?.status.isIndexed ? 'green500' : 'grey500'} backgroundColor={activeItem?.status.isIndexed ? 'green100' : 'grey100'}>
              <Icon menuBook xl color="green900" />
            </StackLayout>
            <StackLayout stretch height={0.5} backgroundColor={activeItem?.status.isIndexed ? 'green500' : 'grey500'} />
          </StackLayout>
          <StackLayout hCenter>
            <Text label>Indexiert</Text>
          </StackLayout>
        </StackLayout>
        <StackLayout height={5} vCenter stretch>
          <StackLayout height={0.5} backgroundColor={activeItem?.status.isIndexed ? 'green500' : 'grey500'} />
        </StackLayout>
        <StackLayout>
          <StackLayout horizontal vCenter>
            <StackLayout stretch height={0.5} backgroundColor={activeItem?.status.isIndexed ? 'green500' : 'grey500'} />
            <StackLayout size={5} border={0.5} borderRadius={2.5} hCenter vCenter borderColor={activeItem?.hasWorkingPackage ? 'green500' : 'grey500'} backgroundColor={activeItem?.hasWorkingPackage ? 'green100' : 'grey100'}>
              <Icon workOutline xl color="grey900" />
            </StackLayout>
            <StackLayout stretch height={0.5} backgroundColor={activeItem?.hasWorkingPackage ? 'green500' : 'grey500'} />
          </StackLayout>
          <StackLayout hCenter>
            <Text label>Arbeitspaket</Text>
          </StackLayout>
        </StackLayout>
        <StackLayout height={5} vCenter stretch>
          <StackLayout height={0.5} backgroundColor={activeItem?.hasWorkingPackage ? 'green500' : 'grey500'} />
        </StackLayout>
        <StackLayout>
          <StackLayout horizontal vCenter>
            <StackLayout stretch height={0.5} backgroundColor={activeItem?.hasWorkingPackage ? 'green500' : 'grey500'} />
            <StackLayout size={5} border={0.5} borderRadius={2.5} hCenter vCenter borderColor={activeItem?.status.isDataCollected ? 'green500' : 'grey500'} backgroundColor={activeItem?.status.isDataCollected ? 'green100' : 'grey100'}>
              <Icon edit xl color="grey900" />
            </StackLayout>
            <StackLayout stretch height={0.5} backgroundColor={activeItem?.status.isDataCollected ? 'green500' : 'grey500'} />
          </StackLayout>
          <StackLayout hCenter>
            <Text label>Erfassen</Text>
          </StackLayout>
        </StackLayout>
        <StackLayout height={5} vCenter stretch>
          <StackLayout height={0.5} backgroundColor={activeItem?.status.isDataCollected ? 'green500' : 'grey500'} />
        </StackLayout>
        <StackLayout>
          <StackLayout horizontal vCenter>
            <StackLayout stretch height={0.5} backgroundColor={activeItem?.status.isDataCollected ? 'green500' : 'grey500'} />
            <StackLayout size={5} border={0.5} borderRadius={2.5} hCenter vCenter borderColor={activeItem?.status.isDataQualityAssured ? 'green500' : 'grey500'} backgroundColor={activeItem?.status.isDataQualityAssured ? 'green100' : 'grey100'}>
              <Icon doneAll xl color="grey900" />
            </StackLayout>
            <StackLayout stretch height={0.5} backgroundColor={activeItem?.status.isDataQualityAssured ? 'green500' : 'grey500'} />
          </StackLayout>
          <StackLayout hCenter>
            <Text label>Qualitätssicherung</Text>
          </StackLayout>
        </StackLayout>

        <StackLayout height={5} vCenter stretch>
          <StackLayout height={0.5} backgroundColor={activeItem?.status.isDataQualityAssured ? 'green500' : 'grey500'} />
        </StackLayout>

        <StackLayout>
          <StackLayout horizontal vCenter>
            <StackLayout stretch height={0.5} backgroundColor={activeItem?.status.isDataQualityAssured ? 'green500' : 'grey500'} />
            <StackLayout size={5} border={0.5} borderRadius={2.5} hCenter vCenter borderColor={activeItem?.status.isDataCollectionVerified ? 'green500' : 'grey500'} backgroundColor={activeItem?.status.isDataCollectionVerified ? 'green100' : 'grey100'}>
              <Icon colorize xl color="grey900" />
            </StackLayout>
          </StackLayout>
          <StackLayout hCenter>
            <Text label>Stichprobe</Text>
          </StackLayout>
        </StackLayout>

        <StackLayout height={5} vCenter stretch>
          <StackLayout height={0.5} backgroundColor={activeItem?.status.isDataCollectionVerified ? 'green500' : 'grey500'} />
        </StackLayout>

        <StackLayout>
          <StackLayout horizontal vCenter>
            <StackLayout stretch height={0.5} backgroundColor={activeItem?.status.isDataCollectionVerified ? 'green500' : 'grey500'} />
            <StackLayout size={5} border={0.5} borderRadius={2.5} hCenter vCenter borderColor={activeItem?.status.isExported ? 'green500' : 'grey500'} backgroundColor={activeItem?.status.isExported ? 'green100' : 'grey100'}>
              <Icon publish xl color="grey900" />
            </StackLayout>
          </StackLayout>
          <StackLayout hCenter>
            <Text label>Export</Text>
          </StackLayout>
        </StackLayout>
      </StackLayout>
      <StackLayout marginTop={5} horizontal height={5} marginBottom vCenter>
        <StackLayout horizontal>
          <StackLayout
            horizontal
            vCenter
            padding
            border={0.125}
            borderColor="primary"
            borderRadius={0.25}
            onPress={(): void => {
              setShowNavigation(!showNavigation);
            }}
            marginRight
          >
            <Icon xl visibility={!showNavigation} visibilityOff={showNavigation} marginRight />
            <Text>{showNavigation ? 'Navigation ausblenden' : 'Navigation einblenden'}</Text>
          </StackLayout>
          <StackLayout
            horizontal
            vCenter
            padding
            marginRight={3}
            border={0.125}
            borderColor="primary"
            borderRadius={0.25}
            onPress={(): void => {
              setShowStaticInfo(!showStaticInfo);
            }}
          >
            <Icon xl info marginRight />
            <Text>{showStaticInfo ? 'Infos ausblenden' : 'Infos einblenden'}</Text>
          </StackLayout>
        </StackLayout>
        <Text title marginRight={2}>{form.name}</Text>
        {hasOnActiveFormOrWorkingPackagePermission('CanPerformSampling') ? collectorModeStore.sampleModeActive ? (
          <StackLayout horizontal>
            <Button
              onPress={(): void => {
                saveSampleResult(false);
            }}
              danger
            >
              Stichprobe nicht bestanden

            </Button>
            <StackLayout width />
            <Button onPress={(): void => {
              saveSampleResult(true);
            }}

            >
              Stichprobe bestanden

            </Button>
          </StackLayout>
        ) : (
          <Button onPress={(): void => {
  modalStore.openModal('startSample');
}}
          >
            Stichprobe starten

          </Button>
        ) : null}
      </StackLayout>
      <StackLayout stretch horizontal>
        {
          showNavigation ? (
            <StackLayout width={{custom: showNavigation ? '20%' : 0}}>
              <DataItemTable showStaticInfo={showStaticInfo} onItemPressed={onActiveItemChanged} />
            </StackLayout>
          ) : null
        }
        {activeItem && dataItem ? (
          <StackLayout maxHeight100 paddingLeft={showNavigation} paddingRight width={{custom: showNavigation ? '40%' : '50%'}}>
            <StackLayout horizontal marginBottom>
              <SegmentedControl
                options={['Indexierung', 'Erfassung', 'Status']}
                defaultOption="Erfassung"
                onOptionSelected={(option): void => {
                  setActiveMainSection(option);
                }}
              />
            </StackLayout>
            <StackLayout stretch>
              <AutoSizer>
                {({
  height, width
}): JSX.Element => (
  <div style={{
height, width
}}
  >
    {activeMainSection === 'Status' ? (
      <ScrollContainer customMaxHeight="100%" border={0.5} borderColor="grey700" backgroundColor="grey100" padding={2}>
        <Text title>Status</Text>
        <StackLayout marginTopBottom height={0.5} backgroundColor="primary" />
        <StackLayout horizontal vCenter>
          <Text color="green500" marginRight>Letzte Änderung</Text>
          <StackLayout width={0.5} />
          <Text noTranslation>{formatToShortDateTimeString(activeItem?.updatedAt)}</Text>
        </StackLayout>
        <StackLayout horizontal vCenter>
          <Text color="green500" marginRight>Arbeitspaket</Text>
          <StackLayout width={0.5} />
          <Text noTranslation>{activeItem?.workingPackageId ? activeForm.getWorkingPackage(activeItem?.workingPackageId)?.name : '-'}</Text>
        </StackLayout>
        <StackLayout horizontal vCenter>
          <Text color="green500" marginRight>DigitTool Datensatz ID</Text>
          <StackLayout width={0.5} />
          <Text noTranslation>{activeItem.id}</Text>
        </StackLayout>
        {activeForm.artSettings.featureEnabled && (
        <>
          <Text title marginTop={2}>ART Status</Text>
          <StackLayout marginTopBottom height={0.5} backgroundColor="primary" />
          <StackLayout horizontal vCenter>
            <Text color="green500" marginRight>Erfasst</Text>
            <StackLayout width={0.5} />
            <Text noTranslation>{activeItem?.artHistory.capturedLog ? userStore.getItem(activeItem?.artHistory.capturedLog.issuerId)?.fullname || 'unbekannt' : '-'}</Text>
            <StackLayout width={0.25} />
            {activeItem?.artHistory.capturedLog ? <Text noTranslation>{`(am ${formatToShortDateTimeString(activeItem?.artHistory.capturedLog.timestamp)})`}</Text> : null}
          </StackLayout>
          <StackLayout horizontal vCenter>
            <Text color="green500" marginRight>Verpackt</Text>
            <StackLayout width={0.5} />
            <Text noTranslation>{activeItem?.artHistory.packedLog ? userStore.getItem(activeItem?.artHistory.packedLog.issuerId)?.fullname || 'unbekannt' : '-'}</Text>
            <StackLayout width={0.25} />
            {activeItem?.artHistory.packedLog ? <Text noTranslation>{`(am ${formatToShortDateTimeString(activeItem?.artHistory.packedLog.timestamp)})`}</Text> : null}
          </StackLayout>
          <StackLayout horizontal vCenter>
            <Text color="green500" marginRight>Abgeholt</Text>
            <StackLayout width={0.5} />
            <Text noTranslation>{activeItem?.artHistory.pickedUpLog ? userStore.getItem(activeItem?.artHistory.pickedUpLog.issuerId)?.fullname || 'unbekannt' : '-'}</Text>
            <StackLayout width={0.25} />
            {activeItem?.artHistory.pickedUpLog ? <Text noTranslation>{`(am ${formatToShortDateTimeString(activeItem?.artHistory.pickedUpLog.timestamp)})`}</Text> : null}
          </StackLayout>
          <StackLayout horizontal vCenter>
            <Text color="green500" marginRight>Digitalisiert</Text>
            <StackLayout width={0.5} />
            <Text noTranslation>{activeItem?.artHistory.digitizedLog ? userStore.getItem(activeItem?.artHistory.digitizedLog.issuerId)?.fullname || 'unbekannt' : '-'}</Text>
            <StackLayout width={0.25} />
            {activeItem?.artHistory.digitizedLog ? <Text noTranslation>{`(am ${formatToShortDateTimeString(activeItem?.artHistory.digitizedLog.timestamp)})`}</Text> : null}
          </StackLayout>
          <StackLayout horizontal vCenter>
            <Text color="green500" marginRight>Bereitgestellt</Text>
            <StackLayout width={0.5} />
            <Text noTranslation>{activeItem?.artHistory.providedLog ? userStore.getItem(activeItem?.artHistory.providedLog.issuerId)?.fullname || 'unbekannt' : '-'}</Text>
            <StackLayout width={0.25} />
            {activeItem?.artHistory.providedLog ? <Text noTranslation>{`(am ${formatToShortDateTimeString(activeItem?.artHistory.providedLog.timestamp)})`}</Text> : null}
          </StackLayout>
          <StackLayout horizontal vCenter>
            <Text color="green500" marginRight>Fehler</Text>
            <StackLayout width={0.5} />
            <Text noTranslation>{activeItem?.artHistory.errorLog ? userStore.getItem(activeItem?.artHistory.errorLog.issuerId)?.fullname || 'unbekannt' : '-'}</Text>
            <StackLayout width={0.25} />
            {activeItem?.artHistory.errorLog ? <Text noTranslation>{`(am ${formatToShortDateTimeString(activeItem?.artHistory.errorLog.timestamp)})`}</Text> : null}
          </StackLayout>
        </>
)}
        <Text title marginTop={5}>Dateien</Text>
        <StackLayout marginTopBottom height={0.5} backgroundColor="primary" />
        <AttachmentList activeAttachment={activeAttachment} dataItem={dataItem} onAttachmentPress={onActiveAttachmentChanged} />
        <StackLayout horizontal hEnd marginTop>
          {
                    clipboardStore.attachmentInClipboard ? (
                      <>
                        <Button onPress={(): void => {
                          if (!clipboardStore.attachmentInClipboardDataItemId || !clipboardStore.attachmentInClipboard) {
                            return;
                          }

                          modalStore.openModal('loadingOverlay');
                          const apiAction = clipboardStore.clipboardIsCopy ? RestApiServiceFactory.attachmentStoreService.copyAttachmentToDataItem : RestApiServiceFactory.attachmentStoreService.moveAttachmentToDataItem;

                          apiAction(activeForm.id, clipboardStore.attachmentInClipboardDataItemId, clipboardStore.attachmentInClipboard, activeItem.id)
                            .then((apiResult): void => {
                              if (!apiResult.ok) {
                                NotificationManager.error(`Es ist ein Fehler aufgetreten: ${apiResult.status}`);
                                return;
                              }

                              const attachment = Attachment.create(apiResult.data);
                              if (clipboardStore.clipboardIsCopy) {
                                dataItem.addAttachment(attachment)
                                onActiveAttachmentChanged(attachment)
                              } else {
                                const sourceDataItem = (clipboardStore.attachmentInClipboardDataItemId ? dataItemStore.getItem(clipboardStore.attachmentInClipboardDataItemId) : undefined) as IDataItem | undefined ;
                                if (sourceDataItem) {
                                  const movedAttachment = sourceDataItem.attachments.find(x => x.id === clipboardStore.attachmentInClipboard);
                                  if (movedAttachment) {
                                    sourceDataItem.removeAttachment(movedAttachment);
                                  }
                                }

                                dataItem.addAttachment(attachment)
                                onActiveAttachmentChanged(attachment)
                              }

                              NotificationManager.success(clipboardStore.clipboardIsCopy ? 'Kopieren erfolgreich' : 'Verschieben erfolgreich');
                            })
                            .finally((): void => {
                              modalStore.closeModal();
                              clipboardStore.clearAttachmentClipboard();
                            });
                        }}
                        >
                          {clipboardStore.clipboardIsCopy ? 'Hierher kopieren' : 'Hierher verschieben'}

                        </Button>
                        <StackLayout width />
                      </>
                    ) : null
                  }
          <FormOrWorkingPackagePermissionFeature requiredPermission="CanAssignLocalAttachments">
            <Button onPress={(): void => {
                                modalStore.openModal('addAttachment', ({
                                  addFromLocal: true,
                                  onAttachmentAdded: onActiveAttachmentChanged
                      }) as IAddAttachmentModelParameters);
                    }}
            >
              Lokale Datei zuordnen

            </Button>
          </FormOrWorkingPackagePermissionFeature>
          <StackLayout width />
          <FormOrWorkingPackagePermissionFeature requiredPermission="CanAssignAttachmentStoreAttachments">
            <Button onPress={(): void => {
                      modalStore.openModal('addAttachment', ({
                        addFromLocal: false,
                        onAttachmentAdded: onActiveAttachmentChanged
            }) as IAddAttachmentModelParameters);
                    }}
            >
              Neue Datei zuordnen

            </Button>
          </FormOrWorkingPackagePermissionFeature>
        </StackLayout>
        <Text title marginTop={5}>Kommentare</Text>
        <StackLayout marginTopBottom height={0.5} backgroundColor="primary" />
        {  activeUserStore.userId ? (
          <DataItemComments
            userId={activeUserStore.userId}
            comments={activeItem?.comments}
            onCommentAdded={(comment): void => {
                    RestApiServiceFactory.commentService.post(activeForm.id, activeItem?.id, comment).then((apiResult): void => {
                      if (apiResult.ok) {
                        NotificationManager.success('Der Kommentar wurde erfolgreich gepostet');
                      activeItem?.addComment(comment);
                      }
                      else {
                        NotificationManager.error('Es ist ein Fehler beim Senden des Kommentars aufgetreten');
                      }
                    });
                  }}
            onCommentIsResolvedUpdated={(comment, isResolved): void => {
                    RestApiServiceFactory.commentService.updateIsResolved(activeForm.id, activeItem?.id, comment.id, isResolved).then((apiResult): void => {
                      if (apiResult.ok) {
                        NotificationManager.success('Der Kommentar wurde als gelöst markiert');
                        comment.setIsResolved(isResolved);
                      }
                      else {
                        NotificationManager.error('Es ist ein Fehler beim Senden des Kommentars aufgetreten');
                      }
                    });
                  }}
            onCommentVisibilityUpdated={(comment, visibility): void => {
                    RestApiServiceFactory.commentService.updateVisibility(activeForm.id, activeItem?.id, comment.id, visibility).then((apiResult): void => {
                      if (apiResult.ok) {
                        NotificationManager.success('Der Kommentar wurde aktualisiert');
                        comment.setVisibility(visibility);
                      }
                      else {
                        NotificationManager.error('Es ist ein Fehler beim Senden des Kommentars aufgetreten');
                      }
                    });
                  }}
          />
                          ) : null}
        <StackLayout horizontal spaceBetween marginTop={5} vCenter>
          <Text title>Historie</Text>
          <FormPermissionFeature requiredPermission="CanResetHistory">
            <TooltipHelp text="Status zurücksetzen" position="left">
              <Icon
                xlDanger
                history
                onPress={(): void => {
                    modalStore.openModal('resetHistory', activeItem)
                }}
              />
            </TooltipHelp>
          </FormPermissionFeature>
        </StackLayout>
        <StackLayout marginTopBottom height={0.5} backgroundColor="primary" />
        <StatusLogEntryList status={activeItem.status} />
      </ScrollContainer>
                      ) : activeMainSection === 'Erfassung' ? (
                        <ScrollContainer customMaxHeight="100%">
                          <FormRoot readonlySections={readonlyFormSections} noScroll activeSection={FormSection.Main} readonly />
                        </ScrollContainer>
            ) : (
              <ScrollContainer customMaxHeight="100%">
                <FormRoot readonlySections={readonlyFormSections} noScroll activeSection={FormSection.Index} readonly />
                <FilenamePreview form={activeForm} dataItem={activeItem} />
                <FormRoot readonlySections={readonlyFormSections} noScroll activeSection={FormSection.Keywording} readonly />
              </ScrollContainer>
            )}
  </div>
              )}
              </AutoSizer>
            </StackLayout>
          </StackLayout>
        ): null}

        <StackLayout width={{custom: showNavigation ? '40%' : '50%'}}>
          {
            activeFileFormat && activeFileUri ? (
              <FileRendererAdvanced
                format={activeFileFormat}
                uri={activeFileUri}
                onOutlineSave={(outline): void => {
                  if (activeItem) {
                    console.log(activeForm.id, activeItem.id, activeAttachment.id, outline);
                    RestApiServiceFactory.outlineApiService.update(
                      activeForm.id,
                      activeItem.id,
                      activeAttachment.id,
                      outline
                    );
                  }
                }}
              />
            ) : null
          }
        </StackLayout>
      </StackLayout>
    </StackLayout>
  );
};

export default observer(CollectorMode);
