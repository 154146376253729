import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { StackLayout, TextInput, Button, Text, Icon } from '@wemogy/reactbase';
import IListInputProps from './IListInputProps';
import NotificationManager from '../../../inAppFeedback/NotificationManager';
import ScrollContainer from '../../../scrollContainer/ScrollContainer';

const ListInput: React.FC<IListInputProps> = ({
  values,
  onAdd,
  onRemove
}) => {
  const [newValue, setNewValue] = React.useState('');
  const handleAddPress = useCallback((): void => {
    if(!newValue){
      return;
    }
    if(values.includes(newValue)) {
      NotificationManager.error('Dieser Wert existiert bereits');
      return;
    }
    onAdd(newValue);
    setNewValue('');
  }, [newValue, values, onAdd]);

  // sort values
  const sortedValues = values.slice().sort();

  return (
  <StackLayout>
    <StackLayout horizontal>
      <TextInput value={newValue} onChange={setNewValue} onEnterKeyPress={handleAddPress} marginRight />
      <Button onPress={handleAddPress}>Erstellen</Button>
    </StackLayout>
    <ScrollContainer maxHeight={40}>
      <StackLayout>
        {sortedValues.map((value) => (
          <StackLayout horizontal key={value}>
            <Icon onPress={(): void => {
              onRemove(value)
            }} xlDanger size={2} delete />
            <Text noTranslation>{value}</Text>
          </StackLayout>
        ))}
      </StackLayout>
    </ScrollContainer>
  </StackLayout>
  );
};

export default observer(ListInput);
