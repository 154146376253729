import { DtoMapper } from '@wemogy/reactbase';

export default class DataItemDtoMapper extends DtoMapper<any, any>{
  public mapToModel(dto: any): any{
    // dto = _.cloneDeep(dto);
    if (dto.data){
      Object.keys(dto.data).forEach((propertyName: string): void => {
        if (propertyName.startsWith('[\'')) {
          const correctPropertyName = propertyName.substring(2, propertyName.length - 2);
          dto.data[correctPropertyName] = dto.data[propertyName];
          delete dto.data[propertyName];
        }
      });
    }
    return dto;
  }
}
