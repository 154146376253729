// global CSS
import 'normalize.css';
// Load Inter typeface
import 'typeface-inter';
import ReactDOM from 'react-dom';
import './index.css';

import 'rc-tooltip/assets/bootstrap_white.css';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import 'mobx-react-lite/batchingForReactDom';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { initializeI18Next } from '@wemogy/reactbase';
import App from './App';
import { Version } from './base/Constants';
import { confirmAlert } from 'react-confirm-alert';


initializeI18Next(10);

if (10 < 5) {
  Sentry.init({
    dsn: 'https://8c96bfb5ccbc409fb129ce54b751bb83@o357976.ingest.sentry.io/5768491',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// every 15 minutes
function checkForUpdate(): void {
  const localVersion = Version;
  console.log('FETCHING VERSION')
  console.log('local version', localVersion)
  fetch(`${location.origin}?request=${new Date().getTime()}`)
    .then((response) => response.text().then((html) => {
      try{
        const remoteVersion = html.split('window.env.version = ')[1].split('\'')[1];
        console.log('remoteVersion', remoteVersion)
        if(remoteVersion !== localVersion){
          confirmAlert({
            title: 'New version available',
            message: 'A new version of the app is available. Do you want to update?',
            closeOnClickOutside: false,
            closeOnEscape: false,
            buttons: [ {
              label: 'Yes',
              onClick: (): void => {
                location.reload()
              }
            },
              {
                label: 'No',
                onClick: (): void => {
                  // nothing to do
                }
              }
            ]
          })
        }
      }catch {
        // nothing to do
      }
    }));
}

checkForUpdate();
setInterval((): void => {
  checkForUpdate();
}, 1000 * 60 * 15);
