import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Modal, useModalStore
} from '@wemogy/reactbase';
import IEditTableModalProps from './IEditTableModalProps';
import { ILayout } from '../../../dataLayer/models/Layout';
import AnnotatedFormView from '../../../containers/formElements/annotatedFormView/AnnotatedFormView';
import NumberPicker from '../../../../../components/numberPicker/NumberPicker';
import TableConfig from '../../../dataLayer/models/layoutConfigs/TableConfig';

const EditTableModal: React.FC<IEditTableModalProps> = () => {
  const modalStore = useModalStore();
  const layout = modalStore.getActiveParameters<ILayout>('editTable');
  const config = layout?.getConfig<TableConfig>();

  if(!layout || !config){
    return null;
  }

  function handleDecrement(): void{
    if(!config || !layout){
      return;
    }
    const oldNumberOfColumns = config.numberOfControlColumns;
    const controlsToRemove = layout.controls.filter(x => x.orderId % oldNumberOfColumns === 1); // REMOVE THEM
    controlsToRemove.forEach(layout.removeControl);
    layout.controls.forEach(x => {
      const rowIndex = Math.ceil(x.orderId / oldNumberOfColumns);
      x.setOrderId(x.orderId - rowIndex);
    });
    layout.updateConfig<TableConfig>(x => x.numberOfControlColumns--);
  }

  function handleIncrement(): void{
    if(!config || !layout){
      return;
    }
    const oldNumberOfColumns = config.numberOfControlColumns;
    layout.controls.forEach(x => {
      const rowIndex = Math.ceil(x.orderId / oldNumberOfColumns);
      x.setOrderId(x.orderId + rowIndex);
    });
    layout.updateConfig<TableConfig>(x => x.numberOfControlColumns++);
  }

  return (
    <Modal modalKey="editTable" icon="edit" title="Tabelle bearbeiten" large>
      <StackLayout>
        <AnnotatedFormView description="Anzahl der Control-Spalten">
          <NumberPicker min={1} onDecrement={handleDecrement} onIncrement={handleIncrement} value={config.numberOfControlColumns} />
        </AnnotatedFormView>
      </StackLayout>
    </Modal>
  );
};

export default observer(EditTableModal);
