import {RestService, ApiWrapper, ApiResult} from '@wemogy/reactbase';
import { getSnapshot } from 'mobx-state-tree';
import { IPdfSettings } from '../../../../ui/wrappers/formBuilder/dataLayer/models/PdfSettings';

export default class AttachmentProcessingService extends RestService<any>{
  public constructor(apiWrapper: ApiWrapper){
    super(apiWrapper, 'attachmentprocessing/');
  }

  public schedule(
    formId: string,
    dataItemId: string,
    attachmentId: string,
    pdfSettings: IPdfSettings,
    suffix: string): Promise<ApiResult<void>>{
    return this.apiWrapper.post('attachmentprocessing/schedule', {
      formId,
      dataItemId,
      attachmentId,
      pdfSettings: getSnapshot(pdfSettings),
      suffix
    });
  }
}
