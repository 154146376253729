import {
types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';

const CollectorModeStore = types.model({sampleModeActive: types.optional(types.boolean, false)})
  .named('CollectorModeStore')
  .actions(self => ({
    setSampleModeActive(sampleModeActive: boolean): void{
      self.sampleModeActive = sampleModeActive;
    },
  }));
export default CollectorModeStore;

export interface ICollectorModeStore extends Instance<typeof CollectorModeStore>{}
export interface ICollectorModeStoreSnapshotIn extends SnapshotIn<typeof CollectorModeStore>{}
export interface ICollectorModeStoreSnapshotOut extends SnapshotOut<typeof CollectorModeStore>{}
