import {
  RestService, ApiWrapper, ApiResult
} from '@wemogy/reactbase';
import autobind from 'autobind-decorator';
import Blob from '../dtos/Blob';
import { IAttachmentSnapshotIn } from '../../models/Attachment';
import { clearDataItemId } from './DataItemService';

export default class AttachmentStoreService extends RestService<string>{
  public constructor(apiWrapper: ApiWrapper){
    super(apiWrapper, 'attachmentStore');
  }


  public getSas(formId: string): Promise<ApiResult<string>>{
    return this.apiWrapper.get(`attachmentStore/${formId}`);
  }

  public cleanupAttachments(formId: string): Promise<ApiResult<void>>{
    return this.apiWrapper.post(`attachmentStore/${formId}/cleanup`, {});
  }

  public getBlobs(formId: string): Promise<ApiResult<Blob[]>>{
    return this.apiWrapper.get(`attachmentStore/blobs/${formId}`);
  }

  public assignAttachmentToDataItem(formId: string, dataItemId: string, blobName: string): Promise<ApiResult<IAttachmentSnapshotIn>>{
    dataItemId = clearDataItemId(dataItemId);
    return this.apiWrapper.post(`attachmentStore/${formId}`, {
      blobName,
      dataItemId
    });
  }

  @autobind
  public moveAttachmentToDataItem(formId: string, sourceDataItemId: string, attachmentId: string, destinationDataItemId: string): Promise<ApiResult<IAttachmentSnapshotIn>>{
    sourceDataItemId = clearDataItemId(sourceDataItemId);
    destinationDataItemId = clearDataItemId(destinationDataItemId);
    return this.apiWrapper.post(`attachmentStore/move/${formId}/${sourceDataItemId}/${attachmentId}/${destinationDataItemId}`, {});
  }

  @autobind
  public copyAttachmentToDataItem(formId: string, sourceDataItemId: string, attachmentId: string, destinationDataItemId: string): Promise<ApiResult<IAttachmentSnapshotIn>>{
    sourceDataItemId = clearDataItemId(sourceDataItemId);
    destinationDataItemId = clearDataItemId(destinationDataItemId);
    return this.apiWrapper.post(`attachmentStore/copy/${formId}/${sourceDataItemId}/${attachmentId}/${destinationDataItemId}`, {});
  }
}
