import FileFormat from '../../formBuilder/dataLayer/enums/FileFormat';

enum FileType{
  Unknown = 0,
  Image = 1,
  Video = 2,
  Audio = 3,
  Pdf = 4,
  MicrosoftOffice = 5
}

export default FileType;

export function getFileType(fileFormat: FileFormat): FileType{
  const asString = fileFormat.toString();
  return parseInt(asString[0]);
}
