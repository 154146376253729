import _ from 'lodash';
import React, { useRef } from 'react';
// @ts-ignore no types :/
import Select from 'react-select-virtualized';
import {
  StackLayout, Text, Icon
} from '@wemogy/reactbase';
import { observer } from 'mobx-react';
import IDropdownProps from './IDropdownProps';
import DigitToolDefaultTheme from '../../themes/DigitToolDefaultTheme';
import IDropdownOption from './interfaces/IDropdownOption';
import TooltipHelp from '../../wrappers/tooltipHelp/TooltipHelp';

const Dropdown: React.FC<IDropdownProps> = ({
  width, width100, height, stretch, options, noOptionSelectedOption, selectedOption, onOptionSelected, onPress, placeholder, light, onRemove, isDisabled,
  backgroundKey, tooltipComponentRenderer, isSearchable, isClearable, maxMenuHeight, disableSorting
}) => {
  const reactSelectRef = useRef<Select>(null);
  const typographyStyle = {
    fontSize: 12,
    color: light ? DigitToolDefaultTheme.colorScheme.grey900 : DigitToolDefaultTheme.colorScheme.grey400,
    fontFamily: 'Inter',
  };
  const primaryColor = light ? DigitToolDefaultTheme.colorScheme.white : DigitToolDefaultTheme.colorScheme.grey900;
  const selectedColor = light ? DigitToolDefaultTheme.colorScheme.grey300 : DigitToolDefaultTheme.colorScheme.grey700;
  const focussedColor = light ? DigitToolDefaultTheme.colorScheme.grey100 : DigitToolDefaultTheme.colorScheme.grey800;


  const activeHeight = height === undefined ? 4 : height;

  const customStyles: any = {
    control: (base, _state) => ({
      ...base,
      height: `${activeHeight * 8}px`,
      minHeight: `${activeHeight * 8}px`,
      borderColor: `${DigitToolDefaultTheme.colorScheme.grey500} !important`,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '8px',
      background: backgroundKey ? DigitToolDefaultTheme.colorScheme[backgroundKey as any] :  'transparent',
      boxShadow: ''
    }),
    indicatorsContainer:  (provided) => ({
      ...provided,
      height: `${activeHeight * 8}px`
    }),
    option: (provided, state) => ({
      ...provided,
      ...typographyStyle,
      backgroundColor: state.isSelected ? selectedColor
        : state.isFocused ? focussedColor : primaryColor,
      cursor: 'pointer'
    }),
    singleValue: (provided) => ({
      ...provided,
      ...typographyStyle
    }),
    placeholder:  (provided) => ({
      ...provided,
      ...typographyStyle
    }),
    indicatorSeparator: () => ({opacity: 0}),
    menu: styles => ({
      ...styles,
      whiteSpace: 'nowrap',
      width:  '100%',
      right: 0,
      borderRadius: 8,
      overflow: 'hidden',
      maxHeight: maxMenuHeight
    }),
    menuList: styles => ({
      ...styles,
      padding: 0
    })

  };

  let allOptions = noOptionSelectedOption ? [...options, noOptionSelectedOption] : options;

  allOptions = disableSorting ? allOptions : _.orderBy(allOptions, x => x.label);

  return(
    <StackLayout width={width} width100={width100} stretch={stretch} height={activeHeight} onPress={onPress}>
      <Select
        maxMenuHeight={maxMenuHeight}
        styles={customStyles}
        value={selectedOption === undefined ? null : selectedOption}
        options={allOptions}
        isSearchable={isSearchable}
        isClearable={isClearable}
        placeholder={placeholder === undefined ? 'Select' : placeholder}
        noOptionsMessage={(): string | null => noOptionSelectedOption?.label || null}
        onChange={(value: any): void => {
          onOptionSelected(value as any);
        }}
        isDisabled={isDisabled || onPress !== undefined}
        autoFocus={false}
        menuPlacement="auto"
        ref={reactSelectRef}
        formatOptionLabel={(option: any, labelMeta: any): JSX.Element => {
          const iconProps = {};
          if((option as IDropdownOption).icon){
            iconProps[(option as any).icon] = true;
          }
          const optionIsNoOptionOption = option.value === noOptionSelectedOption?.value;
          const optionComponent = (
            <StackLayout horizontal vCenter>
              {(option as any).icon ? (<Icon marginRight {...iconProps} />) : null}
              <Text overflowEllipsis stretch>{option.label}</Text>
              {
                labelMeta.context === 'menu' && onRemove ? (
                  <div onClick={(e): void => {
                    onRemove(option);
                    e.stopPropagation();
                  reactSelectRef.current?.onMenuClose();
                  }}
                  >
                    {/*  <IconButton icon="deleteForever" lPrimary withoutBox noPadding withoutFixedHeight /> */}
                  </div>
                ) : null
              }

            </StackLayout>
          );
          return tooltipComponentRenderer && !optionIsNoOptionOption ? (
            <TooltipHelp position="left" component={tooltipComponentRenderer(option.value)}>
              {optionComponent}
            </TooltipHelp>
          ) : optionComponent;
        }}
      />
    </StackLayout>
  );};

export default observer(Dropdown);
