import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import Dropdown from '../../../../components/dropdown/Dropdown';
import IDropdownOption from '../../../../components/dropdown/interfaces/IDropdownOption';
import IPdfSettingsSelectorProps from './IPdfSettingsSelectorProps';

const PdfSettingsSelector: React.FC<IPdfSettingsSelectorProps> = ({ onSelected }) => {
  const { processingTemplateStore } = useDataAccessStore();
  const [selectedOption, setSelectedOption] = useState<IDropdownOption | null>(null);

  useEffect((): void => {
    processingTemplateStore.query();
  }, []);

  const options = useMemo((): IDropdownOption[] => {
    return processingTemplateStore.items.map(x => ({
      label: x.name,
      value: x.id
    }))
  }, [processingTemplateStore.items, processingTemplateStore.items.length]);

  const handleOptionSelected = useCallback((option: IDropdownOption): void => {
    setSelectedOption(option);
    const item = processingTemplateStore.items.find(x => x.id === option.value);
    if (!item) {
      return;
    }
    onSelected(item.pdfSettings);
  }, [onSelected]);


  return (<Dropdown light selectedOption={selectedOption} options={options} onOptionSelected={handleOptionSelected} />)
};

export default observer(PdfSettingsSelector);
