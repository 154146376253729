import {
types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';

const TimePeriodStatisticItem = types.model({
  date: types.Date,
  amount: types.optional(types.number, 0)
})
  .named('TimePeriodStatisticItem');
export default TimePeriodStatisticItem;

export interface ITimePeriodStatisticItem extends Instance<typeof TimePeriodStatisticItem>{}
export interface ITimePeriodStatisticItemSnapshotIn extends SnapshotIn<typeof TimePeriodStatisticItem>{}
export interface ITimePeriodStatisticItemSnapshotOut extends SnapshotOut<typeof TimePeriodStatisticItem>{}
