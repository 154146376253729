import React from 'react';
import { observer } from 'mobx-react';
import {StackLayout, Modal } from '@wemogy/reactbase';
import ISampleSettingsModalProps from './ISampleSettingsModalProps';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import AnnotatedTextInput from '../../containers/formElements/annotatedTextInput/AnnotatedTextInput';

const SampleSettingsModal: React.FC<ISampleSettingsModalProps> = () => {
  const { formStore } = useDataAccessStore();

  const { activeItem } = formStore;
  if(!activeItem){
    return null;
  }



  return (
    <Modal modalKey="sampleSettings" icon="settings" title="Einstellungen für Stichproben" large>
      <StackLayout minHeight={{custom: '50vh'}}>
        <AnnotatedTextInput
          placeholder="Geben Sie hier die notwendige Prozentzahl ein"
          value={activeItem.sampleRate.toString()}
          onChange={(value): void => {
            activeItem.setSampleRate(parseInt(value));
          }}
          description="Anzahl Datensätze pro Arbeitspaket, die die Stichprobe bestehen müssen (in Prozent)"
          numeric
        />
      </StackLayout>
    </Modal>
  );
};

export default observer(SampleSettingsModal);
