import React from 'react';
import { observer } from 'mobx-react';
import {Text} from '@wemogy/reactbase'
import IFilenamePreviewProps from './IFilenamePreviewProps';

const FilenamePreview: React.FC<IFilenamePreviewProps> = ({
form, dataItem
}) => {

    return (
      <Text noTranslation>{`Dateiname Vorschau: ${form.getFilename(dataItem)}`}</Text>
    )
}

export default observer(FilenamePreview);
