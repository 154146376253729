/* eslint-disable react/no-array-index-key */
import React from 'react';
import { observer } from 'mobx-react';
import { StackLayout } from '@wemogy/reactbase';
import IToolboxElementListProps from './IToolboxElementListProps';
import ToolboxElement from '../../components/toolboxElement/ToolboxElement';

const ToolboxElementList: React.FC<IToolboxElementListProps> = ({
  elements, onElementSelected, type
}) => {

  return (
    <StackLayout>
      {
        elements.map((x,i) => (
          <ToolboxElement
            type={type}
            key={`toolboxElement_${i}_${x.key}`}
            referenceKey={x.key}
            label={x.label}
            icon={x.icon}
            config={x.config}
            onPress={(): void => {
              onElementSelected(x);
            }}
          />
        ))
      }
    </StackLayout>
  );
};

export default observer(ToolboxElementList);
