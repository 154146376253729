import { types, Instance } from 'mobx-state-tree';
import ContextMenuStore from './uiStores/ContextMenuStore';
import ClipboardStore from './uiStores/ClipboardStore';
import CollectorModeStore from './uiStores/CollectorModeStore';

const RootUiStore = types.model({
  contextMenuStore: types.optional(ContextMenuStore, () => ContextMenuStore.create()),
  clipboardStore: types.optional(ClipboardStore, () => ClipboardStore.create()),
  collectorModeStore: types.optional(CollectorModeStore, () => CollectorModeStore.create())
})
  .named('RootUiStore');

export default RootUiStore;

export interface IRootUiStore extends Instance<typeof RootUiStore> {}
