import {
  Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import { createBaseDataAccessStore, QueryParameters} from '@wemogy/reactbase';
import RestApiServiceFactory from '../../api/rest/RestApiServiceFactory';
import ProcessingTemplate from '../../../ui/wrappers/formBuilder/dataLayer/models/ProcessingTemplate';
import ProcessingTemplateDtoMapper from '../../api/dtoMappers/ProcessingTemplateDtoMapper';

const ProcessingTemplateStore = createBaseDataAccessStore(
  ProcessingTemplate,
  RestApiServiceFactory.processingTemplateService,
  new ProcessingTemplateDtoMapper(),
  QueryParameters.create(),
);

export interface IProcessingTemplateStore extends Instance<typeof ProcessingTemplateStore>{}
export interface IProcessingTemplateStoreSnapshotIn extends SnapshotIn<typeof ProcessingTemplateStore>{}
export interface IProcessingTemplateStoreSnapshotOut extends SnapshotOut<typeof ProcessingTemplateStore>{}

export default ProcessingTemplateStore;
