import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, Text, Modal, useModalStore
} from '@wemogy/reactbase';
import IManageQrCodeSourcesModalProps from './IManageQrCodeSourcesModalProps';
import QrCodeSourceItemList from '../../containers/qrCodeSourceItemList/QrCodeSourceItemList';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';

const ManageQrCodeSourcesModal: React.FC<IManageQrCodeSourcesModalProps> = () => {
  const {formStore} = useDataAccessStore();
  const modalStore = useModalStore();

  if(!formStore.activeItem){
    return null;
  }

  return (
    <Modal modalKey="manageQrCodeSources" icon="settings" title="QR Codes verwalten" large>
      {
        formStore.activeItem.qrCodeSources.length ? (
          <StackLayout>
            <QrCodeSourceItemList qrCodeSources={formStore.activeItem.qrCodeSources} onQrCodeSourceRemove={formStore.activeItem.removeQrCodeSource} />
          </StackLayout>
        ) : (
          <StackLayout hCenter>
            <Text title marginTopBottom={2}>Es sind keine QR Codes vorhanden</Text>
            <Button onPress={(): void =>{
              modalStore.openModal('addQrCodeSource');
            }}
            >
              Jetzt einen QR Code anlegen

            </Button>
          </StackLayout>
        )
      }

    </Modal>
  );
};

export default observer(ManageQrCodeSourcesModal);
