import { enumerationIntValuesType } from "@wemogy/reactbase";
import { Instance } from "mobx-state-tree";

enum ArtStatus
{
    Captured = 1,
    Packed = 2,
    PickedUp = 3,
    Digitized = 4,
    Provided = 5,
    Error = 6
}

export default ArtStatus;

export const ArtStatusType = enumerationIntValuesType(ArtStatus);

export type IArtStatusType = Instance<typeof ArtStatusType>;
