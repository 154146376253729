import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, Icon, useContextMenuStore
} from '@wemogy/reactbase';
import IAttachmentListProps from './IAttachmentListProps';
import FileFormatIcon from '../../wrappers/formBuilder/components/fileFormatIcon/FileFormatIcon';
import TooltipHelp from '../../wrappers/tooltipHelp/TooltipHelp';

const AttachmentList: React.FC<IAttachmentListProps> = ({
dataItem, activeAttachment, onAttachmentPress
}) => {
  const contextMenuStore = useContextMenuStore();

    return (
      <StackLayout>
        {
            dataItem.attachments.map(attachment => (
              <StackLayout
                onContextMenu={(e): void => {
                  contextMenuStore.openContextMenu(e, 'attachment', attachment);
                }}
                marginBottom
                key={attachment.id}
                horizontal
                vCenter
                padding
                borderRadius={0.25}
                borderColor="green300"
                backgroundColor={activeAttachment?.id === attachment.id ? 'green100' : undefined}
                border={0.125}
              >
                <Icon fileCopy xl marginRight />
                <StackLayout hStart stretch marginRight>
                  <Text noTranslation>{attachment.filename}</Text>
                  <StackLayout horizontal vCenter>
                    <FileFormatIcon fileName={attachment.filename} fileFormat={attachment.fileFormat} />
                    {
                    attachment.isGenerated ? (
                      <TooltipHelp text="Generierte PDF" position="right">
                        <Icon
                          settingsSuggest
                          xl
                          size={2}
                          marginLeft
                        />
                      </TooltipHelp>
): null
                  }
                  </StackLayout>
                </StackLayout>
                {
                  attachment.isViewable ? (
                    <Icon
                      visibility
                      xl
                      onPress={(): void => {
                      onAttachmentPress(attachment);
                    }}
                    />
) : attachment.isGenerated ? (
  <TooltipHelp text={attachment.processingStateDescription} position="left">
    <Icon
      settingsSuggest={!attachment.isProcessingStatePending && !attachment.isProcessingStateFailed}
      hourglassTop={attachment.isProcessingStatePending}
      report={attachment.isProcessingStateFailed}
      xl
    />
  </TooltipHelp>
) : null
                }
              </StackLayout>
            ))
          }
      </StackLayout>
    )
}

export default observer(AttachmentList);
