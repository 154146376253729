import {
types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import TimePeriodStatisticItem from './TimePeriodStatisticItem';

const TimePeriodStatistics = types.model({
  daily: types.optional(types.array(TimePeriodStatisticItem), []),
  weekly: types.optional(types.array(TimePeriodStatisticItem), []),
  monthly: types.optional(types.array(TimePeriodStatisticItem), [])
})
  .named('TimePeriodStatistics');
export default TimePeriodStatistics;

export interface ITimePeriodStatistics extends Instance<typeof TimePeriodStatistics>{}
export interface ITimePeriodStatisticsSnapshotIn extends SnapshotIn<typeof TimePeriodStatistics>{}
export interface ITimePeriodStatisticsSnapshotOut extends SnapshotOut<typeof TimePeriodStatistics>{}
