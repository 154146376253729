import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import {BaseModel} from '@wemogy/reactbase';

const UserSubType = types.model({
  email: types.optional(types.string, ''),
  firstname: types.optional(types.string, ''),
  lastname: types.optional(types.string, ''),
  globalRoleId: types.optional(types.string, 'MAKE_REQUIRED_AFTER_MIGRATION'),
  expiresAt: types.maybe(types.Date),
  personalStatisticsEnabled: types.optional(types.boolean, false)
})
  .named('UserSubType')
  .views(self => ({
    get fullname(): string{
      return `${self.firstname} ${self.lastname}`;
    },
    get initials(): string{
      return `${self.firstname[0] || ''}${self.lastname[0] || ''}`;
    }
  }))
  .actions(self => ({
    setExpiresAt(expiresAt: Date | undefined): void{
      self.expiresAt = expiresAt;
    },
    setPersonalStatisticsEnabled(personalStatisticsEnabled: boolean): void{
      self.personalStatisticsEnabled = personalStatisticsEnabled;
    },
    setGlobalRoleId(globalRoleId: string): void{
      self.globalRoleId = globalRoleId;
    },
  }));

const User = types.compose(BaseModel, UserSubType)
  .named('User');
export default User;

export interface IUser extends Instance<typeof User>{}
export interface IUserSnapshotIn extends SnapshotIn<typeof User>{}
export interface IUserSnapshotOut extends SnapshotOut<typeof User>{}
