import {IPosition} from '@wemogy/reactbase-osm';

export default class GeolocationTypeConfig{
  /**
   * The factor value for the height
   */
  public height: number = 30;

  /**
   * default center is NRW
   */
  public center: IPosition = {
    latitude: 51.433237,
    longitude: 7.661594
  };
}
