import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';

const StatusLogEntry = types.model({
  issuer: types.string,
  timestamp: types.optional(types.Date, () => new Date())
})
  .named('StatusLogEntry');
export default StatusLogEntry;

export interface IStatusLogEntry extends Instance<typeof StatusLogEntry>{}
export interface IStatusLogEntrySnapshotIn extends SnapshotIn<typeof StatusLogEntry>{}
export interface IStatusLogEntrySnapshotOut extends SnapshotOut<typeof StatusLogEntry>{}
