import {
  types, flow, Instance
} from 'mobx-state-tree';
import {
  ApiResult, ApiAuthenticationInformation, DeviceInformation
} from '@wemogy/reactbase';
import RestApiServiceFactory from '../../api/rest/RestApiServiceFactory';
import LoginRequest from '../../api/dtos/authentication/LoginRequest';
import LoginResult from '../../api/dtos/authentication/LoginResult';
import AuthenticationService from '../../services/AuthenticationService';
import LoginMfaRequest from '../../api/dtos/authentication/LoginMfaRequest';

const ActiveUserStore = types.model({
  apiAuthenticationInformation: types.maybe(ApiAuthenticationInformation),
  activeOrganizationId: types.maybe(types.string),
  userUploadSas: types.optional(types.string, '')
})
  .named('ActiveUserStore')
  .views(self => ({
    get isAuthenticated(): boolean{
      return self.apiAuthenticationInformation !== undefined;
    },
    get userId(): string | undefined{
      if(!self.apiAuthenticationInformation){
        return undefined;
      }
      return self.apiAuthenticationInformation.accessTokenPayload.sub;
    }
  }))
  .actions(self => ({
    login: flow(function* login(email: string, password: string): Generator<Promise<ApiResult<string>>, ApiResult<string>, ApiResult<string>>{
      const deviceId = (yield DeviceInformation.getDeviceId() as any) as any;
      const apiResult = yield RestApiServiceFactory.authenticationService.login(new LoginRequest(
        email,
        password,
        deviceId
      ));

      return apiResult;
    }),
    loginMfa: flow(function* login(challengeToken: string, code: string): Generator<Promise<ApiResult<LoginResult>>, boolean | ApiResult<string>, ApiResult<LoginResult>>{
      const deviceId = (yield DeviceInformation.getDeviceId() as any) as any;
      const apiResult = yield RestApiServiceFactory.authenticationService.loginMfa(new LoginMfaRequest(
        challengeToken,
        code
      ));

      if (apiResult.status !== 200) {
        return apiResult as any;
      }

      (self as any).loginManually(apiResult.data, deviceId);

      return true;
    }),
    loginManually(loginResult: LoginResult, deviceId: string): void {
      self.apiAuthenticationInformation = ApiAuthenticationInformation.create({
        accessToken: loginResult.jwt,
        refreshToken: loginResult.refreshToken,
        deviceId
      });

      (self as any).distributeApiAuthenticationInformation();
    },
    distributeApiAuthenticationInformation(): void{
      if(!self.apiAuthenticationInformation){
        return;
      }
      AuthenticationService.distributeApiAuthenticationInformation(self.apiAuthenticationInformation);
    },
    setActiveOrganizationId(organizationId: string): void{
      self.activeOrganizationId = organizationId;
    },
    setUserUploadSas: flow(function* setUserUploadSas(): Generator<any, any, any>{
      const sas = yield RestApiServiceFactory.userService.getUploadSasAsync();
      self.userUploadSas = sas;
    }),
  }));

export default ActiveUserStore;

export interface IActiveUserStore extends Instance<typeof ActiveUserStore>{}
