/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import SinglePermission from '@wemogy/reactbase-authorization/lib/types/SinglePermission';
import { useMemo } from 'react';
import FormRole from '../../../wrappers/formBuilder/dataLayer/enums/roles/FormRole';
import WorkingPackageRole from '../../../wrappers/formBuilder/dataLayer/enums/roles/WorkingPackageRole';
import useFormPermissionFeature from './UseFormPermissionFeature';
import useWorkingPackagePermissionFeature from './UseWorkingPackagePermissionFeature';

export type FormOrWorkingPackageRole = (typeof FormRole) | (typeof WorkingPackageRole);
export type FormOrWorkingPackageRoleKey = keyof FormOrWorkingPackageRole;

export default function useFormOrWorkingPackageFeature() {
  const {hasOnActiveFormPermission} = useFormPermissionFeature();
  const {hasOnActiveWorkingPackagePermission} = useWorkingPackagePermissionFeature();

  const FormOrWorkingPackageFeatureFunctions = useMemo(() => ({
    hasOnActiveFormOrWorkingPackagePermission: (permission: SinglePermission<FormOrWorkingPackageRoleKey>): boolean => {
      return hasOnActiveFormPermission(permission) || hasOnActiveWorkingPackagePermission(permission);
    }
  }), []);

  return FormOrWorkingPackageFeatureFunctions;
}
