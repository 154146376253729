import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Button, StackLayout, useModalStore
} from '@wemogy/reactbase';
import IGlobalMarkerCodeScreenProps from './IGlobalMarkerCodeScreenProps';
import ScrollContainer from '../../wrappers/scrollContainer/ScrollContainer';
import MainLayout from '../../layouts/mainLayout/MainLayout';
import useDataAccessStore from '../../../dataLayer/stores/hooks/UseDataAccessStore';
import MarkerCode from '../../wrappers/formBuilder/components/markerCode/MarkerCode';
import AddGlobalMarkerCodeModal from '../../modals/addGlobalMarkerCode/AddGlobalMarkerCodeModal';
import { confirmDialogYesNoDanger } from '../../wrappers/dialogs/DialogManager';
import NotificationManager from '../../wrappers/inAppFeedback/NotificationManager';

const GlobalMarkerCodeScreen: React.FC<IGlobalMarkerCodeScreenProps> = () => {
  const { globalMarkerCodeStore } = useDataAccessStore();
  const modalStore = useModalStore();

  useEffect((): void => {
    globalMarkerCodeStore.query();
  }, []);

  return (
    <MainLayout>
      <ScrollContainer stretch>
        <StackLayout>
          {globalMarkerCodeStore.items.map(x => (
            <MarkerCode
              key={x.id}
              markerCode={x}
              onEdit={(): void => {
                modalStore.openModal('addGlobalMarkerCode', x);
              }}
              onDelete={(): void => {
                confirmDialogYesNoDanger(`Wollen Sie den Marker Code ${x.name} wirklich löschen?`, (): void => {
                  modalStore.openModal('loadingOverlay');
                  globalMarkerCodeStore.delete(x.id).then((): void => {
                    NotificationManager.success(`Der Marker Code ${x.name} wurde erfolgreich gelöscht.`);
                  }).finally((): void => {
                    modalStore.closeModal();
                  });
                });
              }}
            />
          ))}
          <Button onPress={(): void => {
            modalStore.openModal('addGlobalMarkerCode');
          }}
          >
            Neuen Marker Code hinzufügen

          </Button>
        </StackLayout>
      </ScrollContainer>
      <AddGlobalMarkerCodeModal />
    </MainLayout>
  );
};

export default observer(GlobalMarkerCodeScreen);
