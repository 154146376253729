import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, Text, Modal, useModalStore
} from '@wemogy/reactbase';
import IAssignAttachmentModalProps from './IAssignAttachmentModalProps';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import useDataItemStore from '../../dataLayer/stores/hooks/UseDataItemStore';
import IAssignAttachmentModalParameters from './IAssignAttachmentModalParameters';
import FileRendererAdvanced from '../../../fileRenderer/fileRendererAdvanced/FileRendererAdvanced';

const AssignAttachmentModal: React.FC<IAssignAttachmentModalProps> = () => {
  const {formStore,} = useDataAccessStore();
  const modalStore = useModalStore();
  const dataItemStore = useDataItemStore();


  const { activeItem } = formStore;
  const activeDataItem = dataItemStore?.activeItem;
  const parameters = modalStore.getActiveParameters<IAssignAttachmentModalParameters>('assignAttachment');
  if(!activeItem || !activeDataItem || !parameters){
    return null;
  }

  const {
    format,
    uri
  } = parameters;

  return (
    <Modal
      modalKey="assignAttachment"
      icon="fileCopy"
      title="Zuordnung bestätigen"
      large
    >
      <StackLayout height={{custom: '80vh'}}>
        <StackLayout marginBottom>
          <Text title>Möchten Sie diese Datei wirklich zuordnen?</Text>
          <StackLayout horizontal>
            <Button onPress={(): void => {
              parameters.onConfirmed();
            }}
            >
              Ja

            </Button>
            <StackLayout width />
            <Button onPress={(): void => {
              parameters.onDeclined();
            }}
            >
              Nein

            </Button>
          </StackLayout>
        </StackLayout>
        <FileRendererAdvanced
          format={format}
          uri={uri}
        />


      </StackLayout>
    </Modal>
  );
};

export default observer(AssignAttachmentModal);
