import React from 'react';
import { observer } from 'mobx-react';
import { StackLayout } from '@wemogy/reactbase';
import IAnnotatedViewProps from './IAnnotatedViewProps';
import UiElementDropArea from '../../dropAreas/uiElementDropArea/UiElementDropArea';
import ControlDropArea from '../../dropAreas/controlDropArea/ControlDropArea';
import UiElementRenderer from '../../toolboxElementTypeRenderers/uiElementRenderer/UiElementRenderer';
import IControlConfig from '../../toolboxSegments/allControls/interfaces/IControlConfig';
import ControlRenderer from '../../toolboxElementTypeRenderers/controlRenderer/ControlRenderer';

const AnnotatedView: React.FC<IAnnotatedViewProps> = ({
layout, readonlySections
}) => {
  const uiElement = layout.getUiElementByOrderId(0);
  const control  =layout.getControlByOrderId(0);
  return (
    <StackLayout stretch>
      <UiElementDropArea onDrop={(droppedInformation): void => {
        layout.putUiElement(0, droppedInformation.referenceKey, droppedInformation.config);
      }}
      >
        {
          uiElement
            ? <UiElementRenderer uiElement={uiElement} onDelete={layout.removeUiElement} />
            : null
        }
      </UiElementDropArea>
      <StackLayout height={0.5} />
      <ControlDropArea onDrop={(droppedInformation): void => {
        const controlConfig: IControlConfig = droppedInformation.config;
        layout.putControl(0, droppedInformation.referenceKey, controlConfig.dataType, controlConfig.config);
      }}
      >
        {
          control
            ? <ControlRenderer readonlySections={readonlySections} control={control} onDelete={layout.removeControl} />
            : null
        }

      </ControlDropArea>
    </StackLayout>
  );
};

export default observer(AnnotatedView);
