import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import FormStore from '../../ui/wrappers/formBuilder/dataLayer/stores/entityStores/FormStore';
import UserStatisticStore from '../../ui/wrappers/formBuilder/dataLayer/stores/entityStores/UserStatisticStore';
import DatabaseTemplateStore from '../../ui/wrappers/formBuilder/dataLayer/stores/entityStores/DatabaseTemplateStore';
import DataStructureTemplateStore from '../../ui/wrappers/formBuilder/dataLayer/stores/entityStores/DataStructureTemplateStore';
import CustomerStore from './entityStores/CustomerStore';
import UserStore from './entityStores/UserStore';
import InvitationStore from './entityStores/InvitationStore';
import GlobalMarkerCodeStore from './entityStores/GlobalMarkerCodeStore';
import ProcessingTemplateStore from './entityStores/ProcessingTemplateStore';


const RootDataAccessStore = types.model({
  formStore: types.optional(FormStore, () => FormStore.create()),
  databaseTemplateStore: types.optional(DatabaseTemplateStore, () => DatabaseTemplateStore.create()),
  dataStructureTemplateStore: types.optional(DataStructureTemplateStore, () => DataStructureTemplateStore.create()),
  customerStore: types.optional(CustomerStore, () => CustomerStore.create()),
  userStore: types.optional(UserStore, () => UserStore.create()),
  invitationStore: types.optional(InvitationStore, () => InvitationStore.create()),
  globalMarkerCodeStore: types.optional(GlobalMarkerCodeStore, () => GlobalMarkerCodeStore.create()),
  processingTemplateStore: types.optional(ProcessingTemplateStore, () => ProcessingTemplateStore.create()),
  userStatisticStore: types.optional(UserStatisticStore, () => UserStatisticStore.create())
})
  .named('RootDataAccessStore');
export default RootDataAccessStore;

export interface IRootDataAccessStore extends Instance<typeof RootDataAccessStore>{}
export interface IRootDataAccessStoreSnapshotIn extends SnapshotIn<typeof RootDataAccessStore>{}
export interface IRootDataAccessStoreSnapshotOut extends SnapshotOut<typeof RootDataAccessStore>{}
