import _ from 'lodash';
import React from 'react';
import { observer } from 'mobx-react';
import {
  ScrollView, StackLayout, Text
} from '@wemogy/reactbase';
import IMemberListProps from './IMemberListProps';
import MemberListItem from '../memberListItem/MemberListItem';

const MemberList: React.FC<IMemberListProps> = ({
members, resourceId, useMemberRoleIdChangedHandlerHook, useMembersDeletedHandlerHook
}) => {
  console.log('members.length', members.length)
  if (!members.length) {
    return (
      <StackLayout hCenter>
        <Text noTranslation>Es sind noch keine Benutzer zugewiesen</Text>
      </StackLayout>
    );
  }

  return (
    <StackLayout stretch>
      <ScrollView>
        <StackLayout>
          {_.orderBy(members, x => x.username.toLowerCase()).map(member => (
            <MemberListItem key={member.id} member={member} resourceId={resourceId} useMemberRoleIdChangedHandlerHook={useMemberRoleIdChangedHandlerHook} useMembersDeletedHandlerHook={useMembersDeletedHandlerHook} />
      ))}
        </StackLayout>
      </ScrollView>
    </StackLayout>

  )
}

export default observer(MemberList);
