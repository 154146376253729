/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { observer } from 'mobx-react';
import {RadioGroup as ReactRadioGroup, Radio} from 'react-radio-group';
import {
  invokeEventHandler, StackLayout, Text
} from '@wemogy/reactbase';
import IRadioGroupProps from './IRadioGroupProps';

const RadioGroup: React.FC<IRadioGroupProps> = ({
  options, selectedOption, disabled, onChange
}) => {

  return (
    <ReactRadioGroup disabled={disabled} selectedValue={selectedOption} onChange={onChange}>
      <StackLayout>
        {options.map(({
          key, value
        }) => (
          <label
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 8
            }}
            key={key}
          >
            <StackLayout horizontal vCenter marginBottom>
              <Radio
                disabled={disabled}
                onClick={(): void => {
                  if (selectedOption === value) {
                    invokeEventHandler(onChange, undefined);
                  }
                }}
                value={value}
              />
              <Text marginLeft>{key}</Text>
            </StackLayout>
          </label>
        ))}
      </StackLayout>

    </ReactRadioGroup>
  );
};

export default observer(RadioGroup);
