import React from 'react';
import { observer } from 'mobx-react';
import { Text, useModalStore } from '@wemogy/reactbase';
import IUiElementRendererProps from './IUiElementRendererProps';
import EditWrapper from '../../../components/editWrapper/EditWrapper';
import Divider from '../../../../../components/divider/Divider';
import UiElementType from '../../../dataLayer/enums/UiElementType';

const UiElementRenderer: React.FC<IUiElementRendererProps> = ({
  uiElement, onDelete
}) => {
  const modalStore = useModalStore();
  switch (uiElement.type) {
    case UiElementType.H1:
      return (
        <EditWrapper
          onDelete={onDelete ? (): void => {
            onDelete(uiElement);
          }: undefined}
          onEdit={(): void => {
            modalStore.openModal('editUiElement', uiElement);
          }}
        >
          <Text largeTitle>{uiElement.getConfig<any>().text || 'Überschrift 1'}</Text>
        </EditWrapper>
      );
    case UiElementType.H2:
      return (
        <EditWrapper
          onDelete={onDelete ? (): void => {
            onDelete(uiElement);
          }: undefined}
          onEdit={(): void => {
            modalStore.openModal('editUiElement', uiElement);
          }}
        >
          <Text title>{uiElement.getConfig<any>().text || 'Überschrift 2'}</Text>
        </EditWrapper>
      );
    case UiElementType.Label:
      return (
        <EditWrapper
          onDelete={onDelete ? (): void => {
            onDelete(uiElement);
          }: undefined}
          onEdit={(): void => {
            modalStore.openModal('editUiElement', uiElement);
          }}
        >
          <Text label>{uiElement.getConfig<any>().text || 'Label'}</Text>
        </EditWrapper>
      );
    case UiElementType.Hr:
      return (
        <EditWrapper  onDelete={onDelete ? (): void => {
          onDelete(uiElement);
        }: undefined}
        >
          <Divider />
        </EditWrapper>
      );
    default:
      return null;
  }
};

export default observer(UiElementRenderer);
