import React from 'react';
import { observer } from 'mobx-react';
import {
StackLayout, Text, Icon
} from '@wemogy/reactbase';
import ICommentProps from './ICommentProps';
import TooltipHelp from '../../../tooltipHelp/TooltipHelp';
import { formatToShortDateTimeString } from '../../../dateSelector/DateHelpers';
import Username from '../../containers/username/Username'

const Comment: React.FC<ICommentProps> = ({
  comment, onCommentIsResolvedUpdated
}) => {

  return (
    <StackLayout backgroundColor="green300" border={0.125} borderColor="green800" borderRadius={0.5} marginBottom padding>
      <StackLayout horizontal vCenter marginBottom={2}>
        <Icon xl accountCircle marginRight />
        <StackLayout stretch spaceBetween>
          <Username userId={comment.creatorId} />
          <Text noTranslation label>{formatToShortDateTimeString(comment.createdAt)}</Text>
        </StackLayout>
        {onCommentIsResolvedUpdated ? (
          <>
            <StackLayout width />
            <TooltipHelp position="left" text={comment.isResolved ? 'Dieser Kommentar ist gelöst.' : 'Als gelöst markieren'}>
              <Icon
                onPress={comment.isResolved ? undefined : (): void => {
                  onCommentIsResolvedUpdated(comment, true);
                }}
                xl
                checkCircle
                color={{custom: comment.isResolved ? undefined : 'rgba(0,0,0,0.25)'}}
              />
            </TooltipHelp>
          </>
        ) : null}

      </StackLayout>
      <Text noTranslation>{comment.message}</Text>
    </StackLayout>
  );
};

export default observer(Comment);
