import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  QueryParameters, StackLayout, Text, Icon
} from '@wemogy/reactbase';
import IProcessInformationProps from './IProcessInformationProps';
import RestApiServiceFactory from '../../../dataLayer/api/rest/RestApiServiceFactory';
import useDerivedActor from '../../plugins/authorization/hooks/UseDerivedActor';


const ProcessInformation: React.FC<IProcessInformationProps> = ({
  formId, workingPackageId
}) => {
  const [completed, setCompleted] = useState(0);
  const {
    isCollector, isQualityAssurer
  } = useDerivedActor();


  useEffect((): void => {
    const dataItemService = RestApiServiceFactory.getDataItemService(formId);

    const totalQueryParameters = QueryParameters.create();

    totalQueryParameters.putFilter('formId', formId, 'equals');
    totalQueryParameters.putFilter('workingPackageId', workingPackageId, 'equals')

    if (isCollector()) {
      totalQueryParameters.putFilter('status.dataCollected', null, 'isNotEmpty')
    }
    else if (isQualityAssurer()) {
      totalQueryParameters.putFilter('status.dataQualityAssured', null, 'isNotEmpty')
    }

    console.log('totalQueryParameters', totalQueryParameters)

    dataItemService.queryTotalItemCount(totalQueryParameters).then(t => setCompleted(t));
  }, [workingPackageId])


    return (
      <StackLayout horizontal vCenter hCenter>
        <Icon xlSuccess done marginRight />
        <Text>{`(${completed} Datensätze geschafft)`}</Text>
      </StackLayout>
    )
}

export default observer(ProcessInformation);
