/* eslint-disable import/prefer-default-export */
import { ApiWrapper } from '@wemogy/reactbase';

export function authFetch(apiWrapper: ApiWrapper, url: string, requestInit: RequestInit, noJson: boolean = false): Promise<Response> {
  const headers = new Headers();
  if (!noJson) {
    headers.append('Content-Type', 'application/json');
  }
  if (apiWrapper.authenticationInformation) {
    headers.append(
      'Authorization',
      `Bearer ${apiWrapper.authenticationInformation.accessToken}`
    );
  }

  const fetchRequestInfo: RequestInfo = url;

  const fetchRequestInit: RequestInit = requestInit || {};

  fetchRequestInit.headers = headers;


  return fetch(fetchRequestInfo, fetchRequestInit);
}
