import {
  RestService, ApiResult, ApiWrapper
} from '@wemogy/reactbase';
import ConfirmInvitationRequest from '../../dtos/account/ConfirmInvitationRequest';
import ForgotPasswordRequest from '../../dtos/account/ForgotPasswordRequest';

export default class InvitationService extends RestService<any>{
  public constructor(apiWrapper: ApiWrapper){
    super(apiWrapper, 'account/invitation');
  }

  public resendInvitation(invitationId: string): Promise<ApiResult<void>>{
    return this.apiWrapper.post(`${this.restEndpoint}/${invitationId}/resend`, {});
  }

  public confirmInvitation(confirmInvitationRequest: ConfirmInvitationRequest): Promise<ApiResult<void>>{
    return this.apiWrapper.post(`${this.restEndpoint}/confirm`, confirmInvitationRequest);
  }

  public forgotPassword(email: string): Promise<ApiResult<void>>{
    return this.apiWrapper.post(`${this.restEndpoint}/forgotPassword`, new ForgotPasswordRequest(email));
  }
}
