import {  Instance } from 'mobx-state-tree';
import { enumerationIntValuesType } from '@wemogy/reactbase';

enum UiElementType {
  H1 = 1,
  H2 = 2,
  P = 4,
  Hr = 8,
  Label = 16
}

export default UiElementType;

export const UiElementTypeType = enumerationIntValuesType(UiElementType);

export type IUiElementType = Instance<typeof UiElementTypeType>;
