import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import {DataTypeType} from '../enums/DataType';
import MultiSelectData from './MultiSelectData';
import KeyValuePair from './general/KeyValuePair';

const DataStructureTemplateData = types.model({
  sourceName: types.optional(types.string, ''),
  destinationName: types.optional(types.string, ''),
  dataSourceName: types.maybe(types.string),
  dataType: DataTypeType,
  isRequired: types.optional(types.boolean, false),
  readOnly: types.optional(types.boolean, false),
  defaultValue: types.optional(types.array(MultiSelectData), []),
  multiSelectData: types.optional(types.array(MultiSelectData), []),
  tooltip: types.optional(types.string, ''),
  notes: types.optional(types.string, '')
})
  .named('DataStructureTemplateData')
  .views(self => ({
    get hasMultiselectData(): boolean{
      return self.multiSelectData.length > 0;
    },
    get multiselectDataAsKeyValuePairs(): KeyValuePair<string, string>[] {
      return self.multiSelectData.map(x => new KeyValuePair<string, string>(x.key, x.value));
    },
    get defaultHasMultipleValues(): boolean{
      return self.defaultValue.length > 1;
    }
  }));
export default DataStructureTemplateData;

export interface IDataStructureTemplateData extends Instance<typeof DataStructureTemplateData>{}
export interface IDataStructureTemplateDataSnapshotIn extends SnapshotIn<typeof DataStructureTemplateData>{}
export interface IDataStructureTemplateDataSnapshotOut extends SnapshotOut<typeof DataStructureTemplateData>{}
