import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, TextInput, Text, Icon
} from '@wemogy/reactbase';
import INavigationConfigurationItemProps from './INavigationConfigurationItemProps';
import Dropdown from '../../../../components/dropdown/Dropdown';

const NavigationConfigurationItem: React.FC<INavigationConfigurationItemProps> = ({
  navigationConfigurationColumn, options, onRemove
}) => {

  return (
    <StackLayout horizontal vCenter marginBottom={2} border={0} borderBottom={0.25} borderColor="grey800" paddingBottom={2}>
      <StackLayout marginRight stretch>
        <StackLayout horizontal vCenter>
          <Dropdown
            light
            stretch
            selectedOption={options.find(x => x.value === navigationConfigurationColumn.propertyName)}
            options={options}
            onOptionSelected={(selectedOption): void => {
              navigationConfigurationColumn.setPropertyName(selectedOption.value);
            }}
          />
          <StackLayout width />
          <TextInput placeholder="Überschrift" w50 value={navigationConfigurationColumn.displayName} onChange={navigationConfigurationColumn.setDisplayName} />

        </StackLayout>
        <StackLayout>
          <Text>Spaltenbreite</Text>
          <TextInput
            value={navigationConfigurationColumn.width.toString()}
            onChange={(newText): void => {
              navigationConfigurationColumn.setWidth(parseInt(newText));
            }}
            placeholder="Geben Sie die Breite in Pixel an (z.B. 100)"
            numeric
          />
          <Text>Vorschau</Text>
          <StackLayout height={3} width={{custom: navigationConfigurationColumn.width}} backgroundColor="primary" vCenter>
            <Text overflowEllipsis>ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890</Text>
          </StackLayout>
        </StackLayout>
      </StackLayout>
      <Icon
        xl
        deleteForever
        onPress={(): void => {
          onRemove(navigationConfigurationColumn);
        }}
      />
    </StackLayout>
  );
};

export default observer(NavigationConfigurationItem);
