import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import { enumerationIntValuesType } from '@wemogy/reactbase';
import TransactionOperation from '../../enums/eventSourcing/TransactionOperation';

const Transaction = types.model({
  path: types.string,
  value: types.maybe(types.string),
  issuerId: types.string,
  createdAt: types.Date,
  operation: enumerationIntValuesType(TransactionOperation)
})
  .named('Transaction')
  .views(self => ({
    isOfProperty(property: string): boolean{
      if (property.includes(' ')) {
        property = `['${property}']`;
      }
      return self.path === property;
    }
  }));

export default Transaction;

export interface ITransaction extends Instance<typeof Transaction>{}
export interface ITransactionSnapshotIn extends SnapshotIn<typeof Transaction>{}
export interface ITransactionSnapshotOut extends SnapshotOut<typeof Transaction>{}
