import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, Button, Modal, useModalStore
} from '@wemogy/reactbase';
import IAnalyseResultModalProps from './IAnalyseResultModalProps';
import IAnalyseAttachmentResultParameters from './interfaces/IAnalyseAttachmentResultParameters';
import { copyAsExcelCellsToClipboard } from '../../../clipboard/CopyToClipboard';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import MarkerCodeName from '../../components/markerCodeName/MarkerCodeName';

const AnalyseResultModal: React.FC<IAnalyseResultModalProps> = () => {
  const modalStore = useModalStore();
  const {
    formStore, customerStore
  } = useDataAccessStore();

  const parameters = modalStore.getActiveParameters<IAnalyseAttachmentResultParameters>('analyseAttachmentResult');
  const form = formStore.activeItem;

  if (!parameters || !form) {
    return null;
  }
  const customer = customerStore.getItem(form.relatedId);

  if (!customer) {
    return null;
  }

  const {
    dinPages, smallerThenA3, biggerThenA3
  } = parameters.pdfMetadata.calculateStatistics();

  return (
    <Modal
      modalKey="analyseAttachmentResult"
      icon="assessment"
      title="PDF Analysieren"
      large
    >

      <StackLayout>
        <Text>Analyse Ergbnis:</Text>
        <StackLayout horizontal>
          <Text marginRight={2}>Name -&gt;</Text>
          <Text noTranslation>{parameters.fileName}</Text>
        </StackLayout>
        <StackLayout horizontal>
          <Text marginRight={2}>Anzahl Seiten -&gt;</Text>
          <Text noTranslation>{parameters.pdfMetadata.totalPageCount}</Text>
        </StackLayout>
        <StackLayout horizontal>
          <Text marginRight={2}>Kleiner A3 -&gt;</Text>
          <Text noTranslation>{smallerThenA3}</Text>
        </StackLayout>
        <StackLayout horizontal>
          <Text marginRight={2}>Größer A3 -&gt;</Text>
          <Text noTranslation>{biggerThenA3}</Text>
        </StackLayout>
        <StackLayout>
          <Text title>Formate</Text>
          {
            dinPages.map(x => (
              <StackLayout horizontal key={x.format}>
                <Text marginRight={2} noTranslation>{x.format}</Text>
                <Text noTranslation>{x.numberOfPages}</Text>
              </StackLayout>
            ))
          }
        </StackLayout>
        <StackLayout>
          <Text title>QR Codes</Text>
          {
            parameters.pdfMetadata.markerCodes.map(x => (
              <StackLayout horizontal key={x.markerCodeId}>
                <MarkerCodeName form={form} markerCodeId={x.markerCodeId} />
                <Text marginLeft={2} noTranslation>{x.amount}</Text>
              </StackLayout>
            ))
          }
        </StackLayout>
      </StackLayout>

      <StackLayout marginTop={2} hEnd horizontal>
        <Button onPress={(): void => {
          const dinInformation = dinPages.map(x => `${x.format}: ${x.numberOfPages}`).join('\t');
          copyAsExcelCellsToClipboard(parameters.fileName, parameters.pdfMetadata.totalPageCount, smallerThenA3, biggerThenA3, dinInformation);
        }}
        >
          Alle Werte kopieren

        </Button>
        <StackLayout width />
        <Button onPress={(): void => {
          copyAsExcelCellsToClipboard(parameters.fileName, parameters.pdfMetadata.totalPageCount, smallerThenA3, biggerThenA3);
        }}
        >
          Zusammenfassung kopieren

        </Button>
      </StackLayout>

    </Modal>
  );
};

export default observer(AnalyseResultModal);
