import React from 'react';
import { observer } from 'mobx-react';
import { StackLayout } from '@wemogy/reactbase';
import IDatabaseTemplateListProps from './IDatabaseTemplateListProps';
import DatabaseTemplateItem from '../../components/databaseTemplateItem/DatabaseTemplateItem';

const DatabaseTemplateList: React.FC<IDatabaseTemplateListProps> = ({
  databaseTemplates, onDatabaseTemplateRemove, onDatabaseTemplateOpen
}) => {

  return (
    <StackLayout>
      {
        databaseTemplates.map(databaseTemplate => (
          <DatabaseTemplateItem
            key={databaseTemplate.id}
            databaseTemplate={databaseTemplate}
            onOpen={onDatabaseTemplateOpen ? (): void => {
              onDatabaseTemplateOpen(databaseTemplate);
            } : undefined}
            onRemove={onDatabaseTemplateRemove ? (): void => {
              onDatabaseTemplateRemove(databaseTemplate);
            } : undefined}
          />
        ))
      }
    </StackLayout>

  );
};

export default observer(DatabaseTemplateList);
