/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createPermissionFeatureHook } from '@wemogy/reactbase-authorization';
import { useMemo } from 'react';
import useDataAccessStore from '../../../../dataLayer/stores/hooks/UseDataAccessStore';
import FormRole from '../../../wrappers/formBuilder/dataLayer/enums/roles/FormRole';
import FormSubLevelName from '../types/FormSubLevelName';

const useFormPermissionFeatureHook = createPermissionFeatureHook<FormSubLevelName>()(FormRole, () => useDataAccessStore().formStore);

export default function useFormPermissionFeature() {
  const formPermissionFeatureHook = useFormPermissionFeatureHook();

  const FormPermissionFeatureFunctions = useMemo(() => ({
    hasFormPermission: formPermissionFeatureHook.hasPermission,
    hasFormPermissions: formPermissionFeatureHook.hasPermissions,
    getFormPermissions: formPermissionFeatureHook.getPermissions,
    hasOnActiveFormPermission: formPermissionFeatureHook.hasOnActiveItemPermission,
    hasOnActiveFormPermissions: formPermissionFeatureHook.hasOnActiveItemPermissions
  }), [formPermissionFeatureHook]);

  return FormPermissionFeatureFunctions;
}
