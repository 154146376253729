/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createPermissionFeatureHook } from '@wemogy/reactbase-authorization';
import { useMemo } from 'react';
import CustomerRole from '../../../../dataLayer/enums/CustomerRole';
import useDataAccessStore from '../../../../dataLayer/stores/hooks/UseDataAccessStore';
import CustomerSubLevelName from '../types/CustomerSubLevelName';

const useCustomerPermissionFeatureHook = createPermissionFeatureHook<CustomerSubLevelName>()(CustomerRole, () => useDataAccessStore().customerStore);

export default function useCustomerPermissionFeature() {
  const customerPermissionFeatureHook = useCustomerPermissionFeatureHook();

  const customerPermissionFeatureFunctions = useMemo(() => ({
    hasCustomerPermission: customerPermissionFeatureHook.hasPermission,
    hasCustomerPermissions: customerPermissionFeatureHook.hasPermissions,
    getCustomerPermissions: customerPermissionFeatureHook.getPermissions
  }), [customerPermissionFeatureHook]);

  return customerPermissionFeatureFunctions;
}
