import React from 'react';
import { observer } from 'mobx-react';
import { StackLayout } from '@wemogy/reactbase';
import IQrCodeSourceItemListProps from './IQrCodeSourceItemListProps';
import QrCodeSourceItem from '../../components/qrCodeSourceItem/QrCodeSourceItem';

const QrCodeSourceItemList: React.FC<IQrCodeSourceItemListProps> = ({
  qrCodeSources, onQrCodeSourceRemove
}) => {

  return (
    <StackLayout>
      {
        qrCodeSources.map(QrCodeSource => (
          <QrCodeSourceItem
            key={QrCodeSource.id}
            qrCodeSource={QrCodeSource}
            onRemove={(): void => {
              onQrCodeSourceRemove(QrCodeSource);
            }}
          />
        ))
      }
    </StackLayout>
  );
};

export default observer(QrCodeSourceItemList);
