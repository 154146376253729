import { TranslationInformation, useModalStore } from '@wemogy/reactbase';
import NotificationManager from '../../inAppFeedback/NotificationManager';
import saveFile from '../../ioUtils/SaveFile';

export default function useExportHandler(): ({
  handleExportResult: (exportCall: Promise<Response>, exportMessage: TranslationInformation, csvFilename: string) => void;
}) {
  const modalStore = useModalStore();

  return {
    handleExportResult(exportCall: Promise<Response>, exportMessage: TranslationInformation, csvFilename: string): void{
      modalStore.openModal('loadingOverlay', `${exportMessage} Dies dauert einen Moment.`);
      exportCall.then(async (response): Promise<void> => {
        if (response.ok) {
          if (response.status !== 202) {
            const csvData = await response.text();

            saveFile(csvData, `${csvFilename}.csv`);
            NotificationManager.success('Export erfolgreich abgeschlossen.');
          }
        } else {
          NotificationManager.error(`Beim Export ist etwas schief gelaufen. Statuscode: ${response.status}`);
        }
        modalStore.closeModal();
      }).catch((): void => {
        NotificationManager.error('Beim Export ist etwas schief gelaufen.');
        modalStore.closeModal();
      });
    }
  };
}
