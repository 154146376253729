import React from 'react';
import { observer } from 'mobx-react';
import { StackLayout, useTheme } from '@wemogy/reactbase';
import { PropagateLoader } from 'react-spinners';
import IDataItemLoadingIndicatorProps from './IDataItemLoadingIndicatorProps';
import useDataItemStore from '../../dataLayer/stores/hooks/UseDataItemStore';

const DataItemLoadingIndicator: React.FC<IDataItemLoadingIndicatorProps> = () => {
  const dataItemStore = useDataItemStore();
  const theme = useTheme();

  if (!dataItemStore || !dataItemStore.isLoading) {
    return <StackLayout height />;
  }

  return (
    <StackLayout width100 hCenter height vCenter>
      <PropagateLoader color={theme.colorScheme.primary} />
    </StackLayout>
  );
};

export default observer(DataItemLoadingIndicator);
