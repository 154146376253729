import React from 'react';
import { observer } from 'mobx-react';
import { StackLayout, Text} from '@wemogy/reactbase';
import IDropAreaPlaceholderProps from './IDropAreaPlaceholderProps';

const DropAreaPlaceholder: React.FC<IDropAreaPlaceholderProps> = ({
  children, color
}) => {

  return (
    <StackLayout
      vCenter
      hCenter
      height={5}
      customStyle={{
        backgroundImage: `repeating-linear-gradient(45deg,
                                white 0%,
                                white 2%, ${color} 2%,
                                ${color} 4%, white 4%);`
      }}
    >
      <Text title>{children}</Text>
    </StackLayout>
  );
};

export default observer(DropAreaPlaceholder);
