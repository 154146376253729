const { env } = window as any;

export const MainApiUri: string = env.mainApiUri;
export const PdfViewerUri: string = MainApiUri.replace('api', 'pdf');
export const RealtimeApiUri: string = env.realtimeApiUri;
export const MediaHandlerApiUri: string = env.mediaApiUri;
export const CloudStorageUri: string = env.cloudStorageUri;
export const Version: string = env.version;

// #region root store keys

export const RootAppStoreKey = 'rootAppStore';

// #endregion
