import React from 'react';
import { observer } from 'mobx-react';
import { Modal, useModalStore } from '@wemogy/reactbase';
import IAddCustomerMarkerCodeModalProps from './IAddCustomerMarkerCodeModalProps';
import NotificationManager from '../../../inAppFeedback/NotificationManager';
import MarkerCodeForm from '../../components/markerCodeForm/MarkerCodeForm';
import IAddCustomerMarkerModalParameters from './interface/IAddCustomerMarkerModalParameters';
import RestApiServiceFactory from '../../../../../dataLayer/api/rest/RestApiServiceFactory';

const AddCustomerMarkerCodeModal: React.FC<IAddCustomerMarkerCodeModalProps> = () => {
  const modalStore = useModalStore();

  const parameters = modalStore.getActiveParameters<IAddCustomerMarkerModalParameters>('addCustomerMarkerCode');

  if (!parameters) {
    return null;
  }

  const {
    editMarker,
    customer
  } = parameters;

  return (
    <Modal
      modalKey="addCustomerMarkerCode"
      icon="add"
      title={editMarker ? 'Marker-Code bearbeiten' : 'Marker-Code hinzufügen'}
      large
    >
      <MarkerCodeForm
        markerCode={editMarker}
        onCreated={(markerCode): void => {
          modalStore.openModal('loadingOverlay');
          RestApiServiceFactory.customerService.addMarkerCode(customer.id, markerCode).then((apiResult): void => {
            if (apiResult.ok) {
              NotificationManager.success(`Der Marker Code ${markerCode.name} wurde angelegt.`);
              customer.addMarkerCode(markerCode);
            } else {
              NotificationManager.error(`Beim Anlegen des Marker Code ${markerCode.name} ist ein Fehler aufgetreten.`);
            }
            modalStore.openModal('editCustomer', customer.id);
          });
        }}
        onUpdated={(markerCode): void => {
          if (!editMarker) {
            return;
          }
          RestApiServiceFactory.customerService.putMarkerCode(customer.id, markerCode).then((apiResult): void => {
            if (apiResult.ok) {
              NotificationManager.success(`Der Marker Code ${editMarker.name} wurde aktualisiert.`);
            } else {
              NotificationManager.error(`Beim Aktualisieren des Marker Code ${markerCode.name} ist ein Fehler aufgetreten.`);
            }
            modalStore.openModal('editCustomer', customer.id);
          });

          modalStore.openModal('editCustomer', customer.id);
        }}
      />
    </Modal>
  );
};

export default observer(AddCustomerMarkerCodeModal);
