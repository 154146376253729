import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, TextInput, Icon
} from '@wemogy/reactbase';
import IPrimaryKeyFormProps from './IPrimaryKeyFormProps';
import Dropdown from '../../../../components/dropdown/Dropdown';
import IDropdownOption from '../../../../components/dropdown/interfaces/IDropdownOption';
import ValueComparator, { IValueComparator } from '../../dataLayer/enums/ValueComparator';

const dropdownOptions: IDropdownOption[] = [{
  label: 'Ist gleich',
  value: ValueComparator.Equals.toString()
},
{
  label: 'Groß-/Kleinschreibung egal',
  value: ValueComparator.CaseInsensitive.toString()
},
{
  label: 'Beinhaltet',
  value: ValueComparator.Contains.toString()
}];

const PrimaryKeyForm: React.FC<IPrimaryKeyFormProps> = ({primaryKey}) => {

  return (
    <StackLayout horizontal vCenter marginBottom>
      <Icon vpnKey xl />
      <StackLayout width />
      <TextInput placeholder="Quellname" onChange={primaryKey.setSourceName} value={primaryKey.sourceName} />
      <StackLayout width={30} marginRightLeft>
        <Dropdown
          light
          selectedOption={dropdownOptions.find(x => parseInt(x.value) === primaryKey.comparator)}
          options={dropdownOptions}
          onOptionSelected={(option): void =>{
            primaryKey.setComparator(parseInt(option.value) as IValueComparator);
          }}
        />
      </StackLayout>
      <TextInput placeholder="Zielname" onChange={primaryKey.setDestinationName} value={primaryKey.destinationName} />
    </StackLayout>
  );
};

export default observer(PrimaryKeyForm);
