import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  Button, isEmptyOrWhiteSpace, StackLayout
} from '@wemogy/reactbase';
import IProcessingTemplateFormProps from './IProcessingTemplateFormProps';
import AnnotatedTextInput from '../../containers/formElements/annotatedTextInput/AnnotatedTextInput';
import NotificationManager from '../../../inAppFeedback/NotificationManager';
import ProcessingTemplate from '../../dataLayer/models/ProcessingTemplate';
import PdfSettingsForm from '../pdfSettingsForm/PdfSettingsForm';
import PdfSettings from '../../dataLayer/models/PdfSettings';

const ProcessingTemplateForm: React.FC<IProcessingTemplateFormProps> = ({
  processingTemplate, onCreated, onUpdated
}) => {
  const [name, setName] = useState(processingTemplate?.name || '');
  const [suffix, setSuffix] = useState(processingTemplate?.suffix || '')
  const [pdfSettings, setPdfSettings] = useState(processingTemplate?.pdfSettings || PdfSettings.create());

  useEffect((): (() => void) => {
    if (processingTemplate) {
      setName(processingTemplate.name);
      setSuffix(processingTemplate.suffix);
      setPdfSettings(processingTemplate.pdfSettings);
      processingTemplate.beginEditing();
    }
    else {
      setName('');
      setSuffix('');
      setPdfSettings(PdfSettings.create());
    }
    return (): void => {
      if(!processingTemplate){
        return;
      }
      processingTemplate.discardChanges();
    }
  }, [processingTemplate]);

  return (
    <StackLayout>
      <AnnotatedTextInput marginBottom placeholder="Name" description="Name" onChange={setName} value={name} />
      <AnnotatedTextInput marginBottom placeholder="Suffix" description="Suffix" onChange={setSuffix} value={suffix} />

      <PdfSettingsForm pdfSettings={pdfSettings} />

      <StackLayout hEnd>
        <Button onPress={(): void => {
          if (processingTemplate) {
            processingTemplate.beginEditing();
            processingTemplate.setName(name);
            processingTemplate.setSuffix(suffix);
            const patch = processingTemplate.commitChanges();
            onUpdated(processingTemplate, patch);
          } else {
            if (isEmptyOrWhiteSpace(name)) {
              NotificationManager.error('Bitte geben Sie einen Namen ein');
              return;
            }
            onCreated(ProcessingTemplate.create({
              name,
              suffix,
              pdfSettings
            }));
          }
        }}
        >
          {processingTemplate ? 'Speichern' : 'Hinzufügen'}

        </Button>
      </StackLayout>
    </StackLayout>
  );
};

export default observer(ProcessingTemplateForm);
