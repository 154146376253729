import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, Icon
} from '@wemogy/reactbase';
import _ from 'lodash';
import { SortableElement, SortableContainer } from 'react-sortable-hoc';
import { getSnapshot } from 'mobx-state-tree';
import ITableProps from './ITableProps';
import UiElementDropArea from '../../dropAreas/uiElementDropArea/UiElementDropArea';
import ControlDropArea from '../../dropAreas/controlDropArea/ControlDropArea';
import UiElementRenderer from '../../toolboxElementTypeRenderers/uiElementRenderer/UiElementRenderer';
import IControlConfig from '../../toolboxSegments/allControls/interfaces/IControlConfig';
import ControlRenderer from '../../toolboxElementTypeRenderers/controlRenderer/ControlRenderer';
import TableConfig from '../../../dataLayer/models/layoutConfigs/TableConfig';
import {confirmDialogYesNoDanger} from '../../../../dialogs/DialogManager';
import useFormBuilderUiStore from '../../../dataLayer/stores/hooks/UseFormBuilderUiStore';
import { ILayout } from '../../../dataLayer/models/Layout';
import Control, { IControl, IControlSnapshotIn } from '../../../dataLayer/models/Control';
import UiElementType from '../../../dataLayer/enums/UiElementType';
import { UiElementH2Config } from '../../toolboxSegments/allUiElements/AllUiElements';
import NotificationManager from '../../../../inAppFeedback/NotificationManager';
import UiElement from '../../../dataLayer/models/UiElement';


function getControlsOfRow(layout: ILayout, numberOfColumns: number, row: number): (IControl | undefined)[]{
  const controlColumns = _.range(1, numberOfColumns + 1);

  return controlColumns.map(controlColumn => {
    const orderId = (row * numberOfColumns) - controlColumn + 1;
    const columnControl = layout.getControlByOrderId(orderId);
    return columnControl;
  });
}

// #region SORT


const SortableItem = SortableElement(observer((props: any) => {
  const {
    layout, row, controlColumns, numberOfColumns, readonlySections
  } = props;
  const rowUiElement = layout.getUiElementByOrderId(row);
  const { formBuilderStore } = useFormBuilderUiStore();
  const [isHover, setIsHover] = useState(false);

  return (
    <StackLayout onMouseMove={(): void => {setIsHover(true);}} onMouseLeave={(): void => {setIsHover(false);}} key={`row_${row}`} horizontal width100 marginBottom>
      <UiElementDropArea
        stretch
        onDrop={(droppedInformation): void => {
          layout.putUiElement(row, droppedInformation.referenceKey, droppedInformation.config);
        }}
      >
        {
          rowUiElement
            ? (
              <UiElementRenderer
                uiElement={rowUiElement}
                onDelete={(): void => {
                  layout.removeUiElement(rowUiElement);
                }}
              />
            )
            : null
        }
      </UiElementDropArea>

      {
        controlColumns.map((controlColumn: number) => {
          const orderId = (row * numberOfColumns) - controlColumn + 1;
          const columnControl = layout.getControlByOrderId(orderId);
          return (
            <React.Fragment key={`columnControl_${orderId}`}>
              <StackLayout width={0.5} />
              <ControlDropArea
                stretch
                onDrop={(droppedInformation): void => {
                  const controlConfig: IControlConfig = droppedInformation.config;
                  layout.putControl(orderId, droppedInformation.referenceKey, controlConfig.dataType, controlConfig.config);
                }}
              >
                {
                  columnControl
                    ? (
                      <ControlRenderer
                        readonlySections={readonlySections}
                        control={columnControl}
                        onDelete={(): void => {
                          layout.removeControl(columnControl);
                        }}
                      />
                    )
                    : null
                }

              </ControlDropArea>
            </React.Fragment>
          );
        })
      }
      {
        props.isLast || !formBuilderStore.isEditing || !isHover ? null  : (
          <StackLayout positionAbsolute right={-8} top={0} bottom={0} width={8} vCenter>
            <StackLayout horizontal borderRadiusTopRight={0.5} borderRadiusBottomRight={0.5} backgroundColor="grey500" padding={0.5}>
              <Icon
                onPress={(): void => {
                  // nothing to do
                }}
                xl
                dragIndicator
                marginRight
              />
              <Icon
                onPress={(): void => {
                  props.onRemove(row);
                }}
                xl
                deleteForever
              />
            </StackLayout>
          </StackLayout>
        )
      }
    </StackLayout>
  );
}));

const SortableList = SortableContainer((props: any) => {
  const { formBuilderStore } = useFormBuilderUiStore();
  return (
    <div>
      {props.items.map((orderId: number, index: number) => (
        <SortableItem
          key={`sortableElementAnnotatedBlock${props.layout.id}_${orderId}`}
          disabled={!formBuilderStore.isEditing}
          controlColumns={props.controlColumns}
          numberOfColumns={props.numberOfColumns}
          onRemove={props.onRemove}
          index={orderId}
          row={orderId}
          isLast={props.items.length -1 === index}
          layout={props.layout}
          readonlySections={props.readonlySections}
        />
      )
      )}
    </div>
  );
});

// #endregion

const Table: React.FC<ITableProps> = ({
layout, readonlySections
}) => {
  const headerUiElement = layout.getUiElementByOrderId(0);

  const numberOfColumns = layout.getConfig<TableConfig>()?.numberOfControlColumns || 0;

  const maxControlOrderId = layout.maxControlOrderId === undefined ? 0
    : (layout.maxControlOrderId / numberOfColumns);

  const rows = _.range(1, Math.max(layout.maxUiElementOrderId, maxControlOrderId) + 2);
  const controlColumns = _.range(1, numberOfColumns + 1);

  const {formBuilderStore} = useFormBuilderUiStore();
  return (
    <StackLayout stretch>
      <UiElementDropArea onDrop={(droppedInformation): void => {
        layout.putUiElement(0, droppedInformation.referenceKey, droppedInformation.config);
      }}
      >
        {
          headerUiElement
            ? <UiElementRenderer uiElement={headerUiElement} onDelete={layout.removeUiElement} />
            : null
        }
      </UiElementDropArea>
      <StackLayout height={0.5} />
      <SortableList
        distance={1}
        items={rows}
        layout={layout}
        readonlySections={readonlySections}
        controlColumns={controlColumns}
        numberOfColumns={numberOfColumns}
        onRemove={(row: number): void => {
          // row is starting at 1
          confirmDialogYesNoDanger('Wollen Sie diese Zeile wirklich löschen?', (): void => {
            const rowLeft = row * numberOfColumns;
            const rowRight = rowLeft - (numberOfColumns - 1);

            const controlsToRemove = layout.controls.filter(x => x.orderId <= rowLeft && x.orderId >= rowRight);
            controlsToRemove.forEach(layout.removeControl);

            const uiElementToRemove = layout.getUiElementByOrderId(row);
            if (uiElementToRemove) {
              layout.removeUiElement(uiElementToRemove);
            }

            layout.controls.filter(x => x.orderId > rowLeft).forEach(x => x.decrementOrderId(numberOfColumns));
            layout.uiElements.filter(x => x.orderId > row).forEach(x => x.decrementOrderId());
          });

        }}
        onSortEnd={({
          oldIndex, newIndex
        }: any): void => {
          const movedUp = newIndex < oldIndex;
          const movedUiElement = layout.getUiElementByOrderId(oldIndex);
          const linesMoved = Math.abs(oldIndex - newIndex);
          const controlsOfMovedLine = getControlsOfRow(layout, numberOfColumns, oldIndex);

          if (movedUp) {
            const lines = _.range(oldIndex - 1, newIndex - 1);
            lines.forEach(line => {
              const controlsOfLine = getControlsOfRow(layout, numberOfColumns, line);
              controlsOfLine.forEach(control => control?.incrementOrderId(numberOfColumns));
            });

            layout.uiElements.filter(x => x.orderId >= newIndex && x.orderId < oldIndex).forEach(x => x.incrementOrderId());
            controlsOfMovedLine.forEach(control => control?.decrementOrderId(numberOfColumns * linesMoved));
          }
          else {
            const lines = _.range(oldIndex + 1, newIndex + 1);
            lines.forEach(line => {
              const controlsOfLine = getControlsOfRow(layout, numberOfColumns, line);
              controlsOfLine.forEach(control => control?.decrementOrderId(numberOfColumns));
            });

            layout.uiElements.filter(x => x.orderId <= newIndex && x.orderId > oldIndex).forEach(x => x.decrementOrderId());
            controlsOfMovedLine.forEach(control => control?.incrementOrderId(numberOfColumns * linesMoved));
          }

          movedUiElement?.setOrderId(newIndex);
        }}
      />
      {
        formBuilderStore.isEditing ? (
          <StackLayout horizontal hEnd>
            {layout.uiElements.length > 0 || layout.controls.length > 0 ?(
              <Button onPress={(): void => {
              const lastUiElement = _.last(layout.uiElementsOrdered);
              if (lastUiElement) {
                const uiElement = UiElement.create({
                  ...getSnapshot(lastUiElement),
                  orderId: layout.maxUiElementOrderId + 1
                })
                layout.pushUiElement(uiElement);
              }

              const lastRowControls = _.takeRight(layout.controlsOrdered, numberOfColumns).map(x => getSnapshot(x) as IControlSnapshotIn).map(x => ({
                ...x,
                orderId: x.orderId + numberOfColumns
              }));
              lastRowControls.forEach(x => layout.pushControl(Control.create(x)))

              NotificationManager.success('Die neue Zeile wurde eingefügt.');
            }}
              >
                Letzte Zeile duplizieren

              </Button>
) : null}
            <StackLayout width />
            <Button onPress={(): void => {
              layout.addUiElement(layout.maxUiElementOrderId + 1, UiElementType.H2, UiElementH2Config);
              NotificationManager.success('Die neue Zeile wurde eingefügt.');
            }}
            >
              Neue Zeile mit H2

            </Button>
          </StackLayout>
        ) : null
      }
    </StackLayout>
  );
};

export default observer(Table);
