import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, TextInput, Button, HttpStatus, useModalStore
} from '@wemogy/reactbase';
import _ from 'lodash';
import IInvitationManagementProps from './IInvitationManagementProps';
import useDataAccessStore from '../../../dataLayer/stores/hooks/UseDataAccessStore';
import InvitationList from '../invitationList/InvitationList';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import Invitation from '../../../dataLayer/models/Invitation';
import NotificationManager from '../../wrappers/inAppFeedback/NotificationManager';
import { confirmDialogYesNoDanger } from '../../wrappers/dialogs/DialogManager';
import RestApiServiceFactory from '../../../dataLayer/api/rest/RestApiServiceFactory';
import DatePicker from '../../wrappers/datePicker/DatePicker';

const InvitationManagement: React.FC<IInvitationManagementProps> = () => {
  const { invitationStore } = useDataAccessStore();
  const modalStore = useModalStore();
  const [newInvitationMail, setNewInvitationMail] = useState('');
  const [sendingInvitation, setSendingInvitation] = useState(false);
  const [newInvitationExpireDate, setNewInvitationExpireDate] = useState<Date | undefined>(undefined);

  useEffect((): void => {
    invitationStore.query();
  }, []);

  const sendInvitation = async (): Promise<void> => {
    setSendingInvitation(true);
    const newInvitation = Invitation.create({
      email: newInvitationMail,

      userExpiresAt: newInvitationExpireDate
    });
    const apiResult = await invitationStore.create(newInvitation);
    setSendingInvitation(false);
    if (!apiResult.ok) {
      NotificationManager.error(apiResult.status === HttpStatus.Conflict ? `Es existiert bereits eine Einladung für ${newInvitationMail}` : 'Die Einladung konnte nicht angelegt werden');
      invitationStore.deleteItem(newInvitation.id);
      return;
    }
    NotificationManager.success(`Die Einladung wurde an ${newInvitationMail} gesendet`);
    setNewInvitationMail('');
  };

  return (
    <StackLayout paddingTop marginTopBottom={4} borderTop={0.125} border={0} borderColor="primary">
      <Text largeTitle>Einladungen</Text>
      <InvitationList
        invitations={invitationStore.queryItems()}
        onResend={(invitation): void => {
          confirmDialogYesNoDanger(`Möchten Sie die Einladung erneut an ${invitation.email} senden?`, async (): Promise<void> => {
            modalStore.openModal('loadingOverlay');
            const apiResult = await RestApiServiceFactory.invitationService.resendInvitation(invitation.id);
            if (apiResult.ok) {
              NotificationManager.success('Die Einladung wurde erfolgreich versendet');
            } else {
              NotificationManager.error('Beim Senden der Einladung ist ein Fehler aufgetreten');
            }
            modalStore.closeModal();
          });
        }}
        onRemove={(invitation): void => {
          confirmDialogYesNoDanger(`Möchten Sie die Einladung von ${invitation.email} wirklich löschen?`, async (): Promise<void> => {
            modalStore.openModal('loadingOverlay');
            const apiResult = await invitationStore.delete(invitation.id);
            if (apiResult.ok) {
              NotificationManager.success('Die Einladung wurde erfolgreich gelöscht');
            } else {
              NotificationManager.error('Beim Löschen der Einladung ist ein Fehler aufgetreten');
            }
            modalStore.closeModal();
          });
        }}
      />
      <Text marginTop={2}>Neuen Benutzer einladen</Text>
      {sendingInvitation ? (
        <LoadingIndicator />
      ) : (
        <StackLayout horizontal marginTop vCenter>
          <TextInput placeholder="E-Mail Adresse" value={newInvitationMail} onChange={setNewInvitationMail} marginRight />
          <DatePicker
            value={newInvitationExpireDate}
            onChange={(date): void => {
                if (_.isDate(date)) {
                  setNewInvitationExpireDate(date);
                } else {
                  setNewInvitationExpireDate(undefined)
              }
            }}
            placeholder="Ablaufdatum"
          />
          <Button
            marginLeft
            onPress={(): void => {
              sendInvitation();
            }}
          >
            Einladen

          </Button>
        </StackLayout>
      )}
    </StackLayout>
  );
};

export default observer(InvitationManagement);
