import React, { useCallback, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { QrReader, OnResultFunction } from 'react-qr-reader';
import { StackLayout, Icon } from '@wemogy/reactbase';
import IQrScannerProps from './IQrScannerProps';

const QrScanner: React.FC<IQrScannerProps> = ({ onQrCodeScanned }) => {
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('environment');
  const [userCameraAvailable, setUserCameraAvailable] = useState(false);
  // Workaround because the QrReader don't check the updated handler callback
  const [qrCodeScannedState] = useState({handler: onQrCodeScanned});

  const handleQrReaderResult = useCallback<OnResultFunction>((result): void => {
    const textPayload = result?.getText();

    if(!textPayload) {
      return;
    }

    qrCodeScannedState.handler(textPayload);
  }, []);

  useEffect((): void => {
    qrCodeScannedState.handler = onQrCodeScanned;
  }, [onQrCodeScanned]);

  useEffect((): void => {
    try {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const videoDevices = devices.filter(x => x.kind.toLowerCase() === 'videoinput');
        // maybe we have to check the label also
        // x.label => ... facing front / back
        // front ==> user
        // back ==> environment
        setUserCameraAvailable(videoDevices.length > 1);
      })
    } catch {
      // ToDo: handle this error
      // this error can occur when the permissions are denied, or the app used used via HTTP instead of HTTPS
    }
  }, []);

  const handleSwitchCameraPress = useCallback((): void => {
    if (facingMode === 'environment') {
      setFacingMode('user');
    }
    else {
      setFacingMode('environment');
    }
  }, [facingMode]);

  return (
    <StackLayout stretch>
      <QrReader constraints={{ facingMode }} onResult={handleQrReaderResult} containerStyle={{ width: '70vw', height: '70vh'}} />

      {userCameraAvailable ? (
        <StackLayout positionAbsolute bottom right backgroundColor="primary" borderRadius={{ custom: '50%' }} padding onPress={handleSwitchCameraPress}>
          <Icon color="white" icon={facingMode === 'user' ? 'cameraRear' : 'cameraFront'} />
        </StackLayout>
      ) : null}
    </StackLayout>
  );
};

export default observer(QrScanner);
