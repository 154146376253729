import { confirmDialogYesNo } from '../dialogs/DialogManager';
import NotificationManager from '../inAppFeedback/NotificationManager';

const copyToClipboardWorkingAreaId = 'copyToClipboardWorkingArea';

const copyToClipboardCore = (text: string): boolean => {
  /* Get the text field */
  let workingArea = document.getElementById(copyToClipboardWorkingAreaId) as HTMLTextAreaElement | null;

  if(!workingArea){
    workingArea = document.createElement('textarea');
    workingArea.id = copyToClipboardWorkingAreaId;
    workingArea.style.position = 'absolute';
    workingArea.style.top = '-2000px';
    workingArea.style.left = '-2000px';
    workingArea.style.opacity = '0';
    document.body.appendChild(workingArea);
  }

  workingArea.textContent = text;

  /* Select the text field */
  workingArea.select();
  workingArea.setSelectionRange(0, 99999); /* For mobile devices */

  /* Copy the text inside the text field */
  const res = document.execCommand('copy');

  workingArea.blur();

  if (res) {
    NotificationManager.success('Erfolgreich kopiert');
  }

  return res;
};

const copyToClipboard = (text: string): void  => {
  if (!copyToClipboardCore(text)) {
    confirmDialogYesNo('Kopieren bestätigen', 'Da Sie einen veralteten Browser verwenden, müssen Sie das kopieren, leider nochmal bestätigen', (): void => {
      copyToClipboardCore(text);
    });
  }
};

export function copyAsExcelCellsToClipboard(...values: (string | number)[]): void {
  copyToClipboard(values.join('\t'));
}


export default copyToClipboard;
