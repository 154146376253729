import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import {BaseModel} from '@wemogy/reactbase';

const PdfSettingsSubType = types.model({
  isRotationEnabled: types.optional(types.boolean, false),
  isOcrEnabled: types.optional(types.boolean, false),
  isCompressionEnabled: types.optional(types.boolean, false),
  isPdfAConversionEnabled: types.optional(types.boolean, false),
  isPdfAValidationEnabled: types.optional(types.boolean, false),
  isAggressiveOcrPreProcessingEnabled: types.optional(types.boolean, false),
  preserveBookmarks: types.optional(types.boolean, false),
  threadCount: types.optional(types.number, 1),
  tesseractTimeout: types.optional(types.number, 10000),
  ocrLanguages: types.optional(types.string, 'deu+eng'),
  isDebugEnabled: types.optional(types.boolean, false),
})
  .named('PdfSettingsSubType')
  .actions(self => ({
    setIsRotationEnabled(isRotationEnabled: boolean): void{
      self.isRotationEnabled = isRotationEnabled;
    },
    setIsOcrEnabled(isOcrEnabled: boolean): void{
      self.isOcrEnabled = isOcrEnabled;
    },
    setIsCompressionEnabled(isCompressionEnabled: boolean): void{
      self.isCompressionEnabled = isCompressionEnabled;
    },
    setIsPdfAConversionEnabled(isPdfAConversionEnabled: boolean): void{
      self.isPdfAConversionEnabled = isPdfAConversionEnabled;
    },
    setIsPdfAValidationEnabled(isPdfAValidationEnabled: boolean): void{
      self.isPdfAValidationEnabled = isPdfAValidationEnabled;
    },
    setIsAggressiveOcrPreProcessingEnabled(isAggressiveOcrPreProcessingEnabled: boolean): void{
      self.isAggressiveOcrPreProcessingEnabled = isAggressiveOcrPreProcessingEnabled;
    },
    setPreserveBookmarks(preserveBookmarks: boolean): void{
      self.preserveBookmarks = preserveBookmarks;
    },
    setThreadCount(threadCount: number): void{
      self.threadCount = threadCount;
    },
    setTesseractTimeout(tesseractTimeout: number): void{
      self.tesseractTimeout = tesseractTimeout;
    },
    setOcrLanguages(ocrLanguages: string): void{
      self.ocrLanguages = ocrLanguages;
    },
    setIsDebugEnabled(isDebugEnabled: boolean): void{
      self.isDebugEnabled = isDebugEnabled;
    }
  }));

const PdfSettings = types.compose(BaseModel, PdfSettingsSubType)
  .named('PdfSettings');

export default PdfSettings;

export interface IPdfSettings extends Instance<typeof PdfSettings>{}
export interface IPdfSettingsSnapshotIn extends SnapshotIn<typeof PdfSettings>{}
export interface IPdfSettingsSnapshotOut extends SnapshotOut<typeof PdfSettings>{}
