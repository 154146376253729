import React from 'react';
import { observer } from 'mobx-react';
import {TextInput} from '@wemogy/reactbase';
import IDefaultValueSetterRendererProps from './IDefaultValueSetterRendererProps';
import InputTypeConfig from '../../../../dataLayer/models/controlTypeConfigs/InputTypeConfig';
import Checkbox from '../../../../../../components/checkbox/Checkbox';
import Dropdown from '../../../../../../components/dropdown/Dropdown';
import RadioTypeConfig from '../../../../dataLayer/models/controlTypeConfigs/RadioTypeConfig';
import CheckGroupTypeConfig from '../../../../dataLayer/models/controlTypeConfigs/CheckGroupTypeConfig';
import CheckGroup from '../../../../../../components/checkGroup/CheckGroup';
import DropdownTypeConfig from '../../../../dataLayer/models/controlTypeConfigs/DropdownTypeConfig';
import ControlType from '../../../../dataLayer/enums/ControlType';

const DefaultValueSetterRenderer: React.FC<IDefaultValueSetterRendererProps> = ({control}) => {

  switch (control.type) {
    case ControlType.Input:
      const inputTypeConfig = control.getConfig<InputTypeConfig>();
      if(!inputTypeConfig){
        return null;
      }
      return <TextInput numeric={inputTypeConfig.isNumberInput} placeholder="Der Standardwert, wenn kein Wert angegeben ist" onChange={control.setDefaultValue} value={control.getDefaultValue<string>()} />;
    case ControlType.Checkbox:
      return <Checkbox checked={control.getDefaultValue<boolean>() || false} onChange={control.setDefaultValue} />;
    case ControlType.Radio:
      const radioTypeConfig = control.getConfig<RadioTypeConfig>();
      if(!radioTypeConfig){
        return null;
      }
      const radioOptions = radioTypeConfig.options.map(x => ({
        label: `${x.key} | ${x.value}`,
        value: x.key
      }));
      return (
        <Dropdown
          light
          options={radioOptions}
          selectedOption={radioOptions.find(x => x.value === control.getDefaultValue())}
          onOptionSelected={(option): void => {
            control.setDefaultValue(option.value);
          }}
        />
      );
    case ControlType.CheckGroup:
      const checkGroupConfig = control.getConfig<CheckGroupTypeConfig>();
      if(!checkGroupConfig){
        return null;
      }
      console.log(control.getDefaultValue());
      return (
        <CheckGroup
          options={checkGroupConfig.options}
          selectedOptions={control.getDefaultValue() || []}
          onSelectedOptionsChange={(selectedOptions): void => {
            const serialized = JSON.stringify(selectedOptions);
            control.setDefaultValue(serialized);
          }}
        />
);
    case ControlType.Dropdown:
      const dropdownConfig = control.getConfig<DropdownTypeConfig>();
      if(!dropdownConfig){
        return null;
      }
      const dropdownOptions = dropdownConfig.options.map(x => ({
        label: `${x.key} | ${x.value}`,
        value: x.value
      }));
      return (
        <Dropdown
          light
          options={dropdownOptions}
          selectedOption={dropdownOptions.find(x => x.value === control.getDefaultValue())}
          onOptionSelected={(option): void => {
            control.setDefaultValue(option.value);
          }}
        />
      );
    default:
      return null;
  }
};

export default observer(DefaultValueSetterRenderer);
