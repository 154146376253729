import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout,  Button, Text, Modal, useModalStore
} from '@wemogy/reactbase';
import IManageMarkerCodesModalProps from './IManageMarkerCodesModalProps';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import AnnotatedFormView from '../../containers/formElements/annotatedFormView/AnnotatedFormView';
import { confirmDialogYesNoDanger } from '../../../dialogs/DialogManager';
import NotificationManager from '../../../inAppFeedback/NotificationManager';
import MarkerCode from '../../components/markerCode/MarkerCode';

const ManageMarkerCodesModal: React.FC<IManageMarkerCodesModalProps> = () => {
  const { formStore } = useDataAccessStore();
  const modalStore = useModalStore();

  const { activeItem } = formStore;
  if(!activeItem){
    return null;
  }

  return (
    <Modal modalKey="manageMarkerCodes" icon="settings" title="Marker-Codes verwalten" large>
      <StackLayout minHeight={{custom: '50vh'}}>
        <AnnotatedFormView description="Marker-Codes" marginBottom>
          <StackLayout>
            {
              activeItem.markerCodes.length
                ? activeItem.markerCodes.map(markerCode => (
                  <MarkerCode
                    key={markerCode.id}
                    markerCode={markerCode}
                    onEdit={(m): void => {
                      modalStore.openModal('addMarkerCode', m);
                    }}
                    onDelete={(): void => {
                      confirmDialogYesNoDanger(`Möchten Sie den Marker-Code ${markerCode.name} wirklich löschen?`, (): void => {
                        activeItem.removeMarkerCode(markerCode);
                        NotificationManager.success(`Der Marker Code ${markerCode.name} wurde angelegt. Zum Speichern, speichern Sie bitte die Datenbank (Datenbank -> Speichern)`);
                      });
                    }}
                  />
                )) : (
                  <StackLayout hCenter>
                    <Text title marginTopBottom={2}>Es sind keine Maker-Codes vorhanden</Text>
                    <Button onPress={(): void =>{
                      modalStore.openModal('addMarkerCode');
                    }}
                    >
                      Jetzt einen Marker-Code anlegen

                    </Button>
                  </StackLayout>
                )
            }
          </StackLayout>
        </AnnotatedFormView>
        {
          activeItem.markerCodes.length ? (
            <StackLayout hEnd>
              <Button onPress={(): void => {
                modalStore.openModal('addMarkerCode');
              }}
              >
                Neuen Marker-Code hinzufügen

              </Button>
            </StackLayout>
          ) : null
        }
      </StackLayout>
    </Modal>
  );
};

export default observer(ManageMarkerCodesModal);
