/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import useDataAccessStore from '../../../../dataLayer/stores/hooks/UseDataAccessStore';

export default function workingPackageCustomResolver() {
  const { formStore } = useDataAccessStore();
  return ({
    getActiveId(): string | undefined{
      if (!formStore.activeItem) {
        console.log('formStore.activeItem UNDEFINED')
        return undefined;
      }
      console.log('formStore.activeItem.activeWorkingPackage?', formStore.activeItem.activeWorkingPackage)
      return formStore.activeItem.activeWorkingPackage?.id;
    }
  })
}
