import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, Text, Icon, useModalStore
} from '@wemogy/reactbase';
import { Dropdown } from 'semantic-ui-react'
import IDatabaseBuilderProps from './IDatabaseBuilderProps';
import MainLayout from '../../layouts/mainLayout/MainLayout';
import FormBuilder from '../../wrappers/formBuilder/containers/formBuilder/FormBuilder';
import useDataAccessStore from '../../../dataLayer/stores/hooks/UseDataAccessStore';
import RestApiServiceFactory from '../../../dataLayer/api/rest/RestApiServiceFactory';
import NotificationManager from '../../wrappers/inAppFeedback/NotificationManager';
import NavigationManager from '../../../navigation/NavigationManager';
import { confirmDialogYesNoDanger } from '../../wrappers/dialogs/DialogManager';
import saveFile from '../../wrappers/ioUtils/SaveFile';
import FormPermissionFeature from '../../plugins/authorization/permissionFeatures/FormPermissionFeature';
import IExportDataModalParameters from '../../wrappers/formBuilder/modals/exportDataModal/IExportDataModalParameters';

function startDownloadFromUri(uri: string): void {
  console.log('startDownloadFromUri', uri);
  const link = document.createElement('a');
  link.download = '';
  link.href = uri;
  link.click();
}

const DatabaseBuilder: React.FC<IDatabaseBuilderProps> = ({
  formId, customerId
}) => {
  const modalStore = useModalStore();
  const [notFound, setNotFound] = useState(false);

  RestApiServiceFactory.setCustomBaseHeaders(new Map<string, string>([
    ['customerId', customerId],
    ['formId', formId]
  ]));
  const {
    formStore, databaseTemplateStore
  } = useDataAccessStore();

  useEffect((): void => {
    databaseTemplateStore.query();
  }, []);

  if (notFound) {
    return (
      <StackLayout stretch hCenter vCenter>
        <Icon xl help />
        <Text title marginTopBottom={2}>Datenbank nicht gefunden</Text>
        <Button onPress={(): void => {
          NavigationManager.navigate('/customerOverview', true);
        }}
        >
          Zur Kundenübersicht
        </Button>
      </StackLayout>
    );
  }


  if (formStore.activeItem?.id !== formId) {
    const form = formStore.getItem(formId);
    if (!form) {
      formStore.get(formId).then((): void => {
        const currentForm = formStore.getItem(formId);
        console.log(currentForm, formStore.items.length);
        if (currentForm) {
          formStore.setActiveItem(currentForm);
        }
        else {
          setNotFound(true);
        }
        modalStore.closeModal();
      });
      modalStore.openModal('loadingOverlay', 'Datenbank wird geladen');
      return null;
    }
    formStore.setActiveItem(form);
  }

  const { activeItem } = formStore;

  return (
    <>
      <MainLayout navigation={(

        <>
          <Dropdown item text="Datenbank" simple>
            <Dropdown.Menu>
              <Dropdown.Item onClick={(): void => {
                if (!activeItem) {
                  return;
                }
                modalStore.openModal('loadingOverlay', 'Datenbank wird gespeichert');
                try {
                  formStore.put(activeItem).then((apiResult): void => {
                    if (apiResult.ok) {
                      NotificationManager.success('Erfolgreich gespeichert.');
                    } else {
                      NotificationManager.error(`Es ist ein Fehler aufgetreten: ${apiResult.status}`);
                    }

                    modalStore.closeModal();
                  }).catch((reason): void => {
                    modalStore.closeModal();
                    NotificationManager.error(`Es ist ein Fehler aufgetreten: CATCH Promise -> ${reason ? JSON.stringify(reason) : 'No Reason'}`);
                  });
                } catch (error) {
                  modalStore.closeModal();
                  NotificationManager.error('Es ist ein Fehler aufgetreten: CATCH');
                }
              }}
              >
                Speichern

              </Dropdown.Item>
              <FormPermissionFeature requiredPermission="CanDeleteForm">
                <Dropdown.Item onClick={(): void => {
                  if (!activeItem) {
                    return;
                  }
                  confirmDialogYesNoDanger('Wollen Sie die Datenbank wirklich löschen?', (): void => {
                    modalStore.openModal('loadingOverlay', 'Datenbank wird gelöscht');
                    NavigationManager.navigate('/customerOverview');
                    formStore.unsetActiveItem();
                    formStore.delete(activeItem.id).then((a): void => {
                      if (a.ok) {
                        NotificationManager.success('Erfolgreich gelöscht.');
                      } else {
                        NotificationManager.error('Fehler beim Löschen.');
                      }

                      modalStore.closeModal();
                    });
                  });
                }}
                >
                  Löschen

                </Dropdown.Item>
              </FormPermissionFeature>
              <FormPermissionFeature requiredPermission="CanImportFormTemplates">
                <Dropdown.Item onClick={(): void => {
                  modalStore.openModal('importAutocomplete');
                }}
                >
                  Autocomplete Daten importieren

                </Dropdown.Item>
              </FormPermissionFeature>
              <Dropdown.Item onClick={(): void => {
                modalStore.openModal('loadingOverlay', 'Der Export wird zusammengestellt. Einen Moment bitte.');
                RestApiServiceFactory.dataStructureTemplateService.exportCsv(formId).then((csv): void => {
                  saveFile(csv.data, `${activeItem?.name}_Controls.csv`);
                  modalStore.closeModal();
                }).catch((): void => {
                  modalStore.closeModal();
                  NotificationManager.error('Es ist ein Fehler aufgetreten');
                });
              }}
              >
                Autocomplete Daten exportierten

              </Dropdown.Item>
              <FormPermissionFeature requiredPermission="CanManagePermissions">
                <Dropdown.Item onClick={(): void => {
                  modalStore.openModal('formUser', formId);
                }}
                >
                  Datenbank Rechteverwaltung

                </Dropdown.Item>
              </FormPermissionFeature>
              <Dropdown.Item onClick={(): void => {
                modalStore.openModal('loadingOverlay', 'Die Dateien werden aufgeräumt. Einen Moment bitte.');
                RestApiServiceFactory.attachmentStoreService.cleanupAttachments(formId).then((apiResult): void => {
                  if(apiResult.ok){
                    NotificationManager.success('Die Dateien wurden erfolgreich aufgeräumt');
                  }
                  else {
                    NotificationManager.error(`Beim aufräumen ist ein Fehler aufgetreten: ${apiResult.status}`);
                  }
                  modalStore.closeModal();
                }).catch((): void => {
                  modalStore.closeModal();
                  NotificationManager.error('Es ist ein Fehler aufgetreten');
                });
              }}
              >
                Dateien aufräumen

              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown item text="Vorlagen" simple>
            <Dropdown.Menu>
              <Dropdown.Item onClick={(): void => {
                modalStore.openModal('openDatabaseTemplate');
              }}
              >
                Datenbank Vorlage öffnen

              </Dropdown.Item>
              <Dropdown.Item onClick={(): void => {
                modalStore.openModal('addDatabaseTemplate');
              }}
              >
                Als Datenbank Vorlage speichern

              </Dropdown.Item>
              <Dropdown.Item onClick={(): void => {
                modalStore.openModal('manageDatabaseTemplates');
              }}
              >
                Datenbank Vorlagen verwalten

              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown item text="Datenquellen" simple>
            <Dropdown.Menu>
              <Dropdown.Item onClick={(): void => {
                modalStore.openModal('manageDataSources');
              }}
              >
                Datenquellen verwalten

              </Dropdown.Item>
              <Dropdown.Item onClick={(): void => {
                modalStore.openModal('addDataSource');
              }}
              >
                Datenquelle hinzufügen

              </Dropdown.Item>
              <Dropdown.Item onClick={(): void => {
                modalStore.openModal('manageQrCodeSources');
              }}
              >
                QR Codes verwalten

              </Dropdown.Item>
              <Dropdown.Item onClick={(): void => {
                modalStore.openModal('addQrCodeSource');
              }}
              >
                QR Code hinzufügen

              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown item text="Daten" simple>
            <Dropdown.Menu>
              <FormPermissionFeature requiredPermission="CanImportData">
                <Dropdown.Item onClick={(): void => {
                  modalStore.openModal('importData');
                }}
                >
                  Daten importieren

                </Dropdown.Item>
              </FormPermissionFeature>
              <FormPermissionFeature requiredPermission="CanDeleteDataItems">
                <Dropdown.Item onClick={(): void => {
                  confirmDialogYesNoDanger('Wollen Sie wirklich alle Datensätze löschen?', (): void => {
                    console.log('IN');
                    setTimeout(() => {
                      confirmDialogYesNoDanger('VORSICHT! Sie haben gerade bestätigt alle Datensätze zu löschen. Wollen Sie das wirklich? (letzte Rückfrage!)', (): void => {
                        modalStore.openModal('loadingOverlay', 'Alle Datensätze werden gelöscht');

                        RestApiServiceFactory.getDataItemService(formId).deleteAllDataItems(formId).then((response): void => {
                          if (response.ok) {
                            NotificationManager.success('Alle Datensätze wurden erfolgreich gelöscht');
                          }
                          else {
                            NotificationManager.error(`Beim löschen ist ein Fehler aufgetreten: ${response.status}`);
                          }
                          modalStore.closeModal();
                        })
                          .catch((): void => {
                            NotificationManager.error('Beim löschen ist ein Fehler aufgetreten: ');
                            modalStore.closeModal();
                          });
                      });
                    }, 500);
                  });
                }}
                >
                  Alle Datensätze löschen

                </Dropdown.Item>
              </FormPermissionFeature>

            </Dropdown.Menu>
          </Dropdown>


          <FormPermissionFeature requiredPermission="CanManageWorkingPackages">
            <Dropdown item text="Arbeitspakete" simple>
              <Dropdown.Menu>
                <Dropdown.Item onClick={(): void => {
                  modalStore.openModal('manageWorkingPackages');
                }}
                >
                  Arbeitspakete verwalten

                </Dropdown.Item>
                <Dropdown.Item onClick={(): void => {
                  modalStore.openModal('addWorkingPackage');
                }}
                >
                  Arbeitspaket hinzufügen

                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </FormPermissionFeature>

          <Dropdown item text="Exportieren" simple>
            <Dropdown.Menu>
              <Dropdown.Item onClick={(): void => {
                modalStore.openModal('loadingOverlay', 'CSV wird generiert und heruntergeladen');
                RestApiServiceFactory.formService.getCsvExport(formId, 'controls').then(csv => {
                  saveFile(csv.data, `${activeItem?.name}_Controls.csv`);
                }).catch((): void => {
                  NotificationManager.error('Es ist ein Fehler beim Exportieren aufgetreten');
                }).finally((): void => {
                  modalStore.closeModal();
                });
              }}
              >
                Controls als CSV exportieren

              </Dropdown.Item>
              <Dropdown.Item onClick={(): void => {
                modalStore.openModal('loadingOverlay', 'CSV wird generiert und heruntergeladen');
                RestApiServiceFactory.formService.getCsvExport(formId, 'dataSources').then(csv => {
                  saveFile(csv.data, `${activeItem?.name}_DataSources.csv`);
                }).catch((): void => {
                  NotificationManager.error('Es ist ein Fehler beim Exportieren aufgetreten');
                }).finally((): void => {
                  modalStore.closeModal();
                });
              }}
              >
                Datenquellen als CSV exportieren

              </Dropdown.Item>
              <FormPermissionFeature requiredPermission="CanExportIndex">
                <Dropdown.Item onClick={(): void => {
                  modalStore.openModal('exportKeywording');
                }}
                >
                  Verschlagwortung als CSV exportieren

                </Dropdown.Item>
              </FormPermissionFeature>
              <FormPermissionFeature requiredPermission="CanExportIndex">
                <Dropdown.Item onClick={(): void => {
                  modalStore.openModal('exportKeywording', true);
                }}
                >
                  Verschlagwortung als IMP exportieren

                </Dropdown.Item>
              </FormPermissionFeature>
              <FormPermissionFeature requiredPermission="CanExportComments">
                <Dropdown.Item onClick={(): void => {
                  modalStore.openModal('exportComments');
                }}
                >
                  Kommentare als CSV exportieren

                </Dropdown.Item>
              </FormPermissionFeature>
              <FormPermissionFeature requiredPermission="CanExportDataItems">
                <Dropdown.Item onClick={(): void => {
                  modalStore.openModal('exportData');
                }}
                >
                  Datensätze exportieren

                </Dropdown.Item>
                <Dropdown.Item onClick={(): void => {
                  modalStore.openModal('exportData', {withAttachmentsAndKeywording: true} as IExportDataModalParameters);
                }}
                >
                  Datensätze mit Dateien und Verschlagwortung exportieren

                </Dropdown.Item>
              </FormPermissionFeature>
              {0 < 5 ? null
                : (
                  <Dropdown.Item onClick={(): void => {
                    modalStore.openModal('loadingOverlay', 'Der Dateiexport wird zusammengestellt, dies kann ein wenig Zeit in Anspruch nehmen.');
                    RestApiServiceFactory.getDataItemService(formId).getAttachmentExport().then((downloadUri): void => {
                      modalStore.closeModal();
                      if (!downloadUri) {
                        NotificationManager.error('Es ist ein Fehler aufgetreten');
                        return;
                      }
                      startDownloadFromUri(downloadUri);
                      NotificationManager.success('Der Download startet jetzt');
                    }).catch((): void => {
                      modalStore.closeModal();
                      NotificationManager.error('Es ist ein Fehler aufgetreten');
                    });
                  }}
                  >
                    Dateien exportieren

                  </Dropdown.Item>
                )}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown item text="Einstellungen" simple>
            <Dropdown.Menu>
              <Dropdown.Item onClick={(): void => {
                modalStore.openModal('manageNavigationConfiguration');
              }}
              >
                Erfassermodus navigation

              </Dropdown.Item>
              <Dropdown.Item onClick={(): void => {
                modalStore.openModal('sampleSettings');
              }}
              >
                Einstellungen für Stichproben

              </Dropdown.Item>
              <Dropdown.Item onClick={(): void => {
                modalStore.openModal('artSettings');
              }}
              >
                Einstellungen für ART

              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown item text="Backup" simple>
            <Dropdown.Menu>
              <Dropdown.Item onClick={(): void => {
                modalStore.openModal('createBackup');
              }}
              >
                Manuelles Backup erstellen

              </Dropdown.Item>
              <Dropdown.Item onClick={(): void => {
                modalStore.openModal('backups');
              }}
              >
                Backups anzeigen

              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown item text="Marker-Codes" simple>
            <Dropdown.Menu>
              <Dropdown.Item onClick={(): void => {
                modalStore.openModal('manageMarkerCodes');
              }}
              >
                Marker-Codes verwalten

              </Dropdown.Item>
              <Dropdown.Item onClick={(): void => {
                modalStore.openModal('addMarkerCode');
              }}
              >
                Marker-Code hinzufügen

              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      )}
      >
        <FormBuilder />
      </MainLayout>
    </>
  );
};

export default observer(DatabaseBuilder);
