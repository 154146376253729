import React from 'react';
import { observer } from 'mobx-react';
import { Text } from '@wemogy/reactbase';
import IMarkerCodeNameProps from './IMarkerCodeNameProps';
import useMarkerCodeNameResolver from '../../hooks/UseMarkerCodeNameResolver';

const MarkerCodeName: React.FC<IMarkerCodeNameProps> = ({
form, markerCodeId
}) => {
  const markerCodeNameResolver = useMarkerCodeNameResolver();

    return (
      <Text noTranslation>{markerCodeNameResolver(form, markerCodeId)}</Text>
    )
}

export default observer(MarkerCodeName);
