import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, Icon, useModalStore
} from '@wemogy/reactbase';
import IQrCodeSourceItemProps from './IQrCodeSourceItemProps';
import TextIcon from '../textIcon/TextIcon';

const QrCodeSourceItem: React.FC<IQrCodeSourceItemProps> = ({
  qrCodeSource, onRemove
}) => {
  const modalStore = useModalStore();

  return (
    <StackLayout horizontal padding vCenter>
      <TextIcon>QR Code</TextIcon>
      <Text stretch labelLarge>{qrCodeSource.name}</Text>
      <Icon
        edit
        xl
        marginRight
        onPress={(): void => {
          modalStore.openModal('editQrCodeSource', qrCodeSource);
        }}
      />
      <Icon deleteForever xl onPress={onRemove} />
    </StackLayout>
  );
};

export default observer(QrCodeSourceItem);
