import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, TextInput, Button, resolveComparator, invokeEventHandler, Icon
} from '@wemogy/reactbase';
import IDataItemTableFilterProps from './IDataItemTableFilterProps';
import './DataItemTableFilter.css';
import Dropdown from '../../../../components/dropdown/Dropdown';
import IDropdownOption from '../../../../components/dropdown/interfaces/IDropdownOption';
import NotificationManager from '../../../inAppFeedback/NotificationManager';
import TooltipHelp from '../../../tooltipHelp/TooltipHelp';
import Checkbox from '../../../../components/checkbox/Checkbox';
import DataType from '../../dataLayer/enums/DataType';
import useDataItemStore from '../../dataLayer/stores/hooks/UseDataItemStore';
import useFormOrWorkingPackageFeature from '../../../../plugins/authorization/hooks/UseFormOrWorkingPackageFeature';

const comparatorOptions: IDropdownOption[] = [
  {
    label: 'Ist gleich',
    value: 'equals'
  },
  {
    label: 'Ist nicht gleich',
    value: 'notEquals'
  },
  {
    label: 'Beinhaltet',
    value: 'containsIgnoreCase'
  },
  {
    label: 'Beginnt mit',
    value: 'startsWithIgnoreCase'
  },
];

const DataItemTableFilter: React.FC<IDataItemTableFilterProps> = ({
  form, queryParameterKey, onQueryParametersChanged, onClosed
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [showArtFilters, setShowArtFilters] = useState(false);
  const [showAttachmentProcessingFilters, setShowAttachmentProcessingFilters] = useState(false);
  const [newFilterPropertyOption, setNewFilterPropertyOption] = useState<IDropdownOption | undefined>(undefined);
  const dataItemStore = useDataItemStore();
  const queryParameters = dataItemStore?.getStoredQuery(queryParameterKey);
  const { hasOnActiveFormOrWorkingPackagePermission } = useFormOrWorkingPackageFeature();

  if (!dataItemStore || !queryParameters || !hasOnActiveFormOrWorkingPackagePermission('CanQuery')) {
    console.log('DataItemTableFilter hidden', dataItemStore, queryParameters, hasOnActiveFormOrWorkingPackagePermission('CanQuery'))
    return null;
  }

  const propertyOptions = form.allControlsDestinationNamesUnique.map(x => ({
    label: x,
    value: `data.${x}`
  }));

  const getDataTypeOfFilterProperty = (filterProperty: string): DataType => {
    let dataType: DataType;
    const isDataProperty = filterProperty.startsWith('data.');

    if (isDataProperty) {
      const destinationName = filterProperty.split('data.').pop();
      const control = form.allControls.find(x => x.destinationName === destinationName);

      dataType = control?.dataType || DataType.String;
    } else {
      switch (filterProperty) {
        case 'commentCount':
        case 'attachmentCount':
          dataType = DataType.Int;
          break;
        default:
          dataType = DataType.Int;
      }
    }

    return dataType;
  };


  const commentCountFilter = queryParameters.filters.find(x => x.property === 'commentCount');
  const onlyUnresolvedCommentsFilter = queryParameters.filters.find(x => x.property === 'hasUnresolvedComments');
  const onlyResolvedCommentsFilter = queryParameters.filters.find(x => x.property === 'hasResolvedComments');
  const attachmentCountFilter = queryParameters.filters.find(x => x.property === 'attachmentCount');
  const workingPackageFilter = queryParameters.filters.find(x => x.property === 'workingPackageId');
  const hasWorkingPackageFilter = workingPackageFilter !== undefined;
  const workingPackageFilterIsOnlyWithWorkingPackage = workingPackageFilter !== undefined && !(workingPackageFilter.comparator === resolveComparator('equals') && workingPackageFilter.valueDeserialized === null);
  const dataIndexedFilter = queryParameters.filters.find(x => x.property === 'status.indexed');
  const dataCollectedFilter = queryParameters.filters.find(x => x.property === 'status.dataCollected');
  const dataQualityAssuredFilter = queryParameters.filters.find(x => x.property === 'status.dataQualityAssured');
  const dataCollectionVerifiedFilter = queryParameters.filters.find(x => x.property === 'status.dataCollectionVerified');
  const hasDifferenceBetweenDataCollectionAndQsFilter = queryParameters.filters.find(x => x.property === 'hasDifferenceBetweenDataCollectionAndQs');

  // #region ART

  const artIsCapturedFilter = queryParameters.filters.find(x => x.property === 'artHistory.isCaptured');
  const artIsPackedFilter = queryParameters.filters.find(x => x.property === 'artHistory.isPacked');
  const artIsPickedUpFilter = queryParameters.filters.find(x => x.property === 'artHistory.isPickedUp');
  const artIsDigitizedFilter = queryParameters.filters.find(x => x.property === 'artHistory.isDigitized');
  const artIsProvidedFilter = queryParameters.filters.find(x => x.property === 'artHistory.isProvided');
  const artIsErrorFilter = queryParameters.filters.find(x => x.property === 'artHistory.isError');

  // #endregion

  // #region Attachment Processing

  const attachmentProcessingHasPendingFilter = queryParameters.filters.find(x => x.property === 'hasPendingAttachments');
  const attachmentProcessingHasProcessingFilter = queryParameters.filters.find(x => x.property === 'hasProcessingAttachments');
  const attachmentProcessingHasSucceededFilter = queryParameters.filters.find(x => x.property === 'hasSucceededAttachments');
  const attachmentProcessingHasFailedFilter = queryParameters.filters.find(x => x.property === 'hasFailedAttachments');

  // #endregion

  const workingPackagesOptions = form.workingPackages.map(x => ({
    label: x.name,
    value: x.id
  } as IDropdownOption));

  return (
    <StackLayout className="dataItemTableFilter">
      <StackLayout
        horizontal
        vCenter
        borderRadius={0.25}
        border={0.125}
        borderColor="green800"
        padding
        spaceBetween
        onPress={(): void => {
          setShowFilter(!showFilter);
        }}
      >
        <Text textIcon stretch>{`${queryParameters.filters.length} Filter aktiv`}</Text>
        <TooltipHelp text="Filter verwalten">
          <Icon filterList xl />
        </TooltipHelp>
      </StackLayout>
      {showFilter ? (
        <StackLayout height={{custom: '50vh'}} overflow="scroll" positionAbsolute top="100%" backgroundColor="white" borderColor="grey900" border={0.25} right="-100%" zIndex={2} left={0} padding>
          <StackLayout marginBottom={2} horizontal vCenter spaceBetween>
            <Text title>Filter bearbeiten</Text>
            <Button onPress={(): void => {
              queryParameters.clearFilters();
              onQueryParametersChanged();
            }}
            >
              Alle entfernen

            </Button>
          </StackLayout>

          <StackLayout marginBottom={2}>
            <StackLayout horizontal>
              <StackLayout marginRight={2} stretch>
                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={commentCountFilter?.comparator === resolveComparator('equals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('commentCount', 0, 'equals');
                      } else if (commentCountFilter) {
                        queryParameters.removeFilter(commentCountFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur ohne Kommentare</Text>
                </StackLayout>
                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={onlyUnresolvedCommentsFilter?.comparator === resolveComparator('equals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('hasUnresolvedComments', true, 'equals');
                      } else if (onlyUnresolvedCommentsFilter) {
                        queryParameters.removeFilter(onlyUnresolvedCommentsFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur mit ungelösten Kommentaren</Text>
                </StackLayout>
                <StackLayout marginBottom horizontal vCenter>
                  <Checkbox
                    checked={attachmentCountFilter?.comparator === resolveComparator('equals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('attachmentCount', 0, 'equals');
                      } else if (attachmentCountFilter) {
                        queryParameters.removeFilter(attachmentCountFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur ohne Dateien</Text>
                </StackLayout>
                <StackLayout marginBottom horizontal vCenter>
                  <Checkbox
                    checked={dataIndexedFilter?.hasComparator('isEmpty')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('status.indexed', null, 'isEmpty');
                      } else if (dataIndexedFilter) {
                        queryParameters.removeFilter(dataIndexedFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur nicht indexiert</Text>
                </StackLayout>
                <StackLayout marginBottom horizontal vCenter>
                  <Checkbox
                    checked={hasWorkingPackageFilter && !workingPackageFilterIsOnlyWithWorkingPackage}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('workingPackageId', null, 'equals');
                      } else if (workingPackageFilter) {
                        queryParameters.removeFilter(workingPackageFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur ohne Arbeitspaket</Text>
                </StackLayout>
                <StackLayout marginBottom horizontal vCenter>
                  <Checkbox
                    checked={dataCollectedFilter?.hasComparator('isEmpty')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('status.dataCollected', null, 'isEmpty');
                      } else if (dataCollectedFilter) {
                        queryParameters.removeFilter(dataCollectedFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur nicht erfasst</Text>
                </StackLayout>
                <StackLayout marginBottom horizontal vCenter>
                  <Checkbox
                    checked={dataQualityAssuredFilter?.hasComparator('isEmpty')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('status.dataQualityAssured', null, 'isEmpty');
                      } else if (dataQualityAssuredFilter) {
                        queryParameters.removeFilter(dataQualityAssuredFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur nicht qualitätsgesichert</Text>
                </StackLayout>
                <StackLayout marginBottom horizontal vCenter>
                  <Checkbox
                    checked={hasDifferenceBetweenDataCollectionAndQsFilter?.hasComparator('notEquals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('hasDifferenceBetweenDataCollectionAndQs', true, 'notEquals');
                      } else if (hasDifferenceBetweenDataCollectionAndQsFilter) {
                        queryParameters.removeFilter(hasDifferenceBetweenDataCollectionAndQsFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur ohne Differenz zwischen Erfassung und QS</Text>
                </StackLayout>
                <StackLayout marginBottom horizontal vCenter>
                  <Checkbox
                    checked={dataCollectionVerifiedFilter?.hasComparator('isEmpty')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('status.dataCollectionVerified', null, 'isEmpty');
                      } else if (dataCollectionVerifiedFilter) {
                        queryParameters.removeFilter(dataCollectionVerifiedFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur ohne Stichprobe</Text>
                </StackLayout>
              </StackLayout>
              <StackLayout stretch>
                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={commentCountFilter?.comparator === resolveComparator('notEquals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('commentCount', 0, 'notEquals');
                      } else if (commentCountFilter) {
                        queryParameters.removeFilter(commentCountFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur mit Kommentaren</Text>
                </StackLayout>
                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={onlyResolvedCommentsFilter?.comparator === resolveComparator('equals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('hasResolvedComments', true, 'equals');
                      } else if (onlyResolvedCommentsFilter) {
                        queryParameters.removeFilter(onlyResolvedCommentsFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur mit gelösten Kommentaren</Text>
                </StackLayout>
                <StackLayout marginBottom horizontal vCenter>
                  <Checkbox
                    checked={attachmentCountFilter?.comparator === resolveComparator('notEquals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('attachmentCount', 0, 'notEquals');
                      } else if (attachmentCountFilter) {
                        queryParameters.removeFilter(attachmentCountFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur mit Dateien</Text>
                </StackLayout>
                <StackLayout marginBottom horizontal vCenter>
                  <Checkbox
                    checked={dataIndexedFilter?.hasComparator('isNotEmpty')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('status.indexed', null, 'isNotEmpty');
                      } else if (dataIndexedFilter) {
                        queryParameters.removeFilter(dataIndexedFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur indexiert</Text>
                </StackLayout>
                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={hasWorkingPackageFilter && workingPackageFilterIsOnlyWithWorkingPackage}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('workingPackageId', null, 'notEquals');
                      } else if (workingPackageFilter) {
                        queryParameters.removeFilter(workingPackageFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur mit Arbeitspaket</Text>
                </StackLayout>
                <StackLayout marginBottom horizontal vCenter>
                  <Checkbox
                    checked={dataCollectedFilter?.hasComparator('isNotEmpty')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('status.dataCollected', null, 'isNotEmpty');
                      } else if (dataCollectedFilter) {
                        queryParameters.removeFilter(dataCollectedFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur erfasst</Text>
                </StackLayout>
                <StackLayout marginBottom horizontal vCenter>
                  <Checkbox
                    checked={dataQualityAssuredFilter?.hasComparator('isNotEmpty')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('status.dataQualityAssured', null, 'isNotEmpty');
                      } else if (dataQualityAssuredFilter) {
                        queryParameters.removeFilter(dataQualityAssuredFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur qualitätsgesichert</Text>
                </StackLayout>
                <StackLayout marginBottom horizontal vCenter>
                  <Checkbox
                    checked={hasDifferenceBetweenDataCollectionAndQsFilter?.hasComparator('equals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('hasDifferenceBetweenDataCollectionAndQs', true, 'equals');
                      } else if (hasDifferenceBetweenDataCollectionAndQsFilter) {
                        queryParameters.removeFilter(hasDifferenceBetweenDataCollectionAndQsFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur mit Differenz zwischen Erfassung und QS</Text>
                </StackLayout>
                <StackLayout marginBottom horizontal vCenter>
                  <Checkbox
                    checked={dataCollectionVerifiedFilter?.hasComparator('isNotEmpty')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('status.dataCollectionVerified', null, 'isNotEmpty');
                      } else if (dataCollectionVerifiedFilter) {
                        queryParameters.removeFilter(dataCollectionVerifiedFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur, wenn Teil einer Stichprobe</Text>
                </StackLayout>
              </StackLayout>
            </StackLayout>
            <StackLayout
              backgroundColor="green100"
              padding
              horizontal
              vCenter
              onPress={(): void => {
              setShowArtFilters(!showArtFilters)
            }}
            >
              <Icon chevronRight={!showArtFilters} expandMore={showArtFilters} xl />
              <Text>ART Filter</Text>
            </StackLayout>
            {showArtFilters && (
            <StackLayout marginTop horizontal>
              <StackLayout marginRight={2} stretch>
                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={artIsCapturedFilter?.comparator === resolveComparator('equals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('artHistory.isCaptured', true, 'equals');
                      } else if (artIsCapturedFilter) {
                        queryParameters.removeFilter(artIsCapturedFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur erfasst</Text>
                </StackLayout>

                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={artIsPackedFilter?.comparator === resolveComparator('equals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('artHistory.isPacked', true, 'equals');
                      } else if (artIsPackedFilter) {
                        queryParameters.removeFilter(artIsPackedFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur verpackt</Text>
                </StackLayout>

                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={artIsPickedUpFilter?.comparator === resolveComparator('equals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('artHistory.isPickedUp', true, 'equals');
                      } else if (artIsPickedUpFilter) {
                        queryParameters.removeFilter(artIsPickedUpFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur abgeholt</Text>
                </StackLayout>

                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={artIsDigitizedFilter?.comparator === resolveComparator('equals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('artHistory.isDigitized', true, 'equals');
                      } else if (artIsDigitizedFilter) {
                        queryParameters.removeFilter(artIsDigitizedFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur digitalisiert</Text>
                </StackLayout>

                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={artIsProvidedFilter?.comparator === resolveComparator('equals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('artHistory.isProvided', true, 'equals');
                      } else if (artIsProvidedFilter) {
                        queryParameters.removeFilter(artIsProvidedFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur bereitgestellt</Text>
                </StackLayout>

                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={artIsErrorFilter?.comparator === resolveComparator('equals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('artHistory.isError', true, 'equals');
                      } else if (artIsErrorFilter) {
                        queryParameters.removeFilter(artIsErrorFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur Fehler</Text>
                </StackLayout>

              </StackLayout>
              <StackLayout stretch>
                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={artIsCapturedFilter?.comparator === resolveComparator('notEquals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('artHistory.isCaptured', true, 'notEquals');
                      } else if (artIsCapturedFilter) {
                        queryParameters.removeFilter(artIsCapturedFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur nicht erfasst</Text>
                </StackLayout>

                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={artIsPackedFilter?.comparator === resolveComparator('notEquals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('artHistory.isPacked', true, 'notEquals');
                      } else if (artIsPackedFilter) {
                        queryParameters.removeFilter(artIsPackedFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur nicht verpackt</Text>
                </StackLayout>

                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={artIsPickedUpFilter?.comparator === resolveComparator('notEquals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('artHistory.isPickedUp', true, 'notEquals');
                      } else if (artIsPickedUpFilter) {
                        queryParameters.removeFilter(artIsPickedUpFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur nicht abgeholt</Text>
                </StackLayout>

                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={artIsDigitizedFilter?.comparator === resolveComparator('notEquals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('artHistory.isDigitized', true, 'notEquals');
                      } else if (artIsDigitizedFilter) {
                        queryParameters.removeFilter(artIsDigitizedFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur nicht digitalisiert</Text>
                </StackLayout>

                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={artIsProvidedFilter?.comparator === resolveComparator('notEquals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('artHistory.isProvided', true, 'notEquals');
                      } else if (artIsProvidedFilter) {
                        queryParameters.removeFilter(artIsProvidedFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur nicht bereitgestellt</Text>
                </StackLayout>

                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={artIsErrorFilter?.comparator === resolveComparator('notEquals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('artHistory.isError', true, 'notEquals');
                      } else if (artIsErrorFilter) {
                        queryParameters.removeFilter(artIsErrorFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur ohne Fehler</Text>
                </StackLayout>
              </StackLayout>
            </StackLayout>
)}
            <StackLayout
              marginTop
              backgroundColor="green100"
              padding
              horizontal
              vCenter
              onPress={(): void => {
              setShowAttachmentProcessingFilters(!showAttachmentProcessingFilters)
            }}
            >
              <Icon chevronRight={!showAttachmentProcessingFilters} expandMore={showAttachmentProcessingFilters} xl />
              <Text>PDF-Processing Filter</Text>
            </StackLayout>
            {showAttachmentProcessingFilters && (
            <StackLayout marginTop horizontal>
              <StackLayout marginRight={2} stretch>
                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={attachmentProcessingHasPendingFilter?.comparator === resolveComparator('equals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('hasPendingAttachments', true, 'equals');
                      } else if (attachmentProcessingHasPendingFilter) {
                        queryParameters.removeFilter(attachmentProcessingHasPendingFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur ausstehende</Text>
                </StackLayout>

                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={attachmentProcessingHasProcessingFilter?.comparator === resolveComparator('equals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('hasProcessingAttachments', true, 'equals');
                      } else if (attachmentProcessingHasProcessingFilter) {
                        queryParameters.removeFilter(attachmentProcessingHasProcessingFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur in der Warteschlange</Text>
                </StackLayout>

                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={attachmentProcessingHasSucceededFilter?.comparator === resolveComparator('equals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('hasSucceededAttachments', true, 'equals');
                      } else if (attachmentProcessingHasSucceededFilter) {
                        queryParameters.removeFilter(attachmentProcessingHasSucceededFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur erfolgreiche</Text>
                </StackLayout>

                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={attachmentProcessingHasFailedFilter?.comparator === resolveComparator('equals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('hasFailedAttachments', true, 'equals');
                      } else if (attachmentProcessingHasFailedFilter) {
                        queryParameters.removeFilter(attachmentProcessingHasFailedFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur fehlgeschlagene</Text>
                </StackLayout>
              </StackLayout>
              <StackLayout stretch>
                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={attachmentProcessingHasPendingFilter?.comparator === resolveComparator('notEquals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('hasPendingAttachments', true, 'notEquals');
                      } else if (attachmentProcessingHasPendingFilter) {
                        queryParameters.removeFilter(attachmentProcessingHasPendingFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur nicht ausstehende</Text>
                </StackLayout>

                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={attachmentProcessingHasProcessingFilter?.comparator === resolveComparator('notEquals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('hasProcessingAttachments', true, 'notEquals');
                      } else if (attachmentProcessingHasProcessingFilter) {
                        queryParameters.removeFilter(attachmentProcessingHasProcessingFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur nicht aktive</Text>
                </StackLayout>

                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={attachmentProcessingHasSucceededFilter?.comparator === resolveComparator('notEquals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('hasSucceededAttachments', true, 'notEquals');
                      } else if (attachmentProcessingHasSucceededFilter) {
                        queryParameters.removeFilter(attachmentProcessingHasSucceededFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur nicht erfolgreiche</Text>
                </StackLayout>

                <StackLayout horizontal marginBottom vCenter>
                  <Checkbox
                    checked={attachmentProcessingHasFailedFilter?.comparator === resolveComparator('notEquals')}
                    onChange={(val): void => {
                      if (val) {
                        queryParameters.putFilter('hasFailedAttachments', true, 'notEquals');
                      } else if (attachmentProcessingHasFailedFilter) {
                        queryParameters.removeFilter(attachmentProcessingHasFailedFilter);
                      }
                      onQueryParametersChanged();
                    }}
                  />
                  <Text marginLeft>Nur nicht fehlgeschlagene</Text>
                </StackLayout>
              </StackLayout>
            </StackLayout>
)}
          </StackLayout>

          {
            queryParameters.filters.length
              ? queryParameters.filters.map((filter): JSX.Element | null => {
                const dataType = getDataTypeOfFilterProperty(filter.property);
                const isNumeric = dataType === DataType.Int || dataType === DataType.Double;
                const filterIsOnlyWithWorkingPackage = filter.property === 'workingPackageId' && !(filter.comparator === resolveComparator('equals') && filter.valueDeserialized === null);

                if (filter.property.startsWith('artHistory') || filter.property.startsWith('status') || filter.property === 'hasDifferenceBetweenDataCollectionAndQs') {
                  return null;
                }

                if (filter.property === 'workingPackageId' && !filterIsOnlyWithWorkingPackage) {
                  return null;
                }
                if (filterIsOnlyWithWorkingPackage) {
                  return (
                    <StackLayout key={filter.property} horizontal vCenter marginBottom>
                      <Text marginRight stretch>Arbeitspaket</Text>
                      <Dropdown
                        light
                        stretch
                        width={20}
                        options={workingPackagesOptions}
                        selectedOption={workingPackagesOptions.find(x => x.value === JSON.parse(filter.value))}
                        onOptionSelected={(selectedWorkingPackage): void => {
                          filter.setComparator('equals');
                          filter.setValue(selectedWorkingPackage.value);
                        }}
                      />
                      <Icon
                        marginLeft
                        xl
                        deleteForever
                        onPress={(): void => {
                          queryParameters.removeFilter(filter);
                          onQueryParametersChanged();
                        }}
                      />
                    </StackLayout>
                  );
                }

                return (
                  <StackLayout key={filter.property} horizontal vCenter marginBottom>
                    <Text marginRight stretch>{filter.property.split('data.').pop()}</Text>
                    <Dropdown
                      light
                      stretch
                      width={20}
                      options={comparatorOptions}
                      selectedOption={comparatorOptions.find(x => resolveComparator(x.value as any) === filter.comparator)}
                      onOptionSelected={(selectedComparator): void => {
                        filter.setComparator(selectedComparator.value as any);
                      }}
                    />
                    <StackLayout width />
                    <StackLayout width={{custom: '33%'}}>
                      {
                        dataType === DataType.Boolean ? (
                          <Checkbox checked={JSON.parse(filter.value)} onChange={filter.setValue} />
                        ) : dataType === DataType.Date || dataType === DataType.DateTime ? null
                          : (
                            <TextInput
                              value={JSON.parse(filter.value)}
                              numeric={isNumeric}
                              onChange={(newValue): void => {
                                switch (dataType) {
                                  case DataType.Int:
                                    filter.setValue(parseInt(newValue));
                                    break;
                                  case DataType.Double:
                                    filter.setValue(parseFloat(newValue));
                                    break;
                                  default:
                                    filter.setValue(newValue);
                                    break;
                                }
                              }}
                              placeholder="Suche ..."
                            />
                          )
                      }
                    </StackLayout>
                    <Icon
                      marginLeft
                      xl
                      deleteForever
                      onPress={(): void => {
                        queryParameters.removeFilter(filter);
                        onQueryParametersChanged();
                      }}
                    />
                  </StackLayout>
                );
              }) : (
                <StackLayout hCenter>
                  <Text>Es wurden noch keine Filter hinzugefügt</Text>
                </StackLayout>
              )
          }


          <StackLayout horizontal vCenter marginTop={2}>
            <Dropdown
              light
              stretch
              selectedOption={newFilterPropertyOption}
              options={propertyOptions.filter(propertyOption => !queryParameters.hasFilter(propertyOption.value))}
              placeholder="Attribut"
              onOptionSelected={setNewFilterPropertyOption}
            />
            <StackLayout width />
            <Button onPress={(): void => {
              if (!newFilterPropertyOption) {
                NotificationManager.error('Bitte wählen Sie zunächst das Attribut aus');
                return;
              }
              let defaultValue: any;

              const dataType = getDataTypeOfFilterProperty(newFilterPropertyOption.value);

              switch (dataType) {
                case DataType.Boolean:
                  defaultValue = false;
                  break;
                case DataType.Int:
                case DataType.Double:
                  defaultValue = 0;
                  break;
                case DataType.DateTime:
                  defaultValue = new Date();
                  break;
                default:
                  defaultValue = '';
                  break;
              }

              queryParameters.putFilter(newFilterPropertyOption.value, defaultValue, 'equals');
              setNewFilterPropertyOption(undefined);
              onQueryParametersChanged();
            }}
            >
              Filter hinzufügen

            </Button>
            <StackLayout width />
            <Button onPress={(): void => {
              setShowFilter(!showFilter);
              invokeEventHandler(onClosed, true);
            }}
            >
              Filter anwenden & alle laden

            </Button>
            <StackLayout width />
            <Button onPress={(): void => {
              setShowFilter(!showFilter);
              invokeEventHandler(onClosed);
            }}
            >
              Filter anwenden

            </Button>
          </StackLayout>
        </StackLayout>
      ) : null}
    </StackLayout>
  );
};

export default observer(DataItemTableFilter);
