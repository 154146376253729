import {
  RestService, ApiWrapper, ApiResult
} from '@wemogy/reactbase';
import {IComment} from '../../models/Comment';
import { MainApiUri } from '../../../../../../base/Constants';
import { clearDataItemId } from './DataItemService';
import CommentVisibility from '../../enums/CommentVisibility';

export default class CommentService extends RestService<IComment>{
  public constructor(apiWrapper: ApiWrapper){
    super(apiWrapper, 'comment');
  }


  public post(formId: string, dataItemId: string, comment: IComment): Promise<ApiResult<void>>{
    dataItemId = clearDataItemId(dataItemId);
    return this.apiWrapper.post(`comment/${formId}/${dataItemId}`, comment);
  }

  public updateIsResolved(formId: string, dataItemId: string, commentId: string, isResolved: boolean): Promise<ApiResult<void>>{
    dataItemId = clearDataItemId(dataItemId);
    return this.apiWrapper.post(`comment/${formId}/${dataItemId}/${commentId}/${isResolved}`, {});
  }

  public updateVisibility(formId: string, dataItemId: string, commentId: string, visibility: CommentVisibility): Promise<ApiResult<void>>{
    dataItemId = clearDataItemId(dataItemId);
    return this.apiWrapper.post(`comment/visibility/${formId}/${dataItemId}/${commentId}/${visibility}`, {});
  }

  public getCsvExport(formId: string, onlyUnresolved: boolean, onlyFromTheseWorkingPackages: string[] | undefined): Promise<Response>{
    // ToDo: use API wrapper with authentication
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return fetch(`${MainApiUri}comment/export`, {
      method: 'POST', headers, body: JSON.stringify({
        formId,
        onlyUnresolved,
        onlyFromTheseWorkingPackages
      })
    });
  }
}
