import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, Text, Modal, useModalStore
} from '@wemogy/reactbase';
import IManageDatabaseTemplatesModalProps from './IManageDatabaseTemplatesModalProps';
import useDataAccessStore from '../../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import DatabaseTemplateList from '../../../containers/databaseTemplateList/DatabaseTemplateList';

const ManageDatabaseTemplatesModal: React.FC<IManageDatabaseTemplatesModalProps> = () => {
  const {databaseTemplateStore} = useDataAccessStore();
  const modalStore = useModalStore();

  return (
    <Modal modalKey="manageDatabaseTemplates" icon="extension" title="Vorlagen verwalten" large>
      {
        databaseTemplateStore.items.length ? (
          <StackLayout>
            <DatabaseTemplateList
              databaseTemplates={databaseTemplateStore.items}
              onDatabaseTemplateRemove={(databaseTemplate): void => {
                databaseTemplateStore.delete(databaseTemplate.id);
              }}
            />
          </StackLayout>
        ) : (
          <StackLayout hCenter>
            <Text title marginTopBottom={2}>Es sind keine Vorlagen vorhanden</Text>
            <Button onPress={(): void =>{
              modalStore.openModal('addDatabaseTemplate');
            }}
            >
              Jetzt eine Vorlage anlegen

            </Button>
          </StackLayout>
        )
      }

    </Modal>
  );
};

export default observer(ManageDatabaseTemplatesModal);
