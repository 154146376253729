import React from 'react';
import { observer } from 'mobx-react';
import { Button } from '@wemogy/reactbase';
import IDeleteMemberProps from './IDeleteMemberProps';

const DeleteMember: React.FC<IDeleteMemberProps> = ({
  resourceId, subjectId, useMembersDeletedHandlerHook
}) => {
  const membersDeletedHandler = useMembersDeletedHandlerHook();



  return (
    <Button
      marginLeft
      onPress={(): void => {
      membersDeletedHandler(resourceId, [subjectId])
    }}
    >
      Entfernen

    </Button>
  )
}

export default observer(DeleteMember);
