import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {StackLayout} from '@wemogy/reactbase';
import ISegmentedViewProps from './ISegmentedViewProps';
import SegmentedControl from '../segmentedControl/SegmentedControl';

const SegmentedView: React.FC<ISegmentedViewProps> = ({elements}) => {
  const [activeElement, setActiveElement] = useState(elements[0].key);


  return (
    <StackLayout stretch>
      <SegmentedControl options={elements.map(x => x.key)} defaultOption={elements[0].key} onOptionSelected={setActiveElement} />
      <StackLayout marginTop={2}>
        {
                elements.find(x => x.key === activeElement)?.view || null
        }
      </StackLayout>
    </StackLayout>
  );
};

export default observer(SegmentedView);
