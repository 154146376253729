import React from 'react';
import { observer } from 'mobx-react';
import {
StackLayout, Modal, useModalStore, TextInput
} from '@wemogy/reactbase';
import IEditControlModalProps from './IEditControlModalProps';
import Checkbox from '../../../../components/checkbox/Checkbox';
import AnnotatedFormView from '../../containers/formElements/annotatedFormView/AnnotatedFormView';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import { IControl } from '../../dataLayer/models/Control';
import AnnotatedTextInput from '../../containers/formElements/annotatedTextInput/AnnotatedTextInput';
import Divider from '../../../../components/divider/Divider';
import DefaultValueSetterRenderer from './renderers/defaultValueSetterRenderer/DefaultValueSetterRenderer';
import ControlTypeSpecificEditingRenderer from './renderers/controlTypeSpecificEditingRenderer/ControlTypeSpecificEditingRenderer';
import AutocompleteInput from '../../../autocompleteInput/AutocompleteInput';
import { IDataStructureTemplateData } from '../../dataLayer/models/DataStructureTemplateData';
import NotificationManager from '../../../inAppFeedback/NotificationManager';
import ControlType from '../../dataLayer/enums/ControlType';
import RadioTypeConfig from '../../dataLayer/models/controlTypeConfigs/RadioTypeConfig';
import CheckGroupTypeConfig from '../../dataLayer/models/controlTypeConfigs/CheckGroupTypeConfig';
import DropdownTypeConfig from '../../dataLayer/models/controlTypeConfigs/DropdownTypeConfig';


const EditControlModal: React.FC<IEditControlModalProps> = () => {
  const {
    formStore, dataStructureTemplateStore
  } = useDataAccessStore();
  const modalStore = useModalStore();

  const form = formStore.activeItem;

  const control = modalStore.getActiveParameters<IControl>('editControl');


  if(!form || !control){
    return null;
  }


  const handleSuggestionSelected = (suggestion: IDataStructureTemplateData): void => {
    const {dataSourceName} = suggestion;
    if (dataSourceName) {
      const dataSource = form.dataSources.find(x => x.name.toLowerCase() === dataSourceName.toLowerCase());
      if (dataSource) {
        if (!control.hasDataSource(dataSource.id)) {
          control.addDataSource(dataSource);
        }
        control.getControlDataSource(dataSource)?.setSourceName(suggestion.sourceName);
      } else {
        NotificationManager.error(`Die Datenquelle ${dataSourceName} existiert nicht. Bitte legen Sie diese an und wählen Sie die Vorlage erneut aus.`);
      }
    }
    control.setDestinationName(suggestion.destinationName);
    control.setIsRequired(suggestion.isRequired);
    control.setReadOnly(suggestion.readOnly);
    control.setTooltip(suggestion.tooltip);
    control.setNotes(suggestion.notes);

    if (suggestion.defaultValue.length) {
      if (suggestion.hasMultiselectData) {
        control.setDefaultValue(suggestion.defaultValue.map(x => JSON.parse(x.value)));
      } else {
        control.setDefaultValueRaw(suggestion.defaultValue[0].value);
      }
    }

    if (suggestion.hasMultiselectData) {
      switch (control.type) {
        case ControlType.Radio:
          const radioConfig = new RadioTypeConfig();
          radioConfig.options = suggestion.multiselectDataAsKeyValuePairs;
          control.setConfig(radioConfig);
          break;
        case ControlType.CheckGroup:
          const checkGroupConfig = new CheckGroupTypeConfig();
          checkGroupConfig.options = suggestion.multiselectDataAsKeyValuePairs;
          control.setConfig(checkGroupConfig);
          break;
        case ControlType.Dropdown:
          const dropdownConfig = new DropdownTypeConfig();
          dropdownConfig.options = suggestion.multiselectDataAsKeyValuePairs;
          control.setConfig(dropdownConfig);
          break;
        default:
          break;
      }
    }
  };

  const suggestions = dataStructureTemplateStore.getItem(form.id)?.getForControl(control) || [];
  console.log('suggestions ->', suggestions);
  return (
    <Modal
      onOpened={(): void => {
        dataStructureTemplateStore.get(form.id);
      }}
      modalKey="editControl"
      icon="edit"
      title={`${control.controlName} bearbeiten`}
      large
    >
      <StackLayout minHeight={{custom: '50vh'}}>
        <StackLayout horizontal>
          <StackLayout stretch>
            <AnnotatedFormView description="Datenquellen" marginBottom>
              <StackLayout>
                {form.dataSources.map(dataSource => (
                  <Checkbox
                    key={dataSource.id}
                    marginBottom={0.5}
                    label={dataSource.name}
                    boxStyle
                    checked={control.hasDataSource(dataSource.id)}
                    onChange={(checked): void =>{
                      if(checked){
                        control.addDataSource(dataSource);
                      }
                      else{
                        control.removeDataSource(dataSource);
                      }
                    }}
                  />
                ))}
              </StackLayout>
            </AnnotatedFormView>
          </StackLayout>
          <StackLayout stretch>
            <AnnotatedFormView description="Quellname" marginBottom>
              <StackLayout>
                {form.dataSources.map(dataSource => {
                  const controlDataSource = control.getControlDataSource(dataSource);
                  if (!controlDataSource) {
                    return <StackLayout height={4} />;
                  }

                  return (
                    <AutocompleteInput
                      key={dataSource.id}
                      suggestions={suggestions}
                      getSuggestionValue={(suggestion: IDataStructureTemplateData): string => {
                        return suggestion.sourceName;
                      }}
                      filter={(suggestion: IDataStructureTemplateData, value: string): boolean => {
                        return suggestion.sourceName.toLowerCase().startsWith(value.toLowerCase());
                      }}
                      value={controlDataSource.sourceName}
                      onChange={controlDataSource.setSourceName}
                      onSuggestionSelected={handleSuggestionSelected}
                    />
                  );
                })}
              </StackLayout>
            </AnnotatedFormView>
          </StackLayout>
        </StackLayout>
        <StackLayout horizontal>
          <StackLayout stretch>
            <AnnotatedFormView description="Qr Code Quellen" marginBottom>
              <StackLayout>
                {form.qrCodeSources.map(qrCodeSource => (
                  <Checkbox
                    key={qrCodeSource.id}
                    marginBottom={0.5}
                    label={qrCodeSource.name}
                    boxStyle
                    checked={control.hasQrCodeSource(qrCodeSource.id)}
                    onChange={(checked): void =>{
                      if(checked){
                        control.addQrCodeSource(qrCodeSource);
                      }
                      else{
                        control.removeQrCodeSource(qrCodeSource);
                      }
                    }}
                  />
                ))}
              </StackLayout>
            </AnnotatedFormView>
          </StackLayout>
          <StackLayout stretch>
            <AnnotatedFormView description="Werte Index" marginBottom>
              <StackLayout>
                {form.qrCodeSources.map(qrCodeSource => {
                  const controlQrCodeSource = control.getControlQrCodeSource(qrCodeSource);
                  if (!controlQrCodeSource) {
                    return <StackLayout height={4} />;
                  }

                  return (
                    <TextInput
                      key={qrCodeSource.id}
                      numeric
                      placeholder="Der Index des Wertes (1 für den ersten Wert)"
                      value={controlQrCodeSource.valueIndex.toString()}
                      onChange={(value): void => {
                        controlQrCodeSource.setValueIndex(parseInt(value));
                      }}
                    />
                  );
                })}
              </StackLayout>
            </AnnotatedFormView>
          </StackLayout>
        </StackLayout>
        <AnnotatedFormView description={control.hasAnyDataSource ? 'Ziel-Feldname' : 'Feldname'} marginBottom>
          <AutocompleteInput
            placeholder="Der Feldname in der Datenbank"
            suggestions={suggestions}
            getSuggestionValue={(suggestion: IDataStructureTemplateData): string => {
              return suggestion.destinationName;
            }}
            filter={(suggestion: IDataStructureTemplateData, value: string): boolean => {
              return suggestion.destinationName.toLowerCase().startsWith(value.toLowerCase());
            }}
            value={control.destinationName}
            onChange={control.setDestinationName}
            onSuggestionSelected={handleSuggestionSelected}
          />
        </AnnotatedFormView>
        <AnnotatedFormView description="Nur lesen" marginBottom>
          <Checkbox checked={control.readOnly} onChange={control.setReadOnly} />
        </AnnotatedFormView>
        <AnnotatedFormView description="Pflichtfeld" marginBottom>
          <Checkbox checked={control.isRequired} onChange={control.setIsRequired} />
        </AnnotatedFormView>
        {
          control.isRequired ? (
            <AnnotatedFormView description="Standartwert" marginBottom>
              <DefaultValueSetterRenderer control={control} />
            </AnnotatedFormView>
          ) : null
        }

        <AnnotatedTextInput placeholder="Inhalt des Tooltips" description="Tooltip" onChange={control.setTooltip} value={control.tooltip} marginBottom />
        <AnnotatedTextInput multiline placeholder="Hier können Sie interne Notizen hinterlegen" description="Interne Notizen" onChange={control.setNotes} value={control.notes} marginBottom />
        <Divider marginBottom />
        <ControlTypeSpecificEditingRenderer control={control} />
      </StackLayout>
    </Modal>
  );
};

export default observer(EditControlModal);
