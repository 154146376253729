/* eslint-disable max-classes-per-file */
/* eslint-disable no-restricted-syntax */
import { BaseModel, IBaseModelStoreType } from '@wemogy/reactbase';
import { getSnapshot } from 'mobx-state-tree';
import DataItemStore, { IDataItemStore } from '../dataLayer/stores/entityStores/genericStores/DataItemStore';
import { IForm } from '../dataLayer/models/Form';

class CachedDataItemStore{
  public constructor(

    public store: IBaseModelStoreType<typeof BaseModel, any>,
    public instance: IDataItemStore

  ){

  }
}

class DataItemStoresManager{
  private readonly dataItemStores: Map<string, CachedDataItemStore>;

  private readonly formControlHashes: Map<string, string>;

  public constructor() {
    this.dataItemStores = new Map<string, CachedDataItemStore>();
    this.formControlHashes = new Map<string, string>();
  }

  public getDataItemStore(form: IForm): CachedDataItemStore{
    // ToDo: make this work again
    // check if we have the form already
    if (false && this.dataItemStores.has(form.id)) {
      // check if the dataItemStore is still valid
      if (this.formControlHashes.get(form.id) === form.controlsHash) {
        // still valid, nothing to so
        return this.dataItemStores.get(form.id) as any;
      }
      // otherwise we replace the entry
    }
    let snapshot: any;
    if (this.dataItemStores.has(form.id)) {
      const store = this.dataItemStores.get(form.id);
      if (store) {
        snapshot = getSnapshot(store.instance);
      }

    }
    const store = DataItemStore(form);
    const dataItemStore = store.create(snapshot);
    const cachedDataItemStore = new CachedDataItemStore(store, dataItemStore as any);
    this.dataItemStores.set(form.id, cachedDataItemStore);
    this.formControlHashes.set(form.id, form.controlsHash);
    return cachedDataItemStore;
  }

  // ToDo: not used, maybe not needed?
  /*
  public getDataItemStores(forms: IForm[]): any{
    forms.forEach((form): void => {
      // check if we have the form already
      if (this.dataItemStores.has(form.id)) {
        // check if the dataItemStore is still valid
        if (this.formControlHashes.get(form.id) === form.controlsHash) {
          // still valid, nothing to so
          return;
        }
        // otherwise we replace the entry
      }
      this.dataItemStores.set(form.id, DataItemStore(form).create());
      this.formControlHashes.set(form.id, form.controlsHash);
      console.log('dataItemStore generated', this.dataItemStores);
    });

    // ToDo: remove deprecated caches

    const result = {};

    for (const [key, value] of Array.from(this.dataItemStores.entries())) {
      result[dataItemStoreKey(key)] = value;
    }

    return result;
  }
   */
}

export default new DataItemStoresManager();
