import React from 'react';
import { observer } from 'mobx-react';
import {Text} from '@wemogy/reactbase';
import IGreetingProps from './IGreetingProps';
import useAppStore from '../../../dataLayer/stores/hooks/UseAppStore';
import useDataAccessStore from '../../../dataLayer/stores/hooks/UseDataAccessStore';

const Greeting: React.FC<IGreetingProps> = () => {

  const { activeUserStore } = useAppStore();
  const { userStore } = useDataAccessStore();

  if (!activeUserStore.userId) {
    return <Text>Unknown User</Text>;
  }

  const user = userStore.getItem(activeUserStore.userId);

  if (!user) {
    return <Text>Unknown User</Text>;
  }

  return (
    <Text>{`Guten Tag, ${user.fullname}!`}</Text>
  );
};

export default observer(Greeting);
