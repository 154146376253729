import { IAuthorizationConfig, ISubject } from '@wemogy/reactbase-authorization';
import { useMemo } from 'react';
import CustomerRole from '../../../dataLayer/enums/CustomerRole';
import GlobalRole from '../../../dataLayer/enums/GlobalRole';
import useDataAccessStore from '../../../dataLayer/stores/hooks/UseDataAccessStore';
import FormRole from '../../wrappers/formBuilder/dataLayer/enums/roles/FormRole';
import WorkingPackageRole from '../../wrappers/formBuilder/dataLayer/enums/roles/WorkingPackageRole';

const AuthorizationConfig: IAuthorizationConfig = {
  endpoint: 'https://digittoolgatewaytmp.azurewebsites.net/authorization',
  constants: {administratorRoleId: '22222222-2222-2222-2222-222222222222'},
  permissionLevels: {
    global: {
      pathIndex: 0,
      levelIndex: 0,
      translationPrefix: 'enum.globalRole.en_',
      permissionEnum: GlobalRole,
      permissionDependencies: {  }
    },
    customer: {
      pathIndex: 0,
      levelIndex: 1,
      translationPrefix: 'enum.customerRole.en_',
      permissionEnum: CustomerRole,
      permissionDependencies: {
        [CustomerRole.CanManagePermissions]: [CustomerRole.CanList],
        [CustomerRole.CanRename]: [CustomerRole.CanList],
        [CustomerRole.CanDelete]: [CustomerRole.CanList]
      }
    },
    form: {
      pathIndex: 0,
      levelIndex: 2,
      translationPrefix: 'enum.formRole.en_',
      permissionEnum: FormRole,
      corePermissionDependencies: [FormRole.CanList],
      permissionDependencies: {
        [FormRole.CanImportData]: [FormRole.CanAccessFormManager],
        [FormRole.CanImportFormTemplates]: [FormRole.CanAccessFormManager],
        [FormRole.CanAccessFormManager]: [FormRole.CanRead],
        [FormRole.CanExportIndex]: [FormRole.CanAccessFormManager],
        [FormRole.CanExportComments]: [FormRole.CanAccessFormManager],
        [FormRole.CanExportDataItems]: [FormRole.CanAccessFormManager],
        [FormRole.CanExportDataItemsWithFlags]: [FormRole.CanAccessFormManager],
        [FormRole.CanDeleteForm]: [FormRole.CanAccessFormManager],
        [FormRole.CanManageWorkingPackages]: [FormRole.CanAccessFormManager],
        [FormRole.CanManagePermissions]: [FormRole.CanAccessFormManager],
      }
    },
    workingPackage: {
      pathIndex: 0,
      levelIndex: 3,
      translationPrefix: 'enum.workingPackageRole.en_',
      permissionEnum: WorkingPackageRole,
      permissionDependencies: {}
    }
  },
  ui: {
    manageRoleModal: {
      modalVariation: 'big' as any,
      titles: {
        create: 'Neue Rolle erstellen',
        edit: 'Rolle bearbeiten',
      },
      roleNamePlaceholder: 'Rollenname',
      roleNameTextInputVariation: 'base'
    },
    memberAvatar: {
      resolverHooks: {
        useUsernameResolver: (subjectId: string) => {
          const { userStore } = useDataAccessStore();
          const username = useMemo(() => {
            return userStore.getItem(subjectId)?.fullname || 'Unbekannter User';
          }, [subjectId]);

          return username;
        },
        useEmailResolver: (subjectId: string) => {
          const { userStore } = useDataAccessStore();
          const email = useMemo(() => {
            return userStore.getItem(subjectId)?.email || 'Keine Email';
          }, [subjectId]);

          return email;
        }
      }
    },
    memberList: {},
    memberRoleDropdown: {},
    hooks: {
      useGetSubjectsHook: (): ISubject[] => {
        const { userStore } = useDataAccessStore();

        return userStore.items.map(user => ({
          id: user.id,
          username: user.fullname,
          email: user.email
        }));
      },
    }
  }
}

export default AuthorizationConfig;
