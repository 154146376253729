import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import _ from 'lodash';
import PdfPageMetadata, { IPdfPageMetadata } from './PdfPageMetadata';
import PdfMarkerCodeMetadata from './PdfMarkerCodeMetadata';
import IPdfMetadataStatistics from '../interfaces/IPdfMetadataStatistics';

function getDinFormat(width: number, height: number): string{
  // change width and height portrait format
  if (width < height) {
    const w = width;
    width = height;
    height = w;
  }

  // An DIN A4 page with dimension of 209mm x 296mm is a bit smaller then
  // DIN A4 dimensions of 210mm x 297mm, to support this we add 50mm to width and height before comparison
  const allowedDifference = 50;
  height += allowedDifference;
  width += allowedDifference;

  // map to DIN
  // with help of https://www.din-formate.de/reihe-a-din-groessen-mm-pixel-dpi.html
  // if smaller then A9 => A10
  if (width < 52 && height < 37) {
    return 'DIN A10';
  }

  // if smaller then A8 => A9
  if (width < 74 && height < 52) {
    return 'DIN A9';
  }

  // if smaller then A7 => A8
  if (width < 105 && height < 74) {
    return 'DIN A8';
  }

  // if smaller then A6 => A7
  if (width < 148 && height < 105) {
    return 'DIN A7';
  }

  // if smaller then A5 => A6
  if (width < 210 && height < 148) {
    return 'DIN A6';
  }

  // if smaller then A4 => A5
  if (width < 297 && height < 210) {
    return 'DIN A5';
  }

  // if smaller then A3 => A4
  if (width < 420 && height < 297) {
    return 'DIN A4';
  }

  // if smaller then A2 => A3
  if (width < 594 && height < 420) {
    return 'DIN A3';
  }

  // if smaller then A1 => A2
  if (width < 841 && height < 594) {
    return 'DIN A2';
  }

  // if smaller then A2 => A1
  if (width < 594 && height < 420) {
    return 'DIN A1';
  }

  return 'DIN A0';
}

const PdfMetadata = types.model({
  pages: types.optional(types.array(PdfPageMetadata), []),
  markerCodes: types.optional(types.array(PdfMarkerCodeMetadata), []),
})
  .named('PdfMetadata')
  .views(self => ({
    get totalPageCount(): number{
      return _.sumBy(self.pages, x => x.numberOfPages);
    }
  }))
  .actions(self => ({
    calculateDinPages(): IPdfPageMetadata[]{
      const dinPages: IPdfPageMetadata[] = [];

      self.pages.forEach((page): void => {
        const [width, height] = page.format
          .split('pts')[0]
          .split('x')
          .map(x => parseInt(x))
          .map(x => x * 0.352778); // convert to mm

        const dinFormat = getDinFormat(width, height);
        let dinPage = dinPages.find(x => x.format === dinFormat);

        if (dinPage === undefined) {
          dinPage = PdfPageMetadata.create({format: dinFormat});
          dinPages.push(dinPage);
        }

        dinPage.incrementNumberOfPages(page.numberOfPages);
      });

      return dinPages;
    },
    calculateStatistics(): IPdfMetadataStatistics {
      const dinPages = this.calculateDinPages();

      const smallerThenA3 = _.sumBy(
        dinPages.filter(x => ['A4', 'A5', 'A6', 'A7', 'A8', 'A9', 'A10'].includes(x.format.split('DIN ').pop() || '')),
        x => x.numberOfPages
      );

      const biggerThenA3 = _.sumBy(
        dinPages.filter(x => ['A3', 'A2', 'A1', 'A0'].includes(x.format.split('DIN ').pop() || '')),
        x => x.numberOfPages
      );

      return ({
        dinPages,
        smallerThenA3,
        biggerThenA3
      });
    },
    mergeFromPdfMetadata(pdfMetadata: IPdfMetadata): void{
      pdfMetadata.pages.forEach(page => {
        const existingPage = self.pages.find(x => x.format === page.format);
        if (existingPage) {
          existingPage.incrementNumberOfPages(page.numberOfPages);
        } else {
          self.pages.push(PdfPageMetadata.create({
            format: page.format,
            numberOfPages: page.numberOfPages
          }));
        }
      });
      pdfMetadata.markerCodes.forEach(markerCode => {
        const existingMarkerCode = self.markerCodes.find(x => x.markerCodeId === markerCode.markerCodeId);
        if (existingMarkerCode) {
          existingMarkerCode.incrementAmount(markerCode.amount);
        } else {
          self.markerCodes.push(PdfMarkerCodeMetadata.create({...markerCode}));
        }
      });
    }
  }));
export default PdfMetadata;

export interface IPdfMetadata extends Instance<typeof PdfMetadata>{}
export interface IPdfMetadataSnapshotIn extends SnapshotIn<typeof PdfMetadata>{}
export interface IPdfMetadataSnapshotOut extends SnapshotOut<typeof PdfMetadata>{}
