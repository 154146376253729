import React from 'react';
import { observer } from 'mobx-react';
import { StackLayout, Text } from '@wemogy/reactbase';
import IDataItemDataColumnProps from './IDataItemDataColumnProps';
import TooltipHelp from '../../../../../tooltipHelp/TooltipHelp';
import useDataItemStore from '../../../../dataLayer/stores/hooks/UseDataItemStore';

const DataItemDataColumn: React.FC<IDataItemDataColumnProps> = ({
  dataItem, cellData, onPress, onContextMenu
}) => {
  const dataItemStore = useDataItemStore();

  if (!dataItemStore) {
    return null;
  }

  return (
    <StackLayout
      height100
      width100
      vCenter
      paddingRightLeft={0.25}
      onContextMenu={onContextMenu}
      onPress={onPress}
      className={dataItemStore.isSelected(dataItem) ? 'selectedDataItem' : dataItemStore.isActive(dataItem) ? 'activeDataItem' : ''}
    >
      <TooltipHelp text={cellData}>
        <Text overflowEllipsis stretch>{cellData}</Text>
      </TooltipHelp>
    </StackLayout>
  );
};

export default observer(DataItemDataColumn);
