import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import {BaseModel} from '@wemogy/reactbase';
import PdfSettings from './PdfSettings';

const ProcessingTemplateSubType = types.model({
  name: types.optional(types.string, ''),
  suffix: types.optional(types.string, ''),
  pdfSettings: PdfSettings
})
  .named('ProcessingTemplateSubType')
  .actions(self => ({
    setName(name: string): void{
      self.name = name;
    },
    setSuffix(suffix: string): void{
      self.suffix = suffix;
    }
  }));

const ProcessingTemplate = types.compose(BaseModel, ProcessingTemplateSubType)
  .named('ProcessingTemplate')
  .views(self => ({
    get payload(): string{
      return `digittool-->${self.id}`;
    }
  }));
export default ProcessingTemplate;

export interface IProcessingTemplate extends Instance<typeof ProcessingTemplate>{}
export interface IProcessingTemplateSnapshotIn extends SnapshotIn<typeof ProcessingTemplate>{}
export interface IProcessingTemplateSnapshotOut extends SnapshotOut<typeof ProcessingTemplate>{}
