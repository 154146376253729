import {
  Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import { createBaseDataAccessStore, QueryParameters} from '@wemogy/reactbase';
import User from '../../models/User';
import RestApiServiceFactory from '../../api/rest/RestApiServiceFactory';
import UserDtoMapper from '../../api/dtoMappers/UserDtoMapper';

const UserStore = createBaseDataAccessStore(
  User,
  RestApiServiceFactory.userService,
  new UserDtoMapper(),
  QueryParameters.create().withTake(200),
);

export interface IUserStore extends Instance<typeof UserStore>{}
export interface IUserStoreSnapshotIn extends SnapshotIn<typeof UserStore>{}
export interface IUserStoreSnapshotOut extends SnapshotOut<typeof UserStore>{}

export default UserStore;
