import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, Modal, useModalStore, Bits
} from '@wemogy/reactbase';
import { useActivePermissionCache } from '@wemogy/reactbase-authorization';
import IAddDatabaseModalProps from './IAddDatabaseModalProps';
import AnnotatedTextInput from '../../../wrappers/formBuilder/containers/formElements/annotatedTextInput/AnnotatedTextInput';
import useDataAccessStore from '../../../../dataLayer/stores/hooks/UseDataAccessStore';
import Form from '../../../wrappers/formBuilder/dataLayer/models/Form';
import { ICustomer } from '../../../../dataLayer/models/Customer';
import NotificationManager from '../../../wrappers/inAppFeedback/NotificationManager';

const AddDatabaseModal: React.FC<IAddDatabaseModalProps> = () => {
  const [name, setName] = useState('');
  const {formStore} = useDataAccessStore();
  const modalStore = useModalStore();
  const permissionCache = useActivePermissionCache();

  const customer = modalStore.getActiveParameters<ICustomer>('addDatabase');

  if(!customer){
    return null;
  }

  return (
    <Modal modalKey="addDatabase" icon="add" title="Datenbank hinzufügen" large>
      <StackLayout>
        <AnnotatedTextInput description="Name" placeholder="Name der Datenbank" value={name} onChange={setName} marginBottom />
        <StackLayout hEnd>
          <Button onPress={(): void => {
            const form = Form.create({
              sas: '',
              name, relatedId: customer.id
            });
            formStore.create(form).then((): void => {
              NotificationManager.success('Die Datenbank wurde angelegt');
              customer.addForm(form);
              permissionCache?.resourceCreated(0, 2, form.id, [new Bits('_____') as any, new Bits('_____') as any])

              modalStore.closeModal();
              setName('');
            });
          }}
          >
            Jetzt anlegen

          </Button>
        </StackLayout>
      </StackLayout>
    </Modal>
  );
};

export default observer(AddDatabaseModal);
