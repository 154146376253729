import React from 'react';
import { observer } from 'mobx-react';
import {
StackLayout, Text, Icon, useModalStore
} from '@wemogy/reactbase';
import IAddCustomerProps from './IAddCustomerProps';

const AddCustomer: React.FC<IAddCustomerProps> = () => {
  const modalStore = useModalStore();

  return (
    <StackLayout
      onPress={(): void => {
        modalStore.openModal('addCustomer');
      }}
      horizontal
      padding
      borderRadius={0.5}
      border={0.125}
      borderColor="primary"
      vCenter
    >
      <Icon personAdd size={5} marginRight />

      <Text title>Kunden hinzufügen</Text>
    </StackLayout>
  );
};

export default observer(AddCustomer);
