import React from 'react';
import { observer } from 'mobx-react';
import { StackLayout } from '@wemogy/reactbase';
import IDividerProps from './IDividerProps';

const Divider: React.FC<IDividerProps> = ({marginBottom}) => {

  return (
    <StackLayout width100 height={1} marginBottom={marginBottom} backgroundColor="primary" />
  );
};

export default observer(Divider);
