import React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import {StackLayout, invokeEventHandler} from '@wemogy/reactbase';
import ICheckGroupProps from './ICheckGroupProps';
import Checkbox from '../checkbox/Checkbox';

const CheckGroup: React.FC<ICheckGroupProps> = ({
  options, selectedOptions, disabled, onSelectedOptionsChange
}) => {
  if (!_.isArray(selectedOptions)) {
    selectedOptions = [];
  }

  console.log('selectedOptions', selectedOptions);

  return (
    <StackLayout>
      {
        options.map(option => (
          <Checkbox
            key={option.key}
            disabled={disabled}
            marginBottom={0.5}
            label={option.key}
            boxStyle
            checked={selectedOptions.find(x => x === option.value) !== undefined}
            onChange={(checked): void => {
              console.log('Checkbox clicked', checked);
              if(checked){
                invokeEventHandler(
                  onSelectedOptionsChange,[...selectedOptions, option.value]);
              }
              else{
                invokeEventHandler(
                  onSelectedOptionsChange, selectedOptions.filter(x => x !== option.value));
              }
            }}
          />
        ))
      }
    </StackLayout>
  );
};

export default observer(CheckGroup);
