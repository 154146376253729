import {RestService, ApiWrapper, ApiResult} from '@wemogy/reactbase';
import {MainApiUri} from '../../../../../../base/Constants';
import ImportDataItemFromQrCodeRequest from '../dtos/art/ImportDataItemFromQrCodeRequest';
import { authFetch } from './QuickHelper';

export default class ImportDataService extends RestService<any>{
  public constructor(apiWrapper: ApiWrapper){
    super(apiWrapper, 'importData/');
  }

  public importCsv(realtimeConnectionId: string, formId: string, dataSources: string[], formData: FormData): Promise<Response>{
    const dataSourcesQueryParameters = dataSources.map(x => `dataSources=${x}`).join('&');

    return authFetch(this.apiWrapper, `${MainApiUri}importData/csv/${formId}/${realtimeConnectionId}?${dataSourcesQueryParameters}`, {
      method: 'POST',
      body: formData
    }, true);
  }

  public importQrCode(formId: string, importDataItemFromQrCodeRequest: ImportDataItemFromQrCodeRequest): Promise<ApiResult<void>>{
    return this.apiWrapper.post(`importData/qrCode/${formId}`, importDataItemFromQrCodeRequest);
  }
}
