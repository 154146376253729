import React from 'react';
import { observer } from 'mobx-react';
import { IPermissionFeatureProps } from '@wemogy/reactbase-authorization';
import useFormOrWorkingPackageFeature, { FormOrWorkingPackageRole } from '../hooks/UseFormOrWorkingPackageFeature';

type FormSubLevelNames = 'form' | 'workingPackage';

const FormOrWorkingPackagePermissionFeature: React.FC<IPermissionFeatureProps<FormOrWorkingPackageRole, FormSubLevelNames, FormSubLevelNames>> = ({
  requiredPermission,
  children
}) => {

  const { hasOnActiveFormOrWorkingPackagePermission } = useFormOrWorkingPackageFeature();

  if (requiredPermission && hasOnActiveFormOrWorkingPackagePermission(requiredPermission)) {
    return children as any;
  }

  return null;
}

export default observer(FormOrWorkingPackagePermissionFeature);
