import { createSingleTenantPermissionFeatureComponent,  ISingleTenantPermissionFeatureProps } from '@wemogy/reactbase-authorization';
import { PropsWithChildren } from 'react';
import GlobalRole from '../../../../dataLayer/enums/GlobalRole';
import GlobalSubLevelName from '../types/GlobalSubLevelName';


const GlobalPermissionFeature: <TActiveSubLevelName extends GlobalSubLevelName>(props:
  PropsWithChildren<ISingleTenantPermissionFeatureProps<typeof GlobalRole, GlobalSubLevelName, TActiveSubLevelName>>) => any =
  createSingleTenantPermissionFeatureComponent(GlobalRole) as any;

export default GlobalPermissionFeature;
