import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Tooltip from 'rc-tooltip';
import {
  generateDomId, Text, isEmptyOrWhiteSpace
} from '@wemogy/reactbase';
import ITooltipHelpProps from './ITooltipHelpProps';
import './TooltipHelp.css';
import ReactTooltip from 'react-tooltip';

const TooltipHelp: React.FC<ITooltipHelpProps> = ({
  children, position, text, component, disabled, tourIdentifier, stretch
}) => {
  const [id] = useState(tourIdentifier || generateDomId());

  if(disabled || !text){
    return children;
  }

  if (10 < 8) {
    return (
      <>
        <div data-tip data-for={id}>
          {children}
        </div>
        <ReactTooltip id={id} place="left">
          {component ? (): JSX.Element => component : isEmptyOrWhiteSpace(text?.toString()) ? (): null => null : (): JSX.Element => <Text light>{text}</Text>}
        </ReactTooltip>
      </>
)
      }

      return (
        <Tooltip
      // trigger={['hover']}
          overlay={(): JSX.Element => (
            <div>{ component || (isEmptyOrWhiteSpace(text?.toString()) ?  null : <Text light noTranslation>{text}</Text>)}</div>
          )}
          placement={position || 'top'}
          destroyTooltipOnHide
          getTooltipContainer={(): any => {
        return document.querySelector(`#${id}`)?.closest('.scrollContainer') || document.body;
          }}
        >
          <div
            id={id}
            style={{
          display: 'flex', flex: stretch ? 1 : undefined
        }}
          >
            {children}
          </div>
        </Tooltip>
  );
};

export default observer(TooltipHelp);
