/* eslint-disable filenames/match-exported */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import {
  SingleTenantId, useAuthorizationConfig, usePermissionLevels, useRoleStore
} from '@wemogy/reactbase-authorization';
import ICreateMemberManagementComponentParameters from './interfaces/ICreateMemberManagementComponentParameters';
import MemberList from '../member/memberList/MemberList';
import IMemberManagementProps from './IMemberManagementProps';
import AddSubject from '../member/addSubject/AddSubject';

export default function createMemberManagementComponent(params: ICreateMemberManagementComponentParameters) {
  const {
    pathIndex,
    levelIndex,
    hooks
  } = params;

  const memberManagementComponent: React.FC<IMemberManagementProps> = ({resourceId}) => {
    const permissionLevels = usePermissionLevels();
    const authorizationConfig = useAuthorizationConfig();
    const roleStore = useRoleStore();
    const permissionLevel = useMemo(() => {
      return permissionLevels.find(x => x.pathIndex === pathIndex && x.levelIndex === levelIndex);
    }, []);
    const subjects = authorizationConfig.ui.hooks.useGetSubjectsHook();
    const members = hooks.useMembersResolverHook(resourceId);
    const unassignedSubjects = subjects.filter(x => members.find(y => y.id === x.id) === undefined);

    useEffect((): void => {
      (roleStore as any).queryTenantRoles(SingleTenantId);
    }, []);

    if (!permissionLevel) {
      console.error('createMemberManagementComponent params', params)
      throw new Error('Permission level not found in memberManagementComponent')
    }

    return (
      <>
        {hooks.useSubjectsAddedHandlerHook ? <AddSubject resourceId={resourceId} permissionLevel={permissionLevel} subjects={unassignedSubjects} useSubjectsAddedHandlerHook={hooks.useSubjectsAddedHandlerHook} /> : null}
        <MemberList members={members} resourceId={resourceId} useMemberRoleIdChangedHandlerHook={hooks.useMemberRoleIdChangedHandlerHook} useMembersDeletedHandlerHook={hooks.useMembersDeletedHandlerHook} />
      </>
    );
  }

  return observer(memberManagementComponent);
}
