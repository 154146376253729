import React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, Text, generateGuid, Modal, useModalStore
} from '@wemogy/reactbase';
import { applySnapshot } from 'mobx-state-tree';
import IOpenDatabaseTemplateModalProps from './IOpenDatabaseTemplateModalProps';
import useDataAccessStore from '../../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import DatabaseTemplateList from '../../../containers/databaseTemplateList/DatabaseTemplateList';

const OpenDatabaseTemplateModal: React.FC<IOpenDatabaseTemplateModalProps> = () => {
  const {
    databaseTemplateStore, formStore
  } = useDataAccessStore();
  const modalStore = useModalStore();

  return (
    <Modal modalKey="openDatabaseTemplate" icon="extension" title="Vorlagen öffnen" large>
      {
        databaseTemplateStore.items.length ? (
          <StackLayout>
            <DatabaseTemplateList
              databaseTemplates={databaseTemplateStore.items}
              onDatabaseTemplateOpen={(databaseTemplate): void => {
                const snapshot = databaseTemplate.formSnapshot;
                const form = formStore.activeItem;
                if(!snapshot || !form){
                  console.log('Snapshot or form undefined', snapshot, form);
                  return;
                }
                const ids: string[] = [];
                const snapshotClone = _.cloneDeepWith(snapshot, x => {
                  if (_.isObject(x) && (x as any).id !== undefined) {
                    ids.push((x as any).id);
                  }
                  return x;
                });
                // replace all ids
                let snapshotJson = JSON.stringify(snapshotClone);
                ids.forEach(id => {
                  const idForReplace = id === snapshotClone.id ? form.id : generateGuid();
                  snapshotJson = snapshotJson.replace(`"${id}"`, `"${idForReplace}"`);
                });
                const snapshotFinal = JSON.parse(snapshotJson);
                snapshotFinal.relatedId = form.relatedId;
                snapshotFinal.name = form.name;
                snapshotFinal.members = [];
                applySnapshot(form, snapshotFinal);
                modalStore.closeModal();
              }}
            />
          </StackLayout>
        ) : (
          <StackLayout hCenter>
            <Text title marginTopBottom={2}>Es sind keine Vorlagen vorhanden</Text>
            <Button onPress={(): void =>{
              modalStore.openModal('addDatabaseTemplate');
            }}
            >
              Jetzt eine Vorlage anlegen

            </Button>
          </StackLayout>
        )
      }

    </Modal>
  );
};

export default observer(OpenDatabaseTemplateModal);
