import { Instance } from 'mobx-state-tree';
import { enumerationIntValuesType } from '@wemogy/reactbase';

enum ValueComparator {
  Equals = 1,
  CaseInsensitive = 2,
  Contains = 4
}

export default ValueComparator;

export const ValueComparatorType = enumerationIntValuesType(ValueComparator);

export type IValueComparator = Instance<typeof ValueComparatorType>;
