import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, TextInput, Button, Modal, useModalStore
} from '@wemogy/reactbase';
import IForgotPasswordModalProps from './IForgotPasswordModalProps';
import TooltipHelp from '../../../tooltipHelp/TooltipHelp';
import { validateEmail } from '../../../../../helpers/StringHelpers';
import LoadingIndicator from '../../../../components/loadingIndicator/LoadingIndicator';
import RestApiServiceFactory from '../../../../../dataLayer/api/rest/RestApiServiceFactory';
import NotificationManager from '../../../inAppFeedback/NotificationManager';

const ForgotPasswordModal: React.FC<IForgotPasswordModalProps> = () => {
  const [email, setEmail] = useState('');
  const [sendingRequest, setSendingRequest] = useState(false);
  const modalStore = useModalStore();

  const canSubmit = validateEmail(email);

  return (
    <Modal modalKey="forgotPassword" icon="help" title="Passwort vergessen">
      <Text label marginBottom={0.75}>E-Mail</Text>
      <StackLayout>
        <StackLayout stretch>
          <TooltipHelp text={canSubmit ? undefined : 'Bitte geben Sie eine gültige E-Mail Adressse ein'}>
            <TextInput
              className="focusFirst"
              placeholder="Ihre E-Mail-Adresse"
              value={email}
              onChange={setEmail}
            />
          </TooltipHelp>
        </StackLayout>
        <StackLayout marginTop={2} hEnd={!sendingRequest} height={5} opacity={canSubmit ? 1 : 0.5}>
          {sendingRequest ? (
            <LoadingIndicator />
          ) : (
            <Button
              onPress={canSubmit ? (): void => {
                setSendingRequest(true);
                RestApiServiceFactory.identityAccountService.forgotPassword(email).then((): void => {
                  setEmail('');
                  NotificationManager.success('Wir haben Ihnen eine E-Mail zum Zurücksetzen Ihres Passworts gesendet.');
                  modalStore.closeModal();
                }).finally((): void => {
                  setSendingRequest(false);
                });
              } : undefined}
            >
              Passwort vergessen
            </Button>
          )}
        </StackLayout>
      </StackLayout>

    </Modal>
  );
};

export default observer(ForgotPasswordModal);
