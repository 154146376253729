import React from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import CircleLoader from 'react-spinners/CircleLoader';
import {
  StackLayout, Text, useTheme
} from '@wemogy/reactbase';
import { GridLoader, PuffLoader } from 'react-spinners';
import ILoadingIndicatorProps from './ILoadingIndicatorProps';

const LoadingIndicator: React.FC<ILoadingIndicatorProps> = ({
  fullscreen, grid, text, light, small, animation
}) =>
{
  const theme = useTheme();

  const Animation = animation === 'puff' ? PuffLoader : PropagateLoader;

  const indicator = small ? (
    <StackLayout size={3} minWidth={6} hCenter vCenter><CircleLoader size={theme.designGrid.base * 3} color={theme.colorScheme.primary} /></StackLayout>
  ) : text || grid ? (
    <StackLayout hCenter>
      <GridLoader size={32} color={theme.colorScheme.primary} />
      <Text light={light} marginTop={3}>{text}</Text>
    </StackLayout>
  )
    : (<StackLayout height={2} minWidth={6} hCenter vCenter><Animation color={theme.colorScheme.primary} /></StackLayout>);

  if (fullscreen) {
    return (<StackLayout hCenter vCenter stretch>{indicator}</StackLayout>);
  }

  return indicator;
};

export default LoadingIndicator;
