import { toast } from 'react-toastify';
import {TranslationInformation} from '@wemogy/reactbase';

export default class NotificationManager {
  public static success(message: TranslationInformation, onClick?: () => void): void {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      onClick
});
  }

  public static error(message: TranslationInformation): void{
    toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT });
  }

  public static info(message: TranslationInformation): void {
    toast.info(message, {position: toast.POSITION.BOTTOM_RIGHT});
  }
}
