export default class ConfirmInvitationRequest{
  public constructor(
    public invitationId: string,
    public token: string,
    public firstname: string,
    public lastname: string,
    public password: string,
    public privacyPolicyAccepted: boolean,
    public termsAndConditionsAccepted: boolean
  ){}
}
