import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';

const PdfMarkerCodeMetadata = types.model({
  markerCodeId: types.string,
  amount: types.optional(types.number, 0)
})
  .named('PdfMarkerCodeMetadata')
  .views(self => ({
    get markerCodeDigitToolId(): string{
      return self.markerCodeId.split('digittool-->')[1];
    }
  }))
  .actions(self => ({
    incrementAmount(amount: number): void{
      self.amount += amount;
    }
  }));
export default PdfMarkerCodeMetadata;

export interface IPdfMarkerCodeMetadata extends Instance<typeof PdfMarkerCodeMetadata>{}
export interface IPdfMarkerCodeMetadataSnapshotIn extends SnapshotIn<typeof PdfMarkerCodeMetadata>{}
export interface IPdfMarkerCodeMetadataSnapshotOut extends SnapshotOut<typeof PdfMarkerCodeMetadata>{}
