import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { Search, SearchProps, SearchResultData } from 'semantic-ui-react';
import IAutocompleteInputControlProps from './IAutocompleteInputControlProps';

const AutocompleteInputControl: React.FC<IAutocompleteInputControlProps> = ({className}) => {
  const [value, setValue] = useState('');

  const handleResultSelected = useCallback((_event: React.MouseEvent<HTMLDivElement, MouseEvent>, data: SearchResultData): void => {
    console.log('data', data)
  }, []);

  const handleSearchChanged = useCallback((_event: React.MouseEvent<HTMLElement, MouseEvent>, data: SearchProps): void => {
    if (!data.value) {
      setValue('');
      return;
    }
    setValue(data.value)
  }, []);

    return (
      <Search
        className={className}
        icon={true ? undefined : null}
        loading={true}
        onResultSelect={handleResultSelected}
        onSearchChange={handleSearchChanged}
        results={[
          {
            title: 'Test Item'
          }
        ]}
        value={value}
      ></Search>
    )
}

export default observer(AutocompleteInputControl);
