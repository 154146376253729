import {
  types, Instance, SnapshotIn
} from 'mobx-state-tree';
import ActiveUserStore from './appStores/ActiveUserStore';

const RootAppStore = types.model({
  isInitialized: types.optional(types.boolean, false),
  activeUserStore: types.optional(ActiveUserStore, () => ActiveUserStore.create())
})
  .named('RootAppStore')
  .actions(self => ({
    afterCreate(): void{
      self.activeUserStore.distributeApiAuthenticationInformation();
    },
    setIsInitialized(isInitialized: boolean): void{
      self.isInitialized = isInitialized;
    },
  }));


export default RootAppStore;

export interface IRootAppStore extends Instance<typeof RootAppStore> {}
export interface IRootAppStoreSnapshot extends SnapshotIn<typeof RootAppStore> {}
