import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {StackLayout, Icon} from '@wemogy/reactbase';
import IEditWrapperProps from './IEditWrapperProps';
import useFormBuilderUiStore from '../../dataLayer/stores/hooks/UseFormBuilderUiStore';

const EditWrapper: React.FC<IEditWrapperProps> = ({
  children, outsideMode, onEdit, onDelete
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const {formBuilderStore} = useFormBuilderUiStore();

  if(!formBuilderStore.isEditing){
    return children;
  }

  return (
    <StackLayout
      horizontal
      spaceBetween
      vCenter
      borderRadius={0.5}
      onMouseMove={(): void => {
        setIsMouseOver(true);
      }}
      onMouseLeave={(): void => {
        setIsMouseOver(false);
      }}
      padding={isMouseOver}
      backgroundColor={isMouseOver ? 'grey500' : 'transparent'}
    >
      <StackLayout stretch>
        {children}
      </StackLayout>
      {
        isMouseOver ? (
          <StackLayout
            horizontal
            marginRightLeft={!outsideMode}
            positionAbsolute={outsideMode}
            top={outsideMode ? -3 : undefined}
            right={0}
            backgroundColor="grey500"
            borderRadiusTopLeft={0.5}
            borderRadiusTopRight={0.5}
            padding={outsideMode ? 0.5 : undefined}
          >
            {onEdit ? (
              <Icon marginRight edit xl onPress={onEdit} />
            ): null}

            {onDelete ? ( <Icon deleteForever xl onPress={onDelete} />) : null}
          </StackLayout>

        ) : null
      }
    </StackLayout>
  );
};

export default observer(EditWrapper);
