import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, Icon, useModalStore
} from '@wemogy/reactbase';
import IDataSourceItemProps from './IDataSourceItemProps';
import TextIcon from '../textIcon/TextIcon';

const DataSourceItem: React.FC<IDataSourceItemProps> = ({
  dataSource, onRemove
}) => {
  const modalStore = useModalStore();

  return (
    <StackLayout horizontal padding vCenter>
      <TextIcon>CSV</TextIcon>
      <Text stretch labelLarge>{dataSource.name}</Text>
      <Icon
        edit
        xl
        marginRight
        onPress={(): void => {
          modalStore.openModal('editDataSource', dataSource);
        }}
      />
      <Icon deleteForever xl onPress={onRemove} />
    </StackLayout>
  );
};

export default observer(DataSourceItem);
