import React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { StackLayout } from '@wemogy/reactbase';
import IStatusLogEntryListProps from './IStatusLogEntryListProps';
import IStatusLogEntryInformation from '../../components/statusLogEntryListItem/interfaces/IStatusLogEntryInformation';
import { IStatusLogEntry } from '../../dataLayer/models/status/StatusLogEntry';
import StatusStep from '../../dataLayer/enums/internal/StatusStep';
import StatusLogEntryListItem from '../../components/statusLogEntryListItem/StatusLogEntryListItem';

function getMapper(statusStep: StatusStep): ((statusLogEntry: IStatusLogEntry) => IStatusLogEntryInformation){
  return (statusLogEntry: IStatusLogEntry): IStatusLogEntryInformation => {
    return ({
      statusLogEntry,
      statusStep
    });
  };
}

const StatusLogEntryList: React.FC<IStatusLogEntryListProps> = ({
status, selectedStatusLogEntry, onStatusLogEntrySelected
}) => {
  const entities = status.imported.map(getMapper(StatusStep.Imported));

  entities.push(...status.attachmentsAssigned.map(getMapper(StatusStep.AttachmentsAssigned)));
  entities.push(...status.indexed.map(getMapper(StatusStep.Indexed)));
  entities.push(...status.workingPackageAssigned.map(getMapper(StatusStep.WorkingPackageAssigned)));
  entities.push(...status.dataCollected.map(getMapper(StatusStep.DataCollected)));
  entities.push(...status.dataQualityAssured.map(getMapper(StatusStep.DataQualityAssured)));
  entities.push(...status.dataCollectionVerified.map(getMapper(StatusStep.DataCollectionVerified)));
  entities.push(...status.exported.map(getMapper(StatusStep.Exported)));

  return (
    <StackLayout>
      {_.orderBy(entities, x => x.statusLogEntry.timestamp, 'desc').map(entity => (
        <StatusLogEntryListItem
          statusLogEntryInformation={entity}
          key={`${entity.statusLogEntry.timestamp}${entity.statusLogEntry.issuer}`}
          isSelected={entity.statusLogEntry === selectedStatusLogEntry}
          onPress={onStatusLogEntrySelected ?(): void => {
            onStatusLogEntrySelected(entity.statusLogEntry);
          } : undefined}
        />
      ))}
    </StackLayout>
  );
};

export default observer(StatusLogEntryList);
