/* eslint-disable no-await-in-loop */
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, Button, Modal, useModalStore
} from '@wemogy/reactbase';
import IAssignWorkingPackageModalProps from './IAssignWorkingPackageModalProps';
import Dropdown from '../../../../components/dropdown/Dropdown';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import { IDataItem } from '../../dataLayer/models/genericModels/DataItem';
import useDataItemStore from '../../dataLayer/stores/hooks/UseDataItemStore';
import NotificationManager from '../../../inAppFeedback/NotificationManager';
import RestApiServiceFactory from '../../../../../dataLayer/api/rest/RestApiServiceFactory';

const AssignWorkingPackageModal: React.FC<IAssignWorkingPackageModalProps> = () => {
  const { formStore } = useDataAccessStore();
  const modalStore = useModalStore();
  const dataItemStore = useDataItemStore();
  const [selectedWorkingPackageId, setSelectedWorkingPackageId] = useState<string | undefined>(undefined);

  const { activeItem } = formStore;

  if (!activeItem || !dataItemStore) {
    return null;
  }

  const workingPackagesOptions = activeItem.workingPackages.map(w => ({
    label: `${w.name} (${w.begin?.toDateString() || '?'} - ${w.end?.toDateString() || '?'}) (${w.numberOfDataItemsString})`,
    value: w.id
  }));

  const selectedWorkingPackageOption = workingPackagesOptions.find(x => x.value === selectedWorkingPackageId);

  const dataItems = modalStore.getActiveParameters<IDataItem[]>('assignWorkingPackage');

  if (!dataItems) {
    return null;
  }

  return (
    <Modal modalKey="assignWorkingPackage" icon="workOutline" title="Arbeitspaket zuweisen" large>
      <StackLayout width100 minHeight={{custom: '50vh'}}>
        <Text marginBottom>{`Zu welchem Arbeitspaket möchten Sie die ausgewählen ${dataItems.length} Datensätze zuordnen?`}</Text>
        <Dropdown
          light
          options={workingPackagesOptions}
          onOptionSelected={(option): void => {
            setSelectedWorkingPackageId(option.value);
          }}
          selectedOption={selectedWorkingPackageOption}
        />
        <StackLayout height />
        {
          selectedWorkingPackageId ? (
            <Button onPress={(): void => {
              (async (): Promise<void> => {
                const workingPackage = activeItem.workingPackages.find(x => x.id === selectedWorkingPackageId);

                if (!workingPackage) {
                  return;
                }

                if (!workingPackage.canAddAllDataItems(dataItems.length)) {
                  NotificationManager.error(`Sie können die ausgewählten ${dataItems.length} Datensätze nicht dem Arbeitspaket ${workingPackage.name} mit maximal ${workingPackage.maximumDataItems} Datensätzen hinzufügen. Es dürfen nur noch ${workingPackage.freeDataItems} Datensätze hinzugefügt werden.`);
                  return;
                }

                modalStore.openModal('loadingOverlay', 'Zuweisung wird durchgeführt');

                const apiResult = await RestApiServiceFactory.getDataItemService(activeItem.id).setWorkingPackageId(activeItem.id, dataItems.map(x => x.id), selectedWorkingPackageId);

                if (apiResult.ok) {
                  activeItem.updateWorkingPackages(apiResult.data);
                  dataItems.forEach(dataItem => {
                    dataItem.setWorkingPackageId(selectedWorkingPackageId);
                    if (dataItemStore.isActive(dataItem)) {
                      dataItemStore.activeItem.setWorkingPackageId(selectedWorkingPackageId);
                    }
                  });

                  NotificationManager.success('Die Zuweisung wurde erfolgreich gespeichert');
                } else {
                  NotificationManager.error('Beim Zuweisen ist ein Fehler aufgetreten');
                }
                modalStore.closeModal();
              })();
            }}
            >
              Jetzt zuordnen

            </Button>
          ) : null
        }
      </StackLayout>
    </Modal>
  );
};

export default observer(AssignWorkingPackageModal);
