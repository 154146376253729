import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
QueryParameters, StackLayout, Text, Icon, Modal
} from '@wemogy/reactbase'
import IBackupsModalProps from './IBackupsModalProps';
import RestApiServiceFactory from '../../../dataLayer/api/rest/RestApiServiceFactory';
import Blob from '../../wrappers/formBuilder/dataLayer/api/dtos/Blob';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import TooltipHelp from '../../wrappers/tooltipHelp/TooltipHelp';

function startDownloadFromUri(uri: string): void {
  console.log('startDownloadFromUri', uri);
  const link = document.createElement('a');
  link.download = '';
  link.href = uri;
  link.click();
}

export function bytesAsString(bytes: number, decimals: number = 2): string {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
}

const BackupsModal: React.FC<IBackupsModalProps> = () => {
  const [loading, setLoading] = useState(false);
  const [blobs, setBlobs] = useState<Blob[]>([]);

    return (
      <Modal
        onOpened={(): void => {
        setLoading(true);
        RestApiServiceFactory.backupService.query(QueryParameters.create()).then(apiResult => {
          setLoading(false);
          if (!apiResult.ok) {
            return;
          }
          setBlobs(apiResult.data);
        });
      }}
        modalKey="backups"
        icon="fileCopy"
        title="Backups"
        large
      >
        <StackLayout>
          {
            loading ? (
              <LoadingIndicator />
            )
            : blobs.length === 0 ? (
              <StackLayout hCenter marginTop={4}>
                <Text marginBottom>Es sind noch keine Backups vorhanden</Text>
              </StackLayout>
            ) : blobs.sort((a,b) =>a.name.localeCompare(b.name)).map(blob => (
              <StackLayout key={blob.name} horizontal border={0.125} borderColor="green200" marginBottom padding vCenter>
                <Icon xl fileCopy marginRight />
                <StackLayout stretch marginRight hStart>
                  <Text noTranslation>{blob.name}</Text>
                  <Text noTranslation>{bytesAsString(blob.length)}</Text>
                </StackLayout>
                <TooltipHelp text="Download">
                  <Icon
                    xl
                    getApp
                    onPress={(): void => {
                      startDownloadFromUri(blob.uri)
                  }}
                  />
                </TooltipHelp>
              </StackLayout>
                ))
}
        </StackLayout>
      </Modal>
    )
}

export default observer(BackupsModal);
