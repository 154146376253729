import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import {BaseModel, isEmptyOrWhiteSpace} from '@wemogy/reactbase';
import { IDataSourceType, DataSourceTypeType } from '../enums/DataSourceType';
import PrimaryKey, { IPrimaryKey } from './PrimaryKey';
import ValueComparator from '../enums/ValueComparator';

const DataSourceSubType = types.model({
  type: DataSourceTypeType,
  name: types.optional(types.string, ''),
  primaryKeys: types.optional(types.array(PrimaryKey), []),
  notes: types.optional(types.string, '')
})
  .named('DataSourceSubType')
  .views(self => ({
    get isValid(): boolean{
      return !isEmptyOrWhiteSpace(self.name) && self.primaryKeys.find(x => !x.isValid) === undefined;
    }
  }))
  .actions(self => ({
    setType(type: IDataSourceType): void{
      self.type = type;
    },
    setName(name: string): void{
      self.name = name;
    },
    addPrimaryKey(): void{
      self.primaryKeys.push(PrimaryKey.create({comparator: ValueComparator.Equals}));
    },
    removePrimaryKey(primaryKey: IPrimaryKey): void{
      self.primaryKeys.remove(primaryKey);
    },
    setNotes(notes: string): void{
      self.notes = notes;
    },
  }));

const DataSource = types.compose(BaseModel, DataSourceSubType)
  .named('DataSource');

export default DataSource;

export interface IDataSource extends Instance<typeof DataSource>{}
export interface IDataSourceSnapshotIn extends SnapshotIn<typeof DataSource>{}
export interface IDataSourceSnapshotOut extends SnapshotOut<typeof DataSource>{}
