import React from 'react';
import { observer } from 'mobx-react';
import { Text, useModalStore } from '@wemogy/reactbase';
import IRealtimeStatusProps from './IRealtimeStatusProps';

const RealtimeStatus: React.FC<IRealtimeStatusProps> = () => {
  const modalStore = useModalStore();

  const text = modalStore.getActiveParameters<string>('loadingOverlay');

  if (!text) {
    return null;
  }

  return (
    <Text lightTitle marginTop={5}>{text ? `${text} ...` : ''}</Text>
  );
};

export default observer(RealtimeStatus);
