import React from 'react';
import { observer } from 'mobx-react';
import { StackLayout, Text } from '@wemogy/reactbase';
import IAnnotatedFormViewProps from './IAnnotatedFormViewProps';

const AnnotatedFormView: React.FC<IAnnotatedFormViewProps> = ({
  description, children, marginBottom
}) => {

  return (
    <StackLayout marginBottom={marginBottom}>
      <Text label marginBottom={0.5}>{description}</Text>
      {children}
    </StackLayout>
  );
};

export default observer(AnnotatedFormView);
