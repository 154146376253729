/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useCallback } from 'react';
import useDataAccessStore from '../../../../dataLayer/stores/hooks/UseDataAccessStore';
import useFormPermissionFeature from './UseFormPermissionFeature';
import useWorkingPackagePermissionFeature from './UseWorkingPackagePermissionFeature';

export default function useDerivedActor(workingPackageId?: string) {
  const { hasWorkingPackagePermission } = useWorkingPackagePermissionFeature();
  const { hasOnActiveFormPermission } = useFormPermissionFeature();
  const { formStore } = useDataAccessStore();


  const isCollector = useCallback((): boolean => {
    const activeWorkingPackageId = workingPackageId || formStore.activeItem?.activeWorkingPackage?.id;
    if (activeWorkingPackageId) {
      return hasWorkingPackagePermission(activeWorkingPackageId, 'CanCollectData');
    }
    return hasOnActiveFormPermission('CanCollectData');
  }, [workingPackageId, formStore.activeItem, formStore.activeItem?.activeWorkingPackage]);

  const isQualityAssurer = useCallback((): boolean => {
    const activeWorkingPackageId = workingPackageId || formStore.activeItem?.activeWorkingPackage?.id;
    if (activeWorkingPackageId) {
      return hasWorkingPackagePermission(activeWorkingPackageId, 'CanPerformQualityAssurance');
    }
    return hasOnActiveFormPermission('CanPerformQualityAssurance');
  }, [workingPackageId, formStore.activeItem, formStore.activeItem?.activeWorkingPackage]);

  const isSampleInspector = useCallback((): boolean => {
    const activeWorkingPackageId = workingPackageId || formStore.activeItem?.activeWorkingPackage?.id;
    if (activeWorkingPackageId) {
      return hasWorkingPackagePermission(activeWorkingPackageId, 'CanPerformSampling');
    }
    return hasOnActiveFormPermission('CanPerformSampling')
  }, [workingPackageId, formStore.activeItem, formStore.activeItem?.activeWorkingPackage]);

  return ({
    isCollector,
    isQualityAssurer,
    isSampleInspector
  })
}
