import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';

const QrCodeMapping = types.model({
  destinationName: types.maybe(types.string),
  valueIndex: types.optional(types.number, 0),
})
  .named('QrCodeMapping')
  .actions(self => ({
    setDestinationName(destinationName: string): void {
      self.destinationName = destinationName;
    },
    setValueIndex(valueIndex: number): void {
      self.valueIndex = valueIndex;
    }
  }));

export default QrCodeMapping;

export interface IQrCodeMapping extends Instance<typeof QrCodeMapping>{}
export interface IQrCodeMappingSnapshotIn extends SnapshotIn<typeof QrCodeMapping>{}
export interface IQrCodeMappingSnapshotOut extends SnapshotOut<typeof QrCodeMapping>{}
