import React from 'react';
import { observer } from 'mobx-react';
import {
StackLayout, Text, Icon
} from '@wemogy/reactbase';
import { useDrag, DragSourceMonitor } from 'react-dnd';
import IToolboxElementProps from './IToolboxElementProps';
import useFormBuilderUiStore from '../../dataLayer/stores/hooks/UseFormBuilderUiStore';

const ToolboxElement: React.FC<IToolboxElementProps> = ({
  label, icon, referenceKey, type, config
}) => {
  const {formDesignerStore} = useFormBuilderUiStore();

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: type.toString(), referenceKey, config
    },
    begin: (): void => {
      formDesignerStore.setActiveToolboxElementType(type);
    },
    end: (item: { name: string } | undefined, monitor: DragSourceMonitor) => {
      formDesignerStore.unsetActiveToolboxElementType();
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        console.log(`You dropped ${item.name} into ${dropResult.name}!`);
      }
    },
    collect: monitor => ({isDragging: monitor.isDragging(),}),
  });

  const opacity = isDragging ? 1 : 1;

  return (
    <div ref={drag}>
      <StackLayout
        height={5.5}
        horizontal
        vCenter
        marginBottom={2}
        padding
        border={0.25}
        borderColor="grey500"
        opacity={opacity}
        customStyle={{borderStyle: 'dotted'}}
      >
        {typeof icon === 'string' ? <Icon marginRight {...{[icon]: true}} /> : icon}
        <Text>{label}</Text>
      </StackLayout>
    </div>
  );
};

export default observer(ToolboxElement);
