import React from 'react';
import { observer } from 'mobx-react';
import IMicrosoftOfficeViewerProps from './IMicrosoftOfficeViewerProps';

const MicrosoftOfficeViewer: React.FC<IMicrosoftOfficeViewerProps> = ({
  uri, width, height
}) => {
console.log('MicrosoftOfficeViewer URI', uri)
  return (
    <iframe
      src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(uri)}`}
      width={`${width}px`}
      height={`${height}px`}
      frameBorder='0'
      title="Office Preview"
    />
  );
};

export default observer(MicrosoftOfficeViewer);
