import {
  RestService, ApiWrapper, ApiResult
} from '@wemogy/reactbase';
import { getSnapshot } from 'mobx-state-tree';
import FormRole from '../../enums/roles/FormRole';
import {IForm} from '../../models/Form';

export default class FormService extends RestService<IForm>{
  public constructor(apiWrapper: ApiWrapper){
    super(apiWrapper, 'form');
  }

  public getCsvExport(id: string, ofType: 'controls' | 'dataSources'): Promise<ApiResult<string>>{
    return this.apiWrapper.get(`form/csv/${ofType}/${id}`);
  }

  public put(form: IForm): Promise<ApiResult<void>>{
    return this.apiWrapper.put('form', getSnapshot(form));
  }

  public putCustomerMember(customerId: string, userId: string, role: FormRole): Promise<ApiResult<void>>{
    return this.apiWrapper.put(`${this.restEndpoint}/member/${customerId}/${userId}/${role}`, {});
  }

  public deleteCustomerMember(customerId: string, userId: string): Promise<ApiResult<void>>{
    return this.apiWrapper.delete(`${this.restEndpoint}/member/${customerId}/${userId}`);
  }
}
