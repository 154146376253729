import {
  RestService, ApiResult, ApiWrapper
} from '@wemogy/reactbase';
import { IMarkerCode } from '../../../../ui/wrappers/formBuilder/dataLayer/models/MarkerCode';
import CustomerRole from '../../../enums/CustomerRole';

export default class CustomerService extends RestService<any>{
  public constructor(apiWrapper: ApiWrapper){
    super(apiWrapper, 'customer');
  }

  public addMarkerCode(customerId: string, markerCode: IMarkerCode): Promise<ApiResult<void>>{
    return this.apiWrapper.post(`${this.restEndpoint}/${customerId}/addMarkerCode`, markerCode);
  }

  public putMarkerCode(customerId: string, markerCode: IMarkerCode): Promise<ApiResult<void>>{
    return this.apiWrapper.put(`${this.restEndpoint}/${customerId}/putMarkerCode`, markerCode);
  }

  public deleteMarkerCode(markerCodeId: string): Promise<ApiResult<void>>{
    return this.apiWrapper.delete(`${this.restEndpoint}/deleteMarkerCode/${markerCodeId}`);
  }

  public putCustomerMember(customerId: string, userId: string, role: CustomerRole): Promise<ApiResult<void>>{
    return this.apiWrapper.put(`${this.restEndpoint}/member/${customerId}/${userId}/${role}`, {});
  }

  public deleteCustomerMember(customerId: string, userId: string): Promise<ApiResult<void>>{
    return this.apiWrapper.delete(`${this.restEndpoint}/member/${customerId}/${userId}`);
  }
}
