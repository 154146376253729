import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import {BaseModel} from '@wemogy/reactbase';
import DataStructureTemplateData, { IDataStructureTemplateData } from './DataStructureTemplateData';
import { IControl } from './Control';
import ControlType from '../enums/ControlType';
import DataType from '../enums/DataType';

const DataStructureTemplateSubType = types.model({dataStructureTemplateData: types.optional(types.array(DataStructureTemplateData), []),})
  .named('DataStructureTemplateSubType')
  .views(self => ({
    getForControl(control: IControl): IDataStructureTemplateData[]{
      switch (control.type) {
        case ControlType.Input:
        case ControlType.Checkbox:
          return self.dataStructureTemplateData.filter(x => x.dataType === control.dataType && !x.hasMultiselectData);
        case ControlType.CheckGroup:
          return self.dataStructureTemplateData.filter(x => x.hasMultiselectData && x.dataType === DataType.StringList);
        case ControlType.Dropdown:
        case ControlType.Radio:
          return self.dataStructureTemplateData.filter(x => x.hasMultiselectData && !x.defaultHasMultipleValues && x.dataType === DataType.String);
        case ControlType.Date:
          return self.dataStructureTemplateData.filter(x => x.dataType === DataType.Date && !x.hasMultiselectData);
        case ControlType.DateTime:
          return self.dataStructureTemplateData.filter(x => x.dataType === DataType.DateTime && !x.hasMultiselectData);
        default:
          return [];
      }
    }
  }));

const DataStructureTemplate = types.compose(BaseModel, DataStructureTemplateSubType)
  .named('DataStructureTemplate');
export default DataStructureTemplate;

export interface IDataStructureTemplate extends Instance<typeof DataStructureTemplate>{}
export interface IDataStructureTemplateSnapshotIn extends SnapshotIn<typeof DataStructureTemplate>{}
export interface IDataStructureTemplateSnapshotOut extends SnapshotOut<typeof DataStructureTemplate>{}
