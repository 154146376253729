import React from 'react';
import { observer } from 'mobx-react';
import {StackLayout, Icon} from '@wemogy/reactbase';
import IDataItemWorkingPackageColumnProps from './IDataItemWorkingPackageColumnProps';
import useDataItemStore from '../../../../dataLayer/stores/hooks/UseDataItemStore';
import TooltipHelp from '../../../../../tooltipHelp/TooltipHelp';

const DataItemWorkingPackageColumn: React.FC<IDataItemWorkingPackageColumnProps> = ({
  dataItem, form, onPress, onContextMenu
}) => {
  const dataItemStore = useDataItemStore();

  if (!dataItemStore) {
    return null;
  }

  return (
    <TooltipHelp position="right" text={dataItem.hasWorkingPackage && dataItem.workingPackageId ? `Arbeitspaket: ${form.getWorkingPackage(dataItem.workingPackageId)?.name}` : 'Kein Arbeitspaket zugeordnet'}>
      <StackLayout onPress={onPress} onContextMenu={onContextMenu} height100 width100 className={dataItemStore.isSelected(dataItem) ? 'selectedDataItem' : dataItemStore.isActive(dataItem) ? 'activeDataItem' : ''} vCenter horizontal>
        <Icon xl color={dataItem.hasWorkingPackage ? 'green400' : 'grey500'} workOutline />
      </StackLayout>
    </TooltipHelp>
  );
};

export default observer(DataItemWorkingPackageColumn);
