import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Button, StackLayout, useModalStore
} from '@wemogy/reactbase';
import IProcessingTemplateScreenProps from './IProcessingTemplateScreenProps';
import ScrollContainer from '../../wrappers/scrollContainer/ScrollContainer';
import MainLayout from '../../layouts/mainLayout/MainLayout';
import useDataAccessStore from '../../../dataLayer/stores/hooks/UseDataAccessStore';
import { confirmDialogYesNoDanger } from '../../wrappers/dialogs/DialogManager';
import NotificationManager from '../../wrappers/inAppFeedback/NotificationManager';
import ProcessingTemplate from '../../wrappers/formBuilder/components/processingTemplate/ProcessingTemplate';
import AddProcessingTemplateModal from '../../modals/addProcessingTemplate/AddProcessingTemplateModal';

const ProcessingTemplateScreen: React.FC<IProcessingTemplateScreenProps> = () => {
  const { processingTemplateStore } = useDataAccessStore();
  const modalStore = useModalStore();

  useEffect((): void => {
    processingTemplateStore.query();
  }, []);

  return (
    <MainLayout>
      <ScrollContainer stretch>
        <StackLayout>
          {processingTemplateStore.items.map(x => (
            <ProcessingTemplate
              key={x.id}
              processingTemplate={x}
              onEdit={(): void => {
                modalStore.openModal('addProcessingTemplate', x);
              }}
              onDelete={(): void => {
                confirmDialogYesNoDanger(`Wollen Sie die Optimierungs-Vorlage ${x.name} wirklich löschen?`, (): void => {
                  modalStore.openModal('loadingOverlay');
                  processingTemplateStore.delete(x.id).then((): void => {
                    NotificationManager.success(`Der Marker Code ${x.name} wurde erfolgreich gelöscht.`);
                  }).finally((): void => {
                    modalStore.closeModal();
                  });
                });
              }}
            />
          ))}
          <Button onPress={(): void => {
            modalStore.openModal('addProcessingTemplate');
          }}
          >
            Neue Optimierungs-Vorlage hinzufügen

          </Button>
        </StackLayout>
      </ScrollContainer>
      <AddProcessingTemplateModal />
    </MainLayout>
  );
};

export default observer(ProcessingTemplateScreen);
