import React from 'react';
import { observer } from 'mobx-react';
import { StackLayout, Text } from '@wemogy/reactbase';
import IFileFormatIconProps from './IFileFormatIconProps';
import FileFormat, { fileFormatToString } from '../../dataLayer/enums/FileFormat';

const FileFormatIcon: React.FC<IFileFormatIconProps> = ({
  fileFormat, fileName
}) => {
  const fileFormatAsString = fileFormat === FileFormat.Unknown ? fileName.split('.').pop() : fileFormatToString(fileFormat);
  const fileFormatName = fileFormatAsString?.toUpperCase();
  if (!fileFormatName) {
    return null;
  }
  return (
    <StackLayout height={2} paddingRightLeft={0.125} paddingTopBottom={0.25} border={0.125} borderColor="green500" borderRadius={0.125} vCenter width={{custom: 'fit-content'}}>
      <Text textIcon>{fileFormatName}</Text>
    </StackLayout>
  );
};

export default observer(FileFormatIcon);
