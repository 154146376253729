import {createBaseDataAccessStore, QueryParameters} from '@wemogy/reactbase';
import DataStructureTemplate from '../../models/DataStructureTemplate';
import RestApiServiceFactory from '../../../../../../dataLayer/api/rest/RestApiServiceFactory';
import DataStructureTemplateDtoMapper from '../../api/dtoMappers/DataStructureTemplateDtoMapper';


const DataStructureTemplateStore = createBaseDataAccessStore(
  DataStructureTemplate,
  RestApiServiceFactory.dataStructureTemplateService,
  new DataStructureTemplateDtoMapper(),
  QueryParameters.create(),
);


export default DataStructureTemplateStore;
