import { enumerationIntValuesType } from '@wemogy/reactbase';

enum ProcessingState {
  None = 0,
  Pending = 1,
  Processing = 2,
  Succeeded = 4,
  Failed = 8,
}

export default ProcessingState;

export const ProcessingStateType = enumerationIntValuesType(ProcessingState);
