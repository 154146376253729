import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, invokeEventHandler, Icon
} from '@wemogy/reactbase';
import INumberPickerProps from './INumberPickerProps';

const NumberPicker: React.FC<INumberPickerProps> = ({
  value, min, onChange, onDecrement, onIncrement
}) => {

  return (
    <StackLayout horizontal vCenter>
      <Icon
        removeCircle
        xl
        marginRight
        onPress={(): void => {
          if(value - 1 < min){
            return;
          }
          invokeEventHandler(onDecrement);
          invokeEventHandler(onChange, value - 1);
        }}
      />
      <Text>{value}</Text>
      <Icon
        addCircle
        xl
        marginLeft
        onPress={(): void => {
          invokeEventHandler(onIncrement);
          invokeEventHandler(onChange, value + 1);
        }}
      />
    </StackLayout>
  );
};

export default observer(NumberPicker);
