import React from 'react';
import { observer } from 'mobx-react';
import {StackLayout, useTheme} from '@wemogy/reactbase';
import { GridLoader } from 'react-spinners';
import ICustomerOverviewProps from './ICustomerOverviewProps';
import useDataAccessStore from '../../../../dataLayer/stores/hooks/UseDataAccessStore';
import CustomerItem from '../customerItem/CustomerItem';
import AddCustomer from '../addCustomer/AddCustomer';
import GlobalPermissionFeature from '../../../plugins/authorization/permissionFeatures/GlobalPermissionFeature';

const CustomerOverview: React.FC<ICustomerOverviewProps> = () => {
  const { customerStore } = useDataAccessStore();
  const theme = useTheme();

  return (
    <StackLayout hEnd stretch spaceBetween>
      {customerStore.isLoading && customerStore.items.length === 0 ? (
        <StackLayout stretch vCenter hCenter width100>
          <GridLoader color={theme.colorScheme.primary} size={40} />
        </StackLayout>
      ) : (
        <StackLayout horizontal wrap marginBottom={2} width100>

          {customerStore.items.map(x => (
            <CustomerItem customer={x} key={x.id} />
          ))}

        </StackLayout>
      )}

      <GlobalPermissionFeature requiredPermission="CanManagePermissions">
        <AddCustomer />
      </GlobalPermissionFeature>
    </StackLayout>
  );
};

export default observer(CustomerOverview);
