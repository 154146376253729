import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, Modal, useModalStore
} from '@wemogy/reactbase';
import IEditAccordionModalProps from './IEditAccordionModalProps';
import AnnotatedTextInput from '../../../containers/formElements/annotatedTextInput/AnnotatedTextInput';
import { ILayout } from '../../../dataLayer/models/Layout';
import AccordionConfig from '../../../dataLayer/models/layoutConfigs/AccordionConfig';
import AnnotatedFormView from '../../../containers/formElements/annotatedFormView/AnnotatedFormView';
import Checkbox from '../../../../../components/checkbox/Checkbox';

const EditAccordionModal: React.FC<IEditAccordionModalProps> = () => {
  const modalStore = useModalStore();

  const layout = modalStore.getActiveParameters<ILayout>('editAccordion');
  const config = layout?.getConfig<AccordionConfig>();

  if (!config) {
    return null;
  }

    return (
      <Modal modalKey="editAccordion" icon="edit" title="Akkordeon bearbeiten" large>
        <StackLayout>
          <AnnotatedFormView description="Mehrere Abschnitte gleichzeit öffnen" marginBottom>
            <Checkbox
              checked={config.allowMultipleExpanded}
              onChange={(): void => {
              config.allowMultipleExpanded = !config.allowMultipleExpanded;
              layout?.setConfig(config);
            }}
            />
          </AnnotatedFormView>
          {config.sections.map((section, i) => (
            <AnnotatedTextInput
              marginBottom
              key={`section${i}`}
              description={`Abschnitt ${i + 1}`}
              placeholder="Geben Sie hier die Beschreibung ein"
              value={section}
              onChange={(newValue): void => {
                config.sections[i] = newValue;
              layout?.setConfig(config);
          }}
            />
          ))}

          <StackLayout horizontal hEnd marginTop>
            <Button
              danger
              marginRight
              onPress={(): void => {
              config.sections.pop();
                layout?.setConfig(config);
                layout?.removeLayout(config.sections.length - 1)
            }}
            >
              Letzten Abschnitt löschen

            </Button>
            <Button onPress={(): void => {
              config.sections.push(`Abschnitt ${config.sections.length + 1}`)
              layout?.setConfig(config);
            }}
            >
              Neuen Abschnitt hinzufügen

            </Button>
          </StackLayout>
        </StackLayout>
      </Modal>
    )
}

export default observer(EditAccordionModal);
