enum FormRole{
  CanImportData = 1,
  CanImportFormTemplates = 2,
  CanAssignLocalAttachments = 3,
  CanAssignAttachmentStoreAttachments = 4,
  CanUploadToAttachmentStore = 5,
  CanIndexData = 6,
  CanCollectData = 7,
  CanPerformQualityAssurance = 8,
  CanPerformSampling = 9,
  CanQuery = 10,
  CanCreateDataItems = 11,
  CanDuplicateDataItems = 12,
  CanDeleteDataItems = 13,
  CanAccessStatistics = 14,
  CanDeleteAssignedAttachments = 15,
  CanMoveAssignedAttachments = 16,
  CanCopyAssignedAttachments = 17,
  CanExportIndex = 18,
  CanExportComments = 19,
  CanExportDataItems = 20,
  CanExportDataItemsWithFlags = 21,
  CanAccessFormManager = 22,
  CanDeleteForm = 23,
  CanManageWorkingPackages = 24,
  CanCreateComments = 25,
  CanAnswerComments = 26,
  CanResolveComments = 27,
  // / <summary>
  // / Can manage permissions on form and working package level
  // / </summary>
  CanManagePermissions = 28,
  CanRead = 29,
  CanResetHistory = 30,
  CanList = 31,
  CanCreate = 32,
  CanDownloadAttachments = 33,
  CanSeeValidationColors = 34,
  CanSeeHistory = 35,
  CanUseArt = 36,
  CanUseSearchReplace = 37,
  CanScheduleAttachmentProcessing = 38,
}

export default FormRole;
