import React from 'react';
import { observer } from 'mobx-react';
import { StackLayout, Text } from '@wemogy/reactbase';
import IOnlyTheseWorkingPackagesSelectorProps from './IOnlyTheseWorkingPackagesSelectorProps';
import AnnotatedFormView from '../formElements/annotatedFormView/AnnotatedFormView';
import Checkbox from '../../../../components/checkbox/Checkbox';
import CheckGroup from '../../../../components/checkGroup/CheckGroup';
import KeyValuePair from '../../dataLayer/models/general/KeyValuePair';

const OnlyTheseWorkingPackagesSelector: React.FC<IOnlyTheseWorkingPackagesSelectorProps> = ({
  form, onlyTheseWorkingPackageIds, onOnlyTheseWorkingPackageIdsChanged
}) => {

  return (
    <AnnotatedFormView description="Anhand von Arbeitspaket einschränken" marginBottom>
      <StackLayout vCenter>
        <Checkbox
          marginBottom={0.5}
          label="Egal aus welchem Arbeitspaket"
          boxStyle
          checked={onlyTheseWorkingPackageIds === undefined}
          onChange={(): void => {
            onOnlyTheseWorkingPackageIdsChanged(undefined);
          }}
        />
        <Text label>Nur aus ausgewählten Arbeitspaketen:</Text>
        <CheckGroup
          onSelectedOptionsChange={(selectedOptions): void => {
            onOnlyTheseWorkingPackageIdsChanged(selectedOptions);
          }}
          selectedOptions={onlyTheseWorkingPackageIds || []}
          options={form.workingPackages.map(x => new KeyValuePair(
            `${x.name} (${x.numberOfDataItemsString})`, x.id))}
        />

      </StackLayout>
    </AnnotatedFormView>

  );
};

export default observer(OnlyTheseWorkingPackagesSelector);
