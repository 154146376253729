import React, { useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { StackLayout } from '@wemogy/reactbase';
import ITimePeriodStatisticsChartProps from './ITimePeriodStatisticsChartProps';
import Dropdown from '../../components/dropdown/Dropdown';
import IDropdownOption from '../../components/dropdown/interfaces/IDropdownOption';
import LineChart from '../../wrappers/charts/lineChart/LineChart';
import { ITimePeriodStatisticItem } from '../../wrappers/formBuilder/dataLayer/models/statistics/TimePeriodStatisticItem';
import { formatToSlimDateString, formatToMonthString } from '../../wrappers/dateSelector/DateHelpers';
import ILineChartSection from '../../wrappers/charts/lineChart/interfaces/ILineChartSection';

const timeSpanOptions: IDropdownOption[] = [
  {
    label: 'pro Tag',
    value: 'daily'
  },
  {
    label: 'pro Woche',
    value: 'weekly'
  },
  {
    label: 'pro Monat',
    value: 'monthly'
  }
];

interface IGroupItem{
  date: string,
  amount: number,
  label: string
}

const TimePeriodStatisticsChart: React.FC<ITimePeriodStatisticsChartProps> = ({ items }) => {
  const [timespanOption, setTimespanOption] = useState(timeSpanOptions[0]);

  const dateFormatter = timespanOption.value === 'daily' ? formatToSlimDateString : timespanOption.value === 'weekly' ? formatToSlimDateString : formatToMonthString;

  const groupItems: IGroupItem[] = [];
  items.forEach(item => {
    const timePeriodStatisticItems: ITimePeriodStatisticItem[] = item.timePeriodStatistics[timespanOption.value];
    groupItems.push(...timePeriodStatisticItems.map(x => ({
      date: dateFormatter(x.date),
      amount: x.amount,
      label: item.label
    } as IGroupItem)))
  });


  const grouped = _.groupBy(groupItems, x => x.date);


  const sections: ILineChartSection[] = Object.keys(grouped).map(groupKey => ({
    name: groupKey,
    values: grouped[groupKey].map(x => ({
      name: x.label,
      value: x.amount
    }))
  }))

    return (
      <StackLayout>
        <StackLayout paddingRightLeft={4} marginBottom>
          <Dropdown light options={timeSpanOptions} selectedOption={timespanOption} onOptionSelected={setTimespanOption} />
        </StackLayout>
        <LineChart
          maxValue={300}
          sections={sections}
        />
      </StackLayout>
    )
}

export default observer(TimePeriodStatisticsChart);
