import React, {  useEffect } from 'react';
import { observer } from 'mobx-react';
import IPdfViewerProps from './IPdfViewerProps';
import { PdfViewerUri } from '../../../../base/Constants';

const PdfViewer: React.FC<IPdfViewerProps> = ({
  uri, width, height, onOutlineSave
}) => {
  useEffect((): (() => void) => {
    // iframe message handler
    const messageHandler = (event: MessageEvent): void => {
      if(event.origin !== window.origin){
        return;
      }
      switch(event.data.topic){
        case 'saveOutline':
          if(!onOutlineSave){
            return;
          }
          onOutlineSave(event.data.payload);
          console.log('saveOutline', event.data.payload)
        break;
        default:
        break;
      }
    }
    // subscribe to iframe message
    window.addEventListener('message', messageHandler);

    // unsubscribe to iframe message
    return (): void => {
      window.removeEventListener('message', messageHandler);
    };
  }, [onOutlineSave]);

  return [uri].map(x => (
    <iframe
      key={x}
      title="PDF Viewer"
      style={{
        width, height
        }}
      src={`${PdfViewerUri}/index.html?pdfUri=${encodeURIComponent(uri)}`}
    />
  ))[0];
};

export default observer(PdfViewer);
