import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';

const PdfPageMetadata = types.model({
  format: types.optional(types.string, ''),
  numberOfPages: types.optional(types.number, 0)
})
  .named('PdfPageMetadata')
  .actions(self => ({
    incrementNumberOfPages(amount: number): void{
      self.numberOfPages += amount;
    },

  }));
export default PdfPageMetadata;

export interface IPdfPageMetadata extends Instance<typeof PdfPageMetadata>{}
export interface IPdfPageMetadataSnapshotIn extends SnapshotIn<typeof PdfPageMetadata>{}
export interface IPdfPageMetadataSnapshotOut extends SnapshotOut<typeof PdfPageMetadata>{}
