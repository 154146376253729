import React from 'react';
import { observer } from 'mobx-react';
import { useModalStore } from '@wemogy/reactbase';
import ILayoutRendererProps from './ILayoutRendererProps';
import AnnotatedView from '../../layouts/annotatedView/AnnotatedView';
import AnnotatedBlock from '../../layouts/annotatedBlock/AnnotatedBlock';
import Table from '../../layouts/table/Table';
import Accordion from '../../layouts/accordion/Accordion';
import EditWrapper from '../../../components/editWrapper/EditWrapper';
import LayoutType from '../../../dataLayer/enums/LayoutType';

const LayoutRenderer: React.FC<ILayoutRendererProps> = ({
  layout, readonlySections, onDelete
}) => {
  const modalStore = useModalStore();

  switch (layout.type) {
    case LayoutType.AnnotatedView:
      return (
        <EditWrapper
          outsideMode
          onDelete={onDelete ? (): void => {
            onDelete(layout);
          } : undefined}
        >
          <AnnotatedView layout={layout} readonlySections={readonlySections} />
        </EditWrapper>
      );
    case LayoutType.AnnotatedBlock:
      console.log('RENDER AnnotatedBlock')
      return (
        <EditWrapper
          outsideMode
          onDelete={onDelete ? (): void => {
            onDelete(layout);
          } : undefined}
        >
          <AnnotatedBlock layout={layout} readonlySections={readonlySections} />
        </EditWrapper>
      );
    case LayoutType.Table:
      return (
        <EditWrapper
          outsideMode
          onDelete={onDelete ? (): void => {
            onDelete(layout);
          } : undefined}
          onEdit={(): void => {
            modalStore.openModal('editTable', layout);
          }}
        >
          <Table layout={layout} readonlySections={readonlySections} />
        </EditWrapper>
      );
      case LayoutType.Accordion:
        return (
          <EditWrapper
            outsideMode
            onDelete={onDelete ? (): void => {
              onDelete(layout);
            } : undefined}
            onEdit={(): void => {
              modalStore.openModal('editAccordion', layout);
            }}
          >
            <Accordion layout={layout} readonlySections={readonlySections} />
          </EditWrapper>
        );

    default:
      return null;
  }
};

export default observer(LayoutRenderer);
