import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import StatusLogEntry from './StatusLogEntry';

const StatusLogVerificationEntrySubType = types.model({verified: types.optional(types.boolean, false)})
  .named('StatusLogVerificationEntrySubType');

const StatusLogVerificationEntry = types.compose(StatusLogEntry, StatusLogVerificationEntrySubType)
  .named('StatusLogVerificationEntry');
export default StatusLogVerificationEntry;

export interface IStatusLogVerificationEntry extends Instance<typeof StatusLogVerificationEntry>{}
export interface IStatusLogVerificationEntrySnapshotIn extends SnapshotIn<typeof StatusLogVerificationEntry>{}
export interface IStatusLogVerificationEntrySnapshotOut extends SnapshotOut<typeof StatusLogVerificationEntry>{}
