import React from 'react';
import { observer } from 'mobx-react';
import { StackLayout, Text } from '@wemogy/reactbase';
import IMemberAvatarProps from './IMemberAvatarProps';

const MemberAvatar: React.FC<IMemberAvatarProps> = ({member}) => {

  return (
    <StackLayout horizontal>
      <StackLayout spaceBetween>
        <Text>{member.username}</Text>
        <Text>{member.email}</Text>
      </StackLayout>
    </StackLayout>
  )
}

export default observer(MemberAvatar);
