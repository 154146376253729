import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Modal, useModalStore
} from '@wemogy/reactbase';
import IEditUiElementModalProps from './IEditUiElementModalProps';
import {IUiElement} from '../../dataLayer/models/UiElement';
import AnnotatedFormView from '../../containers/formElements/annotatedFormView/AnnotatedFormView';
import AutocompleteInput from '../../../autocompleteInput/AutocompleteInput';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import { IDataStructureTemplateData } from '../../dataLayer/models/DataStructureTemplateData';

const EditUiElementModal: React.FC<IEditUiElementModalProps> = () => {
  const modalStore = useModalStore();
  const uiElement = modalStore.getActiveParameters<IUiElement>('editUiElement');
  const {
    dataStructureTemplateStore, formStore
  } = useDataAccessStore();

  const form = formStore.activeItem;

  if(!uiElement || !form){
    return null;
  }

  return (
    <Modal modalKey="editUiElement" icon="edit" title="UI Element bearbeiten" large>
      <StackLayout minHeight={{custom: '30vh'}}>
        <AnnotatedFormView description="Text" marginBottom>
          <AutocompleteInput
            placeholder="Text"
            suggestions={dataStructureTemplateStore.getItem(form.id)?.dataStructureTemplateData || []}
            getSuggestionValue={(suggestion: IDataStructureTemplateData): string => {
              return suggestion.destinationName;
            }}
            filter={(suggestion: IDataStructureTemplateData, value: string): boolean => {
              return suggestion.destinationName.toLowerCase().startsWith(value.toLowerCase());
            }}
            value={uiElement.getConfig<any>().text}
            onChange={uiElement.updateConfigText}
            onSuggestionSelected={(suggestion: IDataStructureTemplateData): void => {
              uiElement.updateConfigText(suggestion.destinationName);
            }}
          />
        </AnnotatedFormView>
      </StackLayout>
    </Modal>
  );
};

export default observer(EditUiElementModal);
