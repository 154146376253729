/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createCustomResolverPermissionFeatureHook } from '@wemogy/reactbase-authorization';
import { useMemo } from 'react';
import WorkingPackageRole from '../../../wrappers/formBuilder/dataLayer/enums/roles/WorkingPackageRole';
import workingPackageCustomResolver from './WorkingPackageCustomResolver';

const useWorkingPackagePermissionFeatureHook = createCustomResolverPermissionFeatureHook(WorkingPackageRole, workingPackageCustomResolver);

export default function useWorkingPackagePermissionFeature() {
  const WorkingPackagePermissionFeatureHook = useWorkingPackagePermissionFeatureHook();

  const WorkingPackagePermissionFeatureFunctions = useMemo(() => ({
    hasWorkingPackagePermission: WorkingPackagePermissionFeatureHook.hasPermission,
    hasWorkingPackagePermissions: WorkingPackagePermissionFeatureHook.hasPermissions,
    getWorkingPackagePermissions: WorkingPackagePermissionFeatureHook.getPermissions,
    hasOnActiveWorkingPackagePermission: WorkingPackagePermissionFeatureHook.hasOnActiveItemPermission,
    hasOnActiveWorkingPackagePermissions: WorkingPackagePermissionFeatureHook.hasOnActiveItemPermissions
  }), [WorkingPackagePermissionFeatureHook]);

  return WorkingPackagePermissionFeatureFunctions;
}
