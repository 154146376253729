/* eslint-disable react/no-array-index-key */
import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, invokeEventHandler
} from '@wemogy/reactbase';
import IQrCodeSourceFormProps from './IQrCodeSourceFormProps';
import AnnotatedTextInput from '../../containers/formElements/annotatedTextInput/AnnotatedTextInput';
import AnnotatedFormView from '../../containers/formElements/annotatedFormView/AnnotatedFormView';
import Dropdown from '../../../../components/dropdown/Dropdown';
import ValueSeparator from '../../dataLayer/enums/ValueSeparator';
import IDropdownOption from '../../../../components/dropdown/interfaces/IDropdownOption';

const valueSeparatorDropdownOptions: IDropdownOption[] = [
  {
    label: 'Komma',
    value: ValueSeparator.Comma.toString()
  },
  {
    label: 'Semikolon',
    value: ValueSeparator.Semicolon.toString()
  },
  {
    label: 'Zeilenumbruch',
    value: ValueSeparator.LineBreak.toString()
  }
];

const QrCodeSourceForm: React.FC<IQrCodeSourceFormProps> = ({
  qrCodeSource, addMode, onFinished
}) => {

  return (
    <StackLayout minHeight={50} padding>
      <AnnotatedTextInput placeholder="Name des QR Codes" description="Name des QR Codes" value={qrCodeSource.name} onChange={qrCodeSource.setName} marginBottom />
      <AnnotatedFormView description="Werte Separator" marginBottom>
        <Dropdown
          light
          selectedOption={valueSeparatorDropdownOptions.find(option => option.value === qrCodeSource.valueSeparator.toString())}
          options={valueSeparatorDropdownOptions}
          onOptionSelected={(option): void => {
            qrCodeSource.setValueSeparator(parseInt(option.value));
          }}
        />
      </AnnotatedFormView>
      <AnnotatedTextInput placeholder="Hier können Sie interne Notizen hinterlegen" description="Interne Notizen" value={qrCodeSource.notes} onChange={qrCodeSource.setNotes} multiline marginBottom />
      <StackLayout marginTop={2} horizontal hEnd height={4}>
        {
          qrCodeSource.isValid ? (
            <Button onPress={(): void => {
              invokeEventHandler(onFinished);
            }}
            >
              {addMode ? 'Hinzufügen' : 'Speichern'}
            </Button>
          ): null
        }
      </StackLayout>
    </StackLayout>
  );
};

export default observer(QrCodeSourceForm);
