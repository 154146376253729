import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import {BaseModel} from '@wemogy/reactbase';

const MarkerCodeSubType = types.model({
  name: types.optional(types.string, ''),
  hint: types.optional(types.string, '')
})
  .named('MarkerCodeSubType')
  .actions(self => ({
    setName(name: string): void{
      self.name = name;
    },
    setHint(hint: string): void{
      self.hint = hint;
    },
  }));

const MarkerCode = types.compose(BaseModel, MarkerCodeSubType)
  .named('MarkerCode')
  .views(self => ({
    get payload(): string{
      return `digittool-->${self.id}`;
    }
  }));
export default MarkerCode;

export interface IMarkerCode extends Instance<typeof MarkerCode>{}
export interface IMarkerCodeSnapshotIn extends SnapshotIn<typeof MarkerCode>{}
export interface IMarkerCodeSnapshotOut extends SnapshotOut<typeof MarkerCode>{}
