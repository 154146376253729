import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import { BaseModel } from '@wemogy/reactbase';
import NavigationConfigurationColumn from './NavigationConfigurationColumn';

const NavigationConfigurationSubType = types.model({columns: types.optional(types.array(NavigationConfigurationColumn), []),})
  .named('NavigationConfigurationSubType')
  .actions(self => ({
    addColumn(): void{
      self.columns.push(NavigationConfigurationColumn.create());
    },
    removeColum(index: number): void {
      self.columns.replace(self.columns.filter((_x, i) => i !== index));
    }
  }));

const NavigationConfiguration = types.compose(BaseModel, NavigationConfigurationSubType)
  .named('NavigationConfiguration');
export default NavigationConfiguration;

export interface INavigationConfiguration extends Instance<typeof NavigationConfiguration>{}
export interface INavigationConfigurationSnapshotIn extends SnapshotIn<typeof NavigationConfiguration>{}
export interface INavigationConfigurationSnapshotOut extends SnapshotOut<typeof NavigationConfiguration>{}
