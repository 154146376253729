import { BaseRestApiServiceFactory, RestService } from '@wemogy/reactbase';
import { MainApiUri } from '../../../base/Constants';
import FormService from '../../../ui/wrappers/formBuilder/dataLayer/api/restServices/FormService';
import DataItemService from '../../../ui/wrappers/formBuilder/dataLayer/api/restServices/DataItemService';
import CommentService from '../../../ui/wrappers/formBuilder/dataLayer/api/restServices/CommentService';
import ImportDataService from '../../../ui/wrappers/formBuilder/dataLayer/api/restServices/ImportDataService';
import DatabaseTemplateService from '../../../ui/wrappers/formBuilder/dataLayer/api/restServices/DatabaseTemplateService';
import DataStructureTemplateService from '../../../ui/wrappers/formBuilder/dataLayer/api/restServices/DataStructureTemplateService';
import AttachmentStoreService from '../../../ui/wrappers/formBuilder/dataLayer/api/restServices/AttachmentStoreService';
import AuthenticationService from './services/AuthenticationService';
import InvitationService from './services/InvitationService';
import IdentityAccountService from './services/IdentityAccountService';
import CustomerService from './services/CustomerService';
import RecurringJobService from './services/RecurringJobService'
import BackupService from './services/BackupService';
import MarkerCode, { IMarkerCode } from '../../../ui/wrappers/formBuilder/dataLayer/models/MarkerCode';
import UserStatistic, { IUserStatistic } from '../../../ui/wrappers/formBuilder/dataLayer/models/statistics/UserStatistic';
import UserService from './services/UserService';
import MemberApiService from './services/MemberApiService';
import ArtService from '../../../ui/wrappers/formBuilder/dataLayer/api/restServices/ArtService';
import OutlineApiService from './services/OutlineApiService';
import ProcessingTemplate, { IProcessingTemplate } from '../../../ui/wrappers/formBuilder/dataLayer/models/ProcessingTemplate';
import AttachmentProcessingService from './services/AttachmentProcessingService';

class RestApiServiceFactory extends BaseRestApiServiceFactory{
  public constructor() {
    super(MainApiUri);
  }

  public get customerService(): CustomerService{
    return new CustomerService(this.apiWrapper);
  }

  public get userService(): UserService{
    return new UserService(this.apiWrapper);
  }

  public get globalMarkerCodeService(): RestService<IMarkerCode>{
    return this.getRestService(MarkerCode, 'globalMarkerCode');
  }

  public get processingTemplateService(): RestService<IProcessingTemplate>{
    return this.getRestService(ProcessingTemplate, 'processingTemplate');
  }

  public get userStatisticService(): RestService<IUserStatistic>{
    return this.getRestService(UserStatistic, 'userStatistic');
  }

  public get formService(): FormService{
    return new FormService(this.apiWrapper);
  }

  public getDataItemService(formId: string): DataItemService{
    return new DataItemService(this.apiWrapper, formId);
  }

  public get commentService(): CommentService{
    return new CommentService(this.apiWrapper);
  }

  public get importDataService(): ImportDataService{
    return new ImportDataService(this.apiWrapper);
  }

  public get artService(): ArtService{
    return new ArtService(this.apiWrapper);
  }

  public get databaseTemplateService(): DatabaseTemplateService{
    return new DatabaseTemplateService(this.apiWrapper);
  }

  public get dataStructureTemplateService(): DataStructureTemplateService{
    return new DataStructureTemplateService(this.apiWrapper);
  }

  public get attachmentStoreService(): AttachmentStoreService{
    return new AttachmentStoreService(this.apiWrapper);
  }

  public get authenticationService(): AuthenticationService{
    return new AuthenticationService(this.apiWrapper);
  }

  public get invitationService(): InvitationService{
    return new InvitationService(this.apiWrapper);
  }

  public get identityAccountService(): IdentityAccountService{
    return new IdentityAccountService(this.apiWrapper);
  }

  public get recurringJobService(): RecurringJobService{
    return new RecurringJobService(this.apiWrapper);
  }

  public get backupService(): BackupService{
    return new BackupService(this.apiWrapper);
  }

  public get memberApiService(): MemberApiService{
    return new MemberApiService(this.apiWrapper);
  }

  public get outlineApiService(): OutlineApiService {
    return new OutlineApiService(this.apiWrapper);
  }

  public get attachmentProcessingService(): AttachmentProcessingService {
    return new AttachmentProcessingService(this.apiWrapper);
  }
}

export default new RestApiServiceFactory();
