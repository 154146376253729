import React from 'react';
import { observer } from 'mobx-react';
import { useDrop } from 'react-dnd';
import { useTheme } from '@wemogy/reactbase';
import IBaseDropAreaProps from './IBaseDropAreaProps';
import DroppedInformation from './models/DroppedInformation';
import useRootFormBuilderUiStore from '../../../dataLayer/stores/hooks/UseFormBuilderUiStore';
import DropAreaPlaceholder from '../../../components/dropAreaPlaceholder/DropAreaPlaceholder';

const BaseDropArea: React.FC<IBaseDropAreaProps> = ({
  acceptedTypes, children, placeholder, showAlways, stretch, onlyShowPlaceholderWhenDragging, onDrop
}) => {
  const theme = useTheme();
  const {
    formDesignerStore, formBuilderStore
  } = useRootFormBuilderUiStore();
  const [{ isOverCurrent }, drop] = useDrop({
    accept: acceptedTypes.map(x => x.toString()),
    drop(item: any, monitor) {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        return;
      }
      onDrop(new DroppedInformation(parseInt(item.type), item.referenceKey, item.config));
    },
    collect: (monitor) => ({isOverCurrent: monitor.isOver({ shallow: true })}),
  });

  const background = formDesignerStore.isDragging || showAlways
    ? formDesignerStore.anyOfToolboxElementTypesIsActive(acceptedTypes)
      ? isOverCurrent
        ? theme.colorScheme.success
        : theme.colorScheme.grey500
      : (showAlways && !formDesignerStore.isDragging) ? theme.colorScheme.grey500 : theme.colorScheme.danger
    : theme.colorScheme.transparent;

  return (
    <div
      ref={drop}
      style={{
        minHeight: onlyShowPlaceholderWhenDragging && !formDesignerStore.isDragging ? undefined : children || !formBuilderStore.isEditing ? 0 : 40,
        background,
        flex: stretch ? 1 : undefined,
        height: onlyShowPlaceholderWhenDragging && !formDesignerStore.isDragging ? 0 : undefined,
        overflow:  onlyShowPlaceholderWhenDragging && !formDesignerStore.isDragging ? 'hidden' : undefined
      }}
    >
      {children || ((placeholder && formBuilderStore.isEditing) ? <DropAreaPlaceholder color={background}>{placeholder}</DropAreaPlaceholder> : null )}
    </div>
  );
};

export default observer(BaseDropArea);
