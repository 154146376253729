import { ApiWrapper, RestService } from '@wemogy/reactbase';

export default class MemberApiService extends RestService<any> {
  public constructor(apiWrapper: ApiWrapper) {
    super(apiWrapper, '');
  }

  public async upsertMemberAsync(
    permissionLevelKey: string,
    tenantId: string,
    resourceId: string,
    roleId: string,
    subjectIds: string[]
  ): Promise<void> {
    await this.apiWrapper.put('authorization/member', {
      permissionLevelKey,
      tenantId,
      resourceId,
      roleId,
      subjectIds
    });
  }

  public async deleteMemberAsync(
    permissionLevelKey: string,
    tenantId: string,
    resourceId: string,
    subjectIds: string[]
  ): Promise<void> {
    await this.apiWrapper.delete('authorization/member', undefined, {
      permissionLevelKey,
      tenantId,
      resourceId,
      subjectIds
    });
  }
}
