import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, StackLayout } from '@wemogy/reactbase';
import IAddSubjectProps from './IAddSubjectProps';
import SubjectSearch from '../subjectSearch/SubjectSearch';
import RolesDropdown from '../../rolesDropdown/RolesDropdown';

const AddSubject: React.FC<IAddSubjectProps> = ({
  resourceId, subjects, permissionLevel, useSubjectsAddedHandlerHook
}) => {
  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);
  const [selectedRoleId, setSelectedRoleId] = useState<string | undefined>(undefined);
  const subjectsAddedHandler = useSubjectsAddedHandlerHook();

  const handleAddPress = useCallback((): void => {
    if (!selectedRoleId) {
      // ToDo: show error
      return;
    }
    if (!selectedSubjects.length) {
      // ToDo: show error
      return;
    }
    subjectsAddedHandler(resourceId, selectedSubjects, selectedRoleId)
  }, [resourceId, selectedSubjects, selectedRoleId]);

  console.log('selectedSubjects', selectedSubjects)

    return (
      <StackLayout horizontal marginBottom={2}>
        <StackLayout stretch marginRight>
          <SubjectSearch
            subjects={subjects}
            selectedSubjects={selectedSubjects}
            onSubjectsSelectionChanged={setSelectedSubjects}
          />
        </StackLayout>
        <StackLayout stretch marginRight>
          <RolesDropdown
            permissionLevel={permissionLevel}
            selectedRoleId={selectedRoleId}
            onRoleSelected={setSelectedRoleId}
          />
        </StackLayout>


        <Button onPress={handleAddPress}>Add</Button>
      </StackLayout>
    )
}

export default observer(AddSubject);
