import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import {BaseModel} from '@wemogy/reactbase';

const InvitationSubType = types.model({
  email: types.string,
  userExpiresAt: types.maybe(types.Date),
  globalRoleId: types.optional(types.string, '33333333-3333-3333-3333-333333333333')
})
  .named('InvitationSubType');

const Invitation = types.compose(BaseModel, InvitationSubType)
  .named('Invitation');
export default Invitation;

export interface IInvitation extends Instance<typeof Invitation>{}
export interface IInvitationSnapshotIn extends SnapshotIn<typeof Invitation>{}
export interface IInvitationSnapshotOut extends SnapshotOut<typeof Invitation>{}
