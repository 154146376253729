import { IApiAuthenticationInformation, KeyValueStorage } from '@wemogy/reactbase';
import { applySnapshot } from 'mobx-state-tree';
import autobind from 'autobind-decorator';
import RestApiServiceFactory from '../api/rest/RestApiServiceFactory';
import { IRootAppStore } from '../stores/RootAppStore';
import { IRootDataAccessStore } from '../stores/RootDataAccessStore';
import { IRootUiStore } from '../stores/RootUiStore';
import NavigationManager from '../../navigation/NavigationManager';
import { RootAppStoreKey } from '../../base/Constants';
import RealtimeService from './RealtimeService';

class AuthenticationService{
  public distributeApiAuthenticationInformation(apiAuthenticationInformation: IApiAuthenticationInformation): void{
    RestApiServiceFactory.setApiAuthenticationInformation(apiAuthenticationInformation);
    RestApiServiceFactory.setOnRefreshingTokenFailedHandler((): void => this.logout());
    RealtimeService.updateAccessToken(apiAuthenticationInformation.accessToken);
  }

  private rootAppStore?: IRootAppStore;

  private rootDataAccessStore?: IRootDataAccessStore;

  private rootUiStore?: IRootUiStore;

  public initialize(
    rootAppStore: IRootAppStore,
    rootDataAccessStore: IRootDataAccessStore,
    rootUiStore: IRootUiStore
  ): void{
    this.rootAppStore = rootAppStore;
    this.rootDataAccessStore = rootDataAccessStore;
    this.rootUiStore = rootUiStore;
  }

  @autobind
  public logout(): void {
    if (this.rootAppStore && this.rootDataAccessStore && this.rootUiStore) {
      applySnapshot(this.rootAppStore, {});
      applySnapshot(this.rootDataAccessStore, {});
      applySnapshot(this.rootUiStore, {});
    } else {
      KeyValueStorage.remove(RootAppStoreKey);
    }
    NavigationManager.navigate('/login');
    window.location.reload();
  }
}


export default new AuthenticationService();
