import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import StatusLogEntry from './StatusLogEntry';

const StatusLogAssignEntrySubType = types.model({referenceId: types.maybeNull(types.string)})
  .named('StatusLogAssignEntrySubType');

const StatusLogAssignEntry = types.compose(StatusLogEntry, StatusLogAssignEntrySubType)
  .named('StatusLogAssignEntry');
export default StatusLogAssignEntry;

export interface IStatusLogAssignEntry extends Instance<typeof StatusLogAssignEntry>{}
export interface IStatusLogAssignEntrySnapshotIn extends SnapshotIn<typeof StatusLogAssignEntry>{}
export interface IStatusLogAssignEntrySnapshotOut extends SnapshotOut<typeof StatusLogAssignEntry>{}
