import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useAuthorizationConfig, useRoleStore } from '@wemogy/reactbase-authorization';
import IRolesDropdownProps from './IRolesDropdownProps';
import Dropdown from '../../../components/dropdown/Dropdown';
import IDropdownOption from '../../../components/dropdown/interfaces/IDropdownOption';

const RolesDropdown: React.FC<IRolesDropdownProps> = ({
selectedRoleId, onRoleSelected
}) => {
  const roleStore = useRoleStore();
  const authorizationConfig = useAuthorizationConfig();

  const handleOptionSelected = useCallback((selectedOption: IDropdownOption): void => {
    onRoleSelected(selectedOption.value);
  }, [onRoleSelected]);

  const options = useMemo(() => {
    const customRoles = (roleStore as any).items.map(x => ({
      value: x.id.toString(),
      label: x.name
    }));

    customRoles.push({
      value: authorizationConfig.constants.administratorRoleId,
      label: 'Administrator'
    })

    return _.orderBy(customRoles, x => x.label);
  }, [(roleStore as any).items.length]);

  const selectedOption = useMemo(() => options.find(x => x.value === selectedRoleId), [options, selectedRoleId]);

  const tooltipRenderer = useMemo(() => function TooltipRenderer(roleId: string): JSX.Element{
    const role = (roleStore as any).getItem(roleId);

    if (!role) {
      throw new Error('Role not found for TooltipRenderer')
    }

    return <div></div>
  }, [(roleStore as any)]);

  if (tooltipRenderer === 0 as any) {
    console.log('tooltipRenderer', tooltipRenderer)
  }

  return (
    <Dropdown
      light
      placeholder="Rolle wählen"
      // tooltipComponentRenderer={tooltipRenderer}
      height={4}
      width100
      options={options}
      selectedOption={selectedOption}
      onOptionSelected={handleOptionSelected}
    />
  )
}

export default observer(RolesDropdown);
