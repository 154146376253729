import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, Button, Icon
} from '@wemogy/reactbase';
import IStatisticsJobManagementProps from './IStatisticsJobManagementProps';
import { formatToShortDateTimeString } from '../../wrappers/dateSelector/DateHelpers'
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import RestApiServiceFactory from '../../../dataLayer/api/rest/RestApiServiceFactory'
import RecurringJobInformation from '../../../dataLayer/api/dtos/RecurringJobInformation';
import NotificationManager from '../../wrappers/inAppFeedback/NotificationManager';

const StatisticsJobManagement: React.FC<IStatisticsJobManagementProps> = () => {
  const [recurringJobInformation, setRecurringJobInformation] = useState<RecurringJobInformation | undefined>(undefined);
  const [refreshing, setRefreshing] = useState(false);


  const refreshRecurringJobInformation = (): void => {
    setRefreshing(true);
    RestApiServiceFactory.recurringJobService.get('StatisticService.CalculateStatistics').then(x => {
      setRecurringJobInformation(x.data)
    })
      .finally((): void => {
        setRefreshing(false);
    })
  }

  useEffect(refreshRecurringJobInformation, []);

    return (
      <StackLayout>
        {refreshing ? (<LoadingIndicator />) : (
          <StackLayout horizontal>
            <StackLayout marginRight>
              <Text noTranslation>{`Letzte Ausführung: ${recurringJobInformation?.lastExecution ? formatToShortDateTimeString(recurringJobInformation.lastExecution) : '-'}`}</Text>
              <Text noTranslation>{`Letzte Ausführung Status: ${recurringJobInformation?.lastJobState}`}</Text>
              <Text noTranslation>{`Nächste Ausführung: ${recurringJobInformation?.nextExecution ? formatToShortDateTimeString(recurringJobInformation.nextExecution) : '-'}`}</Text>
            </StackLayout>
            <StackLayout>
              <StackLayout horizontal vCenter spaceBetween>
                <Button onPress={(): void => {
                RestApiServiceFactory.recurringJobService.trigger('StatisticService.CalculateStatistics').then((): void => {
  NotificationManager.success('Der Job wurde erfolgreich getriggert. Die Ausführung kann ein paar Minuten dauern. Der Zeitstempel der letzten Ausführung wird hierdurch nicht geändert.')
})
              }}
                >
                  Jetzt ausführen

                </Button>
                <Icon marginLeft xl refresh onPress={refreshRecurringJobInformation} />
              </StackLayout>
            </StackLayout>
          </StackLayout>
        )}
      </StackLayout>
    )
}

export default observer(StatisticsJobManagement);
