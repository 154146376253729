import {RestService, ApiWrapper, ApiResult} from '@wemogy/reactbase';
import UpdateDataItemArtStatusRequest from '../dtos/art/UpdateDataItemArtStatusRequest';

export default class ArtService extends RestService<any>{
  public constructor(apiWrapper: ApiWrapper){
    super(apiWrapper, 'art/');
  }

  public updateDataItemArtStatus(formId: string, updateDataItemArtStatusRequest: UpdateDataItemArtStatusRequest): Promise<ApiResult<void>>{
    return this.apiWrapper.post(`art/updateStatus/${formId}`, updateDataItemArtStatusRequest);
  }
}
