import {
  RestService, ApiWrapper, JsonPatchDocument, IQueryParameters, ApiResult
} from '@wemogy/reactbase';
import { getSnapshot } from 'mobx-state-tree';
import { MainApiUri, MediaHandlerApiUri } from '../../../../../../base/Constants';
import JsonPatch from '../dtos/patch/JsonPatch';
import { IStatus } from '../../models/status/Status';
import { IDataItem } from '../../models/genericModels/DataItem';
import { IWorkingPackage } from '../../models/WorkingPackage';
import { authFetch } from './QuickHelper';
import ResetToTimestampRequest from '../dtos/ResetToTimestampRequest';
import ResetStatusRequest from '../dtos/ResetStatusRequest';

export function clearDataItemId(dataItemId: string): string{
  return dataItemId.replace('editingDataItem_', '');
}

export default class DataItemService extends RestService<IDataItem>{
  private readonly formId: string;

  public constructor(apiWrapper: ApiWrapper, formId: string){
    super(apiWrapper, `dataItem/${formId}`);
    this.formId = formId;
  }

  public async getCsvExport(realtimeConnectionId: string, onlyFromTheseDataSources: string[] | undefined, onlyFromTheseWorkingPackages: string[] | undefined, onlyTheseDataItems?: string[] | undefined, withModificationSign?: boolean): Promise<string | undefined>{
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const result =  await authFetch(this.apiWrapper, `${MainApiUri}dataItem/export/csv/read`, {
      method: 'POST', headers, body: JSON.stringify({
        formId: this.formId,
        onlyFromTheseWorkingPackages,
        onlyFromTheseDataSources,
        onlyTheseDataItems,
        withModificationSign,
        realtimeConnectionId
      })
    });
    if (result.ok) {
      return result.text();
    }

    return '';
  }

  public async getCsvExportWithKeywordingAndAttachments(
    realtimeConnectionId: string,
    onlyFromTheseDataSources: string[] | undefined,
    onlyFromTheseWorkingPackages: string[] | undefined,
    onlyTheseDataItems?: string[] | undefined,
    withModificationSign?: boolean,
    onlyExportOriginalAttachments?: boolean,
    onlyExportGeneratedAttachments?: boolean,
    attachmentSuffix: string = ''): Promise<string | undefined>{

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const result =  await authFetch(this.apiWrapper, `${MainApiUri}dataItem/export/csv/read/withKeywordingAndAttachments`, {
      method: 'POST', headers, body: JSON.stringify({
        formId: this.formId,
        onlyFromTheseWorkingPackages,
        onlyFromTheseDataSources,
        onlyTheseDataItems,
        withModificationSign,
        realtimeConnectionId,
        onlyExportOriginalAttachments,
        onlyExportGeneratedAttachments,
        attachmentSuffix
      })
    });
    if (result.ok) {
      return result.text();
    }

    return '';
  }

  public getKeywordingCsvExport(onlyFromTheseWorkingPackages: string[] | undefined, onlyTheseDataItems?: string[] | undefined, withModificationSign?: boolean): Promise<Response>{

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return authFetch(this.apiWrapper, `${MainApiUri}dataItem/export/csv/keywording`, {
      method: 'POST', headers, body: JSON.stringify({
        formId: this.formId,
        onlyFromTheseWorkingPackages,
        onlyTheseDataItems,
        withModificationSign
      })
    });
  }

  public getKeywordingImpExport(onlyFromTheseWorkingPackages: string[] | undefined, onlyTheseDataItems?: string[] | undefined, withModificationSign?: boolean): Promise<Response>{

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return authFetch(this.apiWrapper, `${MainApiUri}dataItem/export/imp/keywording`, {
      method: 'POST', headers, body: JSON.stringify({
        formId: this.formId,
        onlyFromTheseWorkingPackages,
        onlyTheseDataItems,
        withModificationSign
      })
    });
  }

  public async queryTotalItemCount(queryParameters: IQueryParameters): Promise<number>{
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const parameters = {filters: encodeURIComponent(JSON.stringify(queryParameters.filters.map(x => getSnapshot(x))))};


    const result = await this.apiWrapper.get<string>(`dataItem/${this.formId}/total`, parameters);

    if (result.ok) {
      return parseInt(result.data);
    }

    return 0;
  }

  public uploadAttachment(formId: string, dataItemId: string, uploadId: string): Promise<Response>{
    dataItemId = clearDataItemId(dataItemId);
    const uri = `${MainApiUri}dataItem/${formId}/upload/${dataItemId}/${uploadId}`;

    return authFetch(this.apiWrapper, uri, {method: 'POST'});
  }

  public analyseAttachment(formId: string, userId: string, uploadId: string): Promise<Response>{
    const uri = `${MediaHandlerApiUri}api/analyseFile/${formId}/${userId}/${uploadId}`;

    return authFetch(this.apiWrapper, uri, {method: 'POST'});
  }

  public analyseAttachmentQuickly(formId: string, userId: string, uploadId: string): Promise<Response>{
    const uri = `${MediaHandlerApiUri}api/analyseFile/quick/${formId}/${userId}/${uploadId}`;

    return authFetch(this.apiWrapper, uri, {method: 'POST'});
  }

  public deleteAttachment(formId: string, dataItemId: string, attachmentId: string): Promise<Response>{
    dataItemId = clearDataItemId(dataItemId);
    const uri = `${MainApiUri}dataItem/attachment/${formId}/${dataItemId}/${attachmentId}`;

    return fetch(uri, {method: 'DELETE'});
  }

  public updateAttachment(formId: string, dataItemId: string, attachmentId: string, isGenerated: boolean): Promise<Response>{
    dataItemId = clearDataItemId(dataItemId);
    const uri = `${MainApiUri}dataItem/attachment/${formId}/${dataItemId}/${attachmentId}`;

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return authFetch(this.apiWrapper, uri, {
      method: 'PATCH', headers, body: JSON.stringify({isGenerated})
    });
  }

  public async setWorkingPackageId(formId: string, dataItemIds: string[], workingPackageId: string | undefined): Promise<ApiResult<IWorkingPackage[]>>{
    const workingPackages = await this.apiWrapper.post<any, IWorkingPackage[]>('dataItem/setWorkingPackageId', {
      formId,
      dataItemIds,
      workingPackageId
    });

    return workingPackages;
  }

  public updateDataItemData(formId: string, dataItemId: string, patch: JsonPatchDocument): Promise<ApiResult<IDataItem>>{
    dataItemId = clearDataItemId(dataItemId);

    const jsonPatchDocument = patch.filter(x => x.path.startsWith('/data/')).map(x => new JsonPatch(x.op, x.path.split('/data/').pop() || '', JSON.stringify(x.value)));

    return this.apiWrapper.post<any, IDataItem>(`dataItem/${formId}/${dataItemId}`, jsonPatchDocument);
  }

  public async getDataItemsForSample(formId: string, workingPackageId: string): Promise<ApiResult<IDataItem[]>>{
    const uri = `dataItem/${formId}/sample/${workingPackageId}`;
    const dataItems = await this.apiWrapper.get<IDataItem[]>(uri);
    return dataItems;
  }

  public putStatus(formId: string, dataItemId: string, status: IStatus): Promise<Response>{
    dataItemId = clearDataItemId(dataItemId);
    const uri = `${MainApiUri}dataItem/status/${formId}/${dataItemId}`;
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return fetch(uri, {
      method: 'POST',
      headers,
      body: JSON.stringify(getSnapshot(status))
    });
  }

  public async getAttachmentExport(): Promise<string | undefined>{
    const result = await authFetch(this.apiWrapper, `${MainApiUri}dataItem/${this.formId}/export/attachments`, { method: 'GET' });

    if (result.ok) {
      return result.text();
    }

    return '';
  }

  public deleteAllDataItems(formId: string): Promise<Response>{
    const uri = `${MainApiUri}dataItem/deleteAllDataItems/${formId}`;
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return fetch(uri, {
      method: 'DELETE',
      headers,
    });
  }

  public deleteDataItems(formId: string, entityIds: string[]): Promise<Response>{
    const uri = `${MainApiUri}dataItem/deleteDataItems/${formId}`;
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return fetch(uri, {
      method: 'DELETE',
      headers,
      body: JSON.stringify({entityIds})
    });
  }

  public duplicateDataItem(dataItemId: string): Promise<ApiResult<IDataItem>>{

    return this.get(`duplicate/${dataItemId}`);
  }

  public addEmptyDataItem(): Promise<ApiResult<IDataItem>>{

    return this.get('addEmpty');
  }

  public resetToTimestamp(dataItemId: string, timestamp: Date): Promise<ApiResult<void>>{
    return this.apiWrapper.post(`dataItem/${this.formId}/resetToTimestamp`, new ResetToTimestampRequest(dataItemId, timestamp.getTime()));
  }

  public resetStatus(dataItemId: string, popIndexed: boolean, popDataCollected: boolean, popDataQualityAssured: boolean): Promise<ApiResult<void>>{
    return this.apiWrapper.post(`dataItem/${this.formId}/resetStatus`, new ResetStatusRequest(dataItemId, popIndexed, popDataCollected, popDataQualityAssured));
  }

  public updateNavigationPropertiesOfDataItems(): Promise<ApiResult<IDataItem>>{
    return this.apiWrapper.post(`dataItem/${this.formId}/updateNavigationPropertiesOfDataItems`, {});
  }
}
