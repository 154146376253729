import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import { BaseModel } from '@wemogy/reactbase';
import FileFormat, {FileFormatType} from '../enums/FileFormat';
import PdfMetadata from './PdfMetadata';
import ProcessingState, { ProcessingStateType } from '../enums/ProcessingState';

const AttachmentSubType = types.model({
  dataItemId: types.string,
  filename: types.string,
  fileFormat: types.optional(FileFormatType, FileFormat.Unknown),
  extension: types.optional(types.string, ''),
  pdfMetadata: types.maybe(PdfMetadata),
  isGenerated: types.optional(types.boolean, false),
  processingState: types.optional(ProcessingStateType, ProcessingState.None),
})
  .named('AttachmentSubType')
  .views(self => ({
    get isPdf(): boolean {
      return self.fileFormat === FileFormat.Pdf;
    },
    get isViewable(): boolean {
      return !self.isGenerated || self.processingState === ProcessingState.Succeeded;
    },
    get isProcessingStateFailed(): boolean {
      return self.processingState === ProcessingState.Failed;
    },
    get isProcessingStatePending(): boolean {
      return self.processingState === ProcessingState.Pending;
    },
    get processingStateDescription(): string {
      switch (self.processingState) {
        case ProcessingState.Pending:
          return 'In Warteschlange';
        case ProcessingState.Processing:
          return 'In Bearbeitung';
        case ProcessingState.Succeeded:
          return 'Erfolgreich';
        case ProcessingState.Failed:
          return 'Fehlgeschlagen';
        default:
          return '';
      }
    }
  }))
  .actions(self =>({
    setIsGenerated(isGenerated: boolean): void {
      self.isGenerated = isGenerated;
    }
  }));

const Attachment = types.compose(BaseModel, AttachmentSubType)
  .named('Attachment');
export default Attachment;

export interface IAttachment extends Instance<typeof Attachment>{}
export interface IAttachmentSnapshotIn extends SnapshotIn<typeof Attachment>{}
export interface IAttachmentSnapshotOut extends SnapshotOut<typeof Attachment>{}
