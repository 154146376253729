import moment from 'moment';

export function dateTimeNow(): Date{
  return new Date();
}

export function todayBegin(): Date{
  const now = new Date();
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);
  return now;
}

export function formatToSlimDateString(date: Date): string{
  return moment(date).format('DD.MM');
}

export function formatToMonthString(date: Date): string{
  return moment(date).format('MMMM');
}

export function formatToShortDateString(date: Date): string{
  return moment(date).format('DD.MM.YY');
}

export function formatToDateString(date: Date): string{
  return moment(date).format('DD.MM.YYYY');
}

export function formatToShortDateTimeString(date: Date): string{
  return moment(date).format('DD.MM.YY HH:mm');
}

export function formatSecondsAsDuration(seconds: number): string{
  return  moment.utc(seconds*1000).format('HH:mm:ss');
}


function addDaysToDate(date: Date, days: number): Date {
  const newDate = new Date(date.valueOf());
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

export function getDates(startDate: Date, stopDate: Date): Date[] {
  const dateArray: Date[] = [];
  let currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(new Date (currentDate));
    currentDate = addDaysToDate(currentDate, 1);
  }
  return dateArray;
}

export function numberOfWeeksBetween(d1: Date, d2: Date): number {
  // @ts-ignore
  return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
}
