import React from 'react';
import { observer } from 'mobx-react';
import {
  isEmptyOrWhiteSpace, Text, StackLayout
} from '@wemogy/reactbase';
import IValueRendererProps from './IValueRendererProps';
import DataType from '../../wrappers/formBuilder/dataLayer/enums/DataType';

const ValueRenderer: React.FC<IValueRendererProps> = ({
  dataType, value
}) => {
  if (!value || isEmptyOrWhiteSpace(value)) {
    return <Text />;
  }

  if (dataType === DataType.StringList) {
    console.log('valuevalue', value);
    const values: string[] = JSON.parse(JSON.parse(value)).map((x: string) => JSON.parse(x));

    return (
      <StackLayout>
        {values.map((v, i): JSX.Element => (
          <Text key={i} value>{v}</Text>
        ))}
      </StackLayout>
    );
  }

  return (
    <Text value customStyle={{whiteSpace: 'pre'}}>{JSON.parse(value)}</Text>
  );
};

export default observer(ValueRenderer);
