/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createSingleTenantPermissionFeatureHook } from '@wemogy/reactbase-authorization';
import { useMemo } from 'react';
import GlobalRole from '../../../../dataLayer/enums/GlobalRole';

const useGlobalPermissionFeatureHook = createSingleTenantPermissionFeatureHook(GlobalRole);

export default function useGlobalPermissionFeature() {
  const globalPermissionFeatureHook = useGlobalPermissionFeatureHook();

  const globalPermissionFeatureFunctions = useMemo(() => ({
    hasGlobalPermission: globalPermissionFeatureHook.hasPermission,
    hasGlobalPermissions: globalPermissionFeatureHook.hasPermissions,
    getGlobalPermissions: globalPermissionFeatureHook.getPermissions
  }), [globalPermissionFeatureHook]);

  return globalPermissionFeatureFunctions;
}
