import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  mount,compose, route
} from 'navi';
import {
  Router, View, NotFoundBoundary, useNavigation
} from 'react-navi';
import {generateDomId, useModalStore} from '@wemogy/reactbase';
import NavigationManager from '../NavigationManager';
import DatabaseBuilder from '../../ui/screens/databaseBuilder/DatabaseBuilder';
import CollectorModeScreen from '../../ui/screens/collectorMode/CollectorModeScreen';
import CustomerOverviewScreen from '../../ui/screens/customerOverview/CustomerOverviewScreen';
import ArtScreen from '../../ui/screens/artScreen/ArtScreen';
import LoginScreen from '../../ui/screens/account/login/LoginScreen';
import AccountManagementScreen from '../../ui/screens/account/management/AccountManagementScreen';
import CustomerStatisticsScreen from '../../ui/screens/statisticsScreens/customerStatisticsScreen/CustomerStatisticsScreen';
import StatisticsScreen from '../../ui/screens/statisticsScreens/statisticsScreen/StatisticsScreen';
import FormStatisticsScreen from '../../ui/screens/statisticsScreens/formStatisticsScreen/FormStatisticsScreen';
import InvitationScreen from '../../ui/screens/account/invitation/InvitationScreen';
import { IRootAppStore } from '../../dataLayer/stores/RootAppStore';
import useAppStore from '../../dataLayer/stores/hooks/UseAppStore';
import AppStartup from '../../initalization/appStartup/AppStartup';
import NotificationManager from '../../ui/wrappers/inAppFeedback/NotificationManager';
import GlobalMarkerCodeScreen from '../../ui/screens/globalMarkerCodeScreen/GlobalMarkerCodeScreen';
import IAppContainerProps from './IAppContainerProps';
import ProcessingTemplateScreen from '../../ui/screens/processingTemplateScreen/ProcessingTemplateScreen';


function getRoutes(appStore: IRootAppStore, modalStore: any): any{
  const authRoute =  (protectedComponentResolver: (request: any) => JSX.Element, trackingAction?: (request: any) => void): any =>  route((request: any): any => {
    if(!appStore.activeUserStore.isAuthenticated){
      // ToDo: redirect the user after login
      window.location.href = '/login';
      return undefined;
    }

    if (trackingAction) {
      // trackingAction(request);
    }

    return {
      title: 'DigitTool',
      view:  (
        <AppStartup>
          {protectedComponentResolver(request)}
        </AppStartup>
      ),
    };
  });

  const callbackRoute = (request: any, topic: string, requiresAuthentication: boolean, action: () => void): any => {
    if (request.params.topic !== topic) {
      return undefined;
    }

    if (requiresAuthentication && !appStore.activeUserStore.isAuthenticated) {
      NotificationManager.info('Bitte melden Sie sich zunächst an');
      return {
        title: 'DigitTool',
        view: <LoginScreen />
      };
    }

    setTimeout(() => {
      action();
    }, 1000);
    return {
      title: 'DigitTool',
      view: requiresAuthentication ? <CustomerOverviewScreen /> : <LoginScreen />
    };
  };

  const routes = {};

  routes['/databaseBuilder/:customerId/:id'] = authRoute((req: any) => (<DatabaseBuilder formId={req.params.id} customerId={req.params.customerId} />));

  routes['/callback/:topic'] = route((req: any) => {
    console.log(req);
    return callbackRoute(req, 'resetPassword', false, (): void => modalStore.openModal('resetPassword', req.query))
      || callbackRoute(req, 'emailChanged', false, (): void => NotificationManager.success('Ihre E-Mail wurde erfolgreich geändert.'));
  });

  routes['/database/:customerId/:id'] = authRoute((req: any) => (
    <CollectorModeScreen key={generateDomId()} formId={req.params.id} customerId={req.params.customerId} />
  ));

  routes['/customerOverview'] = authRoute(() => <CustomerOverviewScreen />);

  routes['/art/:customerId/:id'] = authRoute((req: any) => <ArtScreen formId={req.params.id} customerId={req.params.customerId} />);

  routes['/accountManagement'] = authRoute(() => <AccountManagementScreen />);

  routes['/statistics'] = route((req: any) => ({
    title: req.params.id,
    view: <StatisticsScreen />
  }));

  routes['/globalMarkerCode'] = authRoute(() => (<GlobalMarkerCodeScreen />));
  routes['/processingTemplate'] = authRoute(() => (<ProcessingTemplateScreen />));

  routes['/statistics/customer/:id'] = route((req: any) => ({
    title: req.params.id,
    view: <CustomerStatisticsScreen customerId={req.params.id} />
  }));

  routes['/statistics/form/:id'] = route((req: any) => ({
    title: req.params.id,
    view: <FormStatisticsScreen formId={req.params.id} />
  }));


  routes['/login'] = route((req: any) => ({
    title: req.params.id,
    view: <LoginScreen />
  }));

  routes['/invitation/:id/:token'] = route((req: any) => ({
    title: req.params.id,
    view: <InvitationScreen invitationId={req.params.id} token={req.params.token} />
  }));

  routes['/'] = route((req: any) => ({
    title: req.params.id,
    view: <LoginScreen />
  }));


  return routes;
}

const NavigationConnector: React.FC = () => {
  const navigation = useNavigation();
  NavigationManager.setNavigation(navigation);
  return null;
};

const AppContainer: React.FC<IAppContainerProps> = ({onModalStore}) => {
  const appStore = useAppStore();
  const modalStore = useModalStore();

  useEffect((): void => {
    onModalStore(modalStore)
  }, []);

  return (
    <Router routes={
      compose(
        mount(getRoutes(appStore, modalStore)))
    }
    >
      <NavigationConnector />
      <NotFoundBoundary render={(): JSX.Element =>
        (<h1>Not found</h1>)}
      >
        <View />
      </NotFoundBoundary>
    </Router>
  );
};

export default observer(AppContainer);
