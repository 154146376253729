import {ITheme} from '@wemogy/reactbase';
import {getColorPalette} from '../helpers/ColorHelpers';

const designGridBase = 8;
const borderDefault = 3;
const borderRadiusDefault = 1;

const subheadFontSize = 10;
const bodyFontSize = 12;

const fontWeightBold = 800;
const bodyFontFamily = 'Inter';

/**
greys
100: "#fafafa"
200: "#f5f5f5"
300: "#f0f0f0"
400: "#ebebeb"
500: "#e6e6e6"
600: "#b8b8b8"
700: "#8a8a8a"
800: "#5c5c5c"
900: "#2e2e2e"

 */
const greys = getColorPalette('#e6e6e6');

/**
greens
100: "#daf1e4"
200: "#b6e4c9"
300: "#91d6ae"
400: "#6dc993"
500: "#48bb78"
600: "#3a9660"
700: "#2b7048"
800: "#1d4b30"
900: "#0e2518"
 */
const greens = getColorPalette('#48BB78');


const DigitToolDefaultTheme: ITheme = {
  colorScheme: {
    // #region Greys
    grey100: greys['100'],
    grey200: greys['200'],
    grey300: greys['300'],
    grey400: greys['400'],
    grey500: greys['500'],
    grey600: greys['600'],
    grey700: greys['700'],
    grey800: greys['800'],
    grey900: greys['900'],
    // #endregion
    // #region greens
    green100: greens['100'],
    green200: greens['200'],
    green300: greens['300'],
    green400: greens['400'],
    green500: greens['500'],
    green600: greens['600'],
    green700: greens['700'],
    green800: greens['800'],
    green900: greens['900'],
    // #endregion
    black: '#333333',
    danger: '#F56565',
    grey: '#808080',
    greyDark: '#696969',
    greyDarker: '#A9A9A9',
    greyLight: '#DCDCDC',
    greyLighter: '#D3D3D3',
    info: 'blue',
    primary: '#467b3e',
    quaternary: 'orange',
    secondary: 'red',
    success: '#48BB78',
    tertiary: 'orange',
    transparent: 'transparent',
    warning: 'yellow',
    white: '#ffffff',
  },
  designGrid: {
    base: designGridBase,
    border: {
      default: borderDefault,
      xxs: borderDefault * 0.125,
      xs: borderDefault * 0.25,
      sm: borderDefault * 0.5,
      lg: borderDefault * 1.5,
      xl: borderDefault * 2,
      xxl: borderDefault * 2.125,
    },
    borderRadius: {
      default: borderRadiusDefault,
      xxs: borderRadiusDefault * 0.125,
      xs: borderRadiusDefault * 0.25,
      sm: borderRadiusDefault * 0.5,
      lg: borderRadiusDefault * 1.5,
      xl: borderRadiusDefault * 2,
      xxl: borderRadiusDefault * 2.125,
    },
    margin: {
      default: 1,
      xxs: designGridBase * 0.125,
      xs: designGridBase * 0.25,
      sm: designGridBase * 0.5,
      lg: designGridBase * 1.5,
      xl: designGridBase * 2,
      xxl: designGridBase * 2.125,
    },
    padding: {
      default: 1,
      xxs: designGridBase * 0.125,
      xs: designGridBase * 0.25,
      sm: designGridBase * 0.5,
      lg: designGridBase * 1.5,
      xl: designGridBase * 2,
      xxl: designGridBase * 2.125,
    },
    breakpoints: [
      {
        min: 800,
        max: 1000,
      },
    ],
  },
  typography: {
    fontFamilies: {
      default: bodyFontFamily,
      body: bodyFontFamily,
      primary: 'monospace',
      secondary: 'notoserif',
    },
    fontSizes: {
      default: bodyFontSize,
      body: bodyFontSize,
      caption: 12,
      largeTitle: 22,
      subhead: subheadFontSize,
      title: 18,
    },
    fontWeights: {
      default: 500,
      bold: fontWeightBold,
      light: 200,
      regular: 400,
      semibold: 600,
      thin: 100,
    },
  },
  containerThemes: {
    annotatedView: {
      base: {
        /*    fontFamily: 'body',
        fontSize: subheadFontSize,
        fontWeight: fontWeightBold,
        fontColor: 'lightgreen', */
        backgroundColor: 'grey',
        iconPosition: 'right',
        iconVerticalAlign: 'center',
      },
    },
    autocompleteInput: {
      base: {
        textInputTheme: {},
        suggestionWrapperTheme: {},
      },
    },
  },
  coreThemes: {
    button: {
      base: {
        fontColor: 'black',
        borderColor: 'grey800',
        height: 4,
        borderRadius: 0.5,
      },
      danger: {backgroundColor: 'danger'},
    },
    text: {
      primary: {fontColor: 'primary'},
      base: {fontColor: 'black'},
      title: {
        fontSize: 'title',
        fontWeight: 'semibold',
        fontColor: 'primary',
      },
      lighterTitle: {
        fontSize: 'title',
        fontWeight: 'semibold',
        fontColor: 'grey900',
      },
      largeTitle: {
        fontSize: 'largeTitle',
        fontWeight: 'semibold',
        fontColor: 'primary',
      },
      lightTitle: {
        fontSize: 'title',
        fontWeight: 'semibold',
        fontColor: 'grey100',
      },
      label: {
        fontColor: 'grey800',
        fontSize: 'caption',
      },
      textIcon: {
        fontColor: 'primary',
        fontWeight: 'bold',
        fontSize: {custom: 14},
      },
      danger: {fontColor: 'danger'},
      labelLarge: {fontSize: 'title'},
      light: {fontColor: 'grey100'},
      caption: {
        base: {
          fontSize: 'caption',
          textTransform: 'uppercase',
        },
        primary: {fontColor: 'primary'},
      },
      value: {fontWeight: 'bold'},
      profileInitials: {
        fontColor: 'green700',
        fontFamily: 'primary',
        fontWeight: 'bold',
      },
      invalid: {fontColor: 'danger'},
      valid: {fontColor: 'success'},
    },
    textInput: {
      base: {
        borderColor: 'grey500',
        height: 4,
        borderRadius: 0.5,
        fontColor: 'black',
        placeholderTextColor: 'grey700',
        width100: true,
      },
      multiline: {
        multiline: true,
        numberOfLines: 4,
      },
      numeric: {keyboardType: 'numeric'},
      w50: {width: {custom: '50%'}},
      w33: {width: {custom: '33%'} },
      light: {
        base: {
          backgroundColor: 'white',
          fontColor: 'grey700',
          placeholderTextColor: 'grey500',
          borderColor: 'grey400',
          borderRadius: 0.125,
          padding: true,
          height: 5,
          fontWeight: 'semibold',
        },
        password: {textContentType: 'password'},
      },
      validationMode: {
        base: {},
        equals: {backgroundColor: 'success'},
        unequals: {backgroundColor: 'danger'},
        undefined: {backgroundColor: 'warning'},
      },
    },
    image: {base: {}},
  },
  advancedControlsThemes: {
    progressBar: {base: {}},
    circularProgressBar: {base: {}},
    bottomSheet: {base: {}},
    checkbox: {base: {}},
    breadcrumb: {
      base: {
        activeTextVariation: 'valid',
        linkTextVariation: 'primary',
        separatorIcon: 'chevronRight',
        separatorIconVariation: 'lLight',
        homeIcon: 'home',
        homeIconVariation: 'xlSuccess',
        marginBottom: true
      }
    },
    azureStorageContainer: {
      base: {
        virtualDirectoryIcon: 'folder',
        fileIcon: 'insertDriveFile',
        showVirtualDirectoryIcon: 'chevronRight',
        showFileIcon: 'visibility',
        iconVariation: 'xl',
        textVariation: 'label'
      } as any
    },
    contextMenu:{base: {}},
    segmentedControl: {base: {}},
    tree: {base: {}},
    icon: {
      base: {
color: 'primary', size: 3
},
      xl: {},
      xlSuccess: {color: 'success'},
      xlDanger: { color: 'danger' },
      lLight: {
        size: 2,
        color: 'grey300'
      },
      mapMarker: {size: 4}
    },
    sidebar: { base: {} },
    map: {
      base: {
        markerIcon: 'editLocation',
        markerIconVariation: 'mapMarker',
        gpsPositionIcon: 'gpsFixed',
        gpsPositionIconVariation: 'mapMarker'
      } as any
    },
    camera: {
      base: {
        iconVariation: 'xl',
        frontCameraIcon: 'cameraFront',
        rearCameraIcon: 'cameraRear',
        takePictureIcon: 'addAPhoto',
        closeCameraIcon: 'close',
        confirmTakenPictureIcon: 'done',
        discardTakenPictureIcon: 'refresh'
      }
    },
    addFile: {base: {}},
    modal: {
      base: {
        container: {padding: true},
        content: {
          padding: true,
          minHeight: 60
        },
        header: {
          padding: true,
          titleVariation: 'title',
          closeTextVariation: 'caption',
          saveTextVariation: 'primary',
          iconVariation: 'xl',
          closeIcon: 'close',
          closeIconVariation: 'xlSuccess',
        },
      },
      large: { container: { width100: true } },
      big: {
        container: { width: '80vw'  },
        content: {minHeight: '80vh'}
      }
    }
  },
  layoutThemes: {
    scrollView: {base: {}},
    stackLayout: {base: {}},
    table: { base: {} },
    grid: { base: {} },
    sectionGrid: {
      base: {
        sectionHeader: {
          height: 3,
          titleTextVariation: 'title'
        }
      }
    }
  },
  pluginThemes: {
    authorization: {
      permissionListItem: {
        enabledTextVariation: 'title',
        disabledTextVariation: 'lighterTitle',
        enabledColor: 'green200',
        disabledColor: 'grey200',
      },
      roleListItem: {
        editIcon: 'edit',
        editIconVariation: 'xlPrimary'
      }
    } as any
  }
};

export default DigitToolDefaultTheme;
