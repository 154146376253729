import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Accordion as ReactAccordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import IAccordionProps from './IAccordionProps';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import AccordionConfig from '../../../dataLayer/models/layoutConfigs/AccordionConfig';
import LayoutDropArea from '../../dropAreas/layoutDropArea/LayoutDropArea';
import LayoutRenderer from '../../toolboxElementTypeRenderers/layoutRenderer/LayoutRenderer';

const Accordion: React.FC<IAccordionProps> = ({
  layout, readonlySections
}) => {
  const [forceReRender, setForceReRender] = useState(new Date());

  const config = layout.getConfig<AccordionConfig>();

  if (!config) {
    return null;
  }
console.log('config', config, layout.layouts, forceReRender)
  return (
    <ReactAccordion allowZeroExpanded allowMultipleExpanded={config.allowMultipleExpanded}>
      {config.sections.map((section, i) => {
        const subLayout = layout.layouts.find(x => x.orderId === i);

        return (
          <AccordionItem key={section}>
            <AccordionItemHeading>
              <AccordionItemButton>
                {section}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <LayoutDropArea onDrop={(droppedInformation): void => {
                layout.putLayout(i, droppedInformation.referenceKey as any, droppedInformation.config)
                setForceReRender(new Date());
              }}
              >
                {
                  subLayout ? (
                    <LayoutRenderer
                      layout={subLayout as any}
                      readonlySections={readonlySections}
                      onDelete={(): void => {
                        layout.removeLayout(subLayout);
                      }}
                    />
                  ) : null
                }
              </LayoutDropArea>
            </AccordionItemPanel>
          </AccordionItem>
        );
      })}
    </ReactAccordion>
  )
}

export default observer(Accordion);
