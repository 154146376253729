import {
  ApiResult, ApiWrapper, RestService
} from '@wemogy/reactbase';

export default class OutlineApiService extends RestService<any> {
  public constructor(apiWrapper: ApiWrapper){
    super(apiWrapper, 'outline');
  }

  public update(
    formId: string,
    dataItemId: string,
    attachmentId: string,
    outline: any): Promise<ApiResult<void>>{
    return this.apiWrapper.put(`${this.restEndpoint}/${formId}/${dataItemId}/${attachmentId}`, outline);
  }
}
