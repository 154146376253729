import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { StackLayout, Text } from '@wemogy/reactbase';
import IUserStatisticsProps from './IUserStatisticsProps';
import useDataAccessStore from '../../../../dataLayer/stores/hooks/UseDataAccessStore';
import { IUserStatistic } from '../../../wrappers/formBuilder/dataLayer/models/statistics/UserStatistic';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import TimePeriodStatisticsChart from '../../timePeriodStatisticsChart/TimePeriodStatisticsChart';

const UserStatistics: React.FC<IUserStatisticsProps> = () => {
  const {
    userStatisticStore, userStore
  } = useDataAccessStore();
  const [userStatistics, setUserStatistics] = useState<IUserStatistic | undefined>(undefined);


  useEffect((): void => {
    userStore.query();
    userStatisticStore.query().then((): void => {
      setUserStatistics(userStatisticStore.items[0]);
    })
  }, []);

  if (!userStatistics) {
    return (
      <StackLayout>
        <LoadingIndicator />
      </StackLayout>
    )
  }

  console.log(userStatistics);

  return (
    <StackLayout>
      <Text title>User Statistiken</Text>
      {userStatistics.personalUserStatistics.map(pus => (
        <StackLayout key={pus.userId}>
          <Text labelLarge>{userStore.getItem(pus.userId)?.fullname}</Text>
          <TimePeriodStatisticsChart items={[
            {
              label: 'Indexiert',
              timePeriodStatistics: pus.numberOfIndexedFields
            },
            {
              label: 'Erfasst',
              timePeriodStatistics: pus.numberOfCollectedFields
            },
            {
              label: 'Qualitätsgesichert',
              timePeriodStatistics: pus.numberOfQualityAssuredFields
            }
          ]}
          />
        </StackLayout>
      ))}
    </StackLayout>
  )
}

export default observer(UserStatistics);
