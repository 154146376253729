import { Instance } from 'mobx-state-tree';
import { enumerationIntValuesType } from '@wemogy/reactbase';

enum ValidationRule {
  IsOneOf = 1,
  EqualsRegEx = 2,
  IsBetween = 4
}

export const ValidationRuleType = enumerationIntValuesType(ValidationRule);

export default ValidationRuleType;

export type IValidationRuleType = Instance<typeof ValidationRuleType>;
