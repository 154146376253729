/* eslint-disable no-await-in-loop */
import React from 'react';
import { observer } from 'mobx-react';
import { Item } from 'react-contexify';
import {
  Text, StackLayout, Icon, useContextMenuStore, ContextMenu, useModalStore
} from '@wemogy/reactbase';
import { ExcelApi, ExcelFeature } from '@wemogy/reactbase-office-js';
import IDataItemContextMenuProps from './IDataItemContextMenuProps';
import { confirmDialogYesNoDanger } from '../../../dialogs/DialogManager';
import useDataItemStore from '../../dataLayer/stores/hooks/UseDataItemStore';
import { IDataItem } from '../../dataLayer/models/genericModels/DataItem';
import RestApiServiceFactory from '../../../../../dataLayer/api/rest/RestApiServiceFactory';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import NotificationManager from '../../../inAppFeedback/NotificationManager';
import { clearDataItemId } from '../../dataLayer/api/restServices/DataItemService';
import useExportHandler from '../../hooks/UseExportHandler';
import RealtimeService from '../../../../../dataLayer/services/RealtimeService';
import PdfMetadata from '../../dataLayer/models/PdfMetadata';
import IAnalyseAttachmentResultParameters from '../../modals/analyseResultModal/interfaces/IAnalyseAttachmentResultParameters';
import FormPermissionFeature from '../../../../plugins/authorization/permissionFeatures/FormPermissionFeature';
import FormOrWorkingPackagePermissionFeature from '../../../../plugins/authorization/permissionFeatures/FormOrWorkingPackagePermissionFeature';
import IExportDataModalParameters from '../../modals/exportDataModal/IExportDataModalParameters';

const DataItemContextMenu: React.FC<IDataItemContextMenuProps> = () => {
  const modalStore = useModalStore();
  const contextMenuStore = useContextMenuStore();
  const { formStore } = useDataAccessStore();
  const {handleExportResult} = useExportHandler();

  const dataItemStore = useDataItemStore();
  const { activeItem } = formStore;

  if (!dataItemStore || !activeItem) {
    return null;
  }

  const dataItem = contextMenuStore.getActiveParameters<IDataItem>('dataItem');

  const items: IDataItem[] = (dataItemStore.selectedItems.length ? dataItemStore.selectedItems : [dataItem]) as any;

  return (
    <ContextMenu contextMenuKey="dataItem">
      <FormPermissionFeature requiredPermission="CanManageWorkingPackages">
        <Item onClick={(): void => {
          modalStore.openModal('assignWorkingPackage', items);
        }}
        >
          <StackLayout vCenter padding horizontal>
            <Icon marginRight size={2} workOutline />
            <Text>Zu einem Arbeitspaket zuordnen</Text>
          </StackLayout>
        </Item>
      </FormPermissionFeature>
      <FormPermissionFeature requiredPermission="CanManageWorkingPackages">
        <Item onClick={(): void => {
          const message = items.length === 1 ? 'Wollen Sie wirklich die Zuweisung des Datensatzes aufheben?' : `Wollen Sie wirklich die Zuweisung der ${dataItemStore.selectedItems.length} Datensätze aufheben?`;
          confirmDialogYesNoDanger(message, (): void => {
            modalStore.openModal('loadingOverlay', 'Die Zuweisung wird aufgehoben');

            (async (): Promise<void> => {
              const apiResult = await RestApiServiceFactory.getDataItemService(activeItem.id).setWorkingPackageId(activeItem.id, items.map(x => x.id), undefined);
              if (apiResult.ok) {
                activeItem.updateWorkingPackages(apiResult.data);
                items.forEach(item => item.setWorkingPackageId(null));
                NotificationManager.success('Die Zuweisung wurde erfolgreich aufgehoben');
              } else {
                NotificationManager.error('Beim Zuweisen ist ein Fehler aufgetreten');
              }
              modalStore.closeModal();
            })();
          });
        }}
        >
          <StackLayout vCenter padding horizontal>
            <Icon marginRight size={2} clearAll />
            <Text>Keinem Arbeitspaket zuordnen</Text>
          </StackLayout>
        </Item>
      </FormPermissionFeature>
      <FormOrWorkingPackagePermissionFeature requiredPermission="CanExportDataItems">
        <Item onClick={(): void => {
          (async (): Promise<void> => {
            try {
              if (!RealtimeService.connectionId) {
                NotificationManager.error('Es besteht keine Verbindung zum Echtzeit Service, bitte laden Sie die Seite neu.');
                return;
              }
              modalStore.openModal('loadingOverlay', 'Die Daten werden exportiert und heruntergeladen, einen Moment bitte.');

              await RestApiServiceFactory.getDataItemService(activeItem.id)
                .getCsvExport(RealtimeService.connectionId, [] ,[], items.map(x => x.id));
            } catch {
              NotificationManager.error('Beim Export ist etwas schief gelaufen. ');
              modalStore.closeModal();
            }
          })();
        }}
        >
          <StackLayout vCenter padding horizontal>
            <Icon marginRight size={2} getApp />
            <Text>Datensatz exportieren</Text>
          </StackLayout>
        </Item>
      </FormOrWorkingPackagePermissionFeature>
      <FormOrWorkingPackagePermissionFeature requiredPermission="CanExportDataItems">
        <Item onClick={(): void => {
          (async (): Promise<void> => {
            try {
              if (!RealtimeService.connectionId) {
                NotificationManager.error('Es besteht keine Verbindung zum Echtzeit Service, bitte laden Sie die Seite neu.');
                return;
              }
              modalStore.openModal('loadingOverlay', 'Die Daten werden exportiert und heruntergeladen, einen Moment bitte.');

              await RestApiServiceFactory.getDataItemService(activeItem.id)
                .getCsvExport(RealtimeService.connectionId, [] ,[], items.map(x => x.id), true);
            } catch {
              NotificationManager.error('Beim Export ist etwas schief gelaufen. ');
              modalStore.closeModal();
            }
          })();
        }}
        >
          <StackLayout vCenter padding horizontal>
            <Icon marginRight size={2} getApp />
            <Text>Datensatz mit Markierung exportieren</Text>
          </StackLayout>
        </Item>
      </FormOrWorkingPackagePermissionFeature>
      <FormOrWorkingPackagePermissionFeature requiredPermission="CanExportDataItems">
        <ExcelFeature>
          <Item onClick={(): void => {
          (async (): Promise<void> => {
            try {
              if (!RealtimeService.connectionId) {
                NotificationManager.error('Es besteht keine Verbindung zum Echtzeit Service, bitte laden Sie die Seite neu.');
                return;
              }
              modalStore.openModal('loadingOverlay', 'Die Daten an Excel exportiert, einen Moment bitte.');

              const data = new Map<any, any>(activeItem.allControls.map(control => {
                return [control.destinationName, (x: any): any => {
                  // console.log(x, control.destinationName, x.data[control.destinationName]);
                  return x.data[control.destinationName];
                }]
              }));
              await ExcelApi.createTable(data, items);
              NotificationManager.success('Export abgeschlossen')
            } catch (e: any) {
              NotificationManager.error(e.debugInfo.message);
            }
            finally {
              modalStore.closeModal();
            }
          })();
        }}
          >
            <StackLayout vCenter padding horizontal>
              <Icon marginRight size={2} getApp />
              <Text>EXCEL!!</Text>
            </StackLayout>
          </Item>
        </ExcelFeature>
      </FormOrWorkingPackagePermissionFeature>
      <FormOrWorkingPackagePermissionFeature requiredPermission="CanExportIndex">
        <Item onClick={(): void => {
          handleExportResult(RestApiServiceFactory.getDataItemService(activeItem.id).getKeywordingCsvExport([], items.map(x => x.id)),
            'Verschlagwortung wird exportiert.',
            `${activeItem?.name}_Verschlagwortung`);
        }}
        >
          <StackLayout vCenter padding horizontal>
            <Icon marginRight size={2} getApp />
            <Text>Verschlagwortung exportieren</Text>
          </StackLayout>
        </Item>
      </FormOrWorkingPackagePermissionFeature>
      <FormOrWorkingPackagePermissionFeature requiredPermission="CanExportDataItems">
        <Item onClick={(): void => {
          (async (): Promise<void> => {
            try {
              if (!RealtimeService.connectionId) {
                NotificationManager.error('Es besteht keine Verbindung zum Echtzeit Service, bitte laden Sie die Seite neu.');
                return;
              }

              modalStore.openModal('exportData', {
                withAttachmentsAndKeywording: true,
                onlyTheseDataItems: items.map(x => x.id),
              } as IExportDataModalParameters);
            } catch {
              NotificationManager.error('Beim Export ist etwas schief gelaufen. ');
              modalStore.closeModal();
            }
          })();
        }}
        >
          <StackLayout vCenter padding horizontal>
            <Icon marginRight size={2} getApp />
            <Text>Datensatz mit Verschlagwortung exportieren</Text>
          </StackLayout>
        </Item>
      </FormOrWorkingPackagePermissionFeature>
      {
        items.length > 1 ? null : (
          <>
            <FormOrWorkingPackagePermissionFeature requiredPermission="CanDuplicateDataItems">
              <Item onClick={(): void => {
                modalStore.openModal('loadingOverlay');
                RestApiServiceFactory.getDataItemService(activeItem.id).duplicateDataItem(items[0].id)
                  .then((apiResult): void => {
                    if (!apiResult.ok) {
                      NotificationManager.error('Beim Duplizeren ist ein Fehler aufgetreten');
                      return;
                    }
                    dataItemStore.addOrApplySnapshot(apiResult.data);
                    NotificationManager.success('Erfolgreich dupliziert');
                  })
                  .finally((): void => {
                    modalStore.closeModal();
                  });
              }}
              >
                <StackLayout vCenter padding horizontal>
                  <Icon marginRight size={2} fileCopy />
                  <Text>Duplizieren</Text>
                </StackLayout>
              </Item>
            </FormOrWorkingPackagePermissionFeature>
            <FormOrWorkingPackagePermissionFeature requiredPermission="CanCreateDataItems">
              <Item onClick={(): void => {
                modalStore.openModal('loadingOverlay');
                RestApiServiceFactory.getDataItemService(activeItem.id).addEmptyDataItem()
                  .then((apiResult): void => {
                    if (!apiResult.ok) {
                      NotificationManager.error('Beim Duplizeren ist ein Fehler aufgetreten');
                      return;
                    }
                    dataItemStore.addOrApplySnapshot(apiResult.data);
                    NotificationManager.success('Erfolgreich dupliziert');
                  })
                  .finally((): void => {
                    modalStore.closeModal();
                  });
              }}
              >
                <StackLayout vCenter padding horizontal>
                  <Icon marginRight size={2} addCircle />
                  <Text>Neuen leeren Datensatz einfügen</Text>
                </StackLayout>
              </Item>
            </FormOrWorkingPackagePermissionFeature>

          </>
        )
      }
      <FormPermissionFeature requiredPermission="CanScheduleAttachmentProcessing">
        <Item onClick={(): void => {
            modalStore.openModal('scheduleAttachmentProcessing', {
              dataItems: items, formId: activeItem.id
            });
          }}
        >
          <StackLayout vCenter padding horizontal>
            <Icon marginRight size={2} settingsSuggest />
            <Text>PDF Optimierung</Text>
          </StackLayout>
        </Item>
      </FormPermissionFeature>
      <FormOrWorkingPackagePermissionFeature requiredPermission="CanAccessStatistics">
        <Item onClick={(): void => {
          const pdfMetadata = PdfMetadata.create();
          items.forEach(x => x.attachments.forEach(attachment => {
            if (!attachment.pdfMetadata) {
              return;
            }
            pdfMetadata.mergeFromPdfMetadata(attachment.pdfMetadata);
          }));

          modalStore.openModal('analyseAttachmentResult', {
            pdfMetadata, fileName: 'Manuelle Analyse'
          } as IAnalyseAttachmentResultParameters);
        }}
        >
          <StackLayout vCenter padding horizontal>
            <Icon marginRight size={2} timeline />
            <Text>PDF Statistiken</Text>
          </StackLayout>
        </Item>
      </FormOrWorkingPackagePermissionFeature>
      <FormOrWorkingPackagePermissionFeature requiredPermission="CanDeleteDataItems">
        <Item onClick={(): void => {
          if (!dataItem) {
            return;
          }
          confirmDialogYesNoDanger(items.length === 1 ? 'Wollen Sie den Datensatz wirklich löschen?' : `Wollen Sie die ${items.length} Datensätze wirklich löschen?`, (): void => {
            modalStore.openModal('loadingOverlay', 'Wird gelöscht');
            RestApiServiceFactory.getDataItemService(activeItem.id).deleteDataItems(activeItem.id, items.map(x => x.id))
              .then((): void => {
              // ToDo: Update total and unset active
                items.forEach(x => {
                  if (dataItemStore.activeItem?.id) {
                    const cleared = clearDataItemId(dataItemStore.activeItem.id);
                    if (x.id === cleared) {
                      dataItemStore.unsetActiveItem();
                    }
                  }
                  dataItemStore.deleteItem(x.id);
                });

                NotificationManager.success('Der Datensatz wurde erfolgreich gelöscht');
              })
              .catch((e): void => {
                console.error(e);
                NotificationManager.error('Beim Löschen ist ein Fehler aufgetreten');
              })
              .finally((): void => {
                modalStore.closeModal();
              });
          });
        }}
        >
          <StackLayout vCenter padding horizontal>
            <Icon marginRight size={2} deleteForever xlDanger />
            <Text>Löschen</Text>
          </StackLayout>
        </Item>
      </FormOrWorkingPackagePermissionFeature>
    </ContextMenu>
  );
};

export default observer(DataItemContextMenu);
