import React, { useState , useEffect } from 'react';
import { observer, Provider } from 'mobx-react';
import {
  StackLayout, Text, Icon, useBugReporter, useInsights, useModalStore
} from '@wemogy/reactbase';
import { types } from 'mobx-state-tree';
import { SearchAndReplaceModal } from '@wemogy/reactbase-search-replace';
import ICollectorModeScreenProps from './ICollectorModeScreenProps';
import MainLayout from '../../layouts/mainLayout/MainLayout';
import useDataAccessStore from '../../../dataLayer/stores/hooks/UseDataAccessStore';
import CollectorMode from '../../containers/collector/CollectorMode';
import DataItemStoresManager from '../../wrappers/formBuilder/managers/DataItemStoresManager';
import { IDataItemStore } from '../../wrappers/formBuilder/dataLayer/stores/entityStores/genericStores/DataItemStore';
import AddAttachmentModal from '../../wrappers/formBuilder/modals/addAttachmentModal/AddAttachmentModal';
import AttachmentStoreModal from '../../wrappers/formBuilder/modals/attachmentStoreModal/AttachmentStoreModal';
import AssignAttachmentModal from '../../wrappers/formBuilder/modals/assignAttachmentModal/AssignAttachmentModal';
import { IAttachment } from '../../wrappers/formBuilder/dataLayer/models/Attachment';
import NotificationManager from '../../wrappers/inAppFeedback/NotificationManager';
import RestApiServiceFactory from '../../../dataLayer/api/rest/RestApiServiceFactory';
import FileFormat from '../../wrappers/formBuilder/dataLayer/enums/FileFormat';
import { buildAttachmentUri } from '../../wrappers/formBuilder/base/Conventions';
import StartSampleModal from '../../wrappers/formBuilder/modals/startSample/StartSampleModal';
import ResetHistoryModal from '../../wrappers/formBuilder/modals/resetHistoryModal/ResetHistoryModal';
import AttachmentContextMenu from '../../wrappers/formBuilder/contextMenus/attachmentContextMenu/AttachmentContextMenu';
import ControlContextMenu from '../../wrappers/formBuilder/contextMenus/controlContextMenu/ControlContextMenu';
import useAppStore from '../../../dataLayer/stores/hooks/UseAppStore';
import AnalyseAttachmentModal from '../../wrappers/formBuilder/modals/analyseAttachmentModal/AnalyseAttachmentModal';
import TooltipHelp from '../../wrappers/tooltipHelp/TooltipHelp';
import AnalyseResultModal from '../../wrappers/formBuilder/modals/analyseResultModal/AnalyseResultModal';
import StatusStep from '../../wrappers/formBuilder/dataLayer/enums/internal/StatusStep';
import { confirmDialogDanger } from '../../wrappers/dialogs/DialogManager';
import useDerivedActor from '../../plugins/authorization/hooks/UseDerivedActor';
import ScheduleAttachmentProcessingModal from '../../wrappers/formBuilder/modals/scheduleAttachmentProcessingModal/ScheduleAttachmentProcessingModal';
import ExportDataModal from '../../wrappers/formBuilder/modals/exportDataModal/ExportDataModal';


function startDownloadFromUri(uri: string, name?: string): void {
  console.log('startDownloadFromUri', uri);
  const link = document.createElement('a');
  link.download = name || '';
  link.href = uri;
  link.click();
}


const CollectorModeScreen: React.FC<ICollectorModeScreenProps> = ({
  formId, customerId
}) => {
  const {
    formStore, customerStore
  } = useDataAccessStore();
  const modalStore = useModalStore();
  const { activeUserStore } = useAppStore();
  const [rootDataAccessStore, setRootDataAccessStore] = useState<any | undefined>(undefined);
  const [dataItemStore, setDataItemStore] = useState<IDataItemStore | undefined>(undefined);
  const [activeAttachment, setActiveAttachment] = useState<IAttachment | undefined>(undefined);
  const {
    isCollector, isQualityAssurer, isSampleInspector
  } = useDerivedActor();
  const { openBugReporter } = useBugReporter();
  const { trackEvent } = useInsights();

  RestApiServiceFactory.setCustomBaseHeaders(new Map<string, string>([
    ['customerId', customerId],
    ['formId', formId]
  ]));

  const queryKey = `collectorQueryKey_${formId}`;

  useEffect((): void => {
    const form = formStore.getItem(formId);
    if (!form) {
      modalStore.openModal('loadingOverlay', 'Daten werden vorbereitet');
      formStore.get(formId).then((): void => {
        const currentForm = formStore.getItem(formId);
        if (currentForm) {
          formStore.setActiveItem(currentForm);
        }
        modalStore.closeModal();
      });
      customerStore.query();
      return;
    }
    formStore.setActiveItem(form);
  }, [formId]);

  const { activeItem } = formStore;

  if (!activeItem || activeItem.id !== formId) {
    return null;
  }

  const onBeforeActiveItemChange = (): void => {
    setActiveAttachment(undefined);
  };


  if (!rootDataAccessStore || !dataItemStore) {
    const cachedDataItemStore = DataItemStoresManager.getDataItemStore(activeItem);
    const rootDataAccessStoreType = types.model({ dataItemStore: types.optional(cachedDataItemStore.store, () => cachedDataItemStore.instance) });
    const rootDataAccessStoreInstance = rootDataAccessStoreType.create();
    setDataItemStore(cachedDataItemStore.instance);
    setRootDataAccessStore(rootDataAccessStoreInstance);
    return null;
  }

  const dataItem = dataItemStore.activeItem;

  const saveDataItem = async (notifyIfNoChanges: boolean = false): Promise<void> => {
    const patch = dataItem.commitChanges();
    if (!patch) {
      if (notifyIfNoChanges) {
        NotificationManager.info('Es wurden keine Änderungen erkannt.');
      }
      return;
    }

    const res = await dataItemStore.patchDataItem(formId, dataItem.id, patch);

    if (res.ok) {
      NotificationManager.success(`Der Datensatz wurde erfolgreich gespeichert. Änderungen: ${patch.length}`, (): void => {
        openBugReporter({
          patch,
          dataItem
        });
      });
    } else {
      NotificationManager.error('Beim Speichern ist ein Fehler aufgetreten');
    }
  }


  const isSaveDisabled = !dataItem?.canCompleteStep
  || (dataItem.status.isIndexed && !dataItem.status.isDataCollected && !isCollector())
  || (dataItem.status.isDataCollected && !dataItem.status.isDataQualityAssured && !isQualityAssurer())
  || (dataItem.status.isDataQualityAssured && !isSampleInspector());

  const isCompleteStepDisabled = !dataItem?.canCompleteStep
  || (dataItem.status.isIndexed && !dataItem.status.isDataCollected && !isCollector())
  || (dataItem.status.isDataCollected && !dataItem.status.isDataQualityAssured && !isQualityAssurer())
  || (dataItem.status.isDataQualityAssured);

  return (
    <>
      <Provider rootStore={rootDataAccessStore}>
        <MainLayout navigation={(
          <StackLayout height100 stretch horizontal width100 hCenter vCenter>

            <StackLayout opacity={dataItemStore.hasPreviousItem(queryKey) ? 1 : 0.3} vCenter horizontal padding borderRadius={0.25} backgroundColor="grey200" marginRight>
              <Icon xl chevronLeft marginRight />
              <Text>Vorheriger ungeprüfter</Text>
            </StackLayout>
            <StackLayout
              onPress={(): void => {
                if (!dataItemStore) {
                  return;
                }
                dataItemStore.goToPreviousItem(queryKey);
                onBeforeActiveItemChange();
              }}
              vCenter
              horizontal
              padding
              borderRadius={0.25}
              opacity={dataItemStore.hasPreviousItem(queryKey) ? 1 : 0.3}
              backgroundColor="grey300"
              marginRight
            >
              <Icon xl chevronLeft marginRight />
              <Text>Zurück</Text>
            </StackLayout>
            <StackLayout
              opacity={isSaveDisabled ? 0.5 : 1}
              onPress={isSaveDisabled ? undefined : (): void => {
                try {
                  trackEvent('saveDataItemPress', {
                    dataItemId: dataItem.id,
                    formId,
                    patches: dataItem.activePatch?.length
                  });
                  modalStore.openModal('loadingOverlay', 'Speichern..');
                  saveDataItem(true);
                } finally {
                  modalStore.closeModal();
                }

              }}
              vCenter
              horizontal
              padding
              borderRadius={0.25}
              backgroundColor="green200"
              marginRight
            >
              <Icon xl save marginRight />
              <Text>Speichern</Text>
            </StackLayout>
            <StackLayout
              onPress={!isCompleteStepDisabled && dataItem?.canCompleteStep ? (): void => {
                trackEvent('completeStep', {
                  dataItemId: dataItem.id,
                  formId
                });
                if (dataItem.status.isIndexed && !dataItem.status.isDataCollected && !isCollector()) {
                  NotificationManager.error('Sie dürfen diesen Schritt nicht abschließen, da Sie kein Erfasser sind');
                  return;
                }
                if (dataItem.status.isDataCollected && !dataItem.status.isDataQualityAssured && !isQualityAssurer()) {
                  NotificationManager.error('Sie dürfen diesen Schritt nicht abschließen, da Sie kein Qualitätssicherer sind');
                  return;
                }
                if (!activeUserStore.userId) {
                  return;
                }
                modalStore.openModal('loadingOverlay', 'Schritt wird abgeschlossen');
                saveDataItem(false).then((): void => {
                  if (!activeUserStore.userId) {
                    return;
                  }
                  dataItem.status.completeActiveStep(activeUserStore.userId);
                  RestApiServiceFactory.getDataItemService(formId).putStatus(formId, dataItem.id, dataItem.status).then((response): void => {
                    if (response.ok) {
                      NotificationManager.success('Schritt abgeschlossen');
                    }
                    else {
                      NotificationManager.error('Schritt konnte nicht abgeschlossen werden');
                    }
                    modalStore.closeModal();
                  })
                    .catch((): void => {
                      modalStore.closeModal();
                      NotificationManager.error('Schritt konnte nicht abgeschlossen werden');
                    });
                })

              } : undefined}
              vCenter
              horizontal
              padding
              borderRadius={0.25}
              backgroundColor="green200"
              marginRight
              opacity={isCompleteStepDisabled ? 0.5 : 1}
            >
              <Icon xl checkBox marginRight />
              <Text>Schritt abschließen</Text>
            </StackLayout>
            <StackLayout
              opacity={dataItemStore.hasNextItem(queryKey) ? 1 : 0.3}
              onPress={(): void => {
                if (!dataItemStore) {
                  return;
                }
                dataItemStore.goToNextItem();
                onBeforeActiveItemChange();
              }}
              vCenter
              horizontal
              padding
              borderRadius={0.25}
              backgroundColor="grey300"
              marginRight
            >
              <Text>Weiter</Text>
              <Icon xl chevronRight marginLeft />
            </StackLayout>
            <StackLayout opacity={dataItemStore.hasNextItem(queryKey) ? 1 : 0.3} vCenter horizontal padding borderRadius={0.25} backgroundColor="grey200" marginRight>
              <Text>Nächster ungeprüfter</Text>
              <Icon xl chevronRight marginLeft />
            </StackLayout>
            <TooltipHelp text="PDF Analyse">
              <Icon
                xl
                assessment
                onPress={(): void => {
                  modalStore.openModal('analyseAttachment');
                }}
              />
            </TooltipHelp>

          </StackLayout>
        )}
        >
          <StackLayout stretch>
            <CollectorMode
              form={activeItem}
              activeAttachment={activeAttachment}
              onActiveAttachmentChanged={(attachment): void => {
                if (attachment.fileFormat === FileFormat.Unknown) {
                  const uri = buildAttachmentUri(activeItem, attachment);
                  startDownloadFromUri(uri, attachment.filename);
                  NotificationManager.info(`${attachment.filename} wird heruntergeladen, anschließend können Sie diese in einem installieren Programm öffnen.`);
                } else {
                  setActiveAttachment(attachment);
                }
              }}
              onActiveItemChanged={(item): void => {
                if (dataItemStore.activeItem?.hasChanges) {
                  if(isSaveDisabled){
                    dataItem.discardChanges();
                    return;
                  }
                  confirmDialogDanger(
                    'Der Datensatz hat ungespeicherte Änderungen',
                    'Jetzt speichern',
                    (): void => {
                      trackEvent('unsavedChangesRequestConfirmed', {
                        dataItemId: dataItem.id,
                        formId,
                        patches: dataItem.activePatch?.length
                      });
                      saveDataItem()
                    },
                    'Änderungen verwerfen',
                    (): void => {
                      trackEvent('unsavedChangesRequestDiscard', {
                        dataItemId: dataItem.id,
                        formId
                      });
                      dataItem.discardChanges();
                    }
                  )
                  return;
                }
                onBeforeActiveItemChange();
                let showAllTransactions = false;
                let statusStep: StatusStep = StatusStep.Indexed;
                if(!item.status.isDataCollected || (isCollector() && !isQualityAssurer())){
                  statusStep = StatusStep.DataCollected;
                }else if(!item.status.isDataQualityAssured || (!isCollector() && isQualityAssurer())){
                  statusStep = StatusStep.DataQualityAssured;
                } else {
                  statusStep = StatusStep.DataCollectionVerified;
                  showAllTransactions = (!isCollector() && !isQualityAssurer()) || (isCollector() && isQualityAssurer())
                }

                console.log('statusStep', statusStep)
                console.log(showAllTransactions, isCollector(), isQualityAssurer());

                dataItemStore.setEditingDataItem(item, statusStep, showAllTransactions);
                if (item.attachments.length) {
                  setActiveAttachment(item.attachments[0]);
                }
              }}
            />
            {true ? null : <Text>{`${rootDataAccessStore.dataItemStore.items.length}/`}</Text>}
          </StackLayout>
          <AddAttachmentModal />
          <AttachmentStoreModal />
          <AssignAttachmentModal />
          <StartSampleModal />
          <AttachmentContextMenu />
          <AnalyseAttachmentModal />
          <AnalyseResultModal />
          <ResetHistoryModal />
          <ControlContextMenu />
          <SearchAndReplaceModal />
          <ScheduleAttachmentProcessingModal />
          <ExportDataModal />
        </MainLayout>
      </Provider>
    </>
  );
};

export default observer(CollectorModeScreen);
