import React from 'react';
import { observer } from 'mobx-react';
import { StackLayout, Text } from '@wemogy/reactbase';
import IOnlyTheseDataSourcesSelectorProps from './IOnlyTheseDataSourcesSelectorProps';
import AnnotatedFormView from '../formElements/annotatedFormView/AnnotatedFormView';
import Checkbox from '../../../../components/checkbox/Checkbox';
import CheckGroup from '../../../../components/checkGroup/CheckGroup';
import KeyValuePair from '../../dataLayer/models/general/KeyValuePair';

const OnlyTheseDataSourcesSelector: React.FC<IOnlyTheseDataSourcesSelectorProps> = ({
  form, onlyTheseDataSourceIds, onOnlyTheseDataSourceIdsChanged
}) => {

  return (
    <AnnotatedFormView description="Anhand von Datenquelle einschränken" marginBottom>
      <StackLayout vCenter>
        <Checkbox
          marginBottom={0.5}
          label="Alle Attribute exportieren"
          boxStyle
          checked={onlyTheseDataSourceIds === undefined}
          onChange={(): void => {
            onOnlyTheseDataSourceIdsChanged(undefined);
          }}
        />
        <Text label>Nur für Quellnamen aus ausgewählten Datenquellen:</Text>
        <CheckGroup
          onSelectedOptionsChange={(selectedOptions): void => {
            onOnlyTheseDataSourceIdsChanged(selectedOptions);
          }}
          selectedOptions={onlyTheseDataSourceIds || []}
          options={form.dataSources.map(x => new KeyValuePair(
            x.name, x.id))}
        />

      </StackLayout>
    </AnnotatedFormView>

  );
};

export default observer(OnlyTheseDataSourcesSelector);
