import React from 'react';
import { observer } from 'mobx-react';
import { Menu } from 'semantic-ui-react';
import IMainHeaderProps from './IMainHeaderProps';
import Dropdown from '../../../components/dropdown/Dropdown';
import IDropdownOption from '../../../components/dropdown/interfaces/IDropdownOption';
import NavigationManager from '../../../../navigation/NavigationManager';
import useDataAccessStore from '../../../../dataLayer/stores/hooks/UseDataAccessStore';
import TooltipHelp from '../../../wrappers/tooltipHelp/TooltipHelp';
import { confirmDialogYesNoDanger } from '../../../wrappers/dialogs/DialogManager';
import Greeting from '../../../components/greeting/Greeting';
import AuthenticationService from '../../../../dataLayer/services/AuthenticationService';
import useGlobalPermissionFeature from '../../../plugins/authorization/hooks/UseGlobalPermissionFeature';
import useFormPermissionFeature from '../../../plugins/authorization/hooks/UseFormPermissionFeature';
import { Version } from '../../../../base/Constants';

const appModeOptions: IDropdownOption[] = [{
  label: 'Datenbank Manager',
  value: 'databaseBuilder/:customerId/:activeFormId',
  icon: 'storage'
}, {
  label: 'Erfasser Modus',
  value: 'database/:customerId/:activeFormId',
  icon: 'edit'
},{
  label: 'ART',
  value: 'art/:customerId/:activeFormId',
  icon: 'smartphone'
}, {
  label: 'Kundenübersicht',
  value: 'customerOverview',
  icon: 'business'
}, {
  label: 'Account Management',
  value: 'accountManagement',
  icon: 'supervisorAccount'
}, {
  label: 'Statistik',
  value: 'statistics',
  icon: 'timeline'
},{
  label: 'Globale Marker Codes',
  value: 'globalMarkerCode',
  icon: 'label'
},{
  label: 'Optimierungs-Vorlagen',
  value: 'processingTemplate',
  icon: 'settingsSuggest'
},{
  label: 'Abmelden',
  value: 'logout',
  icon: 'exitToApp'
}];

const MainHeader: React.FC<IMainHeaderProps> = ({navigation}) => {
  const { formStore } = useDataAccessStore();
  const { hasGlobalPermission } = useGlobalPermissionFeature();
  const { hasOnActiveFormPermission } = useFormPermissionFeature();

  let activeAppModeOptions = formStore.activeItem === undefined ? [appModeOptions[2], appModeOptions[3], appModeOptions[4], appModeOptions[5], appModeOptions[6], appModeOptions[7]] : appModeOptions;

  if (!hasGlobalPermission('CanManagePermissions')) {
    activeAppModeOptions = activeAppModeOptions.filter(x => x.value !== 'accountManagement');
  }

  if(!hasGlobalPermission('CanManageProcessingTemplates')){
    activeAppModeOptions = activeAppModeOptions.filter(x => x.value !== 'processingTemplate');
  }

  if (!hasGlobalPermission('CanManagePermissions')) {
    activeAppModeOptions = activeAppModeOptions.filter(x => x.value !== 'accountManagement');
    // ToDo: make this correct
    activeAppModeOptions = activeAppModeOptions.filter(x => x.value !== 'statistics');
  }

  if (!hasOnActiveFormPermission('CanAccessFormManager')) {
    activeAppModeOptions = activeAppModeOptions.filter(x => x.value !== 'databaseBuilder/:customerId/:activeFormId');
  }

  if(!hasOnActiveFormPermission('CanUseArt')){
    activeAppModeOptions = activeAppModeOptions.filter(x => x.value !== 'art/:customerId/:activeFormId');
  }

  const selectedOption = activeAppModeOptions.find(x => {
    let route = x.value;
    route = route.replace(':activeFormId', formStore.activeItem?.id || '');
    route = route.replace(':customerId', formStore.activeItem?.relatedId || '');
    return window.location.href.includes(route);
  });

  return (
    <Menu attached='top' color="grey">
      <Menu.Item
        header
        onClick={(): void => {
          NavigationManager.navigate('/customerOverview');
        }}
      >
        <TooltipHelp text={Version}>
          <img src="/logo.png" alt="logo" />
        </TooltipHelp>
      </Menu.Item>
      {navigation || <Menu.Item><Greeting /></Menu.Item>}
      <Menu.Item position="right">
        <Dropdown
          light
          height={5}
          width={30}
          options={activeAppModeOptions}
          selectedOption={selectedOption}
          disableSorting
          onOptionSelected={(option): void => {
          if (option.value === 'logout') {
            confirmDialogYesNoDanger('Möchten Sie sich wirklich abmelden?', AuthenticationService.logout);
            return;
          }
          let route = option.value;
          route = route.replace(':activeFormId', formStore.activeItem?.id || '');
          route = route.replace(':customerId', formStore.activeItem?.relatedId || '');
          NavigationManager.navigate(`/${route}`);
        }}
        />
      </Menu.Item>
    </Menu>
  );
};

export default observer(MainHeader);
