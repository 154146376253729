import { ApiWrapper, RestService } from '@wemogy/reactbase';

export default class UserService extends RestService<any>{
  public constructor(apiWrapper: ApiWrapper){
    super(apiWrapper, 'user');
  }

  public async getUploadSasAsync(): Promise<string> {
    const apiResult = await this.apiWrapper.get<string>('user/sas');

    if (!apiResult.ok) {
      alert('UploadSAS not returned' + apiResult.status);
      throw new Error('UploadSAS error');
    }

    return apiResult.data;
  }
}
