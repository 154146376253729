import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import {
BaseModel, isEmptyOrWhiteSpace, removeRepeatingCharacters, removeTrailingString
} from '@wemogy/reactbase';
import _ from 'lodash';
import hash from 'object-hash';
import Layout, { ILayout } from './Layout';
import { IControl } from './Control';
import UiElement, { IUiElement } from './UiElement';
import DataSource, { IDataSource } from './DataSource';
import FormSection from '../enums/FormSection';
import WorkingPackage, { IWorkingPackage, IWorkingPackageSnapshotIn } from './WorkingPackage';
import NavigationConfiguration from './NavigationConfiguration';
import { IDataItem } from './genericModels/DataItem';
import MarkerCode, { IMarkerCode } from './MarkerCode';
import FormMember, { IFormMember } from './members/FormMember';
import PdfMetadata from './PdfMetadata';
import QrCodeSource, { IQrCodeSource } from './QrCodeSource';
import ArtSettings from './art/ArtSettings';

const FormSubType = types.model({
  modelVersion: types.optional(types.number, 1),
  name: types.optional(types.string, ''),
  layouts: types.optional(types.array(Layout), []),
  uiElements: types.optional(types.array(UiElement), []),
  dataSources: types.optional(types.array(DataSource), []),
  qrCodeSources: types.optional(types.array(QrCodeSource), []),
  workingPackages: types.optional(types.array(WorkingPackage), []),
  navigationConfiguration: types.optional(NavigationConfiguration, () => NavigationConfiguration.create()),
  markerCodes: types.optional(types.array(MarkerCode), []),
  sampleRate: types.optional(types.number, 12),
  relatedId: types.string,
  sas: types.string,
  members: types.optional(types.array(FormMember), []),
  // #region statistics
  numberOfDataItems: types.maybe(types.number),
  numberOfDataItemsWithAttachment: types.maybe(types.number),
  numberOfDataItemsWithAttachmentWithoutWorkingPackage: types.maybe(types.number),
  numberOfDataItemsWhichAreIndexed: types.maybe(types.number),
  numberOfDataItemsWithWorkingPackage: types.maybe(types.number),
  pdfMetadataStatistics: types.optional(PdfMetadata, () => PdfMetadata.create()),
  numberOfAttachments: types.optional(types.number, 0),
  // #endregion
  activeWorkingPackage: types.maybe(types.safeReference(WorkingPackage)),
  artSettings: types.optional(ArtSettings, () => ArtSettings.create()),
})
  .named('FormSubType')
  .views(self => ({
    /**
     * controls define our schema
     */
    get schema(): IControl[]{
      return self.layouts.map(x => x.controls).reduce((acc, val: any) => acc.concat(val), []);
    },
    getLayoutsOfFormSection(formSection: FormSection): ILayout[]{
      return self.layouts.filter(x => x.formSection === formSection);
    },
    getUiElementsOfFormSection(formSection: FormSection): IUiElement[]{
      return self.uiElements.filter(x => x.formSection === formSection);
    },
    getControlsOfFormSection(formSection: FormSection): IControl[]{
      return _.flatten(
        self.layouts.filter(x => x.formSection === formSection).map(x => x.allControls))
        .sort((a: IControl, b: IControl) => a.orderId.toString()
          .localeCompare(b.orderId.toString()));
    },
    getLayoutOrUiElementAtOrderId(orderId: number, formSection: FormSection): ILayout | IUiElement | undefined{
      const layout = this.getLayoutsOfFormSection(formSection).find(x => x.orderId === orderId);
      if(layout){
        return layout;
      }
      return this.getUiElementsOfFormSection(formSection).find(x => x.orderId === orderId);
    },
    getDataSourceById(dataSourceId: string): IDataSource | undefined{
      return self.dataSources.find(x => x.id === dataSourceId);
    },
    get allControls(): IControl[]{
      return _.flatten(self.layouts.map(x => x.allControls));
    },
    get allControlsDestinationNamesUnique(): string[]{
      return _.uniq(this.allControls.map(x => x.destinationName));
    },
    get allValidDataControls(): IControl[]{
      return _.flatten<IControl>(self.layouts.map(x => x.controls)).filter(x => !isEmptyOrWhiteSpace(x.destinationName));
    },
    get controlsHash(): string{
      return hash(this.allControls);
    },
    getFilename(dataItem: IDataItem): string{
      const controlsForFilename = _.orderBy(this.getControlsOfFormSection(FormSection.Index), x => x.orderId);

      console.log('controlsForFilename', controlsForFilename);

      const withoutRepeating = removeRepeatingCharacters(controlsForFilename.map(control => dataItem.data[control.destinationName] || control.getDefaultValue()).join('_'), '_');

      return removeTrailingString(withoutRepeating, '_')
    },
    getWorkingPackage(workingPackageId: string): IWorkingPackage | undefined{
      return self.workingPackages.find(x => x.id === workingPackageId);
    },
    get numberOfDataItemsWithoutAttachment(): number | undefined{
      if (self.numberOfDataItems === undefined || self.numberOfDataItemsWithAttachment === undefined) {
        return undefined;
      }
      return self.numberOfDataItems - self.numberOfDataItemsWithAttachment;
    },
    getMarkerCode(markerCodeId: string): IMarkerCode | undefined{
      return self.markerCodes.find(x => x.id === markerCodeId);
    },
    getMember(userId: string): IFormMember | undefined{
      return self.members.find(x => x.userId === userId);
    },
    getDataItemName(dataItem: IDataItem): string{
      const nameParts = self.navigationConfiguration.columns.map(x => _.get(dataItem, `data.${x.propertyName}`));

      return nameParts.join(' ');
    }
  }))
  .actions(self => ({
    addLayout(layout: ILayout): void{
      self.layouts.push(layout);
    },
    removeLayout(layout: ILayout): void{
      self.layouts.remove(layout);
    },
    addUiElement(uiElement: IUiElement): void{
      self.uiElements.push(uiElement);
    },
    removeUiElement(uiElement: IUiElement): void {
      self.uiElements.remove(uiElement);
    },
    addDataSource(dataSource: IDataSource): void{
      self.dataSources.push(dataSource);
    },
    removeDataSource(dataSource: IDataSource): void{
      self.dataSources.remove(dataSource);
    },
    addQrCodeSource(qrCodeSource: IQrCodeSource): void{
      self.qrCodeSources.push(qrCodeSource);
    },
    removeQrCodeSource(qrCodeSource: IQrCodeSource): void{
      self.qrCodeSources.remove(qrCodeSource);
    },
    addWorkingPackage(workingPackage: IWorkingPackage): void{
      self.workingPackages.push(workingPackage);
    },
    removeWorkingPackage(workingPackage: IWorkingPackage): void{
      self.workingPackages.remove(workingPackage);
    },
    updateWorkingPackages(workingPackages: IWorkingPackageSnapshotIn[]): void{
      self.workingPackages.clear();
      self.workingPackages.push(...workingPackages.map(x => WorkingPackage.create(x)));
    },
    setSampleRate(sampleRate: number): void{
      self.sampleRate = sampleRate ;
    },
    addMarkerCode(markerCode: IMarkerCode): void{
      self.markerCodes.push(markerCode);
    },
    removeMarkerCode(markerCode: IMarkerCode): void{
      self.markerCodes.remove(markerCode);
    },
    putMember(userId: string, roleId: string): void{
      const member = self.getMember(userId);
      if (member) {
        member.setRoleId(roleId);
      }
      else {
        self.members.push(FormMember.create({
          userId,
          roleId
        }));
      }
    },
    deleteMember(userId: string): void{
      const member = self.getMember(userId);
      if (member) {
        self.members.remove(member);
      }
    },
    setActiveWorkingPackage(workingPackageId: string | undefined): void{
      const activeWorkingPackage = workingPackageId ? self.getWorkingPackage(workingPackageId) : undefined;
      self.activeWorkingPackage = activeWorkingPackage;
    },
    clearDuplicates(): void{
      self.layouts.filter(x => x.formSection === FormSection.Index).forEach(x => {
        console.log('BEFORE clear', x.controls.length);
        x.clearDuplicates();
        console.log('After clear', x.controls.length);
      });
    }
  }));

const Form = types.compose(BaseModel, FormSubType)
  .named('Form');
export default Form;

export interface IForm extends Instance<typeof Form>{}
export interface IFormSnapshotIn extends SnapshotIn<typeof Form>{}
export interface IFormSnapshotOut extends SnapshotOut<typeof Form>{}
