import React from 'react';
import { observer } from 'mobx-react';
import {StackLayout, Text} from '@wemogy/reactbase';
import ITextIconProps from './ITextIconProps';

const TextIcon: React.FC<ITextIconProps> = ({children}) => {

  return (
    <StackLayout padding={0.5} marginRight>
      <Text textIcon>{children}</Text>
    </StackLayout>
  );
};

export default observer(TextIcon);
