import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, Text, Icon, Modal, useModalStore
} from '@wemogy/reactbase';
import IAddDatabaseTemplateModalProps from './IAddDatabaseTemplateModalProps';
import useDataAccessStore from '../../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import AnnotatedTextInput from '../../../containers/formElements/annotatedTextInput/AnnotatedTextInput';
import DatabaseTemplate from '../../../dataLayer/models/DatabaseTemplate';
import NotificationManager from '../../../../inAppFeedback/NotificationManager';

const AddDatabaseTemplateModal: React.FC<IAddDatabaseTemplateModalProps> = () => {
  const {
    formStore, databaseTemplateStore
  } = useDataAccessStore();
  const modalStore = useModalStore();
  const [newDatabaseTemplate, setNewDatabaseTemplate] = useState(DatabaseTemplate.create());

  if(!formStore.activeItem){
    return null;
  }

  return (
    <Modal modalKey="addDatabaseTemplate" icon="add" title="Vorlage hinzufügen" large>
      <StackLayout>
        <AnnotatedTextInput placeholder="Name der Vorlage" description="Name der Vorlage" value={newDatabaseTemplate.name} onChange={newDatabaseTemplate.setName} marginBottom />

        <StackLayout marginBottom horizontal hEnd height={4}>
          {
            newDatabaseTemplate.isReadyToSave ? (
              <Button onPress={(): void => {
                if(!formStore.activeItem){
                  return;
                }
                newDatabaseTemplate.setForm(formStore.activeItem);
                databaseTemplateStore.create(newDatabaseTemplate).then((): void => {
                  NotificationManager.success('Die Vorlage wurde erfolgreich gespeichert');
                  modalStore.openModal('manageDatabaseTemplates');
                  setNewDatabaseTemplate(DatabaseTemplate.create());
                });

              }}
              >
                Hinzufügen
              </Button>
            ): null
          }
        </StackLayout>
        <StackLayout horizontal vCenter>
          <Icon info xl marginRight />
          <Text>Die Vorlage enthält das aktuelle Formular und alle Datenquellen des aktuellen Formulars.</Text>
        </StackLayout>
      </StackLayout>
    </Modal>
  );
};

export default observer(AddDatabaseTemplateModal);
