/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import _ from 'lodash';
import React, {
  useCallback, useMemo, useState
} from 'react';
import { observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import {
  StackLayout, Text, Button, Modal, useModalStore, TextInput
} from '@wemogy/reactbase';
import IScheduleAttachmentProcessingModalProps from './IScheduleAttachmentProcessingModalProps';
import LoadingIndicator from '../../../../components/loadingIndicator/LoadingIndicator';
import RestApiServiceFactory from '../../../../../dataLayer/api/rest/RestApiServiceFactory';
import NotificationManager from '../../../inAppFeedback/NotificationManager';
import PdfSettingsForm from '../../components/pdfSettingsForm/PdfSettingsForm';
import PdfSettings from '../../dataLayer/models/PdfSettings';
import { IDataItem } from '../../dataLayer/models/genericModels/DataItem';
import { IAttachment } from '../../dataLayer/models/Attachment';
import PdfSettingsSelector from '../../components/pdfSettingsSelector/PdfSettingsSelector';
import Divider from '../../../../components/divider/Divider';
import IScheduleAttachmentProcessingModalParameters from './IScheduleAttachmentProcessingModalParameters';
import Checkbox from '../../../../components/checkbox/Checkbox';

interface IAttachmentMetadata {
  attachment: IAttachment;
  dataItem: IDataItem;
}

const ScheduleAttachmentProcessingModal: React.FC<IScheduleAttachmentProcessingModalProps> = () => {
  const [pdfSettings, setPdfSettings] = useState(PdfSettings.create())
  const [sendingRequest, setSendingRequest] = useState(false);
  const [scheduledPdfAttachments, setScheduledPdfAttchments] = useState(0);
  const [cancellationState] = useState({ cancelled: false })
  const [filterOutGeneratedPdfs, setFilterOutGeneratedPdfs] = useState(false);
  const [suffix, setSuffix] = useState('');
  const modalStore = useModalStore();

  const handleModalOpened = useCallback((): void => {
    setPdfSettings(PdfSettings.create())
  }, []);

  const {
    dataItems,
    formId
  } = modalStore.getActiveParameters<IScheduleAttachmentProcessingModalParameters>('scheduleAttachmentProcessing') || {
    dataItems: [],
    formId: ''
  };

  const pdfAttachments = useMemo((): IAttachmentMetadata[] => {
    const attachments = _.flatMap(dataItems, x => x.pdfAttachments.map(attachment => ({
      attachment,
      dataItem: x
    })));

    if (filterOutGeneratedPdfs) {
      return attachments.filter(x => !x.attachment.isGenerated);
    }

    return attachments;
  }, [dataItems, filterOutGeneratedPdfs])

  return (
    <Modal customStyle={{ minHeight: '70vg' }} onOpened={handleModalOpened} modalKey="scheduleAttachmentProcessing" icon="settingsSuggest" title="PDF Optimierung planen">
      {cancellationState.cancelled ? (<Text>Abgebrochen</Text>) : sendingRequest ? (
        <>
          <LoadingIndicator text={`PDF ${scheduledPdfAttachments}/${pdfAttachments.length} geplant`} />
          <Button
            marginTop
            onPress={(): void => {
              cancellationState.cancelled = true;
              NotificationManager.success('PDF Planung wurde abgebrochen.');
            }}
          >
            Planung weiterer PDFs abbrechen
          </Button>
        </>
      ) : (
        <>
          <Text label marginBottom={0.75}>PDF-Einstellungen aus Optimierungsvorlage wählen</Text>
          <PdfSettingsSelector onSelected={(settings): void => {
            setPdfSettings(PdfSettings.create(getSnapshot(settings)));
            NotificationManager.success('PDF-Einstellungen wurden aus der Optimierungsvorlage übernommen.');
          }}
          />
          <StackLayout marginTopBottom>
            <Divider />
          </StackLayout>
          <Text label marginBottom={0.75}>PDF-Einstellungen anpassen</Text>
          <PdfSettingsForm pdfSettings={pdfSettings} />

          <StackLayout marginTopBottom>
            <Divider />
          </StackLayout>

          <Text label marginBottom={0.75}>Dateinamen-Erweiterung (optional, wird vor .pdf als Suffix an den Dateinamen angefügt)</Text>
          <TextInput marginBottom placeholder=".optimized" onChange={setSuffix} value={suffix} />
          <Checkbox boxStyle label="Automatisch generierte PDFs ausschließen" checked={filterOutGeneratedPdfs} onChange={setFilterOutGeneratedPdfs} />

          <StackLayout marginTop={2} hEnd={!sendingRequest} height={5}>
            <Button
              onPress={async (): Promise<void> => {
                setSendingRequest(true);
                setScheduledPdfAttchments(0);

                try {
                  for (const pdfAttachment of pdfAttachments) {
                    if (cancellationState.cancelled) {
                      break;
                    }
                    await RestApiServiceFactory.attachmentProcessingService.schedule(
                      formId,
                      pdfAttachment.dataItem.id,
                      pdfAttachment.attachment.id,
                      pdfSettings,
                      suffix
                    )
                    setScheduledPdfAttchments(pdfAttachments.indexOf(pdfAttachment) + 1);

                    // wait for 200ms to not overload the server
                    await new Promise(resolve => setTimeout(resolve, 200));
                  }
                }
                finally {
                  setSendingRequest(false);
                  cancellationState.cancelled = false;
                  modalStore.closeModal();
                }
              }}
            >
              {pdfAttachments.length === 1 ? 'PDF optimieren' : `${pdfAttachments.length} PDFs optimieren`}
            </Button>
          </StackLayout>
        </>
)}
    </Modal>
  );
};

export default observer(ScheduleAttachmentProcessingModal);
