import React, {
  useState, useEffect, useMemo, useCallback
} from 'react';
import {
  StackLayout, Text, TextInput, Image, useModalStore, ApiResult, decodeJwt
} from '@wemogy/reactbase';
import QRCode from 'react-qr-code';
// import { FileManager } from '@wemogy/media-react-base';
import NavigationManager from '../../../../navigation/NavigationManager';
import NotificationManager from '../../../wrappers/inAppFeedback/NotificationManager';
import ILoginScreenProps from './ILoginScreenProps';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import useAppStore from '../../../../dataLayer/stores/hooks/UseAppStore';
import ForgotPasswordModal from '../../../wrappers/formBuilder/modals/forgotPasswordModal/ForgotPasswordModal';
import ResetPasswordModal from '../../../wrappers/formBuilder/modals/resetPasswordModal/ResetPasswordModal';
import RestApiServiceFactory from '../../../../dataLayer/api/rest/RestApiServiceFactory';

interface ITotpSetupToken {
  sub: string;
  role: string;
  uri: string;
  base32: string;
}

const LoginScreen: React.FC<ILoginScreenProps> = () => {
  const modalStore = useModalStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [loginMfaChallengeToken, setLoginMfaChallengeToken] = useState<string | undefined>(undefined);
  const [totpCode, setTotpCode] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [totpSetupToken, setTotpSetupToken] = useState<string | undefined>(undefined);
  const totpSetupTokenPayload = useMemo((): ITotpSetupToken | undefined => {
    if(!totpSetupToken){
      return undefined;
    }

    return decodeJwt(totpSetupToken) as ITotpSetupToken;
  }, [totpSetupToken]);
  const [showFinishTotpSetup, setShowFinishTotpSetup] = useState<boolean>(false);

  const handleShowFinishTotpSetupPress = useCallback((): void => {
    setShowFinishTotpSetup(true);
  }, []);

  const handleTotpSetupDonePress = useCallback((): void => {
    if(!totpSetupToken){
      return;
    }
    RestApiServiceFactory.authenticationService.setupMfa({
      totpSetupToken,
      code: totpCode
    }).then((result: ApiResult<string>) => {
      if(!result.ok){
        NotificationManager.error(result.data);
        return;
      }
      setTotpSetupToken(undefined);
      setShowFinishTotpSetup(false);
      setTotpCode('');
      NotificationManager.success('Multi-Faktor-Authentifizierung erfolgreich aktiviert. Sie können sich jetzt einloggen.');
    });
  }, [totpSetupToken, totpCode]);

  const { activeUserStore } = useAppStore();

  const login = async (_email: string, _password: string): Promise<void> => {
    try {
      setIsLoading(true);
      const loginResult = await activeUserStore.login(_email, _password);

      switch (loginResult.status) {
        case 200:
          setLoginMfaChallengeToken(loginResult.data);
          break;
        case 420:
          setTotpSetupToken(loginResult.data);
          break;
        default:
          NotificationManager.error('Logindaten ungültig');
          break;
      }
    } catch (e) {
      console.error(e)
      NotificationManager.error('Es ist ein Fehler aufgetreten, bitte probieren Sie es später erneut.');
    }
    finally {
      setIsLoading(false);
    }
  };

  const loginMfa = async (): Promise<void> => {
    if(!loginMfaChallengeToken || !totpCode){
      return;
    }
    try {
      setIsLoading(true);
      const loginResult = await activeUserStore.loginMfa(loginMfaChallengeToken, totpCode);

      if (loginResult === true) {
        NavigationManager.navigate('/customerOverview');
        return;
      }

      NotificationManager.error('Code ungültig');
    } catch (e) {
      console.error(e)
      NotificationManager.error('Es ist ein Fehler aufgetreten, bitte probieren Sie es später erneut.');
    }
    finally {
      setIsLoading(false);
    }
  };

  useEffect((): (() => void) => {
    const callback = (ev: any): void => {
      if (ev.code === 'Enter' && !modalStore.modalIsOpen) {
        const emailInput = document.getElementsByClassName('email')[0];
        const passwordInput = document.getElementsByClassName('password')[0];

        if (!emailInput || !passwordInput) {
          return;
        }

        login((emailInput as HTMLInputElement).value, (passwordInput as HTMLInputElement).value);
      }
    };
    window.addEventListener('keypress', callback);

    return (): void => {
      window.removeEventListener('keypress', callback);
    };
  }, []);

  return (
    <StackLayout testId="loginScreen" height100 stretch hCenter spaceBetween backgroundImageUri="/img/bg.jpeg" backgroundImageResizeMode="cover">
      <StackLayout />
      {loginMfaChallengeToken ? (
        <StackLayout padding={4} minWidth={{ custom: '25vw' }} maxWidth={{custom : '75vw'}} border={0.125} borderColor="grey600" backgroundColor={{ custom: 'rgba(255,255,255,0.95)' }} borderRadius hCenter spaceBetween>
          <StackLayout hCenter width100>
            <Image
              uri="/logo.png"
              customStyle={{
          width: 156,
          height: 46
        }}
              alt="logo"
            />
            <Text marginTop={2} fontSize={{custom: 22}} fontColor="grey900">Multi-Faktor-Authentifizierung</Text>
            <Text marginTop={2} fontSize={{custom: 14}} fontColor="grey900">Bitte geben Sie jetzt einen Code ein.</Text>
            <StackLayout width100 marginTopBottom={2}>
              <TextInput
                light
                placeholder="TOTP Code"
                value={totpCode}
                onChange={setTotpCode}
              />
            </StackLayout>
            <StackLayout
              backgroundColor="primary"
              height={5}
              padding
              paddingRightLeft={3}
              hCenter
              borderRadius={0.25}
              vCenter
              onPress={loginMfa}
            >
              <Text color="white" fontWeight="bold">Login</Text>
            </StackLayout>
          </StackLayout>
        </StackLayout>
      ) : totpSetupTokenPayload ? showFinishTotpSetup ? (
        <StackLayout padding={4} minWidth={{ custom: '25vw' }} maxWidth={{custom : '75vw'}} border={0.125} borderColor="grey600" backgroundColor={{ custom: 'rgba(255,255,255,0.95)' }} borderRadius hCenter spaceBetween>
          <StackLayout hCenter width100>
            <Image
              uri="/logo.png"
              customStyle={{
            width: 156,
            height: 46
          }}
              alt="logo"
            />
            <Text marginTop={2} fontSize={{custom: 22}} fontColor="grey900">Aktivieren der Multi-Faktor-Authentifizierung</Text>
            <Text marginTop={2} fontSize={{custom: 14}} fontColor="grey900">Bitte geben Sie jetzt einen Code ein.</Text>
            <StackLayout width100 marginTopBottom={2}>
              <TextInput
                light
                placeholder="TOTP Code"
                value={totpCode}
                onChange={setTotpCode}
              />
            </StackLayout>
            <StackLayout
              backgroundColor="primary"
              height={5}
              padding
              paddingRightLeft={3}
              hCenter
              borderRadius={0.25}
              vCenter
              onPress={handleTotpSetupDonePress}
            >
              <Text color="white" fontWeight="bold">Setup abschließen</Text>
            </StackLayout>
          </StackLayout>
        </StackLayout>
      ) : (
        <StackLayout padding={4} minWidth={{ custom: '25vw' }} maxWidth={{custom : '75vw'}} border={0.125} borderColor="grey600" backgroundColor={{ custom: 'rgba(255,255,255,0.95)' }} borderRadius hCenter spaceBetween>
          <StackLayout hCenter width100>
            <Image
              uri="/logo.png"
              customStyle={{
              width: 156,
              height: 46
            }}
              alt="logo"
            />
            <Text marginTop={2} fontSize={{custom: 22}} fontColor="grey900">Aktivieren der Multi-Faktor-Authentifizierung</Text>
            <StackLayout width100 backgroundColor="white" hCenter marginTopBottom={5}>
              <QRCode value={totpSetupTokenPayload.uri} size={200} />
            </StackLayout>
            <Text marginTop={2} fontSize={{custom: 14}} fontColor="grey900">Aktivieren Sie die Multi-Faktor-Authentifizierung (MFA) für zusätzlichen Kontoschutz. Scannen Sie den QR-Code oder geben Sie den Geheimcode in Ihrer Authentifizierungs-App ein, um die MFA einzurichten.</Text>
            <StackLayout width100 marginTop marginBottom={3} backgroundColor="green200" padding>
              <Text selectable fontSize={{custom: 14}} fontColor="grey900">{totpSetupTokenPayload.base32}</Text>
            </StackLayout>
            <StackLayout
              backgroundColor="primary"
              height={5}
              padding
              paddingRightLeft={3}
              hCenter
              borderRadius={0.25}
              vCenter
              onPress={handleShowFinishTotpSetupPress}
            >
              <Text color="white" fontWeight="bold">Weiter</Text>
            </StackLayout>
          </StackLayout>
        </StackLayout>
) : (
  <StackLayout padding={4} minWidth={{ custom: '25vw' }} border={0.125} borderColor="grey600" backgroundColor={{ custom: 'rgba(255,255,255,0.95)' }} borderRadius hCenter spaceBetween>
    <StackLayout hCenter width100>
      <Image
        uri="/logo.png"
        customStyle={{
            width: 156,
            height: 46
          }}
        alt="logo"
      />
      <StackLayout width100 marginTop={5} marginBottom={2}>
        <TextInput
          className="email"
          light
          placeholder="E-Mail Adresse"
          value={email}
          testId="emailInput"
          onChange={(newText): void => {
              setEmail(newText);
            }}
        />
      </StackLayout>
      <StackLayout width100 marginBottom={3}>
        <TextInput
          className="password"
          light={{ password: true }}
          placeholder="Passwort"
          value={password}
          testId="passwordInput"
          onChange={(newText): void => {
              setPassword(newText);
            }}
        />
        <StackLayout
          onPress={(): void => {
              modalStore.openModal('forgotPassword');
            }}
          hEnd
          marginTop
          testId="forgotPassword"
        >
          <Text caption={{ primary: true }}>Passwort vergessen?</Text>
        </StackLayout>
      </StackLayout>
      {
          isLoading ? (<LoadingIndicator />) : (
            <StackLayout
              backgroundColor="primary"
              height={5}
              padding
              paddingRightLeft={3}
              hCenter
              borderRadius={0.25}
              vCenter
              onPress={(): void => {
                login(email, password);
              }}
              testId="loginBtn"
            >
              <Text color="white" fontWeight="bold">Login</Text>
            </StackLayout>
          )
        }
    </StackLayout>

  </StackLayout>
      )}

      <StackLayout />
      <ForgotPasswordModal />
      <ResetPasswordModal />
    </StackLayout>
  );
};

export default LoginScreen;
