import { createBaseDataAccessStore, QueryParameters } from '@wemogy/reactbase';
import DatabaseTemplate from '../../models/DatabaseTemplate';
import RestApiServiceFactory from '../../../../../../dataLayer/api/rest/RestApiServiceFactory';
import DatabaseTemplateDtoMapper from '../../api/dtoMappers/DatabaseTemplateDtoMapper';

const DatabaseTemplateStore = createBaseDataAccessStore(
  DatabaseTemplate,
  RestApiServiceFactory.databaseTemplateService,
  new DatabaseTemplateDtoMapper(),
  QueryParameters.create().withSorting('createdAt').withSorting('id')
);

export default DatabaseTemplateStore;
