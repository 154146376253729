import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Button, StackLayout, Modal
} from '@wemogy/reactbase';
import ICreateBackupModalProps from './ICreateBackupModalProps';
import useDataAccessStore from '../../../dataLayer/stores/hooks/UseDataAccessStore';
import RestApiServiceFactory from '../../../dataLayer/api/rest/RestApiServiceFactory';
import CreateBackupRequest from '../../../dataLayer/api/dtos/backup/CreateBackupRequest'
import NotificationManager from '../../wrappers/inAppFeedback/NotificationManager';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';

const CreateBackupModal: React.FC<ICreateBackupModalProps> = () => {
  const {
    customerStore, formStore
  } = useDataAccessStore();
  const [isLoading, setIsLoading] = useState(false);


  const customer = formStore.activeItem ? customerStore.getItem(formStore.activeItem.relatedId) : undefined;

  const createBackup = async (createBackupRequest: CreateBackupRequest): Promise<void> => {
    setIsLoading(true);
    try {
      const apiResult = await RestApiServiceFactory.backupService.create(createBackupRequest);

    if (!apiResult.ok) {
      NotificationManager.error('Es ist ein Fehler aufgetreten');
      return;
    }
    NotificationManager.success('Das Backup wurde erfolgreich geplant');
    }
    catch {
      NotificationManager.error('Es ist ein Fehler aufgetreten');
    }
    finally {
      setIsLoading(false)
    }


  }

    return (
      <Modal
        modalKey="createBackup"
        icon="fileCopy"
        title="Backup erstellen"
        large
      >
        {isLoading ? (
          <LoadingIndicator text="Plane Backup..." />
      ) : (
        <StackLayout>
          <Button onPress={(): void => {
            createBackup(new CreateBackupRequest());
          }}
          >
            Vollständiges Backup aller Kunden erstellen

          </Button>
          <StackLayout height />
          {customer ? (
            <Button onPress={(): void => {
            RestApiServiceFactory.backupService.create(new CreateBackupRequest(customer.id));
          }}
            >
              {`Backup für den Kunden ${customer.companyName} erstellen`}
            </Button>
) : null}
          <StackLayout height />
          {formStore.activeItem ? (
            <Button onPress={(): void => {
              if (!customer || !formStore.activeItem) {
                return;
              }
            RestApiServiceFactory.backupService.create(new CreateBackupRequest(customer.id, formStore.activeItem.id));
          }}
            >
              {`Backup für den Datenbank ${formStore.activeItem.name} erstellen`}
            </Button>
) : null}
          <StackLayout height />
          {
            formStore.activeItem ? formStore.activeItem.workingPackages.map(w => (
              <React.Fragment key={w.id}>
                <Button
                  onPress={(): void => {
                    if (!customer || !formStore.activeItem) {
                      return;
                    }
            RestApiServiceFactory.backupService.create(new CreateBackupRequest(customer.id, formStore.activeItem.id, w.id));
          }}
                >
                  {`Backup für das Arbeitspaket ${w.name} erstellen`}

                </Button>
                <StackLayout height />
              </React.Fragment>
            )) : null
        }
        </StackLayout>
)}
      </Modal>
    )
}

export default observer(CreateBackupModal);
