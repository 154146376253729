import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import {BaseModel, enumerationIntValuesType} from '@wemogy/reactbase';
import CommentVisibility from '../enums/CommentVisibility';

const CommentSubType = types.model({
  message: types.optional(types.string, ''),
  creatorId: types.string,
  isResolved: types.optional(types.boolean, false),
  parentId: types.maybeNull(types.string),
  visibility: types.optional(enumerationIntValuesType(CommentVisibility), CommentVisibility.Public)
})
  .named('CommentSubType')
  .views(self => ({
    get isPublic(): boolean{
      return self.visibility === CommentVisibility.Public;
    }
  }))
  .actions(self => ({
    setIsResolved(isResolved: boolean): void{
      self.isResolved = isResolved;
    },
    setVisibility(visibility: CommentVisibility): void{
      self.visibility = visibility;
    },
  }));

const Comment = types.compose(BaseModel, CommentSubType)
  .named('Comment');
export default Comment;

export interface IComment extends Instance<typeof Comment>{}
export interface ICommentSnapshotIn extends SnapshotIn<typeof Comment>{}
export interface ICommentSnapshotOut extends SnapshotOut<typeof Comment>{}
