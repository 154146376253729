import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, Modal, useModalStore, Text
} from '@wemogy/reactbase';
import IArtQrCodeDetectedModalProps from './IArtQrCodeDetectedModalProps';
import useDataAccessStore from '../../../dataLayer/stores/hooks/UseDataAccessStore';
import RestApiServiceFactory from '../../../dataLayer/api/rest/RestApiServiceFactory';
import AnnotatedFormView from '../../wrappers/formBuilder/containers/formElements/annotatedFormView/AnnotatedFormView';
import Dropdown from '../../components/dropdown/Dropdown';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import NotificationManager from '../../wrappers/inAppFeedback/NotificationManager';

const ArtQrCodeDetectedModal: React.FC<IArtQrCodeDetectedModalProps> = () => {
  const { formStore } = useDataAccessStore();
  const modalStore = useModalStore();
  const [batchNumber, setBatchNumber] = React.useState('');
  const [transportContainerId, setTransportContainerId] = React.useState('');
  const [sending, setSending] = React.useState(false);

  const qrCodePayload = modalStore.getActiveParameters<string>('artQrCodeDetected');

  const { activeItem } = formStore;

  const handleSave = useCallback(async (): Promise<void> => {
    try {
      setSending(true);
      if(!activeItem || !qrCodePayload){
        if(!qrCodePayload){
          NotificationManager.error('Leerer QR Code erkannt')
        }
        if(!activeItem){
          NotificationManager.error('Datenbank nicht geladen')
        }
        return;
      }
      const { scannerQrCodeSourceId } = activeItem.artSettings;

      if(!scannerQrCodeSourceId) {
        NotificationManager.error('Keine QR Code Quelle für in App Scanning gefunden')
        return;
      }

      const apiResult = await RestApiServiceFactory.importDataService.importQrCode(activeItem.id, {
        qrCodeSourceId: scannerQrCodeSourceId,
        qrCodePayload,
        batchNumber,
        transportContainerId
      });

      if(!apiResult.ok) {
        NotificationManager.error(`Speichern fehlgeschlagen: ${apiResult.status} - ${apiResult.errors}`);
        return;
      }

      NotificationManager.success('Speichern erfolgreich');
    } catch (error) {
      NotificationManager.error(`Speichern fehlgeschlagen: ${error}`);
    }
    finally {
      setSending(false);
    }
  }, [activeItem, qrCodePayload, batchNumber, transportContainerId]);

  // ToDo: Call QrCode import endpoint
  // ToDo: check sizzy preview
  // ToDo: create status update endpoint. (I think we need to define the primary destination name in the ArtSettings to resolve the DataItem in the backend)



  if (!activeItem || !qrCodePayload) {
    console.log(RestApiServiceFactory, setSending)
    return null;
  }

  console.log('qrCodePayload', qrCodePayload)

  const batchOptions = activeItem.artSettings.batchNumbers.map(x => ({
    label: x,
    value: x
  }));

  const transportContainerIdsOptions = activeItem.artSettings.transportContainerIds.map(x => ({
    label: x,
    value: x
  }));

  return (
    <Modal modalKey="artQrCodeDetected" icon="smartphone" title="QR Code erkannt" large>
      <StackLayout>
        <Text title>Inhalt</Text>
        <StackLayout wrap backgroundColor="green100">
          <Text noTranslation>{qrCodePayload}</Text>
        </StackLayout>

        <StackLayout marginTopBottom={2} height={0.5} backgroundColor="primary" />

        <AnnotatedFormView description="Chargennummer" marginBottom>
          <Dropdown
            light
            stretch
            selectedOption={batchOptions.find(x => x.value === batchNumber)}
            options={batchOptions}
            onOptionSelected={(selectedOption): void => {
              setBatchNumber(selectedOption.value);
            }}
          />
        </AnnotatedFormView>
        <AnnotatedFormView description="Nummer des Transportbehälters" marginBottom={4}>
          <Dropdown
            light
            stretch
            selectedOption={transportContainerIdsOptions.find(x => x.value === transportContainerId)}
            options={transportContainerIdsOptions}
            onOptionSelected={(selectedOption): void => {
              setTransportContainerId(selectedOption.value);
            }}
          />
        </AnnotatedFormView>

        {sending ? <LoadingIndicator /> : (batchNumber && transportContainerId) && <StackLayout hEnd horizontal>
          <Button onPress={handleSave}
          marginRight={2}
          >
            Speichern

          </Button>
          <Button onPress={(): void => {
            modalStore.openModal('artStatusUpdate', qrCodePayload);
          }}
          >
            Speichern & Nächste Aktion

          </Button>
        </StackLayout>}
      </StackLayout>
    </Modal>
  );
};

export default observer(ArtQrCodeDetectedModal);
