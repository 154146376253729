import React from 'react';
import { observer } from 'mobx-react';
import {
  Modal, StackLayout, useModalStore
} from '@wemogy/reactbase';
import IFormUserModalProps from './IFormUserModalProps';
import AnnotatedFormView from '../../containers/formElements/annotatedFormView/AnnotatedFormView';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import FormMemberManagement from '../../../../plugins/authorization/memberManagements/FormMemberManagement';

const FormUserModal: React.FC<IFormUserModalProps> = () => {
  const { formStore } = useDataAccessStore();
  const modalStore = useModalStore();

  const formId = modalStore.getActiveParameters<string>('formUser');

  const form = formId ? formStore.getItem(formId) : undefined;

  if (!form) {
    return null;
  }

  return (
    <Modal modalKey="formUser" icon="group" title="Benutzer verwalten" large>
      <AnnotatedFormView description="Benutzer">
        <StackLayout height={40}>
          <FormMemberManagement resourceId={form.id} />
        </StackLayout>
      </AnnotatedFormView>
    </Modal>
  );
};

export default observer(FormUserModal);
