import { types } from 'mobx-state-tree';

const FormBuilderStore = types.model({
  isEditing: types.optional(types.boolean, false),
  isSampleMode: types.optional(types.boolean, true),
})
  .named('FormBuilderStore')
  .actions(self => ({
    setIsEditing(isEditing: boolean): void{
      self.isEditing = isEditing;
    },
  }));

export default FormBuilderStore;
