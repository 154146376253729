import React from 'react';
import { observer } from 'mobx-react';
import { StackLayout, Text } from '@wemogy/reactbase';
import IInvitationListProps from './IInvitationListProps';
import Invitation from '../../components/invitation/Invitation';

const InvitationList: React.FC<IInvitationListProps> = ({
  invitations, onResend, onRemove
}) => {

  return (
    <StackLayout>
      {
        invitations.length ? invitations.map(invitation => (
          <Invitation
            key={invitation.id}
            invitation={invitation}
            onResend={(): void => {
              onResend(invitation);
            }}
            onRemove={(): void => {
              onRemove(invitation);
            }}
          />
        )) : (
          <StackLayout hCenter>
            <Text>Keine Einladung vorhanden</Text>
          </StackLayout>
        )
      }
    </StackLayout>
  );
};

export default observer(InvitationList);
