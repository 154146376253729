import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, Icon
} from '@wemogy/reactbase';
import IMarkerCodeProps from './IMarkerCodeProps';
import TooltipHelp from '../../../tooltipHelp/TooltipHelp';
import { MediaHandlerApiUri } from '../../../../../base/Constants';

const MarkerCode: React.FC<IMarkerCodeProps> = ({
  markerCode, onDelete, onEdit
}) => {
  return (
    <StackLayout padding marginBottom border={0.125} borderColor="green300" key={markerCode.id} horizontal vCenter>
      <StackLayout stretch marginRight>
        <Text>{markerCode.name}</Text>
        <Text valid>{markerCode.hint}</Text>
      </StackLayout>
      <TooltipHelp text="Bearbeiten">
        <Icon
          xl
          edit
          onPress={(): void => {
            onEdit(markerCode);
          }}
        />
      </TooltipHelp>
      <StackLayout width />
      <TooltipHelp text="Löschen">
        <Icon
          xlDanger
          deleteForever
          onPress={onDelete}
        />
      </TooltipHelp>
      <StackLayout width />
      <TooltipHelp text="Code herunterladen">
        <Icon
          xl
          getApp
          onPress={(): void => {
            const name = `QR_${markerCode.name}`;
            const uri = `${MediaHandlerApiUri}api/qrcode/pdf/${markerCode.payload}/${name}/${markerCode.hint}`;
            const link = document.createElement('a');
            link.download = `${name}.pdf`;
            link.href = uri;
            link.target = '_blank';
            link.click();
          }}
        />
      </TooltipHelp>
    </StackLayout>
  );
};

export default observer(MarkerCode);
