import {
  RestService, ApiResult, ApiWrapper
} from '@wemogy/reactbase';
import RecurringJobInformation from '../../dtos/RecurringJobInformation'

export default class RecurringJobService extends RestService<RecurringJobInformation>{
  public constructor(apiWrapper: ApiWrapper){
    super(apiWrapper, 'recurringJob');
  }

  public trigger(jobId: string): Promise<ApiResult<void>>{
    return this.apiWrapper.post(`${this.restEndpoint}/${jobId}`, {});
  }
}
