import React from 'react';
import { observer } from 'mobx-react';
import { Modal, useModalStore } from '@wemogy/reactbase';
import IAddGlobalMarkerCodeModalProps from './IAddGlobalMarkerCodeModalProps';
import { IMarkerCode } from '../../wrappers/formBuilder/dataLayer/models/MarkerCode';
import MarkerCodeForm from '../../wrappers/formBuilder/components/markerCodeForm/MarkerCodeForm';
import NotificationManager from '../../wrappers/inAppFeedback/NotificationManager';
import useDataAccessStore from '../../../dataLayer/stores/hooks/UseDataAccessStore';

const AddGlobalMarkerCodeModal: React.FC<IAddGlobalMarkerCodeModalProps> = () => {
  const modalStore = useModalStore();
  const { globalMarkerCodeStore } = useDataAccessStore();

  const editMarker = modalStore.getActiveParameters<IMarkerCode>('addGlobalMarkerCode');

  return (
    <Modal
      modalKey="addGlobalMarkerCode"
      icon="add"
      title={editMarker ? 'Marker-Code bearbeiten' : 'Marker-Code hinzufügen'}
      large
    >
      <MarkerCodeForm
        markerCode={editMarker}
        onCreated={(markerCode): void => {
          modalStore.openModal('loadingOverlay');
          globalMarkerCodeStore.create(markerCode)
            .then((apiResult): void => {
              if (apiResult.ok) {
                NotificationManager.success(`Der Marker Code ${markerCode.name} wurde angelegt.`);
              } else {
                NotificationManager.error(`Beim Anlegen des Marker Code ${markerCode.name} ist ein Fehler aufgetreten.`);
              }
              modalStore.closeModal();
            });

        }}
        onUpdated={(markerCode, patch): void => {
          if (!patch) {
            return;
          }
          globalMarkerCodeStore.patch(markerCode.id, patch).then((apiResult): void => {
            if (apiResult.ok) {
              NotificationManager.success(`Der Marker Code ${markerCode.name} wurde aktualisiert.`);
            } else {
              NotificationManager.error(`Beim Aktualisieren des Marker Code ${markerCode.name} ist ein Fehler aufgetreten.`);
            }
            modalStore.closeModal();
          });
        }}
      />
    </Modal>
  );
};

export default observer(AddGlobalMarkerCodeModal);
