import {
  RestService, ApiResult, ApiWrapper
} from '@wemogy/reactbase';
import ForgotPasswordRequest from '../../dtos/account/ForgotPasswordRequest';
import ResetPasswordRequest from '../../dtos/account/ResetPasswordRequest';

export default class IdentityAccountService extends RestService<any>{
  public constructor(apiWrapper: ApiWrapper){
    super(apiWrapper, 'account');
  }

  public forgotPassword(email: string): Promise<ApiResult<void>>{
    return this.apiWrapper.post(`${this.restEndpoint}/forgotPassword`, new ForgotPasswordRequest(email));
  }

  public resetPassword(email: string, token: string, password: string): Promise<ApiResult<void>>{
    return this.apiWrapper.post(`${this.restEndpoint}/resetPassword`, new ResetPasswordRequest(email, token, password));
  }
}
