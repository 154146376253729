import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Modal, useModalStore
} from '@wemogy/reactbase';
import IAddQrCodeSourceModalProps from './IAddQrCodeSourceModalProps';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import QrCodeSource from '../../dataLayer/models/QrCodeSource';
import QrCodeSourceForm from '../../components/qrCodeSourceForm/QrCodeSourceForm';
import ValueSeparator from '../../dataLayer/enums/ValueSeparator';

const AddQrCodeSourceModal: React.FC<IAddQrCodeSourceModalProps> = () => {
  const {formStore} = useDataAccessStore();
  const modalStore = useModalStore();
  const [newQrCodeSource, setNewQrCodeSource] = useState(QrCodeSource.create({valueSeparator: ValueSeparator.Semicolon}));

  if(!formStore.activeItem){
    return null;
  }

  return (
    <Modal modalKey="addQrCodeSource" icon="add" title="QR Code hinzufügen" large>
      <StackLayout>
        <QrCodeSourceForm
          onFinished={(): void => {
            formStore.activeItem?.addQrCodeSource(newQrCodeSource);
            modalStore.openModal('manageQrCodeSources');
            setNewQrCodeSource(QrCodeSource.create({valueSeparator: ValueSeparator.Semicolon}));
          }}
          qrCodeSource={newQrCodeSource}
          addMode
        />
      </StackLayout>
    </Modal>
  );
};

export default observer(AddQrCodeSourceModal);
