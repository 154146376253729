import {
  useCallback,
  useEffect, useState
} from 'react';
import {
  ReactBaseProvider, KeyValueStorage, InsightsContextProvider, IModalStore
} from '@wemogy/reactbase';
import {Provider, observer} from 'mobx-react';
import { onPatch, getSnapshot } from 'mobx-state-tree';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { ToastContainer } from 'react-toastify';
import { OfficeApplicationSupport } from '@wemogy/reactbase-office-js';
import { AuthorizationProvider, SingleTenantId } from '@wemogy/reactbase-authorization';
import AppContainer from './navigation/container/AppContainer';
import DigitToolDefaultTheme from './ui/themes/DigitToolDefaultTheme';
import RootUiStore from './dataLayer/stores/RootUiStore';
import RootDataAccessStore from './dataLayer/stores/RootDataAccessStore';
import RootFormBuilderUiStore from './ui/wrappers/formBuilder/dataLayer/stores/RootFormBuilderUiStore';
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlayModal from './ui/modals/loadingOverlay/LoadingOverlayModal';
import RealtimeService from './dataLayer/services/RealtimeService';
import { RootAppStoreKey } from './base/Constants';
import RootAppStore, { IRootAppStore } from './dataLayer/stores/RootAppStore';
import iconResolver from './ui/components/icon/IconResolver';
import AuthorizationConfig from './ui/plugins/authorization/AuthorizationConfig';

const rootUiStore = RootUiStore.create();


function App(): JSX.Element | null {
  const [rootAppStore, setRootAppStore] = useState<IRootAppStore | undefined>(undefined);
  const [rootDataAccessStore] = useState(RootDataAccessStore.create());
  const [rootFormBuilderUiStore] = useState(RootFormBuilderUiStore.create());
  const [modalStore, setModalStore] = useState<IModalStore | undefined>(undefined);

  const onModalStore = useCallback(setModalStore, []);
  useEffect((): void => {
    if (!modalStore || !rootAppStore?.activeUserStore.isAuthenticated) {
      return;
    }
    RealtimeService.initialize(modalStore);
  }, [modalStore, rootAppStore?.activeUserStore.isAuthenticated]);

  useEffect((): void => {
    KeyValueStorage.get<string>(RootAppStoreKey).then((rootAppStoreSnapshot: string | undefined): void => {
      // App store get & save on update
      const rootAppStoreInstance: IRootAppStore = RootAppStore.create(rootAppStoreSnapshot);


      onPatch(rootAppStoreInstance, (): void => {
        let snapshot = getSnapshot(rootAppStoreInstance);
        snapshot = JSON.parse(JSON.stringify(snapshot));
        snapshot.isInitialized = false;
        KeyValueStorage.set(RootAppStoreKey, snapshot);
      });

      console.log('setRootAppStore', rootAppStoreInstance);
      setRootAppStore(rootAppStoreInstance);
    });
  }, []);

  if(!rootAppStore){
    return null;
  }

  return (
    <OfficeApplicationSupport>
      <Provider rootAppStore={rootAppStore} rootUiStore={rootUiStore} rootDataAccessStore={rootDataAccessStore} rootFormBuilderUiStore={rootFormBuilderUiStore}>
        <ReactBaseProvider theme={DigitToolDefaultTheme} iconResolver={iconResolver}>
          <InsightsContextProvider instrumentationKey="0be44a2c-7e06-4803-bcba-96f545915a4d">
            <AuthorizationProvider config={AuthorizationConfig} activeSubjectId={rootAppStore.activeUserStore.userId} activeTenantId={SingleTenantId} apiAuthenticationInformation={rootAppStore.activeUserStore.apiAuthenticationInformation}>
              <DndProvider backend={Backend}>
                <AppContainer onModalStore={onModalStore} />
                <ToastContainer />
                <LoadingOverlayModal />
              </DndProvider>
            </AuthorizationProvider>
          </InsightsContextProvider>
        </ReactBaseProvider>
      </Provider>
    </OfficeApplicationSupport>
  );
}

export default observer(App);
