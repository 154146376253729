import React from 'react';
import { observer } from 'mobx-react';
import IAllControlsProps from './IAllControlsProps';
import ToolboxElementList from '../../toolboxElementList/ToolboxElementList';
import ToolboxElementType from '../../../dataLayer/uiEnums/ToolboxElementType';
import IControlConfig from './interfaces/IControlConfig';
import InputTypeConfig from '../../../dataLayer/models/controlTypeConfigs/InputTypeConfig';
import RadioTypeConfig from '../../../dataLayer/models/controlTypeConfigs/RadioTypeConfig';
import CheckGroupTypeConfig from '../../../dataLayer/models/controlTypeConfigs/CheckGroupTypeConfig';
import DropdownTypeConfig from '../../../dataLayer/models/controlTypeConfigs/DropdownTypeConfig';
import GeolocationTypeConfig from '../../../dataLayer/models/controlTypeConfigs/GeolocationTypeConfig';
import ControlType from '../../../dataLayer/enums/ControlType';
import DataType from '../../../dataLayer/enums/DataType';

const AllControls: React.FC<IAllControlsProps> = () => {
  return (
    <ToolboxElementList
      type={ToolboxElementType.Control}
      elements={[{
        key: ControlType.Input,
        label: 'Text',
        icon: 'fontDownload',
        config: {dataType: DataType.String} as IControlConfig
      },
      {
        key: ControlType.Input,
        label: 'Ganzzahl',
        icon: 'exposure',
        config: {
          dataType: DataType.Int, config: {isNumberInput: true} as InputTypeConfig
        } as IControlConfig
      },
      {
        key: ControlType.Input,
        label: 'Kommazahl',
        icon: 'exposure',
        config: {
          dataType: DataType.Double, config: {isNumberInput: true} as InputTypeConfig
        } as IControlConfig
      },
      {
        key: ControlType.Checkbox,
        label: 'Checkbox',
        icon: 'toggleOn',
        config: {dataType: DataType.Boolean} as IControlConfig
      },
      {
        key: ControlType.Radio,
        label: 'Einzelauswahl',
        icon: 'radioButtonChecked',
        config: {
          dataType: DataType.String, config: new RadioTypeConfig()
        } as IControlConfig
      },
      {
        key: ControlType.CheckGroup,
        label: 'Mehrfachauswahl',
        icon: 'checkBox',
        config: {
          dataType: DataType.StringList, config: new CheckGroupTypeConfig()
        } as IControlConfig
      },
      {
        key: ControlType.Dropdown,
        label: 'DropDown',
        icon: 'arrowDropDownCircle',
        config: {
          dataType: DataType.String, config: new DropdownTypeConfig()
        } as IControlConfig
      },
      {
        key: ControlType.Date,
        label: 'Datum',
        icon: 'event',
        config: {
          dataType: DataType.Date, config: new DropdownTypeConfig()
        } as IControlConfig
      },
      {
        key: ControlType.DateTime,
        label: 'Datum mit Uhrzeit',
        icon: 'schedule',
        config: {
          dataType: DataType.DateTime, config: new DropdownTypeConfig()
        } as IControlConfig
      },
      {
        key: ControlType.Image,
        label: 'Bild',
        icon: 'photo',
        config: {
          dataType: DataType.String, config: new DropdownTypeConfig()
        } as IControlConfig
        },
        {
          key: ControlType.Geolocation,
          label: 'Geolocation',
          icon: 'editLocation',
          config: {
            dataType: DataType.Address, config: new GeolocationTypeConfig()
          } as IControlConfig
        }]}
      onElementSelected={(): void => {
        // console.log(element);
      }}
    />
  );
};

export default observer(AllControls);
