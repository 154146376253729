import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import { IAttachment } from '../../../ui/wrappers/formBuilder/dataLayer/models/Attachment';

const ClipboardStore = types.model({
  attachmentInClipboard: types.maybe(types.string),
  attachmentInClipboardDataItemId: types.maybe(types.string),
  clipboardIsCopy: types.optional(types.boolean, false)
})
  .named('ClipboardStore')
  .views(self => ({
    get attachmentClipboardHasAttachment(): boolean{
      return self.attachmentInClipboard !== undefined;
    }
  }))
  .actions(self => ({
    copyAttachment(attachment: IAttachment): void {
      self.clipboardIsCopy = true;
      self.attachmentInClipboard = attachment.id;
      self.attachmentInClipboardDataItemId = attachment.dataItemId;
    },
    moveAttachment(attachment: IAttachment): void {
      self.clipboardIsCopy = false;
      self.attachmentInClipboard = attachment.id;
      self.attachmentInClipboardDataItemId = attachment.dataItemId;
    },
    clearAttachmentClipboard(): void{
      self.attachmentInClipboard = undefined;
      self.attachmentInClipboardDataItemId = undefined;
    }
  }));
export default ClipboardStore;

export interface IClipboardStore extends Instance<typeof ClipboardStore>{}
export interface IClipboardStoreSnapshotIn extends SnapshotIn<typeof ClipboardStore>{}
export interface IClipboardStoreSnapshotOut extends SnapshotOut<typeof ClipboardStore>{}
