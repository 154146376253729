import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, Icon
} from '@wemogy/reactbase';
import IStatisticSectionProps from './IStatisticSectionProps';
import BarChart from '../../../wrappers/charts/barChart/BarChart';
import NoData from '../../../components/noData/NoData';

const StatisticSection: React.FC<IStatisticSectionProps> = ({
  name, barSections, maxValue, marginBottom, infoSection, addMore, onPress
}) => {

  return (
    <StackLayout marginBottom={marginBottom} padding border={0.125} borderRadius borderColor="grey800">
      <StackLayout onPress={onPress} marginBottom={2} horizontal spaceBetween>
        <Text title>{name}</Text>
        {onPress ? <Icon xl chevronRight /> : null}
      </StackLayout>
      {infoSection ? (
        <StackLayout paddingTopBottom marginTopBottom borderTopBottom={0.125} borderRightLeft={0} borderColor="grey500">
          {infoSection}
        </StackLayout>
      ) : null}
      {
        maxValue === undefined ? (
          <NoData />
        ): (
          <BarChart maxValue={maxValue} sections={barSections} />
        )
      }
      {addMore || null}
    </StackLayout>
  );
};

export default observer(StatisticSection);
