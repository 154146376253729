/* eslint-disable no-case-declarations */
import _ from 'lodash';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  TextInput, Text, generateGuid, StackLayout, Icon, useContextMenuStore, useModalStore
} from '@wemogy/reactbase';
import { MapSearch } from '@wemogy/reactbase-osm';
import IControlRendererProps from './IControlRendererProps';
import EditWrapper from '../../../components/editWrapper/EditWrapper';
import InputTypeConfig from '../../../dataLayer/models/controlTypeConfigs/InputTypeConfig';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import RadioGroup from '../../../../../components/radioGroup/RadioGroup';
import RadioTypeConfig from '../../../dataLayer/models/controlTypeConfigs/RadioTypeConfig';
import CheckGroupTypeConfig from '../../../dataLayer/models/controlTypeConfigs/CheckGroupTypeConfig';
import DropdownTypeConfig from '../../../dataLayer/models/controlTypeConfigs/DropdownTypeConfig';
import CheckGroup from '../../../../../components/checkGroup/CheckGroup';
import Dropdown from '../../../../../components/dropdown/Dropdown';
import ControlType from '../../../dataLayer/enums/ControlType';
import useDataItemStore from '../../../dataLayer/stores/hooks/UseDataItemStore';
import { setterName } from '../../../dataLayer/models/genericModels/DataItem';
import TooltipHelp from '../../../../tooltipHelp/TooltipHelp';
import useFormBuilderUiStore from '../../../dataLayer/stores/hooks/UseFormBuilderUiStore';
import DataType from '../../../dataLayer/enums/DataType';
import IHistoryContextMenuParameters from '../../../contextMenus/historyContextMenu/interfaces/IHistoryContextMenuParameters';
import ValidationResult from '../../../dataLayer/enums/ValidationResult';
import GeolocationTypeConfig from '../../../dataLayer/models/controlTypeConfigs/GeolocationTypeConfig';
import LoadingIndicator from '../../../../../components/loadingIndicator/LoadingIndicator';
import useFormPermissionFeature from '../../../../../plugins/authorization/hooks/UseFormPermissionFeature';
import AutocompleteInputControl from '../../../components/autocompleteInputControl/AutocompleteInputControl';

// "yyyy.MM.dd HH:mm"
function valueToDateValue(value: string | null): string {
  if (!value) {
    return '';
  }
  return value.split(' ')[0].split('.').join('-');
}

// "yyyy.MM.dd HH:mm" => 2020-07-18T10:11
function valueToDateTimeValue(value: string | null): string {
  if (!value) {
    return '';
  }
  const date = valueToDateValue(value);
  const time = value.split(' ')[1];
  return `${date}T${time}`;
}


const ControlRenderer: React.FC<IControlRendererProps> = ({
  control, readonlySections, onDelete
}) => {
  const modalStore = useModalStore();
  const contextMenuStore = useContextMenuStore();
  let controlToRender: JSX.Element;
  const dataItemStore = useDataItemStore();
  const { formBuilderStore } = useFormBuilderUiStore();
  const [forceReRender, setForceReRender] = useState(generateGuid());
  const { hasOnActiveFormPermission } = useFormPermissionFeature();

  const dataItem = dataItemStore?.activeItem;
  const value = dataItem && control.destinationName ? dataItem.data[control.destinationName] : control.getDefaultValue();

  const showValidation = hasOnActiveFormPermission('CanSeeValidationColors');

  const readonly = control.readOnly || (control.formSection !== undefined && readonlySections.includes(control.formSection));

  const validationResult = control.isMainFormSection && showValidation && dataItem ? dataItem?.getLatestValidationResult(control.destinationName) : undefined;

  // console.log('READONLY' ,control.destinationName, readonly, control.formSection, readonlySections)
  const handleValueChange = (newValue: any): void => {
    if (!dataItem || !control.destinationName) {
      console.warn('No dataItem');
      return;
    }

    if (!dataItem.isEditing) {
      dataItem.beginEditing();
    }

    dataItem.continueEditing();
    console.log('setter', setterName(control.destinationName), dataItem, newValue)
    dataItem.data[setterName(control.destinationName)](newValue);
    dataItem.pauseEditing();

    if (forceReRender) setForceReRender(generateGuid());
  };

  const validationResultBackground = showValidation
    ? validationResult === ValidationResult.Equals ? 'success'
      : validationResult === ValidationResult.Undefined ? 'warning'
        : validationResult === ValidationResult.Unequals ? 'danger'
          : undefined : undefined;

  if (!control.destinationName) {
    controlToRender = <Text danger>Es ist ein Feldname erforderlich</Text>;
  }
  else {
    switch (control.type) {
      case ControlType.Input:
        const inputConfig = control.getConfig<InputTypeConfig>() || new InputTypeConfig();

        const isOneLineString = false && !inputConfig.isMultiline && control.dataType === DataType.String;

        if (isOneLineString) {
          controlToRender = (<AutocompleteInputControl className={`bg_${validationResultBackground}`} />);
        }
        else {
          controlToRender = (
            // ToDo: This is a hotfix to support background color in number input
            <StackLayout className={`bg_${validationResultBackground}`} width100>
              <TextInput
                noAutocomplete
                noTranslation
                value={value === undefined ? '' : value}
                onChange={readonly ? undefined : (newValue: string): void => {
                  switch (control.dataType) {
                    case DataType.Double:
                      handleValueChange(parseFloat(newValue));
                      break;
                    case DataType.Int:
                      handleValueChange(parseInt(newValue));
                      break;
                    default:
                      handleValueChange(newValue);
                      break;
                  }
                }}
                disabled={readonly}
                numeric={inputConfig.isNumberInput}
                placeholder={inputConfig.placeholder}
                multiline={inputConfig.isMultiline}
                validationMode={showValidation ? validationResult === ValidationResult.Equals
                  ? { equals: true } : validationResult === ValidationResult.Unequals
                    ? { unequals: true } : validationResult === ValidationResult.Undefined
                      ? { undefined: true } : undefined : undefined}
                /* autocompleteResults={[{
                  key: '123',
                  title: 'TIT',
                  reference: 0
                }]}
                onAutocompleteResultSelected={({title}): void => {
                  handleValueChange(title);
                }} */
              />
            </StackLayout>
          );
        }

        break;
      case ControlType.Checkbox:
        controlToRender = (
          <StackLayout backgroundColor={validationResultBackground} width100>
            <Checkbox disabled={readonly} checked={value} onChange={readonly ? undefined : handleValueChange} />
          </StackLayout>
        );
        break;
      case ControlType.Radio:
        const radioConfig = control.getConfig<RadioTypeConfig>() || new RadioTypeConfig();
        controlToRender = (
          <StackLayout backgroundColor={validationResultBackground} width100>
            <RadioGroup
              disabled={readonly}
              selectedOption={value}
              options={radioConfig.options}
              onChange={(option): void => {
                handleValueChange(option);
              }}
            />
          </StackLayout>
        );
        break;
      case ControlType.CheckGroup:
        const checkGroupConfig = control.getConfig<CheckGroupTypeConfig>() || new CheckGroupTypeConfig();
        const sO = _.isArray(value) ? value : value ? JSON.parse(value) : undefined;
        controlToRender = (
          <StackLayout backgroundColor={validationResultBackground} width100>
            <CheckGroup
              disabled={readonly}
              options={checkGroupConfig.options}
              selectedOptions={sO}
              onSelectedOptionsChange={(selectedOptions): void => {
                const serialized = JSON.stringify(selectedOptions);
                handleValueChange(serialized);
              }}
            />
          </StackLayout>
        );
        break;
      case ControlType.Dropdown:
        const dropdownTypeConfig = control.getConfig<DropdownTypeConfig>() || new DropdownTypeConfig();
        const dropdownOptions = dropdownTypeConfig.options.map(x => ({
          label: x.key,
          value: x.value
        }));

        controlToRender = (
          <Dropdown
            backgroundKey={validationResultBackground}
            options={dropdownOptions}
            light
            stretch
            isDisabled={readonly}
            isSearchable
            isClearable
            selectedOption={dropdownOptions.find(x => JSON.parse(x.value) === value)}
            onOptionSelected={(option): void => {
              if (readonly) {
                console.log('control is readonly', control);
                return;
              }
              if (option?.value === null || option?.value === undefined) {
                handleValueChange(undefined);
              }
              else {
                handleValueChange(JSON.parse(option.value));
              }
            }}
            maxMenuHeight={160}
          />
        );
        break;
      case ControlType.Date:
        controlToRender = (
          <StackLayout backgroundColor={validationResultBackground} width100>
            <input
              type="date"
              autoComplete="no"
              value={valueToDateValue(value)}
              style={{ background: validationResultBackground ? 'transparent' : undefined }}
              onChange={readonly ? undefined : (ev): void => {
                if (ev.target.valueAsDate) {
                  handleValueChange(ev.target.value.split('-').join('.'));
                }
                else {
                  handleValueChange(undefined);
                }
              }}
              disabled={readonly}
            />
          </StackLayout>
        );
        break;
      case ControlType.DateTime:
        controlToRender = (
          <StackLayout backgroundColor={validationResultBackground} width100>
            <input
              type="datetime-local"
              value={valueToDateTimeValue(value)}
              style={{ background: validationResultBackground ? 'transparent' : undefined }}
              onChange={readonly ? undefined : (ev): void => {
                if (ev.target.value) {
                  const date = ev.target.value.split('T')[0].split('-').join('.');
                  const time = ev.target.value.split('T').pop();
                  handleValueChange(`${date} ${time}`);
                }
                else {
                  handleValueChange(undefined);
                }
              }}
              disabled={readonly}
            />
          </StackLayout>
        );
        break;
      case ControlType.Geolocation:
        const geolocationTypeConfig = new GeolocationTypeConfig() || control.getConfig<GeolocationTypeConfig>();
        const valueDeserialized = value ? JSON.parse(value) : undefined;
        controlToRender = (
          <MapSearch
            disableFlyAnimation
            center={geolocationTypeConfig.center}
            loadingIndicatorRenderer={(): JSX.Element => (
              <LoadingIndicator animation="puff" />
            )}
            address={valueDeserialized}
            onAddressChanged={(address): void => {
              console.log('Address changed', address)
              handleValueChange(JSON.stringify(address));
            }}
          />
        );
        break;
      default:
        return null;
    }
  }

  return (
    <EditWrapper
      onDelete={onDelete ? (): void => {
        onDelete(control);
      } : undefined}
      onEdit={(): void => {
        modalStore.openModal('editControl', control);
      }}
    >
      {!formBuilderStore.isEditing && hasOnActiveFormPermission('CanSeeHistory') ? (
        <StackLayout width100 horizontal vCenter>
          <StackLayout
            stretch
            onContextMenu={(e): void => {
              contextMenuStore.openContextMenu(e, 'control', control)
            }}
          >
            <TooltipHelp stretch text={control.tooltip}>{controlToRender}</TooltipHelp>
          </StackLayout>
          <Icon
            xl
            history
            marginLeft
            onPress={(event): void => {
              contextMenuStore.openContextMenu(event, 'history', {
                dataItem, property: control.destinationName
              } as IHistoryContextMenuParameters);
            }}
          />
        </StackLayout>
      ) : (
        <StackLayout onContextMenu={(e): void => {
            if (0 < 5) {
              return;
            }
          contextMenuStore.openContextMenu(e, 'control')
        }}
        >
          {!formBuilderStore.isEditing && control.hasTooltip ? <TooltipHelp stretch text={control.tooltip}>{controlToRender}</TooltipHelp> : controlToRender}
        </StackLayout>
      )}
    </EditWrapper>
  );
};

export default observer(ControlRenderer);
