import useDataAccessStore from '../../../../dataLayer/stores/hooks/UseDataAccessStore';
import { IForm } from '../dataLayer/models/Form';

export default function useMarkerCodeNameResolver(): ((form: IForm, markerCodeId: string) => string) {
  const {
    globalMarkerCodeStore, customerStore
    } = useDataAccessStore();

  return (form: IForm, markerCodeId: string): string => {
    const digitToolMarkerCodeId = markerCodeId.split('digittool-->')[1];

    const customer = customerStore.getItem(form.relatedId);

    return form.getMarkerCode(digitToolMarkerCodeId)?.name || customer?.getMarkerCode(digitToolMarkerCodeId)?.name || globalMarkerCodeStore.getItem(digitToolMarkerCodeId)?.name || markerCodeId;
  }
}
