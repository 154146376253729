import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, Icon
} from '@wemogy/reactbase';
import IPasswordValidationOutputProps from './IPasswordValidationOutputProps';
import {
  hasUpperCase, hasLowerCase, hasNumber, hasSpecialCharacter
} from '../../../helpers/StringHelpers';

const PasswordValidationOutput: React.FC<IPasswordValidationOutputProps> = ({password}) => {
  const passwordLengthValid = password.length >= 10;
  const passwordContainsUpperCase = hasUpperCase(password);
  const passwordContainsLowerCase = hasLowerCase(password);
  const passwordContainsNumber = hasNumber(password);
  const passwordContainsSpecialCharacter = hasSpecialCharacter(password);
  return (
    <>
      <StackLayout horizontal marginBottom={0.5}>
        <Icon done={passwordLengthValid} clear={!passwordLengthValid} xlSuccess={passwordLengthValid} xlDanger={!passwordLengthValid} size={2} marginRight />
        <Text valid={passwordLengthValid} invalid={!passwordLengthValid}>Mindestens 10 Zeichen</Text>
      </StackLayout>
      <StackLayout horizontal marginBottom={0.5}>
        <Icon done={passwordContainsUpperCase} clear={!passwordContainsUpperCase} xlSuccess={passwordContainsUpperCase} xlDanger={!passwordContainsUpperCase} size={2} marginRight />
        <Text valid={passwordContainsUpperCase} invalid={!passwordContainsUpperCase}>Mindestens ein Großbuchstabe</Text>
      </StackLayout>
      <StackLayout horizontal marginBottom={0.5}>
        <Icon done={passwordContainsLowerCase} clear={!passwordContainsLowerCase} xlSuccess={passwordContainsLowerCase} xlDanger={!passwordContainsLowerCase} size={2} marginRight />
        <Text valid={passwordContainsLowerCase} invalid={!passwordContainsLowerCase}>Mindestens ein Kleinbuchstabe</Text>
      </StackLayout>
      <StackLayout horizontal marginBottom={0.5}>
        <Icon done={passwordContainsNumber} clear={!passwordContainsNumber} xlSuccess={passwordContainsNumber} xlDanger={!passwordContainsNumber} size={2} marginRight />
        <Text valid={passwordContainsNumber} invalid={!passwordContainsNumber}>Mindestens eine Zahl</Text>
      </StackLayout>
      <StackLayout horizontal>
        <Icon done={passwordContainsSpecialCharacter} clear={!passwordContainsSpecialCharacter} xlSuccess={passwordContainsSpecialCharacter} xlDanger={!passwordContainsSpecialCharacter} size={2} marginRight />
        <Text valid={passwordContainsSpecialCharacter} invalid={!passwordContainsSpecialCharacter}>Mindestens ein Sonderzeichen</Text>
      </StackLayout>
    </>
  );
};

export default observer(PasswordValidationOutput);
