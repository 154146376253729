import React from 'react';
import { observer, Provider } from 'mobx-react';
import {StackLayout} from '@wemogy/reactbase';
import IFormBuilderProps from './IFormBuilderProps';

import Toolbox from '../toolbox/Toolbox';
import FormDesigner from '../formDesigner/FormDesigner';
import EditUiElementModal from '../../modals/editUiElementModal/EditUiElementModal';
import EditControlModal from '../../modals/editControlModal/EditControlModal';
import EditDataSourceModal from '../../modals/editDataSourceModal/EditDataSourceModal';
import AddDataSourceModal from '../../modals/addDataSourceModal/AddDataSourceModal';
import ManageDataSourcesModal from '../../modals/manageDataSourcesModal/ManageDataSourcesModal';
import EditTableModal from '../../modals/layouts/editTableModal/EditTableModal';
import EditAccordionModal from '../../modals/layouts/editAccordionModal/EditAccordionModal';
import AddDatabaseTemplateModal from '../../modals/templates/addDatabaseTemplateModal/AddDatabaseTemplateModal';
import ManageDatabaseTemplatesModal from '../../modals/templates/manageDatabaseTemplatesModal/ManageDatabaseTemplatesModal';
import OpenDatabaseTemplateModal from '../../modals/templates/openDatabaseTemplateModal/OpenDatabaseTemplateModal';
import ImportDataModal from '../../modals/importDataModal/ImportDataModal';
import ExportDataModal from '../../modals/exportDataModal/ExportDataModal';
import AddAutocompleteModal from '../../modals/addAutocompleteModal/AddAutocompleteModal';
import ManageWorkingPackageModal from '../../modals/manageWorkingPackageModal/ManageWorkingPackageModal';
import AddWorkingPackageModal from '../../modals/addWorkingPackageModal/AddWorkingPackageModal';
import EditWorkingPackageModal from '../../modals/editWorkingPackageModal/EditWorkingPackageModal';
import ManageNavigationConfigurationModal from '../../modals/manageNavigationConfigurationModal/ManageNavigationConfigurationModal';
import ExportCommentsModal from '../../../../modals/exportComments/ExportCommentsModal';
import ExportKeywordingModal from '../../../../modals/exportKeywording/ExportKeywordingModal';
import SampleSettingsModal from '../../modals/sampleSettingsModal/SampleSettingsModal';
import StartSampleModal from '../../modals/startSample/StartSampleModal';
import AddMarkerCodeModal from '../../modals/addMarkerCodeModal/AddMarkerCodeModal';
import ManageMarkerCodesModal from '../../modals/manageMarkerCodesModal/ManageMarkerCodesModal';
import FormUserModal from '../../modals/formUserModal/FormUserModal';
import AddQrCodeSourceModal from '../../modals/addQrCodeSourceModal/AddQrCodeSourceModal';
import ManageQrCodeSourcesModal from '../../modals/manageQrCodeSourcesModal/ManageQrCodeSourcesModal';
import EditQrCodeSourceModal from '../../modals/editQrCodeSourceModal/EditQrCodeSourceModal';
import ArtSettingsModal from '../../modals/artSettingsModal/ArtSettingsModal';


const FormBuilder: React.FC<IFormBuilderProps> = () => {

  return (
    <>
      <Provider>
        <StackLayout height100 horizontal stretch>
          <FormDesigner />
          <Toolbox />
        </StackLayout>
        <EditUiElementModal />
        <EditControlModal />
        <EditDataSourceModal />
        <AddDataSourceModal />
        <ManageDataSourcesModal />
        <AddQrCodeSourceModal />
        <ManageQrCodeSourcesModal />
        <EditQrCodeSourceModal />
        <EditTableModal />
        <EditAccordionModal />
        <AddDatabaseTemplateModal />
        <ManageDatabaseTemplatesModal />
        <OpenDatabaseTemplateModal />
        <ImportDataModal />
        <ExportDataModal />
        <ExportCommentsModal />
        <ExportKeywordingModal />
        <AddAutocompleteModal />
        <ManageWorkingPackageModal />
        <AddWorkingPackageModal />
        <EditWorkingPackageModal />
        <ManageNavigationConfigurationModal />
        <SampleSettingsModal />
        <StartSampleModal />
        <AddMarkerCodeModal />
        <ManageMarkerCodesModal />
        <FormUserModal />
        <ArtSettingsModal />
      </Provider>
    </>
  );
};

export default observer(FormBuilder);
