import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Text, StackLayout, Button
} from '@wemogy/reactbase';
import IFormStatisticsScreenProps from './IFormStatisticsScreenProps';
import MainLayout from '../../../layouts/mainLayout/MainLayout';
import ScrollContainer from '../../../wrappers/scrollContainer/ScrollContainer';
import useDataAccessStore from '../../../../dataLayer/stores/hooks/UseDataAccessStore';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import WorkingPackageStatistics from '../../../containers/statistics/workingPackageStatistics/WorkingPackageStatistics';

const FormStatisticsScreen: React.FC<IFormStatisticsScreenProps> = ({ formId }) => {
  const { formStore } = useDataAccessStore();

  useEffect((): void => {
    formStore.get(formId);
  }, []);

  const form = formStore.getItem(formId);

  if (!form) {
    return <LoadingIndicator fullscreen grid />;
  }

  return (
    <MainLayout>
      <StackLayout horizontal marginBottom={2} spaceBetween vCenter>
        <Text largeTitle>{`${form.name} Statistiken`}</Text>
        <Button>Exportieren</Button>
      </StackLayout>
      <ScrollContainer stretch>
        <WorkingPackageStatistics form={form} />
      </ScrollContainer>
    </MainLayout>
  );
};

export default observer(FormStatisticsScreen);
