import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import {ValidationRuleType} from '../enums/ValidationRuleType';

const ValidationRule = types.model({
  type: ValidationRuleType,
  config: types.maybe(types.string)
})
  .named('ValidationRule');
export default ValidationRule;

export interface IValidationRule extends Instance<typeof ValidationRule>{}
export interface IValidationRuleSnapshotIn extends SnapshotIn<typeof ValidationRule>{}
export interface IValidationRuleSnapshotOut extends SnapshotOut<typeof ValidationRule>{}
