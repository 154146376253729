import {
  Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import { createBaseDataAccessStore, QueryParameters} from '@wemogy/reactbase';
import RestApiServiceFactory from '../../api/rest/RestApiServiceFactory';
import MarkerCode from '../../../ui/wrappers/formBuilder/dataLayer/models/MarkerCode';
import MarkerCodeDtoMapper from '../../api/dtoMappers/MarkerCodeDtoMapper';

const GlobalMarkerCodeStore = createBaseDataAccessStore(
  MarkerCode,
  RestApiServiceFactory.globalMarkerCodeService,
  new MarkerCodeDtoMapper(),
  QueryParameters.create(),
);

export interface IGlobalMarkerCodeStore extends Instance<typeof GlobalMarkerCodeStore>{}
export interface IGlobalMarkerCodeStoreSnapshotIn extends SnapshotIn<typeof GlobalMarkerCodeStore>{}
export interface IGlobalMarkerCodeStoreSnapshotOut extends SnapshotOut<typeof GlobalMarkerCodeStore>{}

export default GlobalMarkerCodeStore;
