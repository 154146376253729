import React from 'react';
import { observer } from 'mobx-react';
import { StackLayout } from '@wemogy/reactbase';
import IFileRendererProps from './IFileRendererProps';
import FileType, { getFileType } from '../models/FileType';
import PdfViewer from '../pdfViewer/PdfViewer';
import MicrosoftOfficeViewer from '../microsoftOfficeViewer/MicrosoftOfficeViewer';
import UnknownPreview from '../unknownPreview/UnknownPreview';

const FileRenderer: React.FC<IFileRendererProps> = ({
  format, uri, width, height, onOutlineSave
}) => {

  const fileType = getFileType(format);

  switch (fileType) {
    case FileType.Image:
      return (
        <StackLayout height={{custom: height}} vCenter hCenter>
          <img
            alt="fileRenderer"
            style={{width}}
            src={uri}
          />
        </StackLayout>
      );
    case FileType.Pdf:
      return <PdfViewer uri={uri} width={width} height={height} onOutlineSave={onOutlineSave} />;
    case FileType.MicrosoftOffice:
      return <MicrosoftOfficeViewer uri={uri} width={width} height={height} />;
    case FileType.Unknown:
      return <UnknownPreview width={width} height={height} />;
    default:
      return null;
  }
};

export default observer(FileRenderer);
