import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import { isEmptyOrWhiteSpace } from '@wemogy/reactbase';
import { IValueComparator, ValueComparatorType } from '../enums/ValueComparator';


const PrimaryKey = types.model({
  sourceName: types.optional(types.string, ''),
  destinationName: types.optional(types.string, ''),
  comparator: ValueComparatorType
})
  .named('PrimaryKey')
  .views(self => ({
    get isValid(): boolean{
      return !isEmptyOrWhiteSpace(self.sourceName) && !isEmptyOrWhiteSpace(self.destinationName);
    }
  }))
  .actions(self => ({
    setSourceName(sourceName: string): void{
      self.sourceName = sourceName;
    },
    setDestinationName(destinationName: string): void{
      self.destinationName = destinationName;
    },
    setComparator(comparator: IValueComparator): void{
      self.comparator = comparator;
    },
  }));
export default PrimaryKey;

export interface IPrimaryKey extends Instance<typeof PrimaryKey>{}
export interface IPrimaryKeySnapshotIn extends SnapshotIn<typeof PrimaryKey>{}
export interface IPrimaryKeySnapshotOut extends SnapshotOut<typeof PrimaryKey>{}
