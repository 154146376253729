import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Modal, useModalStore
} from '@wemogy/reactbase';
import IAddDataSourceModalProps from './IAddDataSourceModalProps';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import DataSource from '../../dataLayer/models/DataSource';
import DataSourceForm from '../../components/dataSourceForm/DataSourceForm';
import DataSourceType from '../../dataLayer/enums/DataSourceType';

const AddDataSourceModal: React.FC<IAddDataSourceModalProps> = () => {
  const {formStore} = useDataAccessStore();
  const modalStore = useModalStore();
  const [newDataSource, setNewDataSource] = useState(DataSource.create({type: DataSourceType.Csv}));

  if(!formStore.activeItem){
    return null;
  }

  return (
    <Modal modalKey="addDataSource" icon="add" title="Datenquellen hinzufügen" large>
      <StackLayout>
        <DataSourceForm
          onFinished={(): void => {
            formStore.activeItem?.addDataSource(newDataSource);
            modalStore.openModal('manageDataSources');
            setNewDataSource(DataSource.create({type: DataSourceType.Csv}));
          }}
          dataSource={newDataSource}
          addMode
        />
      </StackLayout>
    </Modal>
  );
};

export default observer(AddDataSourceModal);
