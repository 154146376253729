import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import {BaseModel} from '@wemogy/reactbase';
import Form, { IForm } from '../../ui/wrappers/formBuilder/dataLayer/models/Form';
import CustomerMember, { ICustomerMember } from './members/CustomerMember';
import MarkerCode, { IMarkerCode } from '../../ui/wrappers/formBuilder/dataLayer/models/MarkerCode';

const CustomerSubType = types.model({
  companyName: types.optional(types.string, ''),
  forms: types.optional(types.array(types.reference(Form)), []),
  members: types.optional(types.array(CustomerMember), []),
  markerCodes: types.optional(types.array(MarkerCode), []),
  // #region statistics
  numberOfDataItems: types.maybe(types.number),
  numberOfDataItemsWithAttachment: types.maybe(types.number),
  numberOfDataItemsWithAttachmentWithoutWorkingPackage: types.maybe(types.number),
  numberOfDataItemsWhichAreIndexed: types.maybe(types.number),
  numberOfDataItemsWithWorkingPackage: types.maybe(types.number)
  // #endregion
})
  .named('CustomerSubType')
  .views(self => ({
    get numberOfDataItemsWithoutAttachment(): number | undefined{
      if (self.numberOfDataItems === undefined || self.numberOfDataItemsWithAttachment === undefined) {
        return undefined;
      }
      return self.numberOfDataItems - self.numberOfDataItemsWithAttachment;
    },
    getMember(userId: string): ICustomerMember | undefined{
      return self.members.find(x => x.userId === userId);
    },
    getMarkerCode(markerCodeId: string): IMarkerCode | undefined{
      return self.markerCodes.find(x => x.id === markerCodeId);
    },
  }))
  .actions(self => ({
    setCompanyName(companyName: string): void{
      self.companyName = companyName;
    },
    addForm(form: IForm): void{
      self.forms.push(form);
    },
    addMarkerCode(markerCode: IMarkerCode): void{
      self.markerCodes.push(markerCode);
    },
    deleteMarkerCode(markerCode: IMarkerCode): void{
      self.markerCodes.remove(markerCode);
    },
    putMember(userId: string, roleId: string): void{
      const member = self.getMember(userId);
      if (member) {
        member.setRoleId(roleId);
      }
      else {
        self.members.push(CustomerMember.create({
          userId,
          roleId
        }));
      }
    },
    deleteMember(userId: string): void{
      const member = self.getMember(userId);
      if (member) {
        self.members.remove(member);
      }
    }
  }));

const Customer = types.compose(BaseModel, CustomerSubType)
  .named('Customer');
export default Customer;

export interface ICustomer extends Instance<typeof Customer>{}
export interface ICustomerSnapshotIn extends SnapshotIn<typeof Customer>{}
export interface ICustomerSnapshotOut extends SnapshotOut<typeof Customer>{}
