import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { QueryParameters, StackLayout } from '@wemogy/reactbase';
import IFormStatisticsProps from './IFormStatisticsProps';
import useDataAccessStore from '../../../../dataLayer/stores/hooks/UseDataAccessStore';
import StatisticSection from '../statisticSection/StatisticSection';
import NavigationManager from '../../../../navigation/NavigationManager';
import PdfMetadataStatisticsChart from '../../pdfMetadataStatisticsChart/PdfMetadataStatisticsChart';

const FormStatistics: React.FC<IFormStatisticsProps> = ({ customer }) => {
  const { formStore } = useDataAccessStore();
  const queryParameters = QueryParameters.create().putFilter('relatedId', customer.id, 'equals');

  useEffect((): void => {
    formStore.query(queryParameters);
  }, []);


  const forms = formStore.queryItems(queryParameters);

  return (
    <StackLayout>
      {
        forms.map(form => (
          <StatisticSection
            key={form.id}
            name={form.name}
            marginBottom={2}
            maxValue={form.numberOfDataItems}
            barSections={[
              {
                name: 'Datensätze (Gesamt)',
                value: form.numberOfDataItems !== undefined ? {
                  name: 'Gesamt',
                  value: form.numberOfDataItems
                } : undefined
              },

              {
                name: 'Datensätze in Arbeitspaketen',
                value: form.numberOfDataItemsWithWorkingPackage !== undefined ? {
                  name: 'in Arbeitspaketen',
                  value: form.numberOfDataItemsWithWorkingPackage
                } : undefined
              },

              {
                name: 'Datensätze ohne Arbeitspaket',
                value: form.numberOfDataItemsWithWorkingPackage !== undefined &&  form.numberOfDataItems !== undefined ? {
                  name: 'ohne Arbeitspaket',
                  value: form.numberOfDataItems - form.numberOfDataItemsWithWorkingPackage
                } : undefined
              },

              {
                name: 'Datensätze indexiert',
                value: form.numberOfDataItemsWhichAreIndexed !== undefined ? {
                  name: 'indexiert',
                  value: form.numberOfDataItemsWhichAreIndexed
                } : undefined
              },

              {
                name: 'mit mind. einem Dokument',
                value: form.numberOfDataItemsWithAttachment !== undefined ? {
                  name: 'Datensätze mit mind. einem Dokument',
                  value: form.numberOfDataItemsWithAttachment
                } : undefined
              },

              {
                name: 'mit mind. einem Dokument ohne Arbeitspaket',
                value: form.numberOfDataItemsWithAttachmentWithoutWorkingPackage !== undefined ? {
                  name: 'Datensätze mit mind. ohne Arbeitspake',
                  value: form.numberOfDataItemsWithAttachmentWithoutWorkingPackage
                } : undefined
              },


              {
                name: 'Datensätze ohne Dokument',
                value: form.numberOfDataItemsWithoutAttachment !== undefined ? {
                  name: 'ohne Dokument',
                  value: form.numberOfDataItemsWithoutAttachment
                } : undefined
              },
            ]}
            onPress={(): void => {
              NavigationManager.navigate(`/statistics/form/${form.id}`);
            }}
            addMore={(
              <PdfMetadataStatisticsChart form={form} pdfMarkerCodes={form.pdfMetadataStatistics.markerCodes} pdfMetadataStatisticsChart={form.pdfMetadataStatistics.calculateStatistics()} numberOfAttachments={form.numberOfAttachments} />
            )}
          />
        ))
      }
    </StackLayout>
  );
};

export default observer(FormStatistics);
