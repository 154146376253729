import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Modal
} from '@wemogy/reactbase';
import IArtSettingsModalProps from './IArtSettingsModalProps';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import Checkbox from '../../../../components/checkbox/Checkbox';
import AnnotatedFormView from '../../containers/formElements/annotatedFormView/AnnotatedFormView';
import Dropdown from '../../../../components/dropdown/Dropdown';
import IDropdownOption from '../../../../components/dropdown/interfaces/IDropdownOption';
import ListInput from '../../components/listInput/ListInput';
import ArtQrCodeMappingEditor from '../../containers/artQrCodeMappingEditor/ArtQrCodeMappingEditor';

const ArtSettingsModal: React.FC<IArtSettingsModalProps> = () => {
  const { formStore } = useDataAccessStore();

  const { activeItem } = formStore;
  if(!activeItem){
    return null;
  }

  const qrCodeSourceDropdownOptions = activeItem.qrCodeSources.map((x): IDropdownOption => ({
    value: x.id,
    label: x.name
  }));

  const options = activeItem.allControlsDestinationNamesUnique.map(x => ({
    label: x,
    value: x
  }));


  return (
    <Modal modalKey="artSettings" icon="settings" title="Einstellungen für ART" large>
      <StackLayout minHeight={{custom: '50vh'}}>
        <AnnotatedFormView description="ART Feature aktiviert" marginBottom>
          <Checkbox checked={activeItem.artSettings.featureEnabled} onChange={activeItem.artSettings.setFeatureEnabled} />
        </AnnotatedFormView>
        {activeItem.artSettings.featureEnabled && (
          <>
            <AnnotatedFormView description="Qr Code Quelle für in App Scanning" marginBottom>
              <Dropdown
                light
                selectedOption={qrCodeSourceDropdownOptions.find(x => x.value === activeItem.artSettings.scannerQrCodeSourceId)}
                options={qrCodeSourceDropdownOptions}
                onOptionSelected={(option): void => {
                  activeItem.artSettings.setScannerQrCodeSourceId(option.value);
                }}
              />
            </AnnotatedFormView>

            <AnnotatedFormView description="Qr Code Quelle PDF-Matching" marginBottom>
              <Dropdown
                light
                selectedOption={qrCodeSourceDropdownOptions.find(x => x.value === activeItem.artSettings.pdfMatchingConfig?.qrCodeSourceId)}
                options={qrCodeSourceDropdownOptions}
                onOptionSelected={(option): void => {
                  activeItem.artSettings.setPdfMatchingQrCodeSourceId(option.value);
                }}
              />
            </AnnotatedFormView>
            {activeItem.artSettings.pdfMatchingConfig && <ArtQrCodeMappingEditor pdfMatchingConfig={activeItem.artSettings.pdfMatchingConfig} destinationNames={options} qrCodeSourceDropdownOptions={qrCodeSourceDropdownOptions} />}
            <AnnotatedFormView description="Chargennummer Zielfeld" marginBottom>
              <Dropdown
                light
                stretch
                selectedOption={options.find(x => x.value === activeItem.artSettings.batchNumberDestinationName)}
                options={options}
                onOptionSelected={(selectedOption): void => {
                  activeItem.artSettings.setBatchNumberDestinationName(selectedOption.value);
                }}
              />
            </AnnotatedFormView>
            <AnnotatedFormView description="Transportnummer Zielfeld" marginBottom>
              <Dropdown
                light
                stretch
                selectedOption={options.find(x => x.value === activeItem.artSettings.transportContainerIdDestinationName)}
                options={options}
                onOptionSelected={(selectedOption): void => {
                  activeItem.artSettings.setTransportContainerIdDestinationName(selectedOption.value);
                }}
              />
            </AnnotatedFormView>
            <AnnotatedFormView description="Gesamter QR-Code Inhalt Zielfeld" marginBottom>
              <Dropdown
                light
                stretch
                selectedOption={options.find(x => x.value === activeItem.artSettings.qrCodePayloadDestinationName)}
                options={options}
                onOptionSelected={(selectedOption): void => {
                  activeItem.artSettings.setQrCodePayloadDestinationName(selectedOption.value);
                }}
              />
            </AnnotatedFormView>
            <AnnotatedFormView description="Chargennummern" marginBottom>
              <ListInput values={activeItem.artSettings.batchNumbers} onAdd={activeItem.artSettings.addBatchNumber} onRemove={activeItem.artSettings.removeBatchNumber} />
            </AnnotatedFormView>
            <AnnotatedFormView description="Nummern der Transportbehälter " marginBottom>
              <ListInput values={activeItem.artSettings.transportContainerIds} onAdd={activeItem.artSettings.addTransportContainerId} onRemove={activeItem.artSettings.removeTransportContainerId} />
            </AnnotatedFormView>
          </>
        )}

      </StackLayout>
    </Modal>
  );
};

export default observer(ArtSettingsModal);
