import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, isEmptyOrWhiteSpace, Text, Button, TextInput, Modal, useModalStore
} from '@wemogy/reactbase';
import IResetPasswordModalProps from './IResetPasswordModalProps';
import { validatePassword } from '../../../../../helpers/StringHelpers';
import IResetPasswordModalParameters from './interfaces/IResetPasswordModalParameters';
import TooltipHelp from '../../../tooltipHelp/TooltipHelp';
import PasswordValidationOutput from '../../../../components/passwordValidationOutput/PasswordValidationOutput';
import LoadingIndicator from '../../../../components/loadingIndicator/LoadingIndicator';
import RestApiServiceFactory from '../../../../../dataLayer/api/rest/RestApiServiceFactory';
import NotificationManager from '../../../inAppFeedback/NotificationManager';

const ResetPasswordModal: React.FC<IResetPasswordModalProps> = () => {
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
  const [sendingRequest, setSendingRequest] = useState(false);
  const modalStore = useModalStore();


  const newPasswordIsValid = validatePassword(newPassword);
  const newPasswordRepeatIsValid = !isEmptyOrWhiteSpace(newPasswordRepeat) && newPassword === newPasswordRepeat;
  const canSetPassword = newPasswordIsValid && newPasswordRepeatIsValid;

  const params = modalStore.getActiveParameters<IResetPasswordModalParameters>('resetPassword');

  if (!params) {
    return null;
  }

  return (
    <Modal modalKey="resetPassword" icon="lock" title="Passwort festlegen">
      <StackLayout>
        <StackLayout marginBottom={2}>
          <Text label marginBottom={0.75}>Neues Passwort</Text>
          <TooltipHelp component={<PasswordValidationOutput password={newPassword} />}>
            <TextInput className="focusFirst" noAutocomplete value={newPassword} onChange={setNewPassword} light={{password: true}} placeholder="Neues Passwort" />
          </TooltipHelp>
        </StackLayout>
        <StackLayout marginBottom={2}>
          <Text label marginBottom={0.75}>Neues Passwort wiederholen</Text>
          <TooltipHelp text={newPasswordRepeatIsValid ? undefined : 'Die Passwörter müssen übereinstimmen'}>
            <TextInput noAutocomplete value={newPasswordRepeat} onChange={setNewPasswordRepeat} light={{password: true}} placeholder="Neues Passwort wiederholen" />
          </TooltipHelp>
        </StackLayout>
        <StackLayout marginTop={2} hEnd={!sendingRequest} height={5} opacity={canSetPassword ? 1 : 0.5}>
          {sendingRequest ? (
            <LoadingIndicator />
          ) : (
            <Button
              onPress={(): void => {
                setSendingRequest(true);
                RestApiServiceFactory.identityAccountService.resetPassword(params.email, params.token, newPassword).then((apiResult): void => {
                  setNewPassword('');
                  setNewPasswordRepeat('');
                  if (apiResult.ok) {
                    NotificationManager.success('Wir haben Ihr Passwort geändert. Sie können sich jetzt einloggen.');
                    modalStore.closeModal();
                  }
                  else {
                    NotificationManager.error('Wir konnten Ihr Passwort nicht zurücksetzen. Bitte fordern Sie eine neue E-Mail an, indem Sie auf "Passwort vergessen?" klicken.');
                  }

                }).finally((): void => {
                  setSendingRequest(false);
                });
              }}
            >
              Passwort festlegen
            </Button>
          )}
        </StackLayout>
      </StackLayout>
    </Modal>
  );
};

export default observer(ResetPasswordModal);
