import React from 'react';
import { observer } from 'mobx-react';
import {
  Text, StackLayout, Button, ScrollView
} from '@wemogy/reactbase';
import IStatisticsScreenProps from './IStatisticsScreenProps';
import MainLayout from '../../../layouts/mainLayout/MainLayout';
import CustomerStatistics from '../../../containers/statistics/customerStatistics/CustomerStatistics';
import UserStatistics from '../../../containers/statistics/userStatistics/UserStatistics';

const StatisticsScreen: React.FC<IStatisticsScreenProps> = () => {

  return (
    <MainLayout>
      <StackLayout horizontal marginBottom={2} spaceBetween vCenter>
        <Text largeTitle>Kunden Statistiken</Text>
        <Button>Exportieren</Button>
      </StackLayout>
      <ScrollView>
        <StackLayout width100>
          <CustomerStatistics />
          <UserStatistics />
        </StackLayout>
      </ScrollView>
    </MainLayout>
  );
};

export default observer(StatisticsScreen);
