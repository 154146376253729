import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import {BaseModel, isEmptyOrWhiteSpace} from '@wemogy/reactbase';
import ValueSeparator, { ValueSeparatorType } from '../enums/ValueSeparator';

const QrCodeSourceSubType = types.model({
  name: types.optional(types.string, ''),
  valueSeparator: ValueSeparatorType,
  notes: types.optional(types.string, '')
})
  .named('QrCodeSourceSubType')
  .views(self => ({
    get isValid(): boolean{
      return !isEmptyOrWhiteSpace(self.name);
    }
  }))
  .actions(self => ({
    setName(name: string): void{
      self.name = name;
    },
    setValueSeparator(valueSeparator: ValueSeparator): void{
      self.valueSeparator = valueSeparator;
    },
    setNotes(notes: string): void{
      self.notes = notes;
    },
  }));

const QrCodeSource = types.compose(BaseModel, QrCodeSourceSubType)
  .named('QrCodeSource');

export default QrCodeSource;

export interface IQrCodeSource extends Instance<typeof QrCodeSource>{}
export interface IQrCodeSourceSnapshotIn extends SnapshotIn<typeof QrCodeSource>{}
export interface IQrCodeSourceSnapshotOut extends SnapshotOut<typeof QrCodeSource>{}
