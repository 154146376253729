import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, Icon
} from '@wemogy/reactbase';
import IProcessingTemplateProps from './IProcessingTemplateProps';
import TooltipHelp from '../../../tooltipHelp/TooltipHelp';

const ProcessingTemplate: React.FC<IProcessingTemplateProps> = ({
  processingTemplate, onDelete, onEdit
}) => {
  return (
    <StackLayout padding marginBottom border={0.125} borderColor="green300" key={processingTemplate.id} horizontal vCenter>
      <StackLayout stretch marginRight>
        <Text>{processingTemplate.name}</Text>
      </StackLayout>
      <TooltipHelp text="Bearbeiten">
        <Icon
          xl
          edit
          onPress={(): void => {
            onEdit(processingTemplate);
          }}
        />
      </TooltipHelp>
      <StackLayout width />
      <TooltipHelp text="Löschen">
        <Icon
          xlDanger
          deleteForever
          onPress={onDelete}
        />
      </TooltipHelp>
    </StackLayout>
  );
};

export default observer(ProcessingTemplate);
