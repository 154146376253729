/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, Icon
} from '@wemogy/reactbase';
import IDataItemHistoryListProps from './IDataItemHistoryListProps';
import { formatToShortDateTimeString } from '../../../dateSelector/DateHelpers';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import ValueRenderer from '../../../../components/valueRenderer/ValueRenderer';
import IIcons from '../../../../components/icon/interfaces/IIcons';
import StatusStep from '../../dataLayer/enums/internal/StatusStep';

const DataItemHistoryList: React.FC<IDataItemHistoryListProps> = ({
  dataItem, property
}) => {
  const {
    formStore, userStore
  } = useDataAccessStore();
  const transactions = _.orderBy(dataItem.getTransactionsOfProperty(property), x => x.createdAt, 'desc');

  if (!transactions.length) {
    return (
      <StackLayout>
        <Text>Keine Daten vorhanden</Text>
      </StackLayout>
)
  }

  return (
    <StackLayout maxHeight={30} customStyle={{overflow: 'scroll'}}>
      {
        transactions.map(transaction => {
          const dataType = formStore.activeItem?.allControls.find(x => transaction.isOfProperty(x.destinationName))?.dataType;

          if (!dataType) {
            return null;
          }

          let status: keyof IIcons = 'addCircle';

          const statusStep = dataItem.status.getStatusStepAt(transaction.createdAt);

          switch (statusStep) {
            case StatusStep.Imported:
              status = 'getApp';
              break;
            case StatusStep.AttachmentsAssigned:
              status = 'postAdd';
              break;
            case StatusStep.Indexed:
              status = 'menuBook';
              break;
            case StatusStep.DataCollected:
              status = 'edit';
              break;
            case StatusStep.DataQualityAssured:
              status = 'doneAll';
              break;
            case StatusStep.DataCollectionVerified:
              status = 'colorize';
              break;
            case StatusStep.Exported:
              status = 'publish';
              break;
            default:
              break;
          }


          const statusIcon = {};
          statusIcon[status] = true;

          return (
            <StackLayout key={transaction.createdAt.toString()} padding borderBottom={0.125} borderColor="grey800">
              <StackLayout horizontal vCenter spaceBetween marginBottom>
                <Icon {...statusIcon} xl />
                <StackLayout width={2} />
                <Text>{userStore.getItem(transaction.issuerId)?.fullname || 'Unbekannt'}</Text>
                <StackLayout width={2} />
                <Text noTranslation>{formatToShortDateTimeString(transaction.createdAt)}</Text>
              </StackLayout>

              <ValueRenderer dataType={dataType} value={transaction.value} />
            </StackLayout>
          );
        })
      }
    </StackLayout>
  );
};

export default observer(DataItemHistoryList);
