import { Instance } from 'mobx-state-tree';
import { enumerationIntValuesType } from '@wemogy/reactbase';

enum ControlType{
  Input = 1,
  Dropdown = 2,
  Checkbox = 4,
  CheckGroup = 8,
  Radio = 16,
  Date = 32,
  DateTime = 64,
  Image = 128,
  Geolocation = 256
}

export default ControlType;

export const ControlTypeType = enumerationIntValuesType(ControlType);

export type IControlType = Instance<typeof ControlTypeType>;
