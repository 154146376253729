/* eslint-disable no-await-in-loop */
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, Button, Modal, useModalStore
} from '@wemogy/reactbase';
import IStartSampleModalProps from './IStartSampleModalProps';
import Dropdown from '../../../../components/dropdown/Dropdown';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import useUiStore from '../../../../../dataLayer/stores/hooks/UseUiStore';
import useDataItemStore from '../../dataLayer/stores/hooks/UseDataItemStore';
import NotificationManager from '../../../inAppFeedback/NotificationManager';
import RestApiServiceFactory from '../../../../../dataLayer/api/rest/RestApiServiceFactory';
import StatusStep from '../../dataLayer/enums/internal/StatusStep';

const StartSampleModal: React.FC<IStartSampleModalProps> = () => {
  const { formStore } = useDataAccessStore();
  const {collectorModeStore} = useUiStore();
  const modalStore = useModalStore();
  const dataItemStore = useDataItemStore();
  const [selectedWorkingPackageId, setSelectedWorkingPackageId] = useState<string | undefined>(undefined);

  const { activeItem } = formStore;

  if (!activeItem || !dataItemStore) {
    return null;
  }

  const workingPackagesOptions = activeItem.workingPackages.map(w => ({
    label: `${w.name} (${w.begin?.toDateString() || '?'} - ${w.end?.toDateString() || '?'}) (${w.numberOfDataItemsString})`,
    value: w.id
  }));

  const selectedWorkingPackageOption = workingPackagesOptions.find(x => x.value === selectedWorkingPackageId);

  return (
    <Modal modalKey="startSample" icon="colorize" title={`Stichprobe von ${activeItem.sampleRate}% starten`} large>
      <StackLayout minHeight={{custom: '50vh'}}>
        <Text marginBottom>Bitte wählen Sie das Arbeitspaket, für das Sie die Stichprobe durchführen möchten</Text>
        <Dropdown
          light
          options={workingPackagesOptions}
          onOptionSelected={(option): void => {
            setSelectedWorkingPackageId(option.value);
          }}
          selectedOption={selectedWorkingPackageOption}
        />
        <StackLayout height />
        {
          selectedWorkingPackageId ? (
            <Button onPress={(): void => {
              (async (): Promise<void> => {
                const workingPackage = activeItem.workingPackages.find(x => x.id === selectedWorkingPackageId);

                if (!workingPackage) {
                  return;
                }

                modalStore.openModal('loadingOverlay', 'Datensätze werden ausgewählt');

                const apiResult = await RestApiServiceFactory.getDataItemService(activeItem.id).getDataItemsForSample(activeItem.id, selectedWorkingPackageId);

                if (apiResult.ok) {
                  dataItemStore.unsetActiveItem();
                  dataItemStore.clearItems();
                  apiResult.data.forEach(dataItem => dataItemStore.addOrApplySnapshot(dataItem));
                  const first = dataItemStore.items[0];
                  if (first) {
                    dataItemStore.setEditingDataItem(first, StatusStep.DataCollectionVerified);
                  }

                  collectorModeStore.setSampleModeActive(true);

                  NotificationManager.success('Die Datensätze wurde erfolgreich ausgewählt');
                } else {
                  NotificationManager.error('Beim Zuweisen ist ein Fehler aufgetreten');
                }
                modalStore.closeModal();
              })();
            }}
            >
              Jetzt Stichprobe durchführen

            </Button>
          ) : null
        }
      </StackLayout>
    </Modal>
  );
};

export default observer(StartSampleModal);
