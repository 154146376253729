import React from 'react';
import { observer } from 'mobx-react';
import { Text } from '@wemogy/reactbase';
import IUsernameProps from './IUsernameProps';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore'

const Username: React.FC<IUsernameProps> = ({userId}) => {
  const { userStore } = useDataAccessStore();

  const fullname = userStore.getItem(userId)?.fullname;

    return (
      <Text>{ fullname}</Text>
    )
}

export default observer(Username);
