import React from 'react';
import { observer } from 'mobx-react';
import Switch from 'react-switch';
import {
  useTheme, invokeEventHandler, StackLayout, Text, Icon
} from '@wemogy/reactbase';
import ICheckboxProps from './ICheckboxProps';


const Checkbox: React.FC<ICheckboxProps> = ({
  checked, boxStyle, label, marginRight,marginBottom, disabled, onChange
}) => {
  const theme = useTheme();

  if(boxStyle){
    return (
      <StackLayout
        horizontal
        vCenter
        marginRight={marginRight}
        marginBottom={marginBottom}
        onPress={disabled ? undefined : (): void => {
          invokeEventHandler(onChange, !checked);
        }}
      >
        <Icon checkBox={checked} checkBoxBlank={!checked} xl marginRight />
        <Text>{label}</Text>
      </StackLayout>
    );
  }

  return (
    <Switch
      disabled={disabled}
      height={32}
      offColor={theme.colorScheme.danger}
      onColor={theme.colorScheme.success}
      onChange={(newChecked): void =>{
        invokeEventHandler(onChange, newChecked);
      }}
      checked={checked || false}
    />
  );
};

export default observer(Checkbox);
