import React from 'react';
import { observer } from 'mobx-react';
import ILayoutDropAreaProps from './ILayoutDropAreaProps';
import BaseDropArea from '../baseDropArea/BaseDropArea';
import ToolboxElementType from '../../../dataLayer/uiEnums/ToolboxElementType';

const LayoutDropArea: React.FC<ILayoutDropAreaProps> = ({
  children, onDrop
}) => {

  return (
    <BaseDropArea onDrop={onDrop} acceptedTypes={[ToolboxElementType.Layout]} placeholder="Platzieren Sie ein Layout hier">
      {children}
    </BaseDropArea>
  );
};

export default observer(LayoutDropArea);
