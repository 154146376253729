import React from 'react';
import { observer } from 'mobx-react';
import IUiElementDropAreaProps from './IUiElementDropAreaProps';
import BaseDropArea from '../baseDropArea/BaseDropArea';
import ToolboxElementType from '../../../dataLayer/uiEnums/ToolboxElementType';

const UiElementDropArea: React.FC<IUiElementDropAreaProps> = ({
  children, stretch, onDrop
}) => {

  return (
    <BaseDropArea stretch={stretch} onDrop={onDrop} acceptedTypes={[ToolboxElementType.UiElement]} placeholder="Platzieren Sie eine Beschriftung hier">
      {children}
    </BaseDropArea>
  );
};

export default observer(UiElementDropArea);
