import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import FormDesignerStore from './uiStores/FormDesignerStore';
import FormBuilderStore from './uiStores/FormBuilderStore';

const RootFormBuilderUiStore = types.model({
  formBuilderStore: types.optional(FormBuilderStore, () => FormBuilderStore.create()),
  formDesignerStore: types.optional(FormDesignerStore, () => FormDesignerStore.create())
})
  .named('RootFormBuilderUiStore');
export default RootFormBuilderUiStore;

export interface IRootFormBuilderUiStore extends Instance<typeof RootFormBuilderUiStore>{}
export interface IRootFormBuilderUiStoreSnapshotIn extends SnapshotIn<typeof RootFormBuilderUiStore>{}
export interface IRootFormBuilderUiStoreSnapshotOut extends SnapshotOut<typeof RootFormBuilderUiStore>{}
