import { Instance } from 'mobx-state-tree';
import { enumerationIntValuesType } from '@wemogy/reactbase';

enum LayoutType{
  AnnotatedView = 1,
  AnnotatedBlock = 2,
  Table = 4,
  Accordion = 8
}

export default LayoutType;

export const LayoutTypeType = enumerationIntValuesType(LayoutType);

export type ILayoutType = Instance<typeof LayoutTypeType>;
