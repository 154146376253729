export default class DateRange{
  public constructor(
    public begin: Date,
    public end?: Date | undefined
  ) { }

  public isValidFor(date: Date): boolean{
    if (this.end) {
      return date >= this.begin && date <= this.end;
    }
    return date > this.begin;
  }
}
