import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';

const FormMember = types.model({
  userId: types.string,
  roleId: types.string
})
  .named('FormMember')
  .actions(self => ({
    setRoleId(roleId: string): void{
      self.roleId = roleId;
    },
  }));
export default FormMember;

export interface IFormMember extends Instance<typeof FormMember>{}
export interface IFormMemberSnapshotIn extends SnapshotIn<typeof FormMember>{}
export interface IFormMemberSnapshotOut extends SnapshotOut<typeof FormMember>{}
