import { enumerationIntValuesType } from '@wemogy/reactbase';

enum FileFormat {
  Unknown = 0,

  // #region Image Formats 1CCTTT // C = Category T = Type

  // #region RAW Formats 100XXX

  Tiff = 100001,
  Arw = 100002,
  Cr2 = 100003,
  Nef = 100004,

  // #endregion

  // #region Exported Formats 101XXX

  Png = 101001,
  Jpeg = 101002,

  // #endregion

  // #region Optimized Formats 102XXX

  WebP = 102001,

  // #endregion

  // #endregion

  // #region Video Formats 2CCTTT

  // #region RAW Formats 200XXX

  Mpeg = 200001,
  Mp4 = 200002,
  Mov = 200003,
  Avi = 200004,
  Mxf = 200005,

  // #endregion

  // #region Exported Formats 201XXX

  // #endregion

  // #endregion

  // #region Audio Formats 3CCTTT // https://newsandviews.dataton.com/common-audio-formats-which-one-to-use

  // #region Uncompressed audio formats 300XXX

  Wav = 300001,

  // #endregion

  // #region compressed audio formats (lossless) 301XXX

  Flac = 301001,

  // #endregion

  // #region compressed audio formats (lossy) 302XXX

  Mp3 = 302001,

  // #endregion

  // #endregion

  // #region Pdf Formats 4CCTTT

  Pdf = 400001,

  // #endregion

  // #region Microsoft Office Formats 5CCTTT

  Docx = 500001,
  Xlsx = 500002,
  Pptx = 500003

  // #endregion
}

export default FileFormat;


export function fileFormatToString(fileFormat: FileFormat): string | undefined{
  switch (fileFormat) {
    // region images
    case FileFormat.Tiff:
      return 'tiff';
    case FileFormat.Arw:
      return 'arw';
    case FileFormat.Cr2:
      return 'cr2';
    case FileFormat.Nef:
      return 'nef';
    case FileFormat.Png:
      return 'png';
    case FileFormat.Jpeg:
      return 'jpeg';
    case FileFormat.WebP:
      return 'webp';
      // #endregion

    // #region videos
    case FileFormat.Mpeg:
      return 'mpeg';
    case FileFormat.Mp4:
      return 'mp4';
    case FileFormat.Mov:
      return 'mov';
    case FileFormat.Avi:
      return 'avi';
    case FileFormat.Mxf:
      return 'mxf';
      // #endregion

    // #region  audio
    case FileFormat.Mp3:
      return 'mp3';
      // #endregion

      // #region PDF
    case FileFormat.Pdf:
      return 'pdf';
      // #endregion

      // #region Microsoft Office
    case FileFormat.Docx:
      return 'docx';
    case FileFormat.Xlsx:
      return 'xlsx';
    case FileFormat.Pptx:
      return 'pptx';
      // #endregion
    default:
      return undefined;
  }
}

export function resolveFormat(filenameWithExtension: string): FileFormat{
  filenameWithExtension = filenameWithExtension.split('.').pop()?.toLowerCase() || '';

  switch (filenameWithExtension) {
    case 'pdf':
      return FileFormat.Pdf;
    case 'pptx':
      return FileFormat.Pptx;
    case 'docx':
      return FileFormat.Docx;
    case 'xlsx':
      return FileFormat.Xlsx;
    default:
      return FileFormat.Unknown;
  }
}


export const FileFormatType = enumerationIntValuesType(FileFormat);
