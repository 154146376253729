import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, Icon, useModalStore
} from '@wemogy/reactbase';
import IWorkingPackageItemProps from './IWorkingPackageItemProps';

const WorkingPackageItem: React.FC<IWorkingPackageItemProps> = ({
  workingPackage, onRemove
}) => {
  const modalStore = useModalStore();

  return (
    <StackLayout horizontal padding vCenter>
      <Icon xl folder marginRight />
      <Text stretch labelLarge>{`${workingPackage.name} (${workingPackage.numberOfDataItemsString})`}</Text>
      <Icon
        edit
        xl
        marginRight
        onPress={(): void => {
          modalStore.openModal('editWorkingPackage', workingPackage);
        }}
      />
      <Icon deleteForever xl onPress={onRemove} />
    </StackLayout>
  );
};

export default observer(WorkingPackageItem);
