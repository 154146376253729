import React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { StackLayout, Text } from '@wemogy/reactbase';
import IPdfMetadataStatisticsChartProps from './IPdfMetadataStatisticsChartProps';
import BarChart from '../../wrappers/charts/barChart/BarChart';
import useMarkerCodeNameResolver from '../../wrappers/formBuilder/hooks/UseMarkerCodeNameResolver';

const PdfMetadataStatisticsChart: React.FC<IPdfMetadataStatisticsChartProps> = ({
pdfMetadataStatisticsChart, numberOfAttachments, pdfMarkerCodes, form
}) => {
  const markerCodeNameResolver = useMarkerCodeNameResolver();

  const {
    dinPages, smallerThenA3, biggerThenA3
  } = pdfMetadataStatisticsChart;

  const totalCount = _.sumBy(dinPages, x => x.numberOfPages);
  return (
    <StackLayout>
      <Text title marginTopBottom={2}>{`PDF Statistik (${numberOfAttachments} Dokumente)`}</Text>
      <BarChart
        maxValue={totalCount}
        minHeight={70}
        sections={[
          {
            name: 'Seitenanzahl',
            value:
            {
              name: 'Seitenanzahl',
              value: totalCount
            }
          },
          {
            name: 'Kleiner A3',
            value:
            {
              name: 'Kleiner A3',
              value: smallerThenA3
            }
          },
          {
            name: 'Größer A3',
            value:
                          {
                            name: 'Größer A3',
                            value: biggerThenA3
                          }
          },
          ...dinPages.map(dinPage => ({
            name: dinPage.format,
            value: {
              name: dinPage.format,
              value: dinPage.numberOfPages
            }
          })),
          ...pdfMarkerCodes.map(markerCode => ({
            name: markerCodeNameResolver(form, markerCode.markerCodeId),
            value: {
              name: markerCodeNameResolver(form, markerCode.markerCodeId),
              value: markerCode.amount
            }
          }))
        ]}
      />
      {pdfMarkerCodes.length ? null : (
        <Text>Es sind keine QR Codes erkannt worden</Text>
      )}
    </StackLayout>
  );
};

export default observer(PdfMetadataStatisticsChart);
