import { createPermissionFeatureComponent } from '@wemogy/reactbase-authorization';
import useDataAccessStore from '../../../../dataLayer/stores/hooks/UseDataAccessStore';
import FormRole from '../../../wrappers/formBuilder/dataLayer/enums/roles/FormRole';
import FormSubLevelName from '../types/FormSubLevelName';


const FormPermissionFeature =
  createPermissionFeatureComponent<FormSubLevelName>()(FormRole, () => useDataAccessStore().formStore);

export default FormPermissionFeature;
