import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import {BaseModel} from '@wemogy/reactbase';
import { UiElementTypeType } from '../enums/UiElementType';
import FormSection, { FormSectionType } from '../enums/FormSection';

const UiElementSubType = types.model({
  type: UiElementTypeType,
  formSection: types.optional(FormSectionType, FormSection.Main),
  orderId: types.number,
  config: types.maybe(types.string)
})
  .named('UiElementSubType')
  .views(self => ({
    getConfig<T>(): T{
      return JSON.parse(self.config as any);
    }
  }))
  .actions(self => ({
    setOrderId(orderId: number): void{
      self.orderId = orderId;
    },
    incrementOrderId(incrementAmount: number = 1): void{
      self.orderId += incrementAmount;
    },
    decrementOrderId(decrementAmount: number = 1): void{
      self.orderId -= decrementAmount;
    },
    setConfig(config: any): void{
      self.config = JSON.stringify(config);
    },
    updateConfig(updateAction: (config: any) => void): void{
      const config = self.getConfig();
      updateAction(config);
      this.setConfig(config);
    },
    // #region multiple used configs
    updateConfigText(text: string): void{
      this.updateConfig(c => c.text = text);
    }
  // #endregion
  }));

const UiElement = types.compose(BaseModel, UiElementSubType)
  .named('UiElement');
export default UiElement;

export interface IUiElement extends Instance<typeof UiElement>{}
export interface IUiElementSnapshotIn extends SnapshotIn<typeof UiElement>{}
export interface IUiElementSnapshotOut extends SnapshotOut<typeof UiElement>{}
