import React from 'react';
import {
  BarChart as RechartsBarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, LabelList
} from 'recharts';
import { observer } from 'mobx-react';
import { StackLayout } from '@wemogy/reactbase';
import { AutoSizer } from 'react-virtualized';
import IBarChartProps from './IBarChartProps';
import { toPercentage } from '../../../helpers/NumberHelpers';


const BarChart: React.FC<IBarChartProps> = ({
  maxValue,sections, noTooltip, minHeight
}) => {

  const chartData = sections.map(section => {
    if (!section.value) {
      return {name: section.name};
    }

    const total = section.value.value;
    const percentageToMaxValue = toPercentage(total, maxValue);
    console.log(percentageToMaxValue);
    const chartDataItem = {
      name: section.name, // `${total} ${section.name} (${percentageToMaxValue})`,
      total: section.value.value,
      value: section.value.value,
      valueLabel: section.value.name,
      valuePercentageWithValue: `${section.value.value} (${toPercentage(section.value.value, maxValue)})`
    };


    return chartDataItem;
  });

  return (
    <StackLayout minHeight={minHeight || 50}>
      <AutoSizer>
        {({
          width, height
        }): JSX.Element => (
          <RechartsBarChart
            width={width}
            height={height}
            data={chartData}
            margin={{
              top: 20, right: 30, left: 20, bottom: 5
            }}
            layout="vertical"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              scale="auto" // if using linear, we don't see ma
              tickCount={10}
              interval={0}
              domain={[0, maxValue]}
            />
            <YAxis width={440} dataKey="name" type="category"  />

            {noTooltip ? null : (
              <Tooltip
                formatter={(_value, _name, props): string[] => [`${_value}`, `${props.payload[`${props.dataKey}Label`]}` ]}
                labelFormatter={(label): string => `${label.toString()}`}
              />
            )}
            <Bar maxBarSize={80} dataKey="value" fill="#48BB78">
              <LabelList dataKey="valuePercentageWithValue" position="insideLeft" />
            </Bar>
          </RechartsBarChart>
        )}
      </AutoSizer>

    </StackLayout>
  );
};

export default observer(BarChart);
