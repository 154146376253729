import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import _ from 'lodash';
import {BaseModel} from '@wemogy/reactbase';
import UiElement, { IUiElement } from './UiElement';
import Control, { IControl } from './Control';
import LayoutType, {LayoutTypeType} from '../enums/LayoutType';
import { IUiElementType } from '../enums/UiElementType';
import { IControlType } from '../enums/ControlType';
import { IDataType } from '../enums/DataType';
import  { FormSectionType } from '../enums/FormSection';

const LayoutSubType = types.model({
  type: LayoutTypeType,
  formSection: FormSectionType,
  orderId: types.number,
  uiElements: types.optional(types.array(UiElement), []),
  controls: types.optional(types.array(Control), []),
  config: types.maybe(types.string)
})
  .named('LayoutSubType')
  .views(self => ({
    getUiElementByOrderId(orderId: number): IUiElement | undefined{
      return self.uiElements.find(x => x.orderId === orderId);
    },
    getControlByOrderId(orderId: number): IControl | undefined{
      return self.controls.find(x => x.orderId === orderId);
    },
    get maxUiElementOrderId(): number{
      return _.maxBy(self.uiElements, x => x.orderId)?.orderId || 0;
    },
    get maxControlOrderId(): number | undefined{
      return _.maxBy(self.controls, x => x.orderId)?.orderId;
    },
    getConfig<T>(): T | undefined{
      if(!self.config){
        return undefined;
      }
      return JSON.parse(self.config);
    },
    get uiElementsOrdered(): IUiElement[]{
      return _.orderBy(self.uiElements, x => x.orderId);
    },
    get controlsOrdered(): IControl[]{
      return _.orderBy(self.controls, x => x.orderId);
    }
  }))
  .actions(self => ({
    setOrderId(orderId: number): void{
      self.orderId = orderId;
    },
    incrementOrderId(): void{
      self.orderId++;
    },
    decrementOrderId(): void{
      self.orderId--;
    },
    putUiElement(orderId: number, type: IUiElementType, config?: any): void{
      const existingUiElement = self.uiElements.find(x => x.orderId === orderId);
      if(existingUiElement){
        self.uiElements.remove(existingUiElement);
      }
      this.addUiElement(orderId, type, config);
    },
    addUiElement(orderId: number, type: IUiElementType, config?: any): void{
      const newUiElement = UiElement.create({
        type,
        orderId
      });
      if(config){
        newUiElement.setConfig(config);
      }
      self.uiElements.push(newUiElement);
    },
    pushUiElement(uiElement: IUiElement): void{
      self.uiElements.push(uiElement)
    },
    removeUiElement(uiElement: IUiElement): void{
      self.uiElements.remove(uiElement);
    },
    putControl(orderId: number, type: IControlType, dataType: IDataType, config?: any): void{
      const existingControl = self.controls.find(x => x.orderId === orderId);
      if(existingControl){
        self.controls.remove(existingControl);
      }
      this.addControl(orderId, type, dataType, config);
    },
    addControl(orderId: number, type: IControlType, dataType: IDataType, config?: any): void{
      const newControl = Control.create({
        type,
        dataType,
        orderId
      });
      if(config){
        newControl.setConfig(config);
      }
      self.controls.push(newControl);
    },
    pushControl(control: IControl): void{
      self.controls.push(control)
    },
    removeControl(control: IControl): void{
      self.controls.remove(control);
    },
    setConfig(config: any): void{
      self.config = JSON.stringify(config);
    },
    setConfigIfUndefined<T>(defaultConfig: T): void{
      const config = self.getConfig<T>();
      if(config){
        return;
      }
      this.setConfig(defaultConfig);
    },
    updateConfig<T>(updateAction: (config: T) => void): void{
      const config = self.getConfig<T>();
      if(!config){
        return;
      }
      updateAction(config);
      this.setConfig(config);
    },
    clearDuplicates(): void{
      self.controls.replace(_.uniqBy(self.controls, x => x.destinationName));
    }
  }));

const CoreLayout = types.compose(BaseModel, LayoutSubType)
  .named('LayoutType');

export interface ICoreLayout extends Instance<typeof CoreLayout> { }

const Layout = types.compose(CoreLayout, types
  .model({ layouts: types.array(CoreLayout) })
)
  .named('Layout')
  .views(self => ({
    get allControls(): IControl[]{
      const allControls = [...self.controls];

      self.layouts.forEach(x => {
        allControls.push(...x.controls);
      })

      return allControls;
    }
  }))
  .actions(self => ({
    removeLayout(layout: ICoreLayout | number): void{
      if (typeof layout === 'number') {
        self.layouts.replace(self.layouts.filter(x => x.orderId !== layout));
      }
      else {
        self.layouts.remove(layout);
      }
    },
    putLayout(orderId: number, type: LayoutType, config: any): void{
      const existing = self.layouts.find(x => x.orderId === orderId);
      if (existing) {
        self.layouts.remove(existing);
      }
      self.layouts.push(CoreLayout.create({
        type,
        formSection: self.formSection,
        orderId,
        config: config ? JSON.stringify(config) : undefined
      }))
    }
  }));

export default Layout;

export interface ILayout extends Instance<typeof Layout>{}
export interface ILayoutSnapshotIn extends SnapshotIn<typeof Layout>{}
export interface ILayoutSnapshotOut extends SnapshotOut<typeof Layout>{}
