import { IForm } from '../dataLayer/models/Form';
import { IAttachment } from '../dataLayer/models/Attachment';
import { CloudStorageUri } from '../../../../base/Constants';

/* eslint-disable import/prefer-default-export */
export function dataItemStoreKey(formId: string): string {
  return `dataItemStore${formId.replace('-', '')}`;
}

export function buildAttachmentUri(form: IForm, attachment: IAttachment): string {
  return `${CloudStorageUri}form-attachments-${form.id}/${attachment.id}.${attachment.extension}${form.sas}`;
}
