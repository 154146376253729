import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import ICustomerOverviewProps from './ICustomerOverviewScreenProps';
import MainLayout from '../../layouts/mainLayout/MainLayout';
import CustomerOverview from '../../containers/customer/customerOverview/CustomerOverview';
import AddCustomerModal from '../../modals/customer/addCustomerModal/AddCustomerModal';
import AddDatabaseModal from '../../modals/database/addDatabaseModal/AddDatabaseModal';
import useDataAccessStore from '../../../dataLayer/stores/hooks/UseDataAccessStore';
import EditCustomerModal from '../../wrappers/formBuilder/modals/editCustomerModal/EditCustomerModal';
import AddCustomerMarkerCodeModal from '../../wrappers/formBuilder/modals/addCustomerMarkerCodeModal/AddCustomerMarkerCodeModal';
import ScrollContainer from '../../wrappers/scrollContainer/ScrollContainer';
import useUiStore from '../../../dataLayer/stores/hooks/UseUiStore';

const CustomerOverviewScreen: React.FC<ICustomerOverviewProps> = () => {
  const {
    customerStore, formStore
  } = useDataAccessStore();
  const {collectorModeStore} = useUiStore();
  useEffect((): void => {
    customerStore.query();
    formStore.query();
    collectorModeStore.setSampleModeActive(false);
  }, []);

  return (
    <>
      <MainLayout>
        <ScrollContainer><CustomerOverview /></ScrollContainer>
      </MainLayout>
      <AddCustomerModal />
      <AddDatabaseModal />
      <EditCustomerModal />
      <AddCustomerMarkerCodeModal />
    </>
  );
};

export default observer(CustomerOverviewScreen);
