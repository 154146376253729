export function validateEmail(email: string): boolean {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function hasLowerCase(str: string): boolean {
  return (/[a-z]/.test(str));
}

export function hasUpperCase(str: string): boolean {
  return (/[A-Z]/.test(str));
}

export function hasNumber(str: string): boolean {
  return (/[0-9]/.test(str));
}

export function hasSpecialCharacter(str: string): boolean{
  return (/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/.test(str));
}

export function validatePassword(password: string): boolean{
  const passwordLengthValid = password.length >= 10;
  const passwordContainsUpperCase = hasUpperCase(password);
  const passwordContainsLowerCase = hasLowerCase(password);
  const passwordContainsNumber = hasNumber(password);
  const passwordContainsSpecialCharacter = hasSpecialCharacter(password);
  const passwordIsValid = passwordLengthValid && passwordContainsSpecialCharacter && passwordContainsNumber && passwordContainsUpperCase && passwordContainsLowerCase;
  return passwordIsValid;
}

/**
 * Converts a string in a UTF8 Base64 string
 * Source: https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
 * @param str
 */
export function stringToBase64(str: string): string{
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
    function toSolidBytes(_match, p1) {
      return String.fromCharCode(`0x${p1}` as any);
    }));
}
