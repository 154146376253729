import {
  RestService, ApiWrapper, ApiResult
} from '@wemogy/reactbase';
import {IDataStructureTemplate} from '../../models/DataStructureTemplate';
import { MainApiUri } from '../../../../../../base/Constants';
import { authFetch } from './QuickHelper';

export default class DataStructureTemplateService extends RestService<IDataStructureTemplate>{
  public constructor(apiWrapper: ApiWrapper){
    super(apiWrapper, 'dataStructureTemplate');
  }

  public importCsv(formId: string, formData: FormData): Promise<Response>{

    return authFetch(this.apiWrapper, `${MainApiUri}dataStructureTemplate/import/csv/${formId}`, {
      method: 'POST',
      body: formData
    }, true);
  }

  public exportCsv(formId: string): Promise<ApiResult<string>>{
    return this.apiWrapper.get(`dataStructureTemplate/export/csv/${formId}`);
  }
}
