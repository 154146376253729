import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';


const NavigationConfigurationColumn = types.model({
  displayName: types.optional(types.string, ''),
  propertyName: types.optional(types.string, ''),
  width: types.optional(types.number, 100)
})
  .named('NavigationConfigurationColumn')
  .actions(self => ({
    setDisplayName(displayName: string): void{
      self.displayName = displayName;
    },
    setPropertyName(propertyName: string): void{
      self.propertyName = propertyName;
    },
    setWidth(width: number): void{
      if (width <= 0) {
        return;
      }
      self.width = width;
    },
  }));
export default NavigationConfigurationColumn;

export interface INavigationConfigurationColumn extends Instance<typeof NavigationConfigurationColumn>{}
export interface INavigationConfigurationColumnSnapshotIn extends SnapshotIn<typeof NavigationConfigurationColumn>{}
export interface INavigationConfigurationColumnSnapshotOut extends SnapshotOut<typeof NavigationConfigurationColumn>{}
