import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, Button, Icon
} from '@wemogy/reactbase';
import IDatabaseTemplateItemProps from './IDatabaseTemplateItemProps';

const DatabaseTemplateItem: React.FC<IDatabaseTemplateItemProps> = ({
  databaseTemplate, onRemove, onOpen
}) => {

  return (
    <StackLayout horizontal padding vCenter marginBottom={0.5}>
      <Icon xl extension marginRight />
      <Text stretch>{databaseTemplate.name}</Text>
      {
        onRemove ? (<Icon deleteForever xl onPress={onRemove} marginLeft />) : null
      }
      {
        onOpen ? <Button onPress={onOpen}>Öffnen</Button> : null
      }
    </StackLayout>
  );
};

export default observer(DatabaseTemplateItem);
