
enum CustomerRole{
  CanList = 1,
  CanManagePermissions = 2,
  CanRename = 3,
  CanDelete = 4,
  CanCreate = 5
}

export default CustomerRole;
