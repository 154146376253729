import React, { useState } from 'react';
import { observer } from 'mobx-react';
// @ts-ignore no typings
import { SegmentedControl as ReactSegmentedControl } from 'segmented-control';
import { generateGuid, useTheme } from '@wemogy/reactbase';
import ISegmentedControlProps from './ISegmentedControlProps';

const SegmentedControl: React.FC<ISegmentedControlProps> = ({
  options, defaultOption, onOptionSelected
}) => {
  const [controlName] = useState(generateGuid());
  const theme = useTheme();

  return (
    <ReactSegmentedControl
      name={controlName}
      options={options.map(x => ({
        label: x,
        value: x,
        default: x === defaultOption
      }))}
      setValue={onOptionSelected}
      style={{
        width: '100%', color:  theme.colorScheme.primary, margin: 0
      }}
    />
  );
};

export default observer(SegmentedControl);
