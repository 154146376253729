import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { StackLayout } from '@wemogy/reactbase';
import IAppStartupProps from './IAppStartupProps';
import LoadingIndicator from '../../ui/components/loadingIndicator/LoadingIndicator';
import useDataAccessStore from '../../dataLayer/stores/hooks/UseDataAccessStore';
import useAppStore from '../../dataLayer/stores/hooks/UseAppStore';

const AppStartup: React.FC<IAppStartupProps> = ({children}) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const {
    userStore, customerStore
  } = useDataAccessStore();
  const { activeUserStore } = useAppStore();

  const initalize = async (): Promise<void> => {
    await userStore.query();
    await customerStore.query();
    await activeUserStore.setUserUploadSas();

    setIsInitialized(true);
  };

  useEffect((): void => {
    initalize();
  }, []);


  if (!isInitialized) {
    return  (
      <StackLayout hCenter vCenter stretch>
        <LoadingIndicator text="DigitTool is starting..." />
      </StackLayout>
    );
  }


  return children;
};

export default observer(AppStartup);
