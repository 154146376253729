import {
  createBaseDataAccessStore, QueryParameters, ApiResult
} from '@wemogy/reactbase';
import Form, { IForm } from '../../models/Form';
import RestApiServiceFactory from '../../../../../../dataLayer/api/rest/RestApiServiceFactory';
import FormDtoMapper from '../../api/dtoMappers/FormDtoMapper';
import { IWorkingPackage } from '../../models/WorkingPackage';

const FormStore = createBaseDataAccessStore(
  Form,
  RestApiServiceFactory.formService,
  new FormDtoMapper(),
  QueryParameters.create().withTake(100),
)
  .views(self => ({
    getFormsOf(relatedId: string): IForm[]{
      return self.items.filter(x => x.relatedId === relatedId);
    },
    getWorkingPackage(workingPackageId): IWorkingPackage | undefined {
      return self.items.find(x => x.getWorkingPackage(workingPackageId))?.getWorkingPackage(workingPackageId);
    }
  }))
  .actions(_self => ({
    put(form: IForm): Promise<ApiResult<void>>{
      return RestApiServiceFactory.formService.put(form);
    }
  }));

export default FormStore;
