import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';

const WorkingPackageMember = types.model({
  userId: types.string,
  roleId: types.string
})
  .named('WorkingPackageMember')
  .actions(self => ({
    setRoleId(roleId: string): void{
      self.roleId = roleId;
    },
  }));
export default WorkingPackageMember;

export interface IWorkingPackageMember extends Instance<typeof WorkingPackageMember>{}
export interface IWorkingPackageMemberSnapshotIn extends SnapshotIn<typeof WorkingPackageMember>{}
export interface IWorkingPackageMemberSnapshotOut extends SnapshotOut<typeof WorkingPackageMember>{}
