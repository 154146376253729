/* eslint-disable react/no-array-index-key */
import React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, invokeEventHandler, Text, Icon
} from '@wemogy/reactbase';
import IDataSourceFormProps from './IDataSourceFormProps';
import AnnotatedTextInput from '../../containers/formElements/annotatedTextInput/AnnotatedTextInput';
import AnnotatedFormView from '../../containers/formElements/annotatedFormView/AnnotatedFormView';
import Dropdown from '../../../../components/dropdown/Dropdown';
import { IDataSourceType } from '../../dataLayer/enums/DataSourceType';
import PrimaryKeyForm from '../primaryKeyForm/PrimaryKeyForm';

const DataSourceForm: React.FC<IDataSourceFormProps> = ({
  dataSource, addMode, onFinished
}) => {

  if(!dataSource.primaryKeys.length){
    dataSource.addPrimaryKey();
  }

  return (
    <StackLayout minHeight={50} padding>
      <AnnotatedTextInput placeholder="Name der Datenquelle" description="Name der Datenquelle" value={dataSource.name} onChange={dataSource.setName} marginBottom />
      <AnnotatedFormView description="Datenformat" marginBottom>
        <Dropdown
          light
          selectedOption={{
            label: 'CSV',
            value: dataSource.type
          }}
          options={[
            {
              label: 'CSV',
              value: 'csv'
            }
          ]}
          onOptionSelected={(option): void => {
            dataSource.setType(option.value as IDataSourceType);
          }}
        />
      </AnnotatedFormView>
      <AnnotatedFormView description="Primärschlüssel" marginBottom>
        <StackLayout>
          <StackLayout>
            {
              dataSource.primaryKeys.map((primaryKey, i) => (
                <PrimaryKeyForm primaryKey={primaryKey} key={`primaryKey_${i}`} />
              ))
            }
          </StackLayout>
          <StackLayout horizontal spaceBetween>
            <StackLayout vCenter horizontal onPress={dataSource.addPrimaryKey}>
              <Icon add xl marginRight />
              <Text>Primärschlüssel hinzufügen</Text>
            </StackLayout>
            {
              dataSource.primaryKeys.length > 1 ? (
                <StackLayout
                  vCenter
                  horizontal
                  onPress={(): void => {
                    const lastPrimaryKey = _.last(dataSource.primaryKeys);
                    if(!lastPrimaryKey){
                      return;
                    }
                    dataSource.removePrimaryKey(lastPrimaryKey);
                  }}
                >
                  <Icon xlDanger deleteForever marginRight />
                  <Text danger>Letzten Primärschlüssel entfernen</Text>
                </StackLayout>
              ) : null
            }
          </StackLayout>
        </StackLayout>
      </AnnotatedFormView>
      <AnnotatedTextInput placeholder="Hier können Sie interne Notizen hinterlegen" description="Interne Notizen" value={dataSource.notes} onChange={dataSource.setNotes} multiline marginBottom />
      <StackLayout marginTop={2} horizontal hEnd height={4}>
        {
          dataSource.isValid ? (
            <Button onPress={(): void => {
              invokeEventHandler(onFinished);
            }}
            >
              {addMode ? 'Hinzufügen' : 'Speichern'}
            </Button>
          ): null
        }
      </StackLayout>
    </StackLayout>
  );
};

export default observer(DataSourceForm);
