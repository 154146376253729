import React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { StackLayout } from '@wemogy/reactbase';
import { AutoSizer } from 'react-virtualized';
import {
  LineChart as RechartsLineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line
} from 'recharts';
import ILineChartProps from './ILineChartProps';
import { toPercentage } from '../../../helpers/NumberHelpers';

const LineChart: React.FC<ILineChartProps> = ({
  maxValue, sections
}) => {

  const chartData = sections.map(section => {
    const total = _.sumBy(section.values, x => x.value);
    const chartDataItem = {
      name: section.name,
      total
    };

    section.values.forEach((dataItemValue, index) => {
      chartDataItem[`value${index}`] = dataItemValue.value;
      chartDataItem[`value${index}Label`] = dataItemValue.name;
      const percentage = toPercentage(dataItemValue.value, maxValue);
      chartDataItem[`value${index}PercentageWithValue`] = `${dataItemValue.value} (${percentage})`;
    });

    return chartDataItem;
  });

  return (
    <StackLayout height={40}>
      <AutoSizer>
        {({
          width, height
        }): JSX.Element => (
          <RechartsLineChart
            width={width}
            height={height}
            data={chartData}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis
              scale="auto" // if using linear, we don't see ma
              tickCount={10}
              interval={0}
              domain={[0, maxValue]}
            />
            <Tooltip formatter={(_value, _name, props): string[] => [`${_value}`, `${props.payload[`${props.dataKey}Label`]}` ]} labelFormatter={(label): string => `${label.toString()}`} />
            {true ? null : <Legend />}
            <Line type="monotone" dataKey="value0" stroke="#8884d8" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="value1" stroke="#82ca9d" />
          </RechartsLineChart>
        )}
      </AutoSizer>

    </StackLayout>
  );
};

export default observer(LineChart);
