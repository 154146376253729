import React, { useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import {
  StackLayout,  TextInput, Button, Text, Icon
} from '@wemogy/reactbase';
import IDataItemCommentsProps from './IDataItemCommentsProps';
import Comment, { IComment } from '../../dataLayer/models/Comment';
import CommentComponent from '../../components/comment/Comment';
import TooltipHelp from '../../../tooltipHelp/TooltipHelp';
import { formatToShortDateTimeString } from '../../../dateSelector/DateHelpers';
import Dropdown from '../../../../components/dropdown/Dropdown';
import IDropdownOption from '../../../../components/dropdown/interfaces/IDropdownOption';
import CommentVisibility from '../../dataLayer/enums/CommentVisibility';
import Username from '../username/Username';
import useFormOrWorkingPackageFeature from '../../../../plugins/authorization/hooks/UseFormOrWorkingPackageFeature';
import FormOrWorkingPackagePermissionFeature from '../../../../plugins/authorization/permissionFeatures/FormOrWorkingPackagePermissionFeature';

const commentVisibilityOptions: IDropdownOption[] = [
  {
    label: 'Klärfall Kunde',
    value: CommentVisibility.Public.toString()
  },
  {
    label: 'Klärfall intern',
    value: CommentVisibility.Internal.toString()
  }
];

function newestCommentAtTop(comments: IComment[]): IComment[]{
  return _.orderBy(comments, x => x.createdAt, 'desc');
}

const DataItemComments: React.FC<IDataItemCommentsProps> = ({
  onCommentAdded, onCommentIsResolvedUpdated, onCommentVisibilityUpdated, comments, userId
}) => {
  const [newMessage, setNewMessage] = useState('');
  const [commentToAnswer, setCommentToAnswer] = useState<undefined | IComment>(undefined);
  const [visibility, setVisibility] = useState(commentVisibilityOptions[1]);
  const { hasOnActiveFormOrWorkingPackagePermission } = useFormOrWorkingPackageFeature();
  const canCreateComments = hasOnActiveFormOrWorkingPackagePermission('CanCreateComments');
  const canAnswerComments = hasOnActiveFormOrWorkingPackagePermission('CanAnswerComments');
  const canResolveComments = hasOnActiveFormOrWorkingPackagePermission('CanResolveComments');


  return (
    <StackLayout>
      {
        newestCommentAtTop(comments.filter(x => x.parentId === null)).map(comment => (
          <StackLayout key={comment.id} backgroundColor="green100" borderRadius={0.25} marginBottom padding>
            <StackLayout horizontal vCenter marginBottom={2}>
              <Icon xl accountCircle marginRight />
              <StackLayout stretch spaceBetween>
                <Username userId={comment.creatorId} />
                <Text noTranslation label>{formatToShortDateTimeString(comment.createdAt)}</Text>
              </StackLayout>
              <TooltipHelp text={comment.isPublic ? 'Klärfall Kunde' : 'Klärfall intern'}>
                <Icon
                  onPress={(): void => {
                    const newVisibility = comment.isPublic ? CommentVisibility.Internal : CommentVisibility.Public;
                    onCommentVisibilityUpdated(comment, newVisibility);
                  }}
                  xl
                  public={comment.isPublic}
                  vpnLock={!comment.isPublic}
                />
              </TooltipHelp>
              <StackLayout width />
              <TooltipHelp position="left" text={comment.isResolved ? 'Dieser Kommentar ist gelöst.' : canResolveComments ? 'Als gelöst markieren' : 'Dieser Kommentar ist ungelöst.'}>
                <Icon
                  onPress={comment.isResolved || !canResolveComments ? undefined : (): void => {
                    onCommentIsResolvedUpdated(comment, true);
                  }}
                  xl
                  checkCircle
                  color={{custom: comment.isResolved ? undefined : 'rgba(0,0,0,0.25)'}}
                />
              </TooltipHelp>
            </StackLayout>
            <Text noTranslation>{comment.message}</Text>
            <StackLayout marginTop={2} paddingLeft={2}>
              {
                newestCommentAtTop(comments.filter(x => x.parentId === comment.id)).map(x => (
                  <CommentComponent comment={x} key={x.id} />
                ))
              }
            </StackLayout>
            <StackLayout horizontal hEnd marginTop>
              <FormOrWorkingPackagePermissionFeature requiredPermission="CanAnswerComments">
                <Button onPress={(): void => {
                  setCommentToAnswer(comment);
                }}
                >
                  Antworten

                </Button>
              </FormOrWorkingPackagePermissionFeature>
            </StackLayout>
          </StackLayout>
        ))
      }
      {
        canCreateComments || (commentToAnswer && canAnswerComments) ? (
          <TextInput value={newMessage} onChange={setNewMessage} multiline placeholder={commentToAnswer ? 'Ihre Antwort' : 'Ihr Kommentar'}> </TextInput>
        ) : null
      }
      <StackLayout horizontal spaceBetween marginTop>
        {commentToAnswer && canCreateComments ? (
          <Button onPress={(): void => {
            setCommentToAnswer(undefined);
          }}
          >
            Neuen Kommentar hinzufügen
          </Button>
        ): canCreateComments ? (
          <Dropdown
            width={30}
            light
            options={commentVisibilityOptions}
            selectedOption={visibility}
            onOptionSelected={setVisibility}
          />
        ) : null}
        {canCreateComments || (commentToAnswer && canAnswerComments)  ? (
          <Button onPress={(): void => {
            const newComment = Comment.create({
              message: newMessage,
              creatorId: userId,
              parentId: commentToAnswer ? commentToAnswer.id : null,
              visibility: commentToAnswer ? commentToAnswer.visibility : parseInt(visibility.value)
            });

            onCommentAdded(newComment);
            setNewMessage('');
            setCommentToAnswer(undefined);
          }}
          >
            {
              commentToAnswer ? 'Antwort absenden' : 'Kommentar absenden'
            }

          </Button>
        ):null}
      </StackLayout>
    </StackLayout>
  );
};

export default observer(DataItemComments);
