import {
  BaseModel, QueryParameters, IBaseModelStoreType, createBaseDataAccessStore, IQueryParameters, JsonPatchDocument, ApiResult
} from '@wemogy/reactbase';
import {Instance, types} from 'mobx-state-tree';
import _ from 'lodash';
import { IForm } from '../../../models/Form';
import DataItem, {IDataItem} from '../../../models/genericModels/DataItem';
import RestApiServiceFactory from '../../../../../../../dataLayer/api/rest/RestApiServiceFactory';
import DataItemDtoMapper from '../../../api/dtoMappers/DataItemDtoMapper';
import StatusStep from '../../../enums/internal/StatusStep';

const DataItemStore =(form: IForm): IBaseModelStoreType<typeof BaseModel, any>=> {
  const dataItemStore = DataItem(form);
  const DataItemStoreBaseType = createBaseDataAccessStore(
    dataItemStore,
    RestApiServiceFactory.getDataItemService(form.id),
    new DataItemDtoMapper(),
    QueryParameters.create({ take: 100 })
  ).actions(self => ({
    clearItems(): void {
      self.items.clear();
    },
    async patchDataItem(formId: string, dataItemId: string, patch: JsonPatchDocument): Promise<ApiResult<IDataItem>>{
      const apiResult = await RestApiServiceFactory.getDataItemService(formId).updateDataItemData(formId, dataItemId, patch);

      if (apiResult.ok) {
        (self.getItem(dataItemId) as any)?.setTransactions(apiResult.data.transactions);
      }

      return apiResult;
    }
  }));

  const DataItemStoreSubType = types
    .model({ editingDataItem: types.maybe(dataItemStore) })
    .named('DataItemStoreSubType');

  return types.compose(DataItemStoreBaseType, DataItemStoreSubType)
    .views(self => ({
      get dataItem(): IDataItem | undefined{
        const { activeItem } = self;
        if (!activeItem) {
          return undefined;
        }
        const coreId = activeItem.id.split('editingDataItem_').pop();
        if (coreId) {
          const coreDataItem = self.getItem(coreId) as IDataItem;
          return coreDataItem;
        }
        return undefined;
      },
      isActive(dataItem: IDataItem): boolean{
        return self.activeItem?.id.endsWith(dataItem.id) === true;
      }
    }))
    .actions(self => ({
      setEditingDataItem(dataItem: IDataItem, statusStep: StatusStep, adminMode?: boolean): void{
        dataItem.initializeFromTransactions(statusStep, adminMode);
        self.activeItem = dataItem;
        /*
        self.unsetActiveItem();
        self.editingDataItem = undefined;
        const clone = _.cloneDeep(getSnapshot(dataItem));
        clone.id = `editingDataItem_${dataItem.id}`;
        const dataKeys = Object.keys(clone.data);
        clone.data = {};
        if (!self.editingDataItem) {
          self.editingDataItem = dataItemStore.create(clone);
        } else {
          applySnapshot(self.editingDataItem, clone);
        }

        const editDataItem = self.editingDataItem as IDataItem;
        let { transactions } = editDataItem;

        // if dataCollected, we are now in QS, in this case we show not the transactions of dataCollected
        if (editDataItem.status.isDataCollected) {
          const indexedTimestamp = editDataItem.status.latestIndexed!.timestamp;
          const dataCollectedTimestamp = editDataItem.status.dataCollected ? editDataItem.status.dataCollected[0].timestamp : 0;
          transactions = transactions.filter(x => x.createdAt <= indexedTimestamp || x.createdAt >= dataCollectedTimestamp);
        }

        dataKeys.forEach((dataKey): void => {
          const latestTransaction = latestTransactionOfProperty(transactions, dataKey);

          if (latestTransaction && latestTransaction.value) {
            editDataItem.data[setterName(dataKey)](JSON.parse(latestTransaction.value));
          }

        });


        self.activeItem = editDataItem;
         */
      },
      previous(queryKey: string): void{
        if (!self.activeItem) {
          return;
        }
        self.goToPreviousItem(queryKey)
      }
    }));
};

export default DataItemStore;


type bla =  {
  activeItem: IDataItem;
  items: IDataItem[];
  dataItem: IDataItem | undefined
  queryItems: (queryParametersOrKey?: IQueryParameters | string) => IDataItem[];
  clearItems: () => void;
  setEditingDataItem: (dataItem: IDataItem, statusStep: StatusStep, adminMode?: boolean) => void;
  isActive: (dataItem: IDataItem) => boolean;
  previous: (queryKey: string) => void;
  next: (queryKey: string) => void;
  patchDataItem: (formId: string, dataItemId: string, patch: JsonPatchDocument) => Promise<ApiResult<IDataItem>>
};

export interface IBaseDataItemStore extends Instance<IBaseModelStoreType<typeof BaseModel, any>>{}

export type IDataItemStore = IBaseDataItemStore & bla;
