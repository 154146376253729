import { createPermissionFeatureComponent } from '@wemogy/reactbase-authorization';
import CustomerRole from '../../../../dataLayer/enums/CustomerRole';
import useDataAccessStore from '../../../../dataLayer/stores/hooks/UseDataAccessStore';
import CustomerSubLevelName from '../types/CustomerSubLevelName';


const CustomerPermissionFeature =
  createPermissionFeatureComponent<CustomerSubLevelName>()(CustomerRole, () => useDataAccessStore().customerStore);

export default CustomerPermissionFeature;
