import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Button, Text, Modal, useModalStore
} from '@wemogy/reactbase';
import IManageWorkingPackageModalProps from './IManageWorkingPackageModalProps';
import WorkingPackageItemList from '../../containers/workingPackageItemList/WorkingPackageItemList';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';

const ManageWorkingPackageModal: React.FC<IManageWorkingPackageModalProps> = () => {
  const {formStore} = useDataAccessStore();
  const modalStore = useModalStore();

  if(!formStore.activeItem){
    return null;
  }

  return (
    <Modal modalKey="manageWorkingPackages" icon="settings" title="Arbeitspakete verwalten" large>
      {
        formStore.activeItem.workingPackages.length ? (
          <StackLayout>
            <WorkingPackageItemList workingPackages={formStore.activeItem.workingPackages} onWorkingPackageRemove={formStore.activeItem.removeWorkingPackage} />
            <Button onPress={(): void =>{
              modalStore.openModal('addWorkingPackage');
            }}
            >
              Neues Arbeitspaket anlegen

            </Button>
          </StackLayout>
        ) : (
          <StackLayout hCenter>
            <Text title marginTopBottom={2}>Es sind keine Arbeitspakete vorhanden</Text>
            <Button onPress={(): void =>{
              modalStore.openModal('addWorkingPackage');
            }}
            >
              Jetzt ein Arbeitspaket anlegen

            </Button>
          </StackLayout>
        )
      }

    </Modal>
  );
};

export default observer(ManageWorkingPackageModal);
