import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Text, StackLayout } from '@wemogy/reactbase';
import { SingleTenantId } from '@wemogy/reactbase-authorization';
import IAccountManagementProps from './IAccountManagementProps';
import OrganizationMemberManagement from '../../plugins/authorization/memberManagements/OrganizationMemberManagement';
import useDataAccessStore from '../../../dataLayer/stores/hooks/UseDataAccessStore';

const AccountManagement: React.FC<IAccountManagementProps> = () => {

  const { userStore } = useDataAccessStore();

  useEffect((): void => {
    userStore.query();
  }, []);

  console.log('userStore.items.length', userStore.items.length)

  return (
    <StackLayout height={80}>
      <Text largeTitle>Account Management </Text>
      <OrganizationMemberManagement resourceId={SingleTenantId} />
    </StackLayout>
  );
};

export default observer(AccountManagement);
