import React from 'react';
import { observer } from 'mobx-react';
import IControlDropAreaProps from './IControlDropAreaProps';
import BaseDropArea from '../baseDropArea/BaseDropArea';
import ToolboxElementType from '../../../dataLayer/uiEnums/ToolboxElementType';

const ControlDropArea: React.FC<IControlDropAreaProps> = ({
  children, stretch, onDrop
}) => {

  return (
    <BaseDropArea
      stretch={stretch}
      onDrop={(droppedInformation): void => {
        onDrop(droppedInformation);
      }}
      acceptedTypes={[ToolboxElementType.Control]}
      placeholder="Platzieren Sie ein Eingabe-Element hier"
    >
      {children}
    </BaseDropArea>
  );
};

export default observer(ControlDropArea);
