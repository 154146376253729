/* eslint-disable import/prefer-default-export */
import { TranslationInformation } from '@wemogy/reactbase';
import { confirmAlert } from 'react-confirm-alert'; // Import

export function confirmDialogYesNoDanger(content: TranslationInformation, confirmationHandler: (() => void) | (() => Promise<void>), cancellationHandler?: (() => void) | (() => Promise<void>)): void {
  confirmAlert({
    title: 'ACHTUNG',
    message: content as string,
    buttons: [
      {
        label: 'Ja',
        onClick: confirmationHandler
      },
      {
        label: 'Nein',
        onClick: cancellationHandler ?? ((): void => {
          // nothing to do
        })
      }
    ]
  });
}

export function confirmDialogDanger(content: TranslationInformation, confirmLabel: TranslationInformation, confirmationHandler: (() => void) | (() => Promise<void>), cancelLabel: TranslationInformation, cancellationHandler: (() => void) | (() => Promise<void>)): void {
  confirmAlert({
    title: 'ACHTUNG',
    message: content as string,
    buttons: [
      {
        label: cancelLabel as string,
        onClick: cancellationHandler
      },
      {
        label: confirmLabel as string,
        onClick: confirmationHandler
      }
    ]
  });
}


export function confirmDialogYesNo(title: TranslationInformation, content: TranslationInformation, confirmationHandler: () => void | Promise<void>): void {
  confirmAlert({
    title: title as string,
    message: content as string,
    buttons: [
      {
        label: 'Ja',
        onClick: confirmationHandler
      },
      {
        label: 'Nein',
        onClick: (): void => {
          // nothing to do
        }
      }
    ]
  });
}
