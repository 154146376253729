import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import {
StackLayout, Button, generateGuid, Modal, useModalStore
} from '@wemogy/reactbase';
import IAnalyseAttachmentModalProps from './IAnalyseAttachmentModalProps';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import AnnotatedFormView from '../../containers/formElements/annotatedFormView/AnnotatedFormView';
import RestApiServiceFactory from '../../../../../dataLayer/api/rest/RestApiServiceFactory';
import NotificationManager from '../../../inAppFeedback/NotificationManager';
import SegmentedControl from '../../../segmentedControl/SegmentedControl';
import useAppStore from '../../../../../dataLayer/stores/hooks/UseAppStore';
import RealtimeService from '../../../../../dataLayer/services/RealtimeService';
import AzureBlobStorage from '../../../azureBlobStorage/AzureBlobStorage';

const AnalyseAttachmentModal: React.FC<IAnalyseAttachmentModalProps> = () => {
  const {
    formStore, globalMarkerCodeStore
  } = useDataAccessStore();
  const { activeUserStore } = useAppStore();
  const modalStore = useModalStore();
  const [canUploadLocalFile, setCanUploadLocalFile] = useState(false);
  const [quickAnalyse, setQuickAnalyse] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null);


  const { activeItem } = formStore;
  const {userId} = activeUserStore;
  if(!activeItem || !userId){
    return null;
  }

  return (
    <Modal
      modalKey="analyseAttachment"
      icon="assessment"
      title="PDF Analysieren"
      large
      onOpened={(): void => {
        globalMarkerCodeStore.query();
      }}
    >
      <StackLayout>
        <StackLayout marginBottom={2}>
          <SegmentedControl
            options={['Schnelle Analyse', 'Vollständige Analyse']}
            defaultOption="Schnelle Analyse"
            onOptionSelected={(option): void => {
              setQuickAnalyse(option === 'Schnelle Analyse');
            }}
          />
        </StackLayout>
        <AnnotatedFormView description="Wählen Sie die PDF aus, die Sie analysieren möchten" marginBottom>
          <form id="AnalyseAttachmentModalForm">
            <input
              onChange={(): void => {
                setCanUploadLocalFile(true);
              }}
              name="file"
              type="file"
              ref={fileInput}
            />
          </form>
        </AnnotatedFormView>

        <StackLayout hEnd marginTop height={4}>
          {
            canUploadLocalFile ? (
              <Button onPress={(): void => {
                if (!fileInput?.current?.files) {
                  return;
                }
                const file = fileInput?.current?.files[0];
                if (!file) {
                  return;
                }
                const uploadId = generateGuid();
                RealtimeService.fileName = file.name;

                AzureBlobStorage.upload(
                  uploadId,
                  file,
                  'users',
                  'sv=2019-10-10&st=2020-11-05T21%3A44%3A12Z&se=2024-07-19T20%3A44%3A00Z&sr=c&sp=w&sig=dddlzCgs0leOAAEDqJjMQnyqOL%2BeQL6O9t%2BU3BSGCck%3D',
                  {filename: file.name},
                  (progress, secondsRemaining): void => {
                    modalStore.openModal('loadingOverlay', `${progress}% hochgeladen. (${secondsRemaining}s verbleibend)`);
                  },
                  (): void => {
                    (async (): Promise<void> => {
                      try {
                        const response = quickAnalyse ? await RestApiServiceFactory.getDataItemService(activeItem.id).analyseAttachmentQuickly(activeItem.id, userId, uploadId) : await RestApiServiceFactory.getDataItemService(activeItem.id).analyseAttachment(activeItem.id, userId, uploadId);
                        if (response.ok) {
                          modalStore.openModal('loadingOverlay', 'Analyse wird vorbereitet');
                          NotificationManager.success('Analyse erfolgreich geplant.');
                        }
                        else {
                          NotificationManager.error(`Beim Upload ist etwas schief gelaufen. Statuscode: ${response.status}`);
                          modalStore.closeModal();
                        }
                      } catch {
                        modalStore.closeModal();
                      }
                    })();
                  },
                  (x): void => {
                    modalStore.closeModal();
                    NotificationManager.error(`Beim Upload ist etwas schief gelaufen. ${x}`);
                  }
                );
              }}
              >
                Datei hochladen und analysieren
              </Button>
            ) : null
          }
        </StackLayout>

      </StackLayout>
    </Modal>
  );
};

export default observer(AnalyseAttachmentModal);
