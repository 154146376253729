import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import ArtStatusLog, { IArtStatusLog } from './ArtStatusLog';
import ArtStatus from '../../enums/art/ArtStatus';

const ArtHistory = types.model({
  statusLogs: types.optional(types.array(ArtStatusLog), []),
})
  .named('ArtHistory')
  .views(self => ({
    get isCaptured(): boolean {
      return self.statusLogs.find(x => x.status === ArtStatus.Captured) !== undefined;
    },
    get capturedLog(): IArtStatusLog | undefined {
      console.log('ART status log', self.statusLogs)
      return self.statusLogs.find(x => x.status === ArtStatus.Captured);
    },
    get isPacked(): boolean {
      return self.statusLogs.find(x => x.status === ArtStatus.Packed) !== undefined;
    },
    get packedLog(): IArtStatusLog | undefined {
      return self.statusLogs.find(x => x.status === ArtStatus.Packed);
    },
    get isPickedUp(): boolean {
      return self.statusLogs.find(x => x.status === ArtStatus.PickedUp) !== undefined;
    },
    get pickedUpLog(): IArtStatusLog | undefined {
      return self.statusLogs.find(x => x.status === ArtStatus.PickedUp);
    },
    get isDigitized(): boolean {
      return self.statusLogs.find(x => x.status === ArtStatus.Digitized) !== undefined;
    },
    get digitizedLog(): IArtStatusLog | undefined {
      return self.statusLogs.find(x => x.status === ArtStatus.Digitized);
    },
    get isProvided(): boolean {
      return self.statusLogs.find(x => x.status === ArtStatus.Provided) !== undefined;
    },
    get providedLog(): IArtStatusLog | undefined {
      return self.statusLogs.find(x => x.status === ArtStatus.Provided);
    },
    get isError(): boolean {
      return self.statusLogs.find(x => x.status === ArtStatus.Error) !== undefined;
    },
    get errorLog(): IArtStatusLog | undefined {
      return self.statusLogs.find(x => x.status === ArtStatus.Error);
    }
  }))


export default ArtHistory;

export interface IArtHistory extends Instance<typeof ArtHistory>{}
export interface IArtHistorySnapshotIn extends SnapshotIn<typeof ArtHistory>{}
export interface IArtHistorySnapshotOut extends SnapshotOut<typeof ArtHistory>{}
