import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Modal, useModalStore
} from '@wemogy/reactbase';
import IEditQrCodeSourceModalProps from './IEditQrCodeSourceModalProps';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import { IQrCodeSource } from '../../dataLayer/models/QrCodeSource';
import QrCodeSourceForm from '../../components/qrCodeSourceForm/QrCodeSourceForm';

const EditQrCodeSourceModal: React.FC<IEditQrCodeSourceModalProps> = () => {
  const {formStore} = useDataAccessStore();
  const modalStore = useModalStore();

  const qrCodeSource = modalStore.getActiveParameters<IQrCodeSource>('editQrCodeSource');

  if(!formStore.activeItem || !qrCodeSource){
    return null;
  }

  return (
    <Modal modalKey="editQrCodeSource" icon="settings" title="QR Code bearbeiten" large>
      <StackLayout>
        <QrCodeSourceForm
          qrCodeSource={qrCodeSource}
          onFinished={(): void => {
            modalStore.openModal('manageQrCodeSources');
          }}
        />
      </StackLayout>
    </Modal>
  );
};

export default observer(EditQrCodeSourceModal);
