import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, Icon
} from '@wemogy/reactbase';
import INoDataProps from './INoDataProps';

const NoData: React.FC<INoDataProps> = () => {

  return (
    <StackLayout hCenter vCenter>
      <Icon help xl />
      <Text marginTop>Keine Daten verfügbar</Text>
    </StackLayout>
  );
};

export default observer(NoData);
