/* eslint-disable react/no-array-index-key */
import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, TextInput, Text, isEmptyOrWhiteSpace, Icon
} from '@wemogy/reactbase';
import IKeyValueListProps from './IKeyValueListProps';
import KeyValuePair from '../../dataLayer/models/general/KeyValuePair';

const KeyValueList: React.FC<IKeyValueListProps> = ({
  options,  onChange
}) => {

  const canAdd = options.find(x => isEmptyOrWhiteSpace(x.key)) === undefined;
  return (
    <StackLayout>
      {options.map((option, i) => (
        <StackLayout key={`option_${i}`} horizontal marginBottom vCenter>
          <TextInput
            value={option.key}
            placeholder="Key"
            onChange={(text): void => {
              option.key = text;
              onChange(options);
            }}
          />
          <StackLayout width />
          <TextInput
            value={option.value ? JSON.parse(option.value) : ''}
            placeholder="Value"
            onChange={(text): void => {
              option.value = JSON.stringify(text);
              onChange(options);
            }}
          />
          <Icon
            deleteForever
            xl
            marginLeft
            onPress={(): void => {
              onChange(options.filter(x => x !== option));
            }}
          />
        </StackLayout>
      ))}
      {canAdd ? (
        <StackLayout
          height={3}
          horizontal
          vCenter
          onPress={(): void => {
            onChange([...options, new KeyValuePair('', JSON.stringify(''))]);
          }}
        >
          <Icon xl add marginRight />
          <Text>Neue Option hinzufügen</Text>
        </StackLayout>
      ) : <StackLayout height={3} />}

    </StackLayout>
  );
};

export default observer(KeyValueList);
