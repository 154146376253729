import {useModalStore} from '@wemogy/reactbase';
import {
  SingleTenantId,
} from '@wemogy/reactbase-authorization';
import {useCallback} from 'react';
import RestApiServiceFactory from '../../../../dataLayer/api/rest/RestApiServiceFactory';
import useDataAccessStore from '../../../../dataLayer/stores/hooks/UseDataAccessStore';
import IMember from '../../../containers/authorizationTmp/member/interfaces/IMember';
import IMemberManagementProps from '../../../containers/authorizationTmp/memberManagementCreator/IMemberManagementProps';
import createMemberManagementComponent from '../../../containers/authorizationTmp/memberManagementCreator/MemberManagementComponentCreator';
import { confirmDialogYesNoDanger } from '../../../wrappers/dialogs/DialogManager';
import NotificationManager from '../../../wrappers/inAppFeedback/NotificationManager';

const OrganizationMemberManagement: React.FC<IMemberManagementProps> =
  createMemberManagementComponent({
    pathIndex: 0,
    levelIndex: 0,
    hooks: {
      useMembersResolverHook: (): IMember[] => {
        const {userStore} = useDataAccessStore();

        return userStore.items.map((x) => ({
          id: x.id,
          email: x.email,
          username: x.fullname,
          roleId: x.globalRoleId,
        }));
      },
      useMemberRoleIdChangedHandlerHook: () => {
        const {userStore} = useDataAccessStore();
        const modalStore = useModalStore();

        const memberRoleIdChangedHandler = useCallback(
          (subjectId: string, resourceId: string, roleId: string): void => {
            modalStore.openModal(
              'loadingOverlay',
              'Zuweisung wird durchgeführt',
            );

            RestApiServiceFactory.memberApiService
              .upsertMemberAsync('global', SingleTenantId, resourceId, roleId, [
                subjectId,
              ])
              .then((): void => {
                userStore.getItem(subjectId)?.setGlobalRoleId(roleId);

                NotificationManager.success(
                  'Die Zuweisung wurde erfolgreich gespeichert',
                );
              })
              .finally(() => modalStore.closeModal());
          },
          [userStore],
        );

        return memberRoleIdChangedHandler;
      },
      useMembersDeletedHandlerHook: () => {
        const {userStore} = useDataAccessStore();
        const modalStore = useModalStore();

        return (_: string, subjects: string[]): void => {
          const subject = userStore.getItem(subjects[0]);

          if(!subject){
            return;
          }

          confirmDialogYesNoDanger(`Möchten Sie den User ${subject.fullname} wirklich löschen?`, (): void => {
            modalStore.openModal(
              'loadingOverlay',
              'User wird gelöscht',
            );

            userStore.delete(subject.id)
              .then((): void => {
                NotificationManager.success(
                  'Der User wurde erfolgreich gelöscht',
                );
              })
              .finally((): void => {
                modalStore.closeModal();
              })
          })
        }
      }
    },
  });

export default OrganizationMemberManagement;
