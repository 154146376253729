/* eslint-disable import/prefer-default-export */
import tailwindColorPalette from '@ky-is/tailwind-color-palette';

export interface IColorPalette{
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

export function getColorPalette(color: string): IColorPalette{
  return tailwindColorPalette(color).brand as any;
}

export function getRandomColor(): string{
  return '#3C366B';
}
