import {
  Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import { createBaseDataAccessStore, QueryParameters} from '@wemogy/reactbase';
import Invitation from '../../models/Invitation';
import RestApiServiceFactory from '../../api/rest/RestApiServiceFactory';
import InvitationDtoMapper from '../../api/dtoMappers/InvitationDtoMapper';

const InvitationStore = createBaseDataAccessStore(
  Invitation,
  RestApiServiceFactory.invitationService,
  new InvitationDtoMapper(),
  QueryParameters.create(),
);

export interface IInvitationStore extends Instance<typeof InvitationStore>{}
export interface IInvitationStoreSnapshotIn extends SnapshotIn<typeof InvitationStore>{}
export interface IInvitationStoreSnapshotOut extends SnapshotOut<typeof InvitationStore>{}

export default InvitationStore;
