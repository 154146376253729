import React from 'react';
import { observer } from 'mobx-react';
import { StackLayout } from '@wemogy/reactbase';
import { useAuthorizationConfig } from '@wemogy/reactbase-authorization';
import IMemberListItemProps from './IMemberListItemProps';
import MemberAvatar from '../memberAvatar/MemberAvatar';
import MemberRoleDropdown from '../memberRoleDropdown/MemberRoleDropdown';
import DeleteMember from '../deleteMember/DeleteMember';

const MemberListItem: React.FC<IMemberListItemProps> = ({
member, resourceId, useMemberRoleIdChangedHandlerHook, useMembersDeletedHandlerHook
}) => {
  const authorizationConfig = useAuthorizationConfig();
  const { memberAvatar } = authorizationConfig.ui;

    return (
      <StackLayout horizontal borderColor="green100" border={0.125} marginBottom padding>
        <StackLayout stretch>
          {typeof memberAvatar === 'function'
          ? memberAvatar(member.id)
          : <MemberAvatar member={member} memberAvatarConfig={memberAvatar} />}
        </StackLayout>


        <StackLayout width={20}>
          <MemberRoleDropdown
            permissionLevel={0 as any}
            resourceId={resourceId}
            member={member}
            useMemberRoleIdChangedHandlerHook={useMemberRoleIdChangedHandlerHook}
          />
        </StackLayout>
        {useMembersDeletedHandlerHook ? (<DeleteMember resourceId={resourceId} subjectId={member.id} useMembersDeletedHandlerHook={useMembersDeletedHandlerHook} />) : null}
      </StackLayout>
    )
}

export default observer(MemberListItem);
