import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { StackLayout, Icon } from '@wemogy/reactbase';
import _ from 'lodash';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import IAnnotatedBlockProps from './IAnnotatedBlockProps';
import UiElementDropArea from '../../dropAreas/uiElementDropArea/UiElementDropArea';
import ControlDropArea from '../../dropAreas/controlDropArea/ControlDropArea';
import UiElementRenderer from '../../toolboxElementTypeRenderers/uiElementRenderer/UiElementRenderer';
import IControlConfig from '../../toolboxSegments/allControls/interfaces/IControlConfig';
import ControlRenderer from '../../toolboxElementTypeRenderers/controlRenderer/ControlRenderer';
import useFormBuilderUiStore from '../../../dataLayer/stores/hooks/UseFormBuilderUiStore';
import {confirmDialogYesNoDanger} from '../../../../dialogs/DialogManager';

// #region SORT


const SortableItem = SortableElement(observer((props: any) => {
  const {
    layout, row, readonlySections
  } = props;
  const rowUiElement = layout.getUiElementByOrderId(row);
  const rowControl = layout.getControlByOrderId(row);
  const { formBuilderStore } = useFormBuilderUiStore();
  const [isHover, setIsHover] = useState(false);

  return (
    <div>
      <StackLayout onMouseMove={(): void => {setIsHover(true);}} onMouseLeave={(): void => {setIsHover(false);}} key={`row_${layout.id}_${row}`} horizontal width100 marginBottom>
        <UiElementDropArea
          stretch
          onDrop={(droppedInformation): void => {
            layout.putUiElement(row, droppedInformation.referenceKey, droppedInformation.config);
          }}
        >
          {
            rowUiElement
              ? (
                <UiElementRenderer
                  uiElement={rowUiElement}
                  onDelete={(): void => {
                    layout.removeUiElement(rowUiElement);
                  }}
                />
              )
              : null
          }
        </UiElementDropArea>
        <StackLayout width={0.5} />
        <ControlDropArea
          stretch
          onDrop={(droppedInformation): void => {
            const controlConfig: IControlConfig = droppedInformation.config;
            layout.putControl(row, droppedInformation.referenceKey, controlConfig.dataType, controlConfig.config);
          }}
        >
          {
            rowControl
              ? (
                <ControlRenderer
                  readonlySections={readonlySections}
                  control={rowControl}
                  onDelete={(): void => {
                    layout.removeControl(rowControl);
                  }}
                />
              )
              : null
          }

        </ControlDropArea>
        {
          props.isLast || !formBuilderStore.isEditing || !isHover ? null  : (
            <StackLayout positionAbsolute right={-8} top={0} bottom={0} width={8} vCenter>
              <StackLayout horizontal borderRadiusTopRight={0.5} borderRadiusBottomRight={0.5} backgroundColor="grey500" padding={0.5}>
                <Icon
                  onPress={(): void => {
                  // nothing to do
                  }}
                  xl
                  dragIndicator
                  marginRight
                />
                <Icon
                  onPress={(): void => {
                    props.onRemove(row);
                  }}
                  xl
                  deleteForever
                />
              </StackLayout>
            </StackLayout>
          )
        }
      </StackLayout>
    </div>
  );
}));

const SortableList = SortableContainer((props: any) => {
  const { formBuilderStore } = useFormBuilderUiStore();
  return (
    <div>
      {props.items.map((orderId: number, index: number) => (
        <SortableItem disabled={!formBuilderStore.isEditing} key={`sortableElementAnnotatedBlock${props.layout.id}_${orderId}`} onRemove={props.onRemove} index={orderId} row={orderId} isLast={props.items.length -1 === index} layout={props.layout} readonlySections={props.readonlySections} />
      )
      )}
    </div>
  );
});

// #endregion

const AnnotatedBlock: React.FC<IAnnotatedBlockProps> = ({
layout, readonlySections
}) => {
  const headerUiElement = layout.getUiElementByOrderId(0);

  const rows = _.range(1, Math.max(layout.maxUiElementOrderId, layout.maxControlOrderId || 0) + 2);

  console.log('rows', rows, layout.formSection)

  return (
    <StackLayout stretch>
      <UiElementDropArea onDrop={(droppedInformation): void => {
        layout.putUiElement(0, droppedInformation.referenceKey, droppedInformation.config);
      }}
      >
        {
          headerUiElement
            ? <UiElementRenderer uiElement={headerUiElement} onDelete={layout.removeUiElement} />
            : null
        }
      </UiElementDropArea>
      <StackLayout height={0.5} />
      <SortableList
        distance={1}
        items={rows}
        layout={layout}
        readonlySections={readonlySections}
        onRemove={(row: number): void => {
          confirmDialogYesNoDanger('Wollen Sie diese Zeile wirklich löschen?', (): void => {
            const controlToRemove = layout.getControlByOrderId(row);
            if (controlToRemove) {
              layout.removeControl(controlToRemove);
            }
            const uiElementToRemove = layout.getUiElementByOrderId(row);
            if (uiElementToRemove) {
              layout.removeUiElement(uiElementToRemove);
            }

            layout.controls.filter(x => x.orderId > row).forEach(x => x.decrementOrderId());
            layout.uiElements.filter(x => x.orderId > row).forEach(x => x.decrementOrderId());
          });

        }}
        onSortEnd={({
          oldIndex, newIndex
        }: any): void => {
          const movedUp = newIndex < oldIndex;

          const movedUiElement = layout.getUiElementByOrderId(oldIndex);
          const movedControls = layout.getControlByOrderId(oldIndex);
          if(movedUp){
            layout.controls.filter(x => x.orderId >= newIndex && x.orderId < oldIndex).forEach(x => x.incrementOrderId());
            layout.uiElements.filter(x => x.orderId >= newIndex && x.orderId < oldIndex).forEach(x => x.incrementOrderId());
          }
          else{
            layout.controls.filter(x => x.orderId <= newIndex && x.orderId > oldIndex).forEach(x => x.decrementOrderId());
            layout.uiElements.filter(x => x.orderId <= newIndex && x.orderId > oldIndex).forEach(x => x.decrementOrderId());
          }
          movedUiElement?.setOrderId(newIndex);
          movedControls?.setOrderId(newIndex);
        }}
      />
    </StackLayout>
  );
};

export default observer(AnnotatedBlock);
