import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { useTranslation } from '@wemogy/reactbase'
import ReactDatePicker from 'react-datepicker';
import IDatePickerProps from './IDatePickerProps';

import 'react-datepicker/dist/react-datepicker.css';

const DatePicker: React.FC<IDatePickerProps> = ({
placeholder, value, onChange
}) => {
  const {t} = useTranslation();

    return (
      <ReactDatePicker
        selected={value}
        onChange={(date): void => {
                if (_.isDate(date)) {
                  onChange(date);
                } else {
                  onChange(undefined)
              }
            }}
        isClearable
        placeholderText={t(placeholder)}
      />
    )
}

export default observer(DatePicker);
