import React from 'react';
import { observer } from 'mobx-react';
import { Modal, useModalStore } from '@wemogy/reactbase';
import IAddMarkerCodeModalProps from './IAddMarkerCodeModalProps';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import NotificationManager from '../../../inAppFeedback/NotificationManager';
import { IMarkerCode } from '../../dataLayer/models/MarkerCode';
import MarkerCodeForm from '../../components/markerCodeForm/MarkerCodeForm';

const AddMarkerCodeModal: React.FC<IAddMarkerCodeModalProps> = () => {
  const { formStore } = useDataAccessStore();
  const modalStore = useModalStore();

  const form = formStore.activeItem;

  const editMarker = modalStore.getActiveParameters<IMarkerCode>('addMarkerCode');

  if (!form) {
    return null;
  }

  return (
    <Modal
      modalKey="addMarkerCode"
      icon="add"
      title="Marker-Code hinzufügen"
      large
    >
      <MarkerCodeForm
        markerCode={editMarker}
        onCreated={(markerCode): void => {
          NotificationManager.success(`Der Marker Code ${markerCode.name} wurde angelegt. Zum Speichern, speichern Sie bitte die Datenbank (Datenbank -> Speichern)`);
          form.addMarkerCode(markerCode);
          modalStore.openModal('manageMarkerCodes');
        }}
        onUpdated={(markerCode): void => {
          NotificationManager.success(`Der Marker Code ${markerCode.name} wurde aktualisiert. Zum Speichern, speichern Sie bitte die Datenbank (Datenbank -> Speichern)`);
          modalStore.openModal('manageMarkerCodes');
        }}
      />
    </Modal>
  );
};

export default observer(AddMarkerCodeModal);
