import React from 'react';
import { observer } from 'mobx-react';
import { StackLayout } from '@wemogy/reactbase';
import IControlTypeSpecificEditingRendererProps from './IControlTypeSpecificEditingRendererProps';
import AnnotatedTextInput from '../../../../containers/formElements/annotatedTextInput/AnnotatedTextInput';
import InputTypeConfig from '../../../../dataLayer/models/controlTypeConfigs/InputTypeConfig';
import KeyValueList from '../../../../containers/keyValueList/KeyValueList';
import RadioTypeConfig from '../../../../dataLayer/models/controlTypeConfigs/RadioTypeConfig';
import CheckGroupTypeConfig from '../../../../dataLayer/models/controlTypeConfigs/CheckGroupTypeConfig';
import DropdownTypeConfig from '../../../../dataLayer/models/controlTypeConfigs/DropdownTypeConfig';
import ControlType from '../../../../dataLayer/enums/ControlType';
import OptionsTypeConfig from '../../../../dataLayer/models/controlTypeConfigs/OptionsTypeConfig';
import Checkbox from '../../../../../../components/checkbox/Checkbox';
import AnnotatedFormView from '../../../../containers/formElements/annotatedFormView/AnnotatedFormView';

const ControlTypeSpecificEditingRenderer: React.FC<IControlTypeSpecificEditingRendererProps> = ({ control }) => {

  const editOptionsTypeConfig = (optionsTypeConfig: OptionsTypeConfig): JSX.Element => (
    <StackLayout>
      <KeyValueList
        options={optionsTypeConfig.options}
        onChange={(options): void => {
          control.updateConfig<DropdownTypeConfig>(x => x.options = options);
          // check if deleted option is default option
          const defaultValue = control.getDefaultValue();
          if (defaultValue !== undefined) {
            const defaultOptionIsPartOfOptions = options.find(x => x.value === defaultValue);
            if (!defaultOptionIsPartOfOptions) {
              control.unsetDefaultValue();
            }
          }
        }}
      />
    </StackLayout>
  );


  switch (control.type) {
    case ControlType.Input:
      control.setConfigIfUndefined<InputTypeConfig>(new InputTypeConfig());
      const inputConfig = control.getConfig<InputTypeConfig>();
      if (!inputConfig) {
        return null;
      }
      return (
        <StackLayout>
          <AnnotatedTextInput
            placeholder="Platzhalter"
            description="Platzhaltertext"
            onChange={(placeholder): void => {
              control.updateConfig<InputTypeConfig>(config => config.placeholder = placeholder);
            }}
            value={inputConfig.placeholder}
            marginBottom
          />
          {inputConfig.isNumberInput ? null : (
            <AnnotatedFormView description="Mehrzeilig" marginBottom>
              <Checkbox
                checked={inputConfig.isMultiline}
                onChange={(): void => {
                control.updateConfig<InputTypeConfig>(config => {
                  config.isMultiline = !config.isMultiline;
                });
              }}
              />
            </AnnotatedFormView>
)}
        </StackLayout>
      );
    case ControlType.Radio:
      control.setConfigIfUndefined<RadioTypeConfig>(new RadioTypeConfig());
      const radioConfig = control.getConfig<RadioTypeConfig>();
      if (!radioConfig) {
        return null;
      }
      return editOptionsTypeConfig(radioConfig);
    case ControlType.CheckGroup:
      control.setConfigIfUndefined<CheckGroupTypeConfig>(new CheckGroupTypeConfig());
      const checkGroupConfig = control.getConfig<CheckGroupTypeConfig>();
      if (!checkGroupConfig) {
        return null;
      }
      return editOptionsTypeConfig(checkGroupConfig);
    case ControlType.Dropdown:
      control.setConfigIfUndefined<DropdownTypeConfig>(new DropdownTypeConfig());
      const dropdownConfig = control.getConfig<DropdownTypeConfig>();
      if (!dropdownConfig) {
        return null;
      }
      return editOptionsTypeConfig(dropdownConfig);
    default:
      return null;
  }
};

export default observer(ControlTypeSpecificEditingRenderer);
