/* eslint-disable no-await-in-loop */
import React from 'react';
import { observer } from 'mobx-react';
import { Item } from 'react-contexify';
import { ContextMenu, useContextMenuStore } from '@wemogy/reactbase';
import IHistoryContextMenuProps from './IHistoryContextMenuProps';
import useDataItemStore from '../../dataLayer/stores/hooks/UseDataItemStore';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import DataItemHistoryList from '../../containers/dataItemHistoryList/DataItemHistoryList';
import IHistoryContextMenuParameters from './interfaces/IHistoryContextMenuParameters';

const HistoryContextMenu: React.FC<IHistoryContextMenuProps> = () => {
  const contextMenuStore = useContextMenuStore();
  const {formStore} = useDataAccessStore();

  const dataItemStore = useDataItemStore();
  const { activeItem } = formStore;

  if (!dataItemStore || !activeItem) {
    return null;
  }

  const parameters = contextMenuStore.getActiveParameters<IHistoryContextMenuParameters>('history');

  if (!parameters) {
    return (
      <ContextMenu
        contextMenuKey="history"
      >
        {null}

      </ContextMenu>
    );
  }

  const {
    dataItem, property
  } = parameters;

  return (
    <ContextMenu
      contextMenuKey="history"
    >
      <Item>
        <DataItemHistoryList dataItem={dataItem} property={property} />
      </Item>
    </ContextMenu>
  );
};

export default observer(HistoryContextMenu);
