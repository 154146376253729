import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';


const MultiSelectData = types.model({
  key: types.string,
  value: types.string
})
  .named('MultiSelectData');
export default MultiSelectData;

export interface IMultiSelectData extends Instance<typeof MultiSelectData>{}
export interface IMultiSelectDataSnapshotIn extends SnapshotIn<typeof MultiSelectData>{}
export interface IMultiSelectDataSnapshotOut extends SnapshotOut<typeof MultiSelectData>{}
