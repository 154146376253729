import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, TextInput, Button
} from '@wemogy/reactbase';
import IConfirmCriticalOperationProps from './IConfirmCriticalOperationProps';

const ConfirmCriticalOperation: React.FC<IConfirmCriticalOperationProps> = ({
  question, onConfirmed
}) => {
  const [confirmationText, setConfirmationText] = useState('');

  const isConfirmed = confirmationText.toLowerCase() === 'ja';

    return (
      <StackLayout paddingTop borderTop={0.25} border={0} borderColor="primary">
        <Text marginBottom>{question}</Text>
        <TextInput placeholder="Bitte geben Sie Ja ein, um die Operation zu bestätigen" value={confirmationText} onChange={setConfirmationText} />
        {isConfirmed ? <Button marginTop onPress={onConfirmed}>Jetzt ausführen</Button> : undefined}
      </StackLayout>
    )
}

export default observer(ConfirmCriticalOperation);
