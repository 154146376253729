import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Modal, useModalStore
} from '@wemogy/reactbase';
import IEditWorkingPackageModalProps from './IEditWorkingPackageModalProps';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import { IWorkingPackage } from '../../dataLayer/models/WorkingPackage';
import WorkingPackageForm from '../../components/workingPackageForm/WorkingPackageForm';
import NotificationManager from '../../../inAppFeedback/NotificationManager';

const EditWorkingPackageModal: React.FC<IEditWorkingPackageModalProps> = () => {
  const {formStore} = useDataAccessStore();
  const modalStore = useModalStore();
  const workingPackage = modalStore.getActiveParameters<IWorkingPackage>('editWorkingPackage');

  if(!formStore.activeItem || !workingPackage){
    return null;
  }

  return (
    <Modal modalKey="editWorkingPackage" icon="edit" title="Arbeitspaket bearbeiten" large>
      <StackLayout>
        <WorkingPackageForm
          onFinished={(): void => {
            if (!formStore.activeItem) {
              return;
            }
            modalStore.openModal('loadingOverlay', 'Datenbank wird gespeichert');
            try {
              formStore.put(formStore.activeItem).then((apiResult): void => {
                if (apiResult.ok) {
                  NotificationManager.success('Erfolgreich gespeichert.');
                } else {
                  NotificationManager.error(`Es ist ein Fehler aufgetreten: ${apiResult.status}`);
                }

                modalStore.openModal('manageWorkingPackages');
              }).catch((reason): void => {
                modalStore.closeModal();
                NotificationManager.error(`Es ist ein Fehler aufgetreten: CATCH Promise -> ${reason ? JSON.stringify(reason) : 'No Reason'}`);
              });
            } catch (error) {
              modalStore.closeModal();
              NotificationManager.error('Es ist ein Fehler aufgetreten: CATCH');
            }
          }}
          workingPackage={workingPackage}
        />
      </StackLayout>
    </Modal>
  );
};

export default observer(EditWorkingPackageModal);
