import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import _ from 'lodash';
import StatusLogEntry, { IStatusLogEntry } from './StatusLogEntry';
import StatusLogVerificationEntry from './StatusLogVerificationEntry';
import StatusStep from '../../enums/internal/StatusStep';
import StatusLogAssignEntry from './StatusLogAssignEntry';
import DateRange from '../general/DateRange';


const Status = types.model({
  imported: types.optional(types.array(StatusLogEntry), []),
  attachmentsAssigned: types.optional(types.array(StatusLogAssignEntry), []),
  indexed: types.optional(types.array(StatusLogEntry), []),
  workingPackageAssigned: types.optional(types.array(StatusLogAssignEntry), []),
  dataCollected: types.optional(types.array(StatusLogEntry), []),
  dataQualityAssured: types.optional(types.array(StatusLogEntry), []),
  dataCollectionVerified: types.optional(types.array(StatusLogVerificationEntry), []),
  exported: types.optional(types.array(StatusLogEntry), []),
})
  .named('Status')
  .views(self => ({
    get isImported(): boolean {
      return self.imported.length > 0;
    },
    get isIndexed(): boolean {
      return self.indexed !== null && self.indexed.length > 0;
    },
    get isDataCollected(): boolean {
      return self.dataCollected.length > 0;
    },
    get isDataQualityAssured(): boolean {
      return self.dataQualityAssured.length > 0;
    },
    get hasDataCollectionVerifiedData(): boolean{
      return self.dataCollectionVerified.length > 0;
    },
    get isDataCollectionVerified(): boolean {
      return self.dataCollectionVerified.length > 0 && self.dataCollectionVerified[self.dataCollectionVerified.length - 1].verified;
    },
    get isExported(): boolean {
      return self.exported.length > 0;
    },
    get latestImported(): IStatusLogEntry | undefined{
      return _.last(self.imported);
    },
    get latestIndexed(): IStatusLogEntry | undefined{
      return _.last(self.indexed);
    },
    get latestDataCollected(): IStatusLogEntry | undefined{
      return _.last(self.dataCollected);
    },
    get latestDataQualityAssured(): IStatusLogEntry | undefined{
      return _.last(self.dataQualityAssured);
    },
    get latestDataCollectionVerified(): IStatusLogEntry | undefined{
      return _.last(self.dataCollectionVerified);
    },
    get firstDataQualityAssured(): IStatusLogEntry | undefined{
      return _.first(self.dataQualityAssured);
    },
    getStatusStepAt(timestamp: Date): StatusStep {
      const latestImport = this.latestImported?.timestamp;
      if (!latestImport || timestamp <= latestImport) {
        return StatusStep.Created;
      }
      const latestIndexed = this.latestIndexed?.timestamp;
      if (!latestIndexed || timestamp <= latestIndexed) {
        return StatusStep.Imported;
      }
      const latestDataCollected = this.latestDataCollected?.timestamp;
      const latestDataQualityAssured = this.latestDataQualityAssured?.timestamp;
      const latestDataCollectionVerified = this.latestDataCollectionVerified?.timestamp;
      if (!latestDataCollected || timestamp <= latestDataCollected) {
        return StatusStep.DataCollected;
      }
      if (!latestDataQualityAssured || timestamp <= latestDataQualityAssured) {
        return StatusStep.DataQualityAssured;
      }
      if (!latestDataCollectionVerified || timestamp <= latestDataCollectionVerified) {
        return StatusStep.DataCollectionVerified;
      }
      return StatusStep.Exported;
    },
    getDateRangesOfStatusStep(statusStep: StatusStep, createdAt: Date): DateRange[]{
      switch (statusStep) {
        case StatusStep.DataCollected:
          if (!this.latestDataCollected) {
            return [
              new DateRange(createdAt)
            ];
          }
          return [
            new DateRange(createdAt, this.latestDataCollected.timestamp)
          ];
        case StatusStep.DataQualityAssured:
          if (!this.latestDataCollected) {
            return [];
          }
          return [
            // all data collected before "data collection"
            new DateRange(createdAt, this.latestIndexed?.timestamp),
            // plus all data of QS (after data collected done)
            new DateRange(this.latestDataCollected.timestamp)
          ];
        default:
          return [
            new DateRange(createdAt)
          ];
      }
    }
  }))
  .actions(self => ({
    completeActiveStep(issuer: string): boolean{
      const snap = { issuer };
      const logEntry = StatusLogEntry.create(snap);
      if (!self.isIndexed) {
        self.indexed.push(logEntry);
        return true;
      }
      if (!self.isDataCollected) {
        self.dataCollected.push(logEntry);
        return true;
      }
      if (!self.isDataQualityAssured) {
        self.dataQualityAssured.push(logEntry);
      }
      return true;
    },
    verifyDataCollection(verified: boolean, issuer: string): void{
      self.dataCollectionVerified.push(StatusLogVerificationEntry.create({
        issuer,
        verified
      }))
    },
    resetToTimestamp(timestamp: Date): void {
      const timestampFilter = (x: IStatusLogEntry): boolean => x.timestamp <= timestamp;
      self.imported.replace(self.imported.filter(timestampFilter));
      self.attachmentsAssigned.replace(self.attachmentsAssigned.filter(timestampFilter));
      self.indexed.replace(self.indexed.filter(timestampFilter));
      self.workingPackageAssigned.replace(self.workingPackageAssigned.filter(timestampFilter));
      self.dataCollected.replace(self.dataCollected.filter(timestampFilter));
      self.dataQualityAssured.replace(self.dataQualityAssured.filter(timestampFilter));
      self.dataCollectionVerified.replace(self.dataCollectionVerified.filter(timestampFilter));
      self.exported.replace(self.exported.filter(timestampFilter));
    },
    removeLastIndexed(): void{
      self.indexed.replace(self.indexed.filter(x => x !== _.last(self.indexed)));
    },
    removeLastDataCollected(): void{
      self.dataCollected.pop();
    },
    removeLastDataQualityAssured(): void{
      self.dataQualityAssured.pop();
    }
  }));
export default Status;

export interface IStatus extends Instance<typeof Status>{}
export interface IStatusSnapshotIn extends SnapshotIn<typeof Status>{}
export interface IStatusSnapshotOut extends SnapshotOut<typeof Status>{}
