import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, Icon
} from '@wemogy/reactbase';
import IWorkingPackageStatisticsProps from './IWorkingPackageStatisticsProps';
import StatisticSection from '../statisticSection/StatisticSection';
import {formatToShortDateTimeString} from '../../../wrappers/dateSelector/DateHelpers';
import BarChart from '../../../wrappers/charts/barChart/BarChart';
import TimePeriodStatisticsChart from '../../timePeriodStatisticsChart/TimePeriodStatisticsChart';
import PdfMetadataStatisticsChart from '../../pdfMetadataStatisticsChart/PdfMetadataStatisticsChart';
import FieldStatisticsChart from '../../fieldStatisticsChart/FieldStatisticsChart';

const WorkingPackageStatistics: React.FC<IWorkingPackageStatisticsProps> = ({ form }) => {
  return (
    <StackLayout>
      {
        form.workingPackages.map(workingPackage => {

          return  (
            <StatisticSection
              key={workingPackage.id}
              infoSection={(
                <StackLayout horizontal>
                  <StackLayout stretch>
                    <Text caption>angelegt am</Text>
                    <Text marginBottom={2} noTranslation>{formatToShortDateTimeString(workingPackage.createdAt)}</Text>
                    <Text caption>abgeschlossen am</Text>
                    <Text noTranslation>{workingPackage.end ? formatToShortDateTimeString(workingPackage.end) : '-'}</Text>
                  </StackLayout>
                  <StackLayout stretch hEnd>
                    <Text caption>Anzahl Dokumente</Text>
                    <Text marginBottom={2}>{workingPackage.numberOfAttachments}</Text>
                    <Text caption>alle Datensätze mit mindestens einem Dokument</Text>
                    <StackLayout horizontal vCenter>
                      <Icon xl checkCircle />
                      <Text>Ja</Text>
                    </StackLayout>
                  </StackLayout>
                </StackLayout>
              )}
              name={workingPackage.name}
              marginBottom={2}
              maxValue={workingPackage.numberOfDataItems}
              barSections={[
                {
                  name: 'Datensätze (Arbeitspaket)',
                  value:
                    {
                      name: 'Datensätze (Arbeitspaket)',
                      value: workingPackage.numberOfDataItems
                    }
                },
                {
                  name: 'Erfassung abgeschlossen',
                  value:
                    {
                      name: 'Erfassung abgeschlossen',
                      value: workingPackage.numberOfDataItemsWhereDataCollected
                    }
                },
                {
                  name: 'Qualitätssicherung abgeschlossen',
                  value:
                    {
                      name: 'Qualitätssicherung abgeschlossen',
                      value: workingPackage.numberOfDataItemsWhereDataQualityAssured
                    }
                },
                {
                  name: 'Datensätze in Stichprobe (-en) geprüft',
                  value:
                    {
                      name: 'Datensätze in Stichprobe (-en) geprüft',
                      value: workingPackage.numberOfDataItemsWhereDataCollectionVerified
                    }
                },
                {
                  name: 'Datensätze mit Rückfragen',
                  value:
                    {
                      name: 'Datensätze mit Rückfragen',
                      value: workingPackage.numberOfDataItemsWithComments
                    }
                },
                {
                  name: 'Datensätze mit gelösten Rückfragen',
                  value:
                    {
                      name: 'Datensätze mit gelösten Rückfragen',
                      value: workingPackage.numberOfDataItemsWithResolvedComments
                    }
                },
                {
                  name: 'Datensätze mit offenen Rückfragen',
                  value:
                    {
                      name: 'Datensätze mit offenen Rückfragen',
                      value: workingPackage.numberOfDataItemsWithComments - workingPackage.numberOfDataItemsWithResolvedComments
                    }
                },
                {
                  name: 'Datensätze offen *',
                  value:
                    {
                      name: 'Datensätze offen *',
                      value: workingPackage.numberOfDataItems - workingPackage.numberOfDataItemsWhereDataCollected
                    }
                },
                {
                  name: 'Datensätze mit Differenz zwischen Erfassung und 1. QS',
                  value:
                    {
                      name: 'Datensätze mit Differenz zwischen Erfassung und 1. QS',
                      value: workingPackage.numberOfDataItemsWhereDataCollectedAndQualityAssuredNotMatch
                    }
                },
              ]}
              addMore={(
                <>
                  <BarChart
                    maxValue={200}
                    sections={[
                      {
                        name: 'mit Differenzen zwischen Erfassung und Qualitätssicherung',
                        value:
                          {
                            name: 'mit Differenzen zwischen Erfassung und Qualitätssicherung',
                            value: 50
                          }
                      }
                    ]}
                  />
                  <TimePeriodStatisticsChart items={[{
                    label: 'Erfasst',
                    timePeriodStatistics: workingPackage.dataCollectedHistory
                  },
                  {
                    label: 'QS',
                    timePeriodStatistics: workingPackage.dataQualityAssuredHistory
                  }]}
                  />

                  <FieldStatisticsChart fieldStatistics={workingPackage.fieldStatistics} max={workingPackage.numberOfDataItems} />

                  <PdfMetadataStatisticsChart form={form} pdfMarkerCodes={workingPackage.pdfMetadataStatistics.markerCodes} pdfMetadataStatisticsChart={workingPackage.pdfMetadataStatistics.calculateStatistics()} numberOfAttachments={workingPackage.numberOfAttachments} />
                </>
              )}
            />
          );
        })
      }
    </StackLayout>
  );
};

export default observer(WorkingPackageStatistics);
