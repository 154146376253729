import React from 'react';
import { observer } from 'mobx-react';
import {StackLayout, Text} from '@wemogy/reactbase';
import IToolboxProps from './IToolboxProps';
import SegmentedView from '../../../segmentedView/SegmentedView';
import AllLayouts from '../toolboxSegments/allLayouts/AllLayouts';
import AllUiElements from '../toolboxSegments/allUiElements/AllUiElements';
import AllControls from '../toolboxSegments/allControls/AllControls';
import useFormBuilderUiStore from '../../dataLayer/stores/hooks/UseFormBuilderUiStore';
import ScrollContainer from '../../../scrollContainer/ScrollContainer';

const Toolbox: React.FC<IToolboxProps> = () => {
  const {formBuilderStore} = useFormBuilderUiStore();

  return (
    <StackLayout height100 width={50} opacity={formBuilderStore.isEditing ? undefined : 0.5}>
      <StackLayout hCenter height={5}>
        <Text title>Toolbox</Text>
      </StackLayout>
      <ScrollContainer>
        <SegmentedView elements={[
          {
            key: 'Layout',
            view: <AllLayouts />
          },
          {
            key: 'Beschriftung',
            view: <AllUiElements />
          },
          {
            key: 'Eingabe',
            view: <AllControls />
          }
        ]}
        />
      </ScrollContainer>
    </StackLayout>
  );
};

export default observer(Toolbox);
