import {createBaseDataAccessStore, QueryParameters} from '@wemogy/reactbase';
import UserStatistic from '../../models/statistics/UserStatistic';
import RestApiServiceFactory from '../../../../../../dataLayer/api/rest/RestApiServiceFactory';
import UserStatisticDtoMapper from '../../api/dtoMappers/UserStatisticDtoMapper';

const UserStatisticStore = createBaseDataAccessStore(
  UserStatistic,
  RestApiServiceFactory.userStatisticService,
  new UserStatisticDtoMapper(),
  QueryParameters.create().withTake(100),
);

export default UserStatisticStore;
