import React, { useState } from 'react';
import { observer } from 'mobx-react';
import DayPicker, { DateUtils } from 'react-day-picker';
import { useTheme } from '@wemogy/reactbase';
import IDateSelectorProps from './IDateSelectorProps';
import 'react-day-picker/lib/style.css';
import { todayBegin } from './DateHelpers';


const DateSelector: React.FC<IDateSelectorProps> = ({
  startDate, endDate, minDate, onStartDateChanged, onEndDateChanged
}) => {
  const theme = useTheme();
  const [enteredTo, setEnteredTo] = useState<Date | undefined>(endDate);
  const modifiers = {
    start: startDate, end: enteredTo
  };
  const disabledDays: any = { before: startDate || todayBegin()};
  const selectedDays: any = [startDate, {
    from: startDate, to: enteredTo
  }];

  const isSelectingFirstDay = (from: Date | undefined, to: Date | undefined, day: Date): boolean => {
    const isBeforeFirstDay = from !== undefined && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from !== undefined && to !== undefined;
    return from === undefined || isBeforeFirstDay || isRangeSelected;
  };

  const reset = (): void => {
    onStartDateChanged(undefined);
    onEndDateChanged(undefined);
    setEnteredTo(undefined);
  };

  const handleDayClick = (day: Date): void => {
    if(minDate && day < minDate){
      return;
    }
    if (startDate && endDate && day >= startDate && day <= endDate) {
      reset();
      return;
    }
    if (isSelectingFirstDay(startDate, endDate, day)) {
      onStartDateChanged(day);
      onEndDateChanged(undefined);
      setEnteredTo(undefined);
    } else {
      onEndDateChanged(day);
      setEnteredTo(day);
    }
  };

  const handleDayMouseEnter = (day: Date): void => {
    if (!isSelectingFirstDay(startDate, endDate, day)) {
      setEnteredTo(day);
    }
  };



  return (
    <DayPicker
      className="Range"
      numberOfMonths={2}
      fromMonth={new Date()}
      selectedDays={selectedDays}
      disabledDays={disabledDays}
      modifiers={modifiers}
      onDayClick={handleDayClick}
      onDayMouseEnter={handleDayMouseEnter}
      initialMonth={startDate || new Date()}
      modifiersStyles={{
        selected: {backgroundColor: theme.colorScheme.primary}, today: {color: theme.colorScheme.primary}
      }}
    />
  );
};

export default observer(DateSelector);
