import React from 'react';
import ReactModal from 'react-modal';
import { observer } from 'mobx-react';
import {
StackLayout, useTheme, useModalStore
} from '@wemogy/reactbase';
import {GridLoader} from 'react-spinners';
import ILoadingOverlayModalProps from './ILoadingOverlayModalProps';
import RealtimeStatus from './realtimeStatus/RealtimeStatus';

const LoadingOverlayModal: React.FC<ILoadingOverlayModalProps> = () => {
  const modalStore = useModalStore();
  const theme = useTheme();

  return (
    <ReactModal
      isOpen={modalStore.showModal('loadingOverlay')}
      ariaHideApp={false}
      onRequestClose={modalStore.closeModal}
      style={{
        content: {
          background: 'transparent', display: 'flex'
        }
      }}
    >
      <StackLayout stretch hCenter vCenter width={30} minHeight={10}>
        <GridLoader size={40} color={theme.colorScheme.primary} />
        <RealtimeStatus />
      </StackLayout>
    </ReactModal>
  );
};

export default observer(LoadingOverlayModal);
