import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import {StackLayout, useTheme} from '@wemogy/reactbase';
import { useDrop } from 'react-dnd';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import IFormRootProps from './IFormRootProps';
import ToolboxElementType from '../../dataLayer/uiEnums/ToolboxElementType';
import Layout from '../../dataLayer/models/Layout';
import LayoutRenderer from '../toolboxElementTypeRenderers/layoutRenderer/LayoutRenderer';
import UiElementRenderer from '../toolboxElementTypeRenderers/uiElementRenderer/UiElementRenderer';
import UiElement from '../../dataLayer/models/UiElement';
import BaseDropArea from '../dropAreas/baseDropArea/BaseDropArea';
import useFormBuilderUiStore from '../../dataLayer/stores/hooks/UseFormBuilderUiStore';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import ScrollContainer from '../../../scrollContainer/ScrollContainer';


const FormRoot: React.FC<IFormRootProps> = ({
  readonly, activeSection, noScroll, readonlySections
}) => {
  const {formStore} = useDataAccessStore();
  const [force] = useState<any>(undefined);
  const { formBuilderStore } = useFormBuilderUiStore();
  const theme = useTheme();


  const [, drop] = useDrop({accept: [ToolboxElementType.Layout.toString(), ToolboxElementType.UiElement.toString()]});

  useEffect((): void =>{
    formBuilderStore.setIsEditing(!readonly);
  }, []);

  const {activeItem} = formStore;

  if(!activeItem){
    return null;
  }

  const layouts = activeItem.layouts.filter(x => x.formSection === activeSection);
  const uiElements = activeItem.uiElements.filter(x => x.formSection === activeSection);

  console.log('layouts', layouts);
  console.log('uiElements', uiElements);
  console.log('form', activeItem)

  const totalNumberOfLayoutsAndUiElements = layouts.length + uiElements.length;
  const orderIds = _.range(1, totalNumberOfLayoutsAndUiElements + 1);

  const ElementRenderer = observer(({orderId}: any): JSX.Element | null => {
    const layout = layouts.find(x => x.orderId === orderId);
    if(layout){
      return (
        <LayoutRenderer readonlySections={readonlySections} layout={layout} onDelete={activeItem.removeLayout} />
      );
    }
    const uiElement = uiElements.find(x => x.orderId === orderId);
    if(uiElement){
      return (
        <UiElementRenderer uiElement={uiElement} onDelete={activeItem.removeUiElement} />
      );
    }

    return null;
  });

  const renderDropPlaceholder = (orderId: number, showAlways?: boolean): JSX.Element | null => {
    if(!formBuilderStore.isEditing){
      return null;
    }

    return (
      <BaseDropArea
        showAlways={showAlways}
        onlyShowPlaceholderWhenDragging
        placeholder="Platzieren Sie ein Layout oder eine Beschriftung hier"
        acceptedTypes={[ToolboxElementType.Layout, ToolboxElementType.UiElement]}
        onDrop={(droppedInformation): void => {
          layouts.filter(x => x.orderId > orderId).forEach(x => x.incrementOrderId());
          uiElements.filter(x => x.orderId > orderId).forEach(x => x.incrementOrderId());

          switch (droppedInformation.type) {
            case ToolboxElementType.Layout:
              const newLayout = Layout.create({
                type: droppedInformation.referenceKey as any,
                orderId: orderId + 1,
                formSection: activeSection
              });
              if(droppedInformation.config){
                newLayout.setConfig(droppedInformation.config);
              }
              formStore.activeItem?.addLayout(newLayout);
              break;
            case ToolboxElementType.UiElement:
              const newUiElement = UiElement.create({
                type: droppedInformation.referenceKey as any,
                orderId: orderId + 1,
                formSection: activeSection
              });
              newUiElement.setConfig(droppedInformation.config);
                  formStore.activeItem?.addUiElement(newUiElement);
              break;

            default:
              break;
          }

        }}
      />
    );
  };

  console.log(force);


  // #region SORT


  const SortableItem = SortableElement((props: any) => (
    <div>
      <React.Fragment key={`formRoot_${props.orderId}`}>
        <ElementRenderer orderId={props.orderId} />
        <StackLayout height />
        {
          renderDropPlaceholder(props.orderId)
        }
      </React.Fragment>
    </div>
  ));

  const SortableList = SortableContainer((props: any) => {
    return (
      <div>
        {props.items.map((orderId: number) => (
          <SortableItem disabled={!formBuilderStore.isEditing} key={`sortableElement${orderId}`} index={orderId} orderId={orderId} />
        )
        )}
      </div>
    );
  });

  // #endregion

  const content =  (
    <div
      ref={drop}
    >
      {
        totalNumberOfLayoutsAndUiElements > 0  ? renderDropPlaceholder(0) : null
      }
      <SortableList
        distance={1}
        items={orderIds}
        onSortEnd={({
          oldIndex, newIndex
        }: any): void => {
          const movedUp = newIndex < oldIndex;
          const movedElement = activeItem.getLayoutOrUiElementAtOrderId(oldIndex, activeSection);
          if(!movedElement){
            return;
          }
          if(movedUp){
            layouts.filter(x => x.orderId >= newIndex && x.orderId < oldIndex).forEach(x => x.incrementOrderId());
            uiElements.filter(x => x.orderId >= newIndex && x.orderId < oldIndex).forEach(x => x.incrementOrderId());
          }
          else{
            layouts.filter(x => x.orderId <= newIndex && x.orderId > oldIndex).forEach(x => x.decrementOrderId());
            uiElements.filter(x => x.orderId <= newIndex && x.orderId > oldIndex).forEach(x => x.decrementOrderId());
          }
          movedElement.setOrderId(newIndex);
        }}
      />

      {
        totalNumberOfLayoutsAndUiElements === 0 ? renderDropPlaceholder(totalNumberOfLayoutsAndUiElements, totalNumberOfLayoutsAndUiElements === 0) : null
      }
      <div />
    </div>
  );

  if (!readonly) {
    return (
      <ScrollContainer customMaxHeight="100%" stretch paddingTop={4} paddingRight={8}>
        {content}
      </ScrollContainer>
    );
  }

  if (noScroll) {
    return (
      <StackLayout width100 stretch backgroundColor="grey100" padding={2}>
        {content}
      </StackLayout>
    );
  }

  return (
    <ScrollContainer customMaxHeight="100%" width100 stretch border={0.25} borderColor={theme.colorScheme.grey700} backgroundColor={theme.colorScheme.grey100} padding={2}>
      {content}
    </ScrollContainer>
  );
};

export default observer(FormRoot);
