import {types, IModelType} from 'mobx-state-tree';
import _ from 'lodash';
import moment from 'moment';
import {BaseModel, IBaseModel} from '@wemogy/reactbase';
import { IForm } from '../Form';
import DataType from '../../enums/DataType';
import { IControl } from '../Control';
import Comment, { IComment } from '../Comment';
import Attachment, { IAttachment } from '../Attachment';
import Status, { IStatus } from '../status/Status';
import ValidationResult from '../../enums/ValidationResult';
import Transaction, { ITransaction, ITransactionSnapshotIn } from '../eventSourcing/Transaction';
import StatusStep from '../../enums/internal/StatusStep';
import FormSection from '../../enums/FormSection';
import ArtHistory, { IArtHistory } from '../art/ArtHistory';

// #region properties

function getModelProperties(controls: IControl[]): any {
  const modelProperties = {};

  controls.forEach(control => {
    if (!control.destinationName) {
      return;
    }
    switch (control.dataType) {
      case DataType.String:
      case DataType.StringList: // StringList contains a serialized string array
        modelProperties[control.destinationName] = types.optional(types.string, '');
        break;
      case DataType.Int:
      case DataType.Double:
        modelProperties[control.destinationName] = types.maybeNull(types.number);
        break;
      case DataType.Boolean:
        modelProperties[control.destinationName] = types.optional(types.boolean, false);
        break;
      case DataType.Date:
      case DataType.DateTime:
        modelProperties[control.destinationName] = types.maybeNull(types.string);
        break;
      case DataType.Address:
        // serialized address object
        modelProperties[control.destinationName] = types.maybeNull(types.string);
        break;
      default:
        break;
    }
  });

  return modelProperties;
}

// #endregion

// #region actions


export function setterName(propertyName: string): string {
  return `set${_.upperFirst(propertyName)}`;
}

function getModelActions(self: any, controls: IControl[]): any {
  const modelActions = {};

  controls.forEach(control => {
    modelActions[setterName(control.destinationName)] = (value: any): void => {
      self[control.destinationName] = value;
    };
  });

  return modelActions;
}

// #endregion

const DataItemSubType = (form: IForm): IModelType<any, any> => {
  const controls = form.allControls;

  return types.model({...getModelProperties(controls)})
    .named('DataItemSubType')
    .actions(self => getModelActions(self, controls));
};

const BaseDataItem = types.model({
  comments: types.optional(types.array(Comment), []),
  attachments: types.optional(types.array(Attachment), []),
  workingPackageId: types.optional(types.maybeNull(types.string), null),
  commentCount: types.optional(types.number, 0),
  attachmentCount: types.optional(types.number, 0),
  status: Status,
  transactions: types.optional(types.array(Transaction), []),
  hasDifferenceBetweenDataCollectionAndQs: types.optional(types.boolean, false),
  hasDifferenceBetweenDataCollectionAndFirstQs: types.optional(types.boolean, false),
  hasResolvedComments: types.optional(types.boolean, false),
  hasUnresolvedComments: types.optional(types.boolean, false),
  artHistory: types.optional(ArtHistory, () => ArtHistory.create()),
  hasPendingAttachments: types.optional(types.boolean, false),
  hasProcessingAttachments: types.optional(types.boolean, false),
  hasSucceededAttachments: types.optional(types.boolean, false),
  hasFailedAttachments: types.optional(types.boolean, false),
})
  .views(self => ({
    get hasWorkingPackage(): boolean{
      return self.workingPackageId !== null;
    },
    get hasAttachments(): boolean{
      return self.attachmentCount > 0;
    },
    get canCompleteStep(): boolean{
      if (!this.hasAttachments) {
        return false;
      }
      if (self.status.isIndexed && !this.hasWorkingPackage) {
        return false;
      }
      return true;
    },
    get pdfAttachments(): IAttachment[]{
      return self.attachments.filter(x => x.isPdf);
    },
    getTransactionsOfProperty(property: string): ITransaction[]{
      return self.transactions.filter(x => x.isOfProperty(property));
    },
    getLatestTransactionOfProperty(property: string): ITransaction | undefined{
      return _.last(this.getTransactionsOfProperty(property));
    },
    getLatestValidationResult(property: string): ValidationResult {
      const transactions = this.getTransactionsOfProperty(property);
      const { latestIndexed } = self.status;
      const {latestDataCollected} = self.status;
      const {latestDataQualityAssured} = self.status;

      if (!latestDataCollected || !latestDataQualityAssured || !latestIndexed) {
        return ValidationResult.Undefined;
      }

      const collectedTransactions = transactions.filter(x => x.createdAt >= latestIndexed.timestamp && x.createdAt <= latestDataCollected.timestamp);
      const qualityAssuredTransactions = transactions.filter(x => x.createdAt >= latestDataCollected.timestamp && x.createdAt <= latestDataQualityAssured.timestamp);

      let value1 = _.last(collectedTransactions)?.value;
      let value2 = _.last(qualityAssuredTransactions)?.value;

      try {
        if (value1) {
          const parsed1 = JSON.parse(value1);
          if (typeof (parsed1) === 'string') {
            value1 = parsed1.trim();
          }
        }
        if (value2) {
          const parsed2 = JSON.parse(value2);
          if (typeof (parsed2) === 'string') {
            value2 = parsed2.trim();
          }
        }
      } catch {
        // ignore for now...
      }

      // if equals, then it is valid
      if (value1 === value2) {
        return ValidationResult.Equals;
      }

      // otherwise we need to check if only one is collected
      if([value1, value2].filter(x => x !== undefined).length === 1) {
        return ValidationResult.Undefined;
      }

      return ValidationResult.Unequals;
    }
  }))
  .actions(self => ({
    addAttachment(attachment: IAttachment): void {
      self.attachmentCount++;
      self.attachments.push(attachment);
    },
    incrementAttachmentCount(): void {
      self.attachmentCount++;
    },
    removeAttachment(attachment: IAttachment): void {
      const attachmentToRemove = self.attachments.find(x => x.id === attachment.id);
      if (attachmentToRemove) {
        self.attachments.remove(attachmentToRemove);
        self.attachmentCount--;
      }
    },
    updateAttachment(attachmentId: string, action: (attachment: IAttachment) => void): void {
      const attachment = self.attachments.find(x => x.id === attachmentId);
      if (attachment) {
        action(attachment)
      }
    },
    setWorkingPackageId(workingPackageId: string | null): void{
      self.workingPackageId = workingPackageId;
    },
    addComment(comment: IComment): void{
      self.commentCount++;
      self.comments.push(comment);
    },
    setTransactions(transactions: ITransactionSnapshotIn[]): void{
      self.transactions.replace(transactions.map(x => Transaction.create(x)));
    }
  }));

const DataItem = (form: IForm): (typeof BaseModel) => types.compose(BaseModel, BaseDataItem, types.model(
  { data: DataItemSubType(form) })
)
  .named('DataItem')
  .actions(self => ({
    initializeFromTransactions(statusStep: StatusStep, showAllTransactions?: boolean): void{
      // the initial transactions have the same createdAt as the data item. For that reason we subtract a second
      const createdAt = moment(self.createdAt).subtract(1, 'seconds').toDate();
      const transactionDateRanges = self.status.getDateRangesOfStatusStep(statusStep, createdAt);

      form.allControls.forEach(control => {
        const showAllTransactionsForThisControl = showAllTransactions || control.formSection !== FormSection.Main;
        const transactions = self.transactions.filter(x => showAllTransactionsForThisControl || transactionDateRanges.find(y => y.isValidFor(x.createdAt)));
        const propertyTransactions = transactions.filter(x => x.isOfProperty(control.destinationName));

        const latestPropertyTransaction = _.maxBy(propertyTransactions, x => x.createdAt);

        if (latestPropertyTransaction?.value) {
          self.data[control.destinationName] = JSON.parse(latestPropertyTransaction.value);
        }
        else if(control.formSection === FormSection.Main && !showAllTransactions) {
          self.data[control.destinationName] = undefined;
        }
      });
    },
    resetToTimestamp(timestamp: Date): void{
      self.status.resetToTimestamp(timestamp);
      self.transactions.replace(self.transactions.filter(x => x.createdAt <= timestamp));
    },
    setPropertyValue(propertyName: string, value: any): void{
      self.data[setterName(propertyName)](value);
    }
  }));

export default DataItem;

export interface IDataItem extends IBaseModel{
  data: any;
  comments: IComment[];
  attachments: IAttachment[];
  workingPackageId: string | undefined;
  hasWorkingPackage: boolean;
  canCompleteStep: boolean;
  status: IStatus;
  hasAttachments: boolean;
  attachmentCount: number;
  transactions: ITransaction[];
  artHistory: IArtHistory;
  pdfAttachments: IAttachment[];
  getTransactionsOfProperty: (property: string) => ITransaction[];
  getLatestValidationResult: (property: string) => ValidationResult;
  addAttachment: (attachment: IAttachment) => void;
  removeAttachment: (attachment: IAttachment) => void;
  updateAttachment: (attachmentId: string, action: (attachment: IAttachment) => void) => void;
  setWorkingPackageId: (workingPackageId: string | null) => void;
  addComment: (comment: IComment) => void;
  incrementAttachmentCount: () => void;
  initializeFromTransactions: (statusStep: StatusStep, showAllTransactions?: boolean) => void;
  setTransactions: (transactions: ITransactionSnapshotIn[]) => void;
  resetToTimestamp: (timestamp: Date) => void;
  setPropertyValue: (propertyName: string, value: any) => void;
}
