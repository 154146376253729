import {
types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import {BaseModel} from '@wemogy/reactbase';
import PersonalUserStatistic from './PersonalUserStatistic';

const UserStatisticSubType = types.model({personalUserStatistics: types.array(PersonalUserStatistic)})
.named('UserStatisticSubType');

const UserStatistic = types.compose(BaseModel, UserStatisticSubType)
  .named('UserStatistic');
export default UserStatistic;

export interface IUserStatistic extends Instance<typeof UserStatistic>{}
export interface IUserStatisticSnapshotIn extends SnapshotIn<typeof UserStatistic>{}
export interface IUserStatisticSnapshotOut extends SnapshotOut<typeof UserStatistic>{}
