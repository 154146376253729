/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Text, Icon
} from '@wemogy/reactbase';
import IStatusLogEntryListItemProps from './IStatusLogEntryListItemProps';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import { formatToShortDateTimeString } from '../../../dateSelector/DateHelpers';
import StatusStep from '../../dataLayer/enums/internal/StatusStep';
import IIcons from '../../../../components/icon/interfaces/IIcons';
import { IStatusLogAssignEntry } from '../../dataLayer/models/status/StatusLogAssignEntry';

const StatusLogEntryListItem: React.FC<IStatusLogEntryListItemProps> = ({
statusLogEntryInformation, isSelected, onPress
}) => {
  const { userStore } = useDataAccessStore();

  const {
    statusLogEntry, statusStep
  } = statusLogEntryInformation;

  let fullname = userStore.getItem(statusLogEntry.issuer)?.fullname || 'unbekannt';

  if(statusLogEntry.issuer === '33333333-3333-3333-3333-333333333333'){
    fullname = 'Automatischer Prozess'
  }

  let status: keyof IIcons = 'addCircle';
  let statusDescription = '';

  switch (statusStep) {
    case StatusStep.Imported:
      status = 'getApp';
      statusDescription = 'Datensatz importiert';
      break;
    case StatusStep.AttachmentsAssigned:
      status = 'postAdd';
      statusDescription = 'Anhang zugeordnet';
      break;
    case StatusStep.Indexed:
      status = 'menuBook';
      statusDescription = 'Indexierung abgeschlossen';
      break;
    case StatusStep.WorkingPackageAssigned:
      status = 'workOutline';
      const unassigned = (statusLogEntry as IStatusLogAssignEntry)?.referenceId === null;
      statusDescription = unassigned ? 'Aus Arbeitspaket entfernt' : 'Arbeitspaket zugeordnet';
      break;
    case StatusStep.DataCollected:
      status = 'edit';
      statusDescription = 'Erfasst';
      break;
    case StatusStep.DataQualityAssured:
      status = 'doneAll';
      statusDescription = 'Qualitätsgesichert';
      break;
    case StatusStep.DataCollectionVerified:
      status = 'colorize';
      statusDescription = 'Stichproben geprüft';
      break;
    case StatusStep.Exported:
      status = 'publish';
      statusDescription = 'Exportiert';
      break;
    default:
      break;
  }

  const statusIcon = {};
  statusIcon[status] = true;

  return (
    <StackLayout horizontal vCenter borderBottom={0.25} border={0} borderColor="green800" padding onPress={onPress} backgroundColor={isSelected ? 'grey500' : undefined}>
      <Icon {...statusIcon} xl marginRight />
      <StackLayout>
        <Text>{`${statusDescription} von ${fullname}`}</Text>
        <Text noTranslation>{formatToShortDateTimeString(statusLogEntry.timestamp)}</Text>
      </StackLayout>
    </StackLayout>
  );
};

export default observer(StatusLogEntryListItem);
