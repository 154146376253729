import React from 'react';
import { observer } from 'mobx-react';
import { RoleManagement, SingleTenantId } from '@wemogy/reactbase-authorization';
import { StackLayout } from '@wemogy/reactbase';
import IAccountManagementScreenProps from './IAccountManagementScreenProps';
import MainLayout from '../../../layouts/mainLayout/MainLayout';
import AccountManagement from '../../../containers/accountManagement/AccountManagement';
import ScrollContainer from '../../../wrappers/scrollContainer/ScrollContainer';
import InvitationManagement from '../../../containers/invitationManagement/InvitationManagement';

const AccountManagementScreen: React.FC<IAccountManagementScreenProps> = () => {

  return (
    <MainLayout>
      <ScrollContainer stretch customMaxHeight="calc(100vh - 70px)">
        <StackLayout horizontal width100>
          <StackLayout stretch marginRight>
            <AccountManagement />
          </StackLayout>
          <StackLayout stretch>
            <RoleManagement tenantId={SingleTenantId} />
            <InvitationManagement />
          </StackLayout>
        </StackLayout>
      </ScrollContainer>
    </MainLayout>
  );
};

export default observer(AccountManagementScreen);
