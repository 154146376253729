import {
  types, Instance, SnapshotIn
} from 'mobx-state-tree';
import { contextMenu } from 'react-contexify';

const contextMenuKeys = types.enumeration([
  'dataItemTableFilter',
  'dataItem',
  'history',
  'attachment',
  'control']);
export type ContextMenuKey = typeof contextMenuKeys.SnapshotType;

interface IVolatileContextMenuStore {
  activeParameters: any | undefined;
}

const ContextMenuStore = types.model({activeContextMenu: types.maybe(contextMenuKeys)})
  .named('ContextMenuStore')
  .volatile(_self => ({activeParameters: undefined} as IVolatileContextMenuStore))
  .views(self => ({
    getActiveParameters<T>(key: ContextMenuKey): T | undefined{
      if(self.activeContextMenu !== key){
        return undefined;
      }
      return self.activeParameters;
    }
  }))
  .actions(self => ({
    openContextMenu(event: MouseEvent | TouchEvent | React.MouseEvent | React.TouchEvent, contextMenuToOpen: ContextMenuKey, parameters?: any): void{
      event.preventDefault();
      event.stopPropagation();
      self.activeParameters = parameters;
      self.activeContextMenu = contextMenuToOpen;
      contextMenu.show({
        id: contextMenuToOpen,
        event
      });

    },
    closeContextMenu(): void {
      self.activeContextMenu = undefined;
      self.activeParameters = undefined;
    }
  }));

export default ContextMenuStore;

export interface IContextMenuStore extends Instance<typeof ContextMenuStore> {}
export interface IContextMenuStoreSnapshot extends SnapshotIn<typeof ContextMenuStore> {}
