import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import TimePeriodStatistics from './TimePeriodStatistics';

const PersonalUserStatistic = types.model({
  userId: types.string,
  numberOfIndexedFields: TimePeriodStatistics,
  numberOfCollectedFields: TimePeriodStatistics,
  numberOfQualityAssuredFields: TimePeriodStatistics,
})
  .named('PersonalUserStatistic');
export default PersonalUserStatistic;

export interface IPersonalUserStatistic extends Instance<typeof PersonalUserStatistic>{}
export interface IPersonalUserStatisticSnapshotIn extends SnapshotIn<typeof PersonalUserStatistic>{}
export interface IPersonalUserStatisticSnapshotOut extends SnapshotOut<typeof PersonalUserStatistic>{}
