import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';

const CustomerMember = types.model({
  userId: types.string,
  roleId: types.string
})
  .named('CustomerMember')
  .actions(self => ({
    setRoleId(roleId: string): void{
      self.roleId = roleId;
    },
  }));
export default CustomerMember;

export interface ICustomerMember extends Instance<typeof CustomerMember>{}
export interface ICustomerMemberSnapshotIn extends SnapshotIn<typeof CustomerMember>{}
export interface ICustomerMemberSnapshotOut extends SnapshotOut<typeof CustomerMember>{}
