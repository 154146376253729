import _ from 'lodash';
import {BlockBlobClient, ContainerClient} from '@azure/storage-blob';
import {AbortController} from '@azure/abort-controller';
import {invokeEventHandler} from '@wemogy/reactbase';
import {CloudStorageUri} from '../../../base/Constants';
import {stringToBase64} from '../../../helpers/StringHelpers';

class AzureBlobStorage {
  public async listContainer(containerNameWithSas: string): Promise<void> {
    const containerClient = new ContainerClient(containerNameWithSas);
    const iterator = containerClient.listBlobsByHierarchy('/');

    // const iterator = containerClient.listBlobsFlat().byPage({ maxPageSize: 2 });
    const response = (await iterator.next()).value;

    let entity = await iterator.next();
    while (!entity.done) {
      const item = entity.value;
      if (item.kind === 'prefix') {
        console.log(`\tBlobPrefix: ${item.name}`);
      } else {
        console.log(
          `\tBlobItem: name - ${item.name}, last modified - ${item.properties.lastModified}`,
        );
      }
      entity = await iterator.next();
    }

    console.log('blobs', response);
  }

  public async upload(
    uploadId: string,
    fileReference: File,
    uploaderId: string,
    uploaderSas: string,
    metadata: any,
    progressCallback?: (percentage: number, secondsRemaining: number) => void,
    successCallback?: () => void,
    failedCallback?: (error: string) => void,
    beforeStartingCallback?: () => void,
  ): Promise<void> {
    invokeEventHandler(beforeStartingCallback);
    const containerName = `upload-${uploaderId}`;
    const ab = new AbortController();

    // all metadata keys should be lowercase!!
    // ensure that metadata values not contain invalid characters
    // to fix this we encode them Base64
    // suggested in https://stackoverflow.com/questions/14899461/invalid-character-exception-when-adding-metadata-to-a-cloudblob
    Object.keys(metadata).forEach((metadataKey) => {
      metadata[metadataKey.toLowerCase()] = stringToBase64(
        metadata[metadataKey],
      );
    });

    const begin = new Date().getTime();
    new BlockBlobClient(
      `${CloudStorageUri}${containerName}/${uploadId}?${uploaderSas}`,
    )
      .uploadBrowserData(fileReference, {
        abortSignal: ab.signal,
        onProgress: ({loadedBytes}): void => {
          const percentage = loadedBytes / fileReference.size;
          const roundedPercentage = _.round(percentage * 100);
          const timeSpent = new Date().getTime() - begin;
          const secondsRemaining = Math.round(
            (timeSpent / percentage - timeSpent) / 1000,
          );
          invokeEventHandler(
            progressCallback,
            roundedPercentage,
            secondsRemaining,
          );
        },
        metadata,
      })
      .then(({errorCode}): void => {
        if (errorCode) {
          invokeEventHandler(failedCallback, errorCode);
          return;
        }
        invokeEventHandler(successCallback);
      })
      .catch((reason): void => {
        invokeEventHandler(failedCallback, reason);
      });
  }
}

export default new AzureBlobStorage();
