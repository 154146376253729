import React from 'react';
import { observer } from 'mobx-react';
import { TextInput, Text } from '@wemogy/reactbase';
import IAnnotatedTextInputProps from './IAnnotatedTextInputProps';
import AnnotatedFormView from '../annotatedFormView/AnnotatedFormView';

const AnnotatedTextInput: React.FC<IAnnotatedTextInputProps> = ({
  description, placeholder, value, marginBottom, readonly, multiline, numeric, onChange
}) => {

  return (
    <AnnotatedFormView description={description} marginBottom={marginBottom}>
      {readonly ? (
        <Text>{value}</Text>
      ): (
        <TextInput numeric={numeric} placeholder={placeholder} multiline={multiline} onChange={onChange} value={value} />
      )}
    </AnnotatedFormView>
  );
};

export default observer(AnnotatedTextInput);
