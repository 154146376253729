import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  StackLayout, Modal, useModalStore
} from '@wemogy/reactbase';
import IAddWorkingPackageModalProps from './IAddWorkingPackageModalProps';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import WorkingPackage from '../../dataLayer/models/WorkingPackage';
import WorkingPackageForm from '../../components/workingPackageForm/WorkingPackageForm';
import NotificationManager from '../../../inAppFeedback/NotificationManager';

const AddWorkingPackageModal: React.FC<IAddWorkingPackageModalProps> = () => {
  const {formStore} = useDataAccessStore();
  const modalStore = useModalStore();
  const [newWorkingPackage, setNewWorkingPackage] = useState(WorkingPackage.create({}));

  if(!formStore.activeItem){
    return null;
  }

  return (
    <Modal
      modalKey="addWorkingPackage"
      icon="add"
      title="Arbeitspaket hinzufügen"
      large
      onClosed={(): void => {
      setNewWorkingPackage(WorkingPackage.create({}));
    }}
    >
      <StackLayout>
        <WorkingPackageForm
          onFinished={(): void => {
            if (formStore.activeItem?.workingPackages.find(x => x.name === newWorkingPackage.name)) {
              NotificationManager.error(`Es existiert bereits ein Arbeitspaket mit dem Namen ${newWorkingPackage.name}`)
              return;
            }
            formStore.activeItem?.addWorkingPackage(newWorkingPackage);


            if (!formStore.activeItem) {
              return;
            }
            modalStore.openModal('loadingOverlay', 'Datenbank wird gespeichert');
            try {
              formStore.put(formStore.activeItem).then((apiResult): void => {
                if (apiResult.ok) {
                  NotificationManager.success('Erfolgreich gespeichert.');
                } else {
                  NotificationManager.error(`Es ist ein Fehler aufgetreten: ${apiResult.status}`);
                }

                modalStore.openModal('manageWorkingPackages');
              }).catch((reason): void => {
                modalStore.closeModal();
                NotificationManager.error(`Es ist ein Fehler aufgetreten: CATCH Promise -> ${reason ? JSON.stringify(reason) : 'No Reason'}`);
              });
            } catch (error) {
              modalStore.closeModal();
              NotificationManager.error('Es ist ein Fehler aufgetreten: CATCH');
            }
            finally {
              // reset new working package
              setNewWorkingPackage(WorkingPackage.create({}));
            }
          }}
          workingPackage={newWorkingPackage}
          addMode
        />
      </StackLayout>
    </Modal>
  );
};

export default observer(AddWorkingPackageModal);
