import React from 'react';
import { observer } from 'mobx-react';
import { Modal, useModalStore } from '@wemogy/reactbase';
import IAddProcessingTemplateModalProps from './IAddProcessingTemplateModalProps';
import NotificationManager from '../../wrappers/inAppFeedback/NotificationManager';
import useDataAccessStore from '../../../dataLayer/stores/hooks/UseDataAccessStore';
import { IProcessingTemplate } from '../../wrappers/formBuilder/dataLayer/models/ProcessingTemplate';
import ProcessingTemplateForm from '../../wrappers/formBuilder/components/processingTemplateForm/ProcessingTemplateForm';

const AddProcessingTemplateModal: React.FC<IAddProcessingTemplateModalProps> = () => {
  const modalStore = useModalStore();
  const { processingTemplateStore } = useDataAccessStore();

  const editProcessingTemplate = modalStore.getActiveParameters<IProcessingTemplate>('addProcessingTemplate');

  return (
    <Modal
      modalKey="addProcessingTemplate"
      icon="add"
      title={editProcessingTemplate ? 'Optimierungs-Vorlage bearbeiten' : 'Optimierungs-Vorlage hinzufügen'}
      large
    >
      <ProcessingTemplateForm
        processingTemplate={editProcessingTemplate}
        onCreated={(processingTemplate): void => {
          modalStore.openModal('loadingOverlay');
          processingTemplateStore.create(processingTemplate)
            .then((apiResult): void => {
              if (apiResult.ok) {
                NotificationManager.success(`Die Optimierungs-Vorlage ${processingTemplate.name} wurde angelegt.`);
              } else {
                NotificationManager.error(`Beim Anlegen der Optimierungs-Vorlage ${processingTemplate.name} ist ein Fehler aufgetreten.`);
              }
              modalStore.closeModal();
            });

        }}
        onUpdated={(processingTemplate, patch): void => {
          if (!patch) {
            return;
          }
          processingTemplateStore.patch(processingTemplate.id, patch).then((apiResult): void => {
            if (apiResult.ok) {
              NotificationManager.success(`Die Optimierungs-Vorlage ${processingTemplate.name} wurde aktualisiert.`);
            } else {
              NotificationManager.error(`Beim Aktualisieren der Optimierungs-Vorlage ${processingTemplate.name} ist ein Fehler aufgetreten.`);
            }
          }).finally((): void => {
            modalStore.closeModal();
          });
        }}
      />
    </Modal>
  );
};

export default observer(AddProcessingTemplateModal);
