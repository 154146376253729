import React from 'react';
import { observer } from 'mobx-react';
import { StackLayout} from '@wemogy/reactbase';
import IMainLayoutProps from './IMainLayoutProps';
import MainHeader from '../../containers/layoutHeaders/mainHeader/MainHeader';
import BackupsModal from '../../modals/backupsModal/BackupsModal';
import CreateBackupModal from '../../modals/createBackupModal/CreateBackupModal'

const MainLayout: React.FC<IMainLayoutProps> = ({
  children, navigation
}) => {

  return (
    <StackLayout stretch>
      <MainHeader navigation={navigation} />
      <StackLayout stretch padding>
        {children}
      </StackLayout>
      <BackupsModal />
      <CreateBackupModal />
    </StackLayout>
  );
};

export default observer(MainLayout);
