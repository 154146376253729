import React from 'react';
import { observer } from 'mobx-react';
import { StackLayout } from '@wemogy/reactbase';
import IUnknownPreviewProps from './IUnknownPreviewProps';

const UnknownPreview: React.FC<IUnknownPreviewProps> = ({
  width, onPress
}) => {

  return (
    <StackLayout onPress={onPress} width100 height={{custom: 'calc(100% - 24px)'}} vCenter hCenter>
      <img
        src="/img/File_Question-512-Kopie.png"
        alt="unknown"
        style={{width}}
      />
    </StackLayout>

  );
};

export default observer(UnknownPreview);
