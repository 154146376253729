import React from 'react';
import { observer } from 'mobx-react';
import IAllLayoutsProps from './IAllLayoutsProps';
import ToolboxElementList from '../../toolboxElementList/ToolboxElementList';
import ToolboxElementType from '../../../dataLayer/uiEnums/ToolboxElementType';
import TableConfig from '../../../dataLayer/models/layoutConfigs/TableConfig';
import AccordionConfig from '../../../dataLayer/models/layoutConfigs/AccordionConfig';
import LayoutType from '../../../dataLayer/enums/LayoutType';

const AllLayouts: React.FC<IAllLayoutsProps> = () => {

  return (
    <ToolboxElementList
      type={ToolboxElementType.Layout}
      elements={[
        {
          key: LayoutType.AnnotatedView,
          label: 'Eingabe mit Beschriftung',
          icon: 'powerInput'
        },
        {
          key: LayoutType.AnnotatedBlock,
          label: 'Box',
          icon: 'lineStyle'
        },
        {
          key: LayoutType.Table,
          label: 'Tabelle',
          icon: 'tableChart',
          config: {numberOfControlColumns: 2} as TableConfig
        },
        {
          key: LayoutType.Accordion,
          label: 'Akkordeon',
          icon: 'horizontalSplit',
          config: new AccordionConfig()
        }
      ]}
      onElementSelected={(element): void => {
        console.log(element);
      }}
    />
  );
};

export default observer(AllLayouts);
