import React from 'react';
import { observer } from 'mobx-react';
import {
  Button, StackLayout, TextInput, Modal, useModalStore
} from '@wemogy/reactbase';
import IEditCustomerModalProps from './IEditCustomerModalProps';
import AnnotatedFormView from '../../containers/formElements/annotatedFormView/AnnotatedFormView';
import useDataAccessStore from '../../../../../dataLayer/stores/hooks/UseDataAccessStore';
import MarkerCode from '../../components/markerCode/MarkerCode';
import IAddCustomerMarkerModalParameters from '../addCustomerMarkerCodeModal/interface/IAddCustomerMarkerModalParameters';
import { confirmDialogYesNoDanger } from '../../../dialogs/DialogManager';
import RestApiServiceFactory from '../../../../../dataLayer/api/rest/RestApiServiceFactory';
import NotificationManager from '../../../inAppFeedback/NotificationManager';
import useCustomerPermissionFeature from '../../../../plugins/authorization/hooks/UseCustomerPermissionFeature';
import CustomerMemberManagement from '../../../../plugins/authorization/memberManagements/CustomerMemberManagement';

const EditCustomerModal: React.FC<IEditCustomerModalProps> = () => {
  const { customerStore } = useDataAccessStore();
  const modalStore = useModalStore();
  const {hasCustomerPermission} = useCustomerPermissionFeature();

  const customerId = modalStore.getActiveParameters<string>('editCustomer');

  const customer = customerId ? customerStore.getItem(customerId) : undefined;

  if (!customer) {
    return null;
  }

  return (
    <Modal modalKey="editCustomer" icon="edit" title={`${customer.companyName} verwalten`} large>
      <AnnotatedFormView description="Kundenname">
        <TextInput
          disabled={!hasCustomerPermission(customer.id, 'CanRename')}
          value={customer.companyName}
          placeholder="Wie möchten Sie den Kunden nennen?"
          onFocus={(): void => {
            customer.beginEditing();
          }}
          onChange={(newValue): void => {
            customer.setCompanyName(newValue)
          }}
          onBlur={(): void => {
            const patch = customer.commitChanges();
            if (!patch) {
              return;
            }
            customerStore.patch(customer.id, patch).then(x => {
              if (x.ok) {
                NotificationManager.success('Der Name wurde erfolgreich geändert')
              }
            });
          }}
        />
      </AnnotatedFormView>
      <AnnotatedFormView description="Benutzer">
        <StackLayout height={40}>
          <CustomerMemberManagement resourceId={customer.id} />
        </StackLayout>

      </AnnotatedFormView>
      <StackLayout marginTopBottom />
      <AnnotatedFormView description="Marker Codes">
        <StackLayout>
          {
            customer.markerCodes.map(x => (
              <MarkerCode
                key={x.id}
                markerCode={x}
                onEdit={(markerCode): void => {
                  modalStore.openModal('addCustomerMarkerCode', {
                    editMarker: markerCode,
                    customer
                  } as IAddCustomerMarkerModalParameters);
                }}
                onDelete={(): void => {
                  confirmDialogYesNoDanger(`Wollen Sie den Marker Code ${x.name} wirklich löschen?`, (): void => {
                    modalStore.openModal('loadingOverlay');
                    RestApiServiceFactory.customerService.deleteMarkerCode(x.id)
                      .then((apiResult): void => {
                        if (apiResult.ok) {
                          NotificationManager.success('Der Maker Code wurde gelöscht');
                          customer.deleteMarkerCode(x);
                        } else {
                          NotificationManager.error('Beim Löschen des Marker Codes ist ein Fehler aufgetreten.');
                        }
                      })
                      .finally((): void => {
                        modalStore.openModal('editCustomer', customer.id);
                      });
                  });
                }}
              />
            ))
          }
          <Button onPress={(): void => {
            modalStore.openModal('addCustomerMarkerCode', { customer } as IAddCustomerMarkerModalParameters);
          }}
          >
            Neuen Marker Code hinzufügen

          </Button>
        </StackLayout>
      </AnnotatedFormView>
    </Modal>
  );
};

export default observer(EditCustomerModal);
