import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';

const ControlQrCodeSource =  types.model({
  id: types.string,
  valueIndex: types.optional(types.number, 1)
})
  .named('ControlQrCodeSource')
  .actions(self => ({
    setValueIndex(valueIndex: number): void{
      self.valueIndex = valueIndex;
    }
  }));
export default ControlQrCodeSource;

export interface IControlQrCodeSource extends Instance<typeof ControlQrCodeSource>{}
export interface IControlQrCodeSourceSnapshotIn extends SnapshotIn<typeof ControlQrCodeSource>{}
export interface IControlQrCodeSourceSnapshotOut extends SnapshotOut<typeof ControlQrCodeSource>{}
