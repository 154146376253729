import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import { ArtStatusType } from '../../enums/art/ArtStatus';

const ArtStatusLog = types.model({
  issuerId: types.string,
  timestamp: types.Date,
  status: ArtStatusType
})
  .named('ArtStatusLog');

export default ArtStatusLog;

export interface IArtStatusLog extends Instance<typeof ArtStatusLog>{}
export interface IArtStatusLogSnapshotIn extends SnapshotIn<typeof ArtStatusLog>{}
export interface IArtStatusLogSnapshotOut extends SnapshotOut<typeof ArtStatusLog>{}
