import {
  types, Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import QrCodeMapping from './QrCodeMapping';

const PdfMatchingConfig = types.model({
  qrCodeSourceId: types.string,
  qrCodeMappings: types.optional(types.array(QrCodeMapping), []),
})
  .named('PdfMatchingConfig')
  .views(self => ({
    get hasQrCodeMappings(): boolean {
      return self.qrCodeMappings.length > 0;
    }
  }))
  .actions(self => ({
    setPdfMatchingQrCodeSourceId(pdfMatchingQrCodeSourceId: string): void {
      self.qrCodeSourceId = pdfMatchingQrCodeSourceId;
    },
    addQrCodeMapping(destinationName: string): void {
      self.qrCodeMappings.push(QrCodeMapping.create({
        destinationName,
        valueIndex: 0
      }));
    }
  }));

export default PdfMatchingConfig;

export interface IPdfMatchingConfig extends Instance<typeof PdfMatchingConfig>{}
export interface IPdfMatchingConfigSnapshotIn extends SnapshotIn<typeof PdfMatchingConfig>{}
export interface IPdfMatchingConfigSnapshotOut extends SnapshotOut<typeof PdfMatchingConfig>{}
