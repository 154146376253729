import {
  Instance, SnapshotIn, SnapshotOut
} from 'mobx-state-tree';
import { createBaseDataAccessStore, QueryParameters} from '@wemogy/reactbase';
import Customer from '../../models/Customer';
import RestApiServiceFactory from '../../api/rest/RestApiServiceFactory';
import CustomerDtoMapper from '../../api/dtoMappers/CustomerDtoMapper';

const CustomerStore = createBaseDataAccessStore(
  Customer,
  RestApiServiceFactory.customerService,
  new CustomerDtoMapper(),
  QueryParameters.create(),
);

export interface ICustomerStore extends Instance<typeof CustomerStore>{}
export interface ICustomerStoreSnapshotIn extends SnapshotIn<typeof CustomerStore>{}
export interface ICustomerStoreSnapshotOut extends SnapshotOut<typeof CustomerStore>{}

export default CustomerStore;
