enum GlobalRole{
  /// <summary>
  /// Can manage permissions on global, customer, form and working package level
  /// </summary>
  CanManagePermissions = 1,
  CanCreateRole = 2,
  CanUpdateRole = 3,
  CanDeleteRole = 4,
  CanManageProcessingTemplates = 5,
}

export default GlobalRole;
